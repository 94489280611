import { format } from 'date-fns';

const getReadableDateTimeShort = (timeAt: string) => {
  try {
    return format(new Date(timeAt), "M/d/yyyy',' h:mm a");
  } catch (e) {
    console.warn('getReadableDateTime', e);
  }

  return '';
};

export { getReadableDateTimeShort };
