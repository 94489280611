import { ClientWorker } from '@/clientWorkers';

export class ClientWorkerAuthToken extends ClientWorker {
  protected lastToken: string = '';
  protected timeoutID: number = 0;

  public get currentStafferToken(): string {
    return localStorage.getItem('token') || '';
  }

  public onReady(): void {
    this.registerStorageEvent();
    this.registerUnloadEvent();
    this.setToken();
    this.retry();
  }

  public onMessage({ data = {} }: MessageEvent) {
    const { type = '', payload = {} } = data;

    switch (type) {
      case 'Poll::Set':
        if (payload?.state === 'success') {
          this.stopRetry();
        }
        break;
      case 'Poll::Start':
      case 'Poll::State':
      case 'Poll::Sync':
        this.updateToke();
        break;
    }
  }

  public setToken(): void {
    this.postMessage({ type: 'Poll::Set', payload: { currentStafferToken: this.currentStafferToken } });
  }

  public updateToke(): void {
    if (this.lastToken === this.currentStafferToken) {
      return;
    }

    this.lastToken = this.currentStafferToken;
    this.setToken();
  }

  protected registerStorageEvent(): void {
    if (!window?.addEventListener) {
      console.error('ClientWorkerAuthToken: Register "Storage" event failed');
      return;
    }

    window?.addEventListener('storage', (event: StorageEvent): void => {
      if (event.key === 'token' && event.newValue !== event.oldValue) {
        this.setToken();
      }
    });
  }

  protected registerUnloadEvent(): void {
    window?.addEventListener('unload', () => this.stopRetry());
  }

  protected retry() {
    this.stopRetry();
    this.timeoutID = window.setTimeout(() => {
      this.setToken();
      this.retry();
    }, 1000);
  }

  protected stopRetry(): void {
    window.clearTimeout(this.timeoutID);
    this.timeoutID = 0;
  }
}
