
import { SubscriptionPlansCardProps } from './props';
import type { SubscriptionPlansCardData } from './types';
import { PatientSubscriptionPlansQuery } from './gql';

export default SubscriptionPlansCardProps.extend({
  name: 'SubscriptionPlansCard',
  apollo: {
    ...PatientSubscriptionPlansQuery,
  },
  props: {
    requestedSubscriptionPlan: { type: String, default: '', required: false },
  },
  data(): SubscriptionPlansCardData {
    return {
      patientSubscriptionPlans: [],
      isLoading: true,
    };
  },
  computed: {
    isEmpty(): boolean {
      return !this.patientSubscriptionPlans.length && !this.requestedSubscriptionPlan;
    },
    title(): string {
      if (this.isLoading) {
        return 'Loading subscription plans...';
      }

      return 'Subscription plans';
    },
    startCollapsed(): boolean {
      return this.isLoading || this.isEmpty;
    },
    collapsibleCardProps(): Record<string, any> {
      return {
        icon: 'file-contract',
        title: this.title,
        placeholder: 'No subscription plans',
        isEmpty: this.isEmpty,
        startCollapsed: this.startCollapsed,
      };
    },
  },
});
