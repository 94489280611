
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  ref,
  toRef,
  watch,
} from 'vue';

import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { Builder } from 'builder-pattern';

import type { BFormInput } from 'bootstrap-vue/src/components/form-input';
import { promiseTimeout, refDebounced } from '@vueuse/core';

import { AttachmentModel } from '@/components/AttachFile/models';

import {
  CHAT_ACTION_LIMIT,
  ChatModel,
  ChatsCacheService,
  ClientModel,
  ClientsCacheService,
  formatSmsText,
  getComposerFooterText,
  getShortMessageTextBody,
  MessageModel,
  MessagesCacheService,
  RepliesCacheService,
  ReplyModel,
  SendMessageRequest,
  stripHtml,
  UpdateNoteRequest,
  useMessaging,
  useMessagingApi,
  useMessagingReplySuggestions,
  useMessagingShortcuts,
  useMessagingStore,
} from '~/messaging';

import { showError } from '@/components/errors';

import { useRoot } from '@/composables/atoms/useRoot';

import { getHtml, setHtml, setText } from '@/components/Editor/helpers';
import { EditorInstancesService } from '@/components/Editor/services';

import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import { MessageChannelEnum } from '@/types';

const ChatComposer = defineComponent({
  setup() {
    const root = useRoot();

    const { onShortcut, emitShortcut, offShortcut } = useMessagingShortcuts();

    const vm = getCurrentInstance?.()?.proxy;

    const attachments = ref<AttachmentModel[]>([]);
    const textAreaElement = ref<InstanceType<typeof BFormInput>>(null);

    const { addOrUpdateChat } = useMessaging();
    const { sendMessage: sendChatMessage, updateNote } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const timestamp = toRef(messagingStore, 'timestamp');
    const activeChat = toRef(messagingStore, 'activeChat');
    const currentClient = toRef(messagingStore, 'currentClient');
    const currentChannel = toRef(messagingStore, 'currentChannel');
    const currentChatLatestMessage = toRef(messagingStore, 'currentChatLatestMessage');
    const currentChatLatestClientMessage = toRef(messagingStore, 'currentChatLatestClientMessage');
    const currentDraftMessage = toRef(messagingStore, 'currentDraftMessage');
    const currentMessageSubject = toRef(messagingStore, 'currentMessageSubject');
    const currentReplyToMessage = toRef(messagingStore, 'currentReplyToMessage');
    const currentEditMessage = toRef(messagingStore, 'currentEditMessage');
    const hasPendingActions = toRef(messagingStore, 'hasPendingActions');

    const {
      setDraft,
      setCurrentChannel,
      setCurrentDraft,
      setCurrentSubject,
      setCurrentReplyToMessage,
      setCurrentEditMessage,
      increaseChatActions,
      decreaseChatActions,
      updateSubject,
    } = messagingStore;

    const { currentMessageReplySuggestion } = useMessagingReplySuggestions(activeChat);

    const sending = ref<number>(0);
    const replySuggestionPage = ref<number>(0);

    const debouncedCurrentDraftMessage = refDebounced(currentDraftMessage, 600);

    const client = computed((): ClientModel => ClientsCacheService.get(currentClient.value) || new ClientModel());
    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    // this also disables toggle
    const sendMessageDisabled = computed(
      (): boolean => !!attachments.value.filter((attachment: AttachmentModel) => !attachment?.uploaded).length
    );

    const isChannelEmailOrNoteOrAction = computed((): boolean =>
      [MessageChannelEnum.NOTE, MessageChannelEnum.ACTION, MessageChannelEnum.EMAIL].includes(currentChannel.value)
    );

    const currentReplySuggestions = computed((): ReplyModel[] =>
      RepliesCacheService.getSuggestions(debouncedCurrentDraftMessage.value)
    );

    const currentReplySuggestion = computed(
      (): ReplyModel => currentReplySuggestions.value?.[replySuggestionPage.value] || new ReplyModel()
    );

    const patientTime = computed((): Date | '' => {
      if (!client.value.state) {
        return '';
      }

      try {
        return toZonedTime(new Date(timestamp.value), client.value.timezone);
      } catch (error: unknown) {
        console.error('patientTime error:', error);
        return '';
      }
    });

    const isNightForPatient = computed((): boolean => {
      if (!(patientTime.value instanceof Date)) {
        return false;
      }

      // more than from 0 till 8 is nigh time
      return patientTime.value.getHours() < 8;
    });

    const isSmsAndEmailChannelsAvailable = computed(
      (): boolean => !client.value.unsubscribedEmail && !client.value.unsubscribedSms
    );

    const timezoneText = computed((): string => {
      if (!client.value.state.length) {
        return '';
      }

      if (!(patientTime.value instanceof Date)) {
        return `Patient from ${client.value.state}`;
      }

      try {
        const time: string = format(patientTime.value, 'p');
        if (!time) {
          return `Patient from ${client.value.state}`;
        }

        return `Its ${time} in ${client.value.state}`;
      } catch (error: unknown) {
        console.error('timezoneText error:', error);
        return '';
      }
    });

    const buttonText = computed((): string => {
      if (currentEditMessage.value.length) {
        return 'Save message';
      }

      switch (currentChannel.value) {
        case MessageChannelEnum.ACTION:
          return 'Add action';
        case MessageChannelEnum.NOTE:
          return 'Add note';
        case MessageChannelEnum.SMS:
          return `Send SMS${client.value.unsubscribedSms ? ' ❌' : ''}`;
        case MessageChannelEnum.EMAIL_AND_SMS:
          return 'Send Email & SMS';
        case MessageChannelEnum.EMAIL:
          return `Send email${client.value.unsubscribedEmail ? ' ❌' : ''}`;
        case MessageChannelEnum.INAPP:
          return 'Send in chat';
        default:
          return 'Send ' + currentChannel.value;
      }
    });

    const footerText = computed((): string => {
      if (!sending.value) {
        return getComposerFooterText(currentDraftMessage.value, currentChannel.value);
      }

      if (currentEditMessage.value.length && sending.value) {
        return 'Saving message...';
      }

      return 'Sending message...';
    });

    const textAreaPlaceholder = computed((): string => {
      if (currentMessageReplySuggestion.value.length) {
        return `Press TAB to insert suggestion: ${getShortMessageTextBody(currentMessageReplySuggestion.value)}`;
      }

      const firstName: string = client.value.firstName || 'patient';
      const firstNameCharUpperCase: string = client.value.firstName || 'Patient';

      switch (currentChannel.value) {
        case MessageChannelEnum.ACTION:
          return `Type action for ${firstName} here`;
        case MessageChannelEnum.NOTE:
          return `Type note for ${firstName} here`;
        case MessageChannelEnum.SMS:
          if (client.value.unsubscribedSms) {
            return `${firstNameCharUpperCase} has unsubscribed from SMS. To resume sending messages to the patient, please call ${firstName} and kindly ask them to send 'START' via SMS to us.`;
          }

          return `Type SMS for ${client.value.firstName} here`;
        case MessageChannelEnum.EMAIL:
          if (client.value.unsubscribedEmail) {
            return `${firstNameCharUpperCase} has unsubscribed from email(s). To resume sending messages to the patient, please call ${firstName} and kindly ask them to send any message to us via email.`;
          }

          return `Please type the email body for ${firstName} here`;
        case MessageChannelEnum.EMAIL_AND_SMS:
          return `Please type the email and SMS body for ${firstName} here`;
        case MessageChannelEnum.INAPP:
          return `Type chat message for ${firstName} here`;
        default:
          return `Type ${currentChannel.value} message for ${firstName} here`;
      }
    });

    const footerColor = computed((): Partial<CSSStyleDeclaration> => {
      switch (currentChannel.value) {
        case MessageChannelEnum.ACTION:
          return { backgroundColor: '#D6FFE7' };
        case MessageChannelEnum.NOTE:
          return { backgroundColor: '#FCF2ED' };
        case MessageChannelEnum.SMS:
          return { backgroundColor: '#E4FFF0' };
        case MessageChannelEnum.EMAIL:
          return { backgroundColor: '#E9F7FF' };
        case MessageChannelEnum.EMAIL_AND_SMS:
          return { backgroundColor: '#FFFFFF' };
        default:
          return { backgroundColor: '#FFFFFF' };
      }
    });

    const dropdownVariant = computed((): 'light' | 'warning' | 'success' | 'primary' | 'info' => {
      switch (currentChannel.value) {
        case MessageChannelEnum.ACTION:
          return 'light';
        case MessageChannelEnum.NOTE:
          return 'warning';
        case MessageChannelEnum.SMS:
          return 'success';
        case MessageChannelEnum.EMAIL_AND_SMS:
          return 'light';
        case MessageChannelEnum.EMAIL:
          return 'primary';
        default:
          return 'light';
      }
    });

    const splitClasses = computed((): string => {
      let classes: string = 'border-right-0 border-top-0 border-bottom-0 rounded-0';
      if (currentChannel.value === MessageChannelEnum.NOTE) {
        classes += ' ChatComposerWrapper__button--yellow';
      }

      if (currentChannel.value === MessageChannelEnum.ACTION) {
        classes += ' ChatComposerWrapper__button--green';
      }

      return classes;
    });

    const toggleClasses = computed((): string => {
      let classes: string = 'border-right-0 border-top-0 border-bottom-0 rounded-0 border-white';
      if (currentChannel.value === MessageChannelEnum.NOTE) {
        classes += ' ChatComposerWrapper__button--yellow';
      }

      if (currentChannel.value === MessageChannelEnum.ACTION) {
        classes += ' ChatComposerWrapper__button--green';
      }

      return classes;
    });

    const sendMessage = async (): Promise<void | true> => {
      if (isChannelEmailOrNoteOrAction.value) {
        await saveCurrentEditorDraft();
      }

      if (!currentDraftMessage.value.trim().length) {
        showError(vm, 'Attempted to send empty message.');
        return;
      }

      if (currentChannel.value === MessageChannelEnum.NOTE && currentEditMessage.value.length) {
        await updateMessage();
        return;
      }

      if (currentChannel.value === MessageChannelEnum.ACTION && currentDraftMessage.value.trim().length > 255) {
        showError(vm, 'Action text is too long, we dont support long actions for now. Max 255 characters.');
        return;
      }

      if (client.value.unsubscribedSms && currentChannel.value === MessageChannelEnum.SMS) {
        showError(vm, `${currentChat.value.displayNameShort} is unsubscribed from SMS.`);
        return;
      }

      if (client.value.unsubscribedEmail && currentChannel.value === MessageChannelEnum.EMAIL) {
        showError(vm, `${currentChat.value.displayNameShort} is unsubscribed from email.`);
        return;
      }

      if (
        client.value.unsubscribedEmail &&
        client.value.unsubscribedSms &&
        currentChannel.value === MessageChannelEnum.EMAIL_AND_SMS
      ) {
        showError(vm, `${currentChat.value.displayNameShort} is unsubscribed from SMS & email.`);
        return;
      }

      // copy chat properly handle loading state
      const { id, key, updatedAt, displayName }: ChatModel = currentChat.value;
      if (!key) {
        console.error('sendMessage chatModel not found', activeChat.value);
        return;
      }

      sending.value++;
      increaseChatActions(id);

      const replyTo: string | undefined =
        [MessageChannelEnum.EMAIL, MessageChannelEnum.EMAIL_AND_SMS].includes(currentChannel.value) &&
        currentReplyToMessage.value
          ? currentReplyToMessage.value
          : undefined;

      const subject: string | undefined =
        [MessageChannelEnum.EMAIL, MessageChannelEnum.EMAIL_AND_SMS].includes(currentChannel.value) && !replyTo
          ? currentMessageSubject.value
          : undefined;

      const attachmentIds: string[] = attachments.value
        .filter((attachment: AttachmentModel) => attachment?.uploaded)
        .map((attachment: AttachmentModel) => attachment?.id);

      const request: SendMessageRequest = Builder<SendMessageRequest>()
        .chatId(id)
        .limit(CHAT_ACTION_LIMIT)
        .onlyMessagesAfter(updatedAt)
        .channels([currentChannel.value])
        .message(currentDraftMessage.value)
        .replyTo(replyTo || undefined)
        .subject(subject || undefined)
        .attachmentIds(attachmentIds.length ? attachmentIds : undefined)
        .build();

      setCurrentDraft('');
      if (isChannelEmailOrNoteOrAction.value) {
        setCurrentSubject('');
        setCurrentReplyToMessage('');
        EditorInstancesService.get(currentChat.value.id)?.clear();
        attachments.value = [];
        setCurrentEditMessage('');
      }

      if (currentChannel.value === MessageChannelEnum.EMAIL_AND_SMS) {
        setCurrentSubject('');
      }

      focusOnActiveEditor();

      try {
        if (currentChannel.value === MessageChannelEnum.EMAIL_AND_SMS) {
          const result = await Promise.all([
            await sendChatMessage(
              Builder<SendMessageRequest>(request)
                .channels([MessageChannelEnum.EMAIL])
                .message(formatSmsText(request.message))
                .build()
            ),
            await sendChatMessage(Builder<SendMessageRequest>(request).channels([MessageChannelEnum.SMS]).build()),
          ]);

          if (result[1]) {
            addOrUpdateChat(result[1]);
          }

          return true;
        }

        addOrUpdateChat(await sendChatMessage(request));

        return true;
      } catch (error: unknown) {
        showError(vm, `Failed to send message to ${displayName}. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        decreaseChatActions(id);
        sending.value--;
      }
    };

    const updateMessage = async (): Promise<void> => {
      // copy chat id to properly handle loading state
      const { id, key, updatedAt, displayName }: ChatModel = currentChat.value;
      if (!key) {
        console.error('updateMessage chatModel not found', activeChat.value);
        return;
      }

      sending.value++;
      increaseChatActions(id);

      const attachmentIds: string[] = attachments.value
        .filter((attachment: AttachmentModel) => attachment?.uploaded)
        .map((attachment: AttachmentModel) => attachment?.id);

      const request: UpdateNoteRequest = Builder<UpdateNoteRequest>()
        .limit(CHAT_ACTION_LIMIT)
        .content(currentDraftMessage.value)
        .attachmentIds(attachmentIds)
        .messagingNoteId(currentEditMessage.value)
        .onlyMessagesAfter(updatedAt)
        .build();

      setCurrentDraft('');
      if (isChannelEmailOrNoteOrAction.value) {
        setCurrentSubject('');
        setCurrentReplyToMessage('');
        EditorInstancesService.get(currentChat.value.id)?.clear();
        attachments.value = [];
        setCurrentEditMessage('');
      }

      focusOnActiveEditor();

      try {
        addOrUpdateChat(await updateNote(request));
      } catch (error) {
        showError(
          vm,
          `Failed to update note in conversation with ${displayName}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        decreaseChatActions(id);
        sending.value--;
      }
    };

    const saveDraft = async (): Promise<void> => {
      if (isChannelEmailOrNoteOrAction.value) {
        setDraft(currentChat.value.key, currentChannel.value, await getHtml(currentChat.value.id));
        return;
      }
    };

    const saveCurrentEditorDraft = async (): Promise<void> => {
      setDraft(currentChat.value.key, currentChannel.value, await getHtml(currentChat.value.id));
    };

    const focusOnActiveEditor = (): void => {
      if (isChannelEmailOrNoteOrAction.value) {
        EditorInstancesService.get(currentChat.value.id)?.focus?.(true);
        return;
      }

      nextTick(() => textAreaElement.value?.focus?.());
    };

    const updateChannel = async (newChannel: MessageChannelEnum = MessageChannelEnum.EMAIL): Promise<void> => {
      if (newChannel === currentChannel.value) {
        focusOnActiveEditor();
        return;
      }

      if (currentEditMessage.value.length) {
        setCurrentEditMessage('');
      }

      if (isChannelEmailOrNoteOrAction.value) {
        await saveCurrentEditorDraft();

        if ([MessageChannelEnum.NOTE, MessageChannelEnum.ACTION, MessageChannelEnum.EMAIL].includes(newChannel)) {
          setCurrentChannel(newChannel);
          focusOnActiveEditor();
          return;
        }
      }

      if (newChannel === MessageChannelEnum.EMAIL && currentDraftMessage.value.length) {
        // we want to move current draft into new editor, reset other states
        setCurrentSubject('');
        setCurrentReplyToMessage('');
        attachments.value = [];
      }

      if ([MessageChannelEnum.NOTE, MessageChannelEnum.ACTION, MessageChannelEnum.EMAIL].includes(newChannel)) {
        await setText(currentChat.value.id, currentDraftMessage.value);
        setDraft(currentChat.value.key, newChannel, currentDraftMessage.value);
      } else {
        setDraft(currentChat.value.key, newChannel, stripHtml(currentDraftMessage.value));
      }

      setCurrentChannel(newChannel);
      focusOnActiveEditor();
    };

    const init = (): void => {
      attachments.value = [];

      prepareEditorForReply();

      root.$on('cx-chat-macros-modal::focus', focusOnActiveEditor);

      mountListeners();
    };

    const prepareEditorForReply = async (): Promise<void> => {
      // todo: move in a proper place
      const lastMessageClientOrStaffer: MessageModel | void =
        (currentChatLatestClientMessage.value && MessagesCacheService.get(currentChatLatestClientMessage.value)) ||
        (currentChatLatestMessage.value && MessagesCacheService.get(currentChatLatestMessage.value));
      if (!lastMessageClientOrStaffer) {
        return;
      }

      if (
        lastMessageClientOrStaffer.channel !== MessageChannelEnum.INAPP &&
        lastMessageClientOrStaffer.channel !== MessageChannelEnum.UNKNOWN
      ) {
        await updateChannel(lastMessageClientOrStaffer.channel);
      }

      if (
        [MessageChannelEnum.EMAIL, MessageChannelEnum.EMAIL_AND_SMS].includes(lastMessageClientOrStaffer.channel) &&
        !currentMessageSubject.value.length &&
        !currentReplyToMessage.value.length
      ) {
        setCurrentSubject(lastMessageClientOrStaffer.subject);
        setCurrentReplyToMessage(lastMessageClientOrStaffer.id);
      }
    };

    const composeReply = async (): Promise<void> => await prepareEditorForReply();
    const composeNote = async (): Promise<void> => await updateChannel(MessageChannelEnum.NOTE);
    const composeAction = async (): Promise<void> => await updateChannel(MessageChannelEnum.ACTION);

    const sendAndAction = async (snooze: boolean = false): Promise<void> => {
      const { id, key }: ChatModel = currentChat.value;
      if (!key.length) {
        return;
      }

      increaseChatActions(id);
      if (await sendMessage()) {
        await promiseTimeout(500);
        emitShortcut(snooze ? 'SNOOZE' : 'CLOSE');
      }

      decreaseChatActions(id);
      !snooze && focusOnActiveEditor();
    };

    const sendAndClose = async (): Promise<void> => await sendAndAction(false);
    const sendAndSnooze = async (): Promise<void> => await sendAndAction(true);

    const insertSuggestion = async (): Promise<void> => {
      if (!currentMessageReplySuggestion.value.length) {
        return;
      }

      if (
        [
          MessageChannelEnum.EMAIL_AND_SMS,
          MessageChannelEnum.SMS,
          MessageChannelEnum.INAPP,
          MessageChannelEnum.UNKNOWN,
        ].includes(currentChannel.value) &&
        currentDraftMessage.value.length
      ) {
        return;
      }

      if ((await getHtml(currentChat.value.id))?.length) {
        return;
      }

      if (currentEditMessage.value.length) {
        setCurrentEditMessage('');
      }

      if (
        [MessageChannelEnum.NOTE, MessageChannelEnum.ACTION, MessageChannelEnum.EMAIL].includes(currentChannel.value)
      ) {
        await setText(currentChat.value.id, currentMessageReplySuggestion.value);

        if (currentChannel.value !== MessageChannelEnum.NOTE) {
          setCurrentSubject('');
          setCurrentReplyToMessage('');
        }
      }

      setCurrentDraft(currentMessageReplySuggestion.value);
      focusOnActiveEditor();
    };

    const unmountListeners = (): void => {
      offShortcut('COMPOSE_REPLY', composeReply);
      offShortcut('COMPOSE_NOTE', composeNote);
      offShortcut('COMPOSE_ACTION', composeAction);

      offShortcut('SEND', sendMessage);
      offShortcut('SEND_AND_CLOSE', sendAndClose);
      offShortcut('SEND_AND_SNOOZE', sendAndSnooze);

      offShortcut('INSERT_SUGGESTION', insertSuggestion);
    };

    const mountListeners = (): void => {
      onShortcut('COMPOSE_REPLY', composeReply);
      onShortcut('COMPOSE_NOTE', composeNote);
      onShortcut('COMPOSE_ACTION', composeAction);

      onShortcut('SEND', sendMessage);
      onShortcut('SEND_AND_CLOSE', sendAndClose);
      onShortcut('SEND_AND_SNOOZE', sendAndSnooze);

      onShortcut('INSERT_SUGGESTION', insertSuggestion);
    };

    onBeforeMount(init);
    onActivated(init);

    onBeforeUnmount(unmountListeners);
    onDeactivated(unmountListeners);

    watch(
      currentDraftMessage,
      (newCurrentDraftMessage: string = '', oldCurrentDraftMessage: string = ''): void => {
        if (newCurrentDraftMessage === oldCurrentDraftMessage || !replySuggestionPage.value) {
          return;
        }

        replySuggestionPage.value = 0;
      },
      { flush: 'pre' }
    );

    watch(
      currentEditMessage,
      async (
        newCurrentEditMessage: MessageModel['id'] = '',
        oldCurrentEditMessage: MessageModel['id'] = ''
      ): Promise<void> => {
        if (newCurrentEditMessage === oldCurrentEditMessage || !newCurrentEditMessage) {
          return;
        }

        const messageToEdit: MessageModel | void = MessagesCacheService.getById(newCurrentEditMessage);
        if (!messageToEdit) {
          return;
        }

        const { id, key }: ChatModel = currentChat.value;
        if (!key) {
          console.error('sendMessage chatModel not found', activeChat.value);
          return;
        }

        increaseChatActions(id);

        try {
          attachments.value = messageToEdit.attachments.map((attachment: MessageModel['attachments'][0]) =>
            Builder(AttachmentModel)
              .id(attachment.id)
              .name(attachment.name)
              .previewUrl(attachment.url)
              .size(attachment.filesize)
              .uploaded(true)
              .type(attachment.contentType as AttachmentModel['type'])
              .build()
          );

          setCurrentDraft(messageToEdit.body);
          await setHtml(currentChat.value.id, messageToEdit.body);
          focusOnActiveEditor();
        } catch (e) {
          console.error('failed to set message to edit', id);
        } finally {
          decreaseChatActions(id);
        }
      },
      { flush: 'pre', immediate: true }
    );

    return {
      currentChat,
      attachments,

      textAreaElement,

      sendMessageDisabled,
      isChannelEmailOrNoteOrAction,
      isNightForPatient,
      isSmsAndEmailChannelsAvailable,

      replySuggestionPage,
      currentReplySuggestion,
      currentReplySuggestions,

      currentChannel,
      currentDraftMessage,
      currentMessageSubject,
      hasPendingActions,

      timezoneText,
      buttonText,
      footerText,
      textAreaPlaceholder,
      footerColor,
      dropdownVariant,
      splitClasses,
      toggleClasses,

      setCurrentDraft,
      setCurrentSubject,

      sendMessage,
      updateChannel,
      updateSubject,

      saveDraft,
    };
  },
});

export default ChatComposer;
