import memoize from 'lodash/memoize';

import { STAFFERS_BLACK_LIST } from '../constants';

import type { StafferType } from '@/types';

// todo: add unassign feature

// every argument is passed like this, to keep those properties reactive
const createList = (staffers: StafferType[] = [], currentStaffer?: StafferType | void): StafferType[] => {
  const formattedStaffers: StafferType[] = staffers
    // valid accounts
    .filter((staffer: StafferType) => staffer?.id?.length)
    // current staffer is always on top
    .filter((staffer: StafferType) => (!currentStaffer ? true : staffer?.id !== currentStaffer.id))
    // frontend blacklist
    .filter((staffer: StafferType) => !STAFFERS_BLACK_LIST.includes(staffer?.id));

  const formattedCurrentStaffer: StafferType | null =
    (staffers.find((staffer: StafferType) => currentStaffer && staffer?.id === currentStaffer?.id) && currentStaffer) ||
    null;

  return [formattedCurrentStaffer as StafferType, ...formattedStaffers].filter(Boolean);
};

const createListMemoized: typeof createList = memoize(createList);

export { createListMemoized as createList };
