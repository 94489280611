import {
  AppointmentType,
  MessageType,
  PatientType,
  ProviderActionKindEnum,
  ProviderActionStatusEnum,
  UserType,
} from '.';

export interface ProviderActionType {
  _rowVariant?: string | undefined;
  actionSubjectId?: string;
  appointment?: AppointmentType;
  assignee?: UserType;
  createdAt?: string;
  description?: string;
  displayKind?: string;
  displayStatus?: string;
  followUpDate?: Date;
  id?: string;
  kind?: ProviderActionKindEnum;
  message?: MessageType;
  patient?: PatientType;
  status?: ProviderActionStatusEnum;
  updatedAt?: string;
  zendeskTicketId?: number;
}

export enum ProviderActionKindLabelEnum {
  prescribe_slit = 'Prescribe SLIT',
  sign_prescription = 'Sign SLIT Rx',
  prescribe_epi = 'Prescribe Epipen',
  prescribe_in_dosespot = 'Prescribe in DoseSpot',
  prescribe_other = 'Prescribe Other Rx - (name below)',
  lab_order_curex = 'Lab Requisition - Curex',
  lab_order_other = 'Lab Requisition - External',
  lab_order_getlabs = 'Lab Requisition - GetLabs',
  lab_order_labcorp = 'Lab Requisition - LabCorp',
  lab_order_quest = 'Lab Requisition - Quest',
  lab_order_examone = 'Lab Requisition - ExamOne',
  arl_self_test = 'Send ARL Self Help Test',
  sign_lab_order_other = 'Sign Lab Requisition',
  scarlet = 'BRL',
  follow_up = 'Follow up',
  other = 'Other',
}

export const providerActionKindOptions = Object.entries(ProviderActionKindLabelEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));
