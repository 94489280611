export enum PhysicianTitleEnum {
  DO = 'DO',
  FNPBC = 'FNPBC',
  FNPC = 'FNPC',
  MD = 'MD',
  NP = 'NP',
  NPC = 'NPC',
  PAC = 'PAC',
  UNK = 'UNK',
}

export type PhysicianTitleEnumKey = keyof typeof PhysicianTitleEnum;
