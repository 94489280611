import { Builder } from 'builder-pattern';

import { AttachmentModel } from '@/components/AttachFile/models';

import { MedicalFileModel } from '~/medicalDocuments/models';

const fileModelToAttachmentModel = (fileModel: MedicalFileModel): AttachmentModel => {
  if (!fileModel || !fileModel?.id?.length) {
    return new AttachmentModel();
  }

  return Builder(AttachmentModel)
    .id(fileModel.id)
    .name(fileModel.name)
    .previewUrl(fileModel.s3Url)
    .type(fileModel.contentType)
    .uploaded(true)
    .build();
};

export { fileModelToAttachmentModel };
