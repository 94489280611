import { GET_SYSTEM_EVENT } from '../gql/GET_SYSTEM_EVENT';
import { GET_SYSTEM_EVENTS } from '../gql/GET_SYSTEM_EVENTS';
import { GET_SYSTEM_EVENTS_TOTAL } from '../gql/GET_SYSTEM_EVENTS_TOTAL';

import type { SystemEventsArgumentsModel } from '../models/SystemEventsArgumentsModel';

import type { PatientType, SystemEventType } from '@/types';

import { useApollo } from '@/composables/atoms/useApollo';

// sort choices are:
// additional_details,
// created_at,
// id,
// initiated_by,
// initiated_by_id,
// is_hidden,
// patient,
// patient_id,
// source,
// subject,
// subject_ct,
// subject_ct_id,
// subject_id,
// type,
// user,
// user_id"

const useSystemEventsApi = () => {
  const { loading, query } = useApollo();

  const getSystemEvent = async (userId: PatientType['id']): Promise<SystemEventType> => {
    const response = await query<{
      systemEvent: SystemEventType;
    }>({
      query: GET_SYSTEM_EVENT,
      fetchPolicy: 'no-cache',
      variables: {
        id: userId,
      },
    });

    if (response?.errors?.length || !response?.data?.systemEvent?.id?.length) {
      console.error('getSystemEvent.error', response?.errors);
      return;
    }

    return response.data?.systemEvent;
  };

  const getSystemEvents = async (variables: SystemEventsArgumentsModel): Promise<SystemEventType[]> => {
    const response = await query<{
      systemEvents: SystemEventType[];
    }>({
      query: GET_SYSTEM_EVENTS,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (response?.errors?.length || !response?.data?.systemEvents?.length) {
      console.error('getSystemEvents.error', response?.errors);
      return [];
    }

    return response?.data?.systemEvents || [];
  };

  const getSystemEventsTotal = async (): Promise<number | 0> => {
    const response = await query<{ totalSystemEvents?: number }>({
      query: GET_SYSTEM_EVENTS_TOTAL,
      fetchPolicy: 'no-cache',
    });

    if (response?.errors?.length) {
      console.error('getSystemEventsTotal.error', response?.errors);
      return 0;
    }

    return response?.data?.totalSystemEvents || 0;
  };

  return {
    loading,

    getSystemEvent,
    getSystemEvents,
    getSystemEventsTotal,
  };
};

export { useSystemEventsApi };
