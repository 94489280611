class Node<T = any> {
  public left?: Node;
  public right?: Node;
  public group: T[];

  public get value(): T | undefined {
    return this.group?.[0];
  }

  constructor(value?: T) {
    this.group = value !== undefined ? [value] : [];
  }
}

type CmpFn<T = any> = (item1: T, item2: T) => -1 | 0 | 1;

export function sortWithGroups<T = any>(items: T[], cmpFunction: CmpFn<T>): T[][] {
  if (!items?.length) {
    return [];
  }

  const root = new Node<T>(items[0]);

  items.slice(1).forEach((item: T) => {
    let ptr = root;

    do {
      switch (cmpFunction(ptr.value, item)) {
        case -1: // When value 'bigger' than in node
          if (ptr.right) {
            ptr = ptr.right;
            continue;
          }

          ptr.right = new Node(item);
          return;

        case 0: // When value equal to node
          ptr.group = [...ptr.group, item].sort(cmpFunction);
          return;

        case 1: // When value 'smaller' than in node
          if (ptr.left) {
            ptr = ptr.left;
            continue;
          }

          ptr.left = new Node(item);
          return;
      }
    } while (ptr);
  });

  const collect = (node: Node<T>): T[][] => {
    const result: T[][] = [];

    if (node.left) {
      result.push(...collect(node.left));
    }

    result.push(node.group);

    if (node.right) {
      result.push(...collect(node.right));
    }

    return result;
  };

  return collect(root);
}
