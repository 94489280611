import gql from 'graphql-tag';

import { MEDIAL_DOCUMENT } from './fragments/MEDIAL_DOCUMENT';

// in this mutation you can only add uploaded files and change description
// to remove files, we need to use `unlinkUploadedFile` mutation
const UPDATE_MEDICAL_DOCUMENT = gql`
  ${MEDIAL_DOCUMENT}
  mutation UpdateMedicalDocument($id: UUID!, $description: String, $uploadedFiles: [UUID]!) {
    updateMedicalDocument(medicalDocument: $id, description: $description, uploadedFiles: $uploadedFiles) {
      ...MedicalDocument
    }
  }
`;

export { UPDATE_MEDICAL_DOCUMENT };
