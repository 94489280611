const rules: any = {};
const modulesCache: any = {};

(function updateModules() {
  const requireModule = require.context('.', false, /^((?!index|\.spec\.).)*\.ts$/);

  requireModule.keys().forEach((fileName) => {
    const moduleDefinition = requireModule(fileName).default || requireModule(fileName);

    if (modulesCache[fileName] === moduleDefinition) return;

    modulesCache[fileName] = moduleDefinition;

    const modulePath = fileName
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .replace(/Rule/, '')
      .replace(/\.?([A-Z]+)/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '');

    rules[modulePath] = moduleDefinition;

    if (module.hot) {
      // Whenever any Rule is updated...
      module.hot.accept(requireModule.id, () => {
        // Update with the latest definitions.
        updateModules();
        // Trigger a hot update in rules.
        // eslint-disable-next-line
        require('./index').default.hotUpdate({ modules: rules });
      });
    }
  });
})();

export default rules;
