import type { Route } from 'vue-router';
import type { NavigationGuard, NavigationGuardNext } from 'vue-router/types/router';

export const useNavigationGuardBeforeEnterCheckParams: (...params: string[]) => NavigationGuard =
  (...params: string[]): NavigationGuard =>
  (to: Route, from: Route, next: NavigationGuardNext) => {
    params.every((param: string): boolean => {
      if (!to.params?.[param]?.length) {
        console.warn('Navigation Guard [BeforeEnterCheckParams] stops navigation because of missing param', param);
        next(false);
        return false;
      }

      return true;
    });

    next();
  };
