enum PatientSegmentEnum {
  FROM_SEVEN_DROPS = 'from_seven_drops',
  WITH_CUREXORG_EMAIL = 'with_curexorg_email',
  WITH_CONVERSATIONS = 'with_conversations',
  WITH_CONVERSATIONS_ASSIGNED_TO_USER = 'with_conversations_assigned_to_user',
  WITH_SNOOZED_CONVERSATIONS_ASSIGNED_TO_USER = 'with_snoozed_conversations_assigned_to_user',
  WITH_CLOSED_CONVERSATIONS = 'with_closed_conversations',
  WITH_CONVERSATIONS_ASSIGNED_TO_TEAM = 'with_conversations_assigned_to_team',
  WITH_SNOOZED_CONVERSATIONS_ASSIGNED_TO_TEAM = 'with_snoozed_conversations_assigned_to_team',
  WITH_CLOSED_CONVERSATIONS_ASSIGNED_TO_TEAM = 'with_closed_conversations_assigned_to_team',
  WITH_CONTACT_CHANNELS = 'with_contact_channels',
  ASSIGNED_TO_USER = 'assigned_to_user',
  WITH_SLIT_INITIATED_OUTCOME = 'with_SLIT_initiated_outcome',
  AETNA_INSURED = 'aetna_insured',
  ANTHEM_INSURED = 'anthem_insured',
  BCBS_INSURED = 'bcbs_insured',
  NOTIFIED_BY_SMS_ON_CURRENT_STAGE = 'notified_by_sms_on_current_stage',
  NOTIFIED_BY_SMS_FROM_CURRENT_SOURCE = 'notified_by_sms_from_current_source',
  WITH_KIND_INITIAL_INSURANCE_CONSULTATION = 'with_kind_initial_insurance_consultation',
  INSURANCE_PAYS_FOR_DROPS = 'insurance_pays_for_drops',
  SELF_PAYING_FOR_DROPS = 'self_paying_for_drops',
  INSURANCE_PAYS_FOR_TESTING = 'insurance_pays_for_testing',
  SELF_PAYING_FOR_TESTING = 'self_paying_for_testing',
  FROM_CURRENT_MONTH_RENEWAL_DATE_PATIENTS = 'from_current_month_renewal_date_patients',
  WITH_NO_SIGNED_SLIT_PRESCRIPTION = 'with_no_signed_SLIT_prescription',
  WITH_DECLINED_NOT_ENGAGED_OUTCOME = 'with_declined_not_engaged_outcome',
  WITH_PRECLINICAL_NO_RESPONSE_TAG = 'preclinical_no_response',
}

export { PatientSegmentEnum };
