import { Builder } from 'builder-pattern';

import { getReadableDate } from './getReadableDate';

import { MessageModel } from '../models/MessageModel';
import { MessagesCacheService } from '../services/MessagesCache';

import { Type } from '../types/type';

const createDateMessage = (messageForDate?: MessageModel | void): MessageModel | void => {
  if (!messageForDate || !messageForDate.createdAt) {
    return;
  }

  const dateMessage: MessageModel = Builder(MessageModel)
    .id(messageForDate.createdAt)
    .key(messageForDate.createdAt)
    .createdAt(messageForDate.createdAt)
    .updatedAt(messageForDate.updatedAt)
    .deletedAt(messageForDate.deletedAt)
    .sentAt(messageForDate.sentAt)
    .messageTime(getReadableDate(messageForDate.createdAt))
    .type(Type.DATE)
    .build();

  MessagesCacheService.set(dateMessage.key, dateMessage);

  return dateMessage;
};

export { createDateMessage };
