
import { computed, defineComponent, SetupContext, toRefs } from 'vue';

import { onKeyStroke } from '@vueuse/core';

import { Action } from './types/action';
import { COMPONENT_NAME, menuOptionsNames } from './constants';
import { ChatMessageContextMenuPropsModel } from './models/ChatMessageContextMenuPropsModel';

import { buildPropsFromModel } from '@/tools/props';

const ChatMessageContextMenu = defineComponent({
  props: buildPropsFromModel(new ChatMessageContextMenuPropsModel()),
  emits: ['input', 'click'],
  setup(props: ChatMessageContextMenuPropsModel, { emit }: SetupContext) {
    const { value, actions, x, y } = toRefs<ChatMessageContextMenuPropsModel>(props);

    onKeyStroke('Escape', (e) => {
      e.preventDefault();

      closeContextMenu();
    });

    const chatMessageContextMenuClasses = computed(
      (): Record<string, boolean> => ({
        [`${COMPONENT_NAME}--shown`]: value.value,
      })
    );

    const chatMessageContextMenuOverlayClasses = computed(
      (): Record<string, boolean> => ({
        [`${COMPONENT_NAME}__overlay--shown`]: value.value,
      })
    );

    const chatMessageContextMenuMenuStyles = computed(
      (): Partial<CSSStyleDeclaration> => ({
        top: `${y.value}px`,
        left: `${x.value}px`,
      })
    );

    const menuOptions = computed((): [string, Action][] =>
      actions.value.map((action: Action) => [menuOptionsNames?.[action], action])
    );

    const optionSelected = (action: Action) => {
      emit('click', action);
      closeContextMenu();
    };

    const closeContextMenu = () => {
      emit('input', false);
    };

    return {
      chatMessageContextMenuClasses,
      chatMessageContextMenuOverlayClasses,

      chatMessageContextMenuMenuStyles,

      menuOptions,

      optionSelected,
      closeContextMenu,
    };
  },
});

export default ChatMessageContextMenu;
