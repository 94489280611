import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const GET_CHAT = gql`
  ${CHAT}
  ${MESSAGE}
  query GetChat($id: UUID, $search: String, $onlyMessagesBefore: DateTime, $onlyMessagesAfter: DateTime, $limit: Int) {
    chat(
      id: $id
      search: $search
      onlyMessagesBefore: $onlyMessagesBefore
      onlyMessagesAfter: $onlyMessagesAfter
      limit: $limit
    ) {
      ...Chat
      messages {
        ...Message
      }
      latestMessage {
        ...Message
      }
      latestClientMessage {
        ...Message
      }
    }
  }
`;

export { GET_CHAT };
