const sortByReference = <T>(values: T[], reference: T[]): T[] => {
  const map: Map<T, number> = new Map<T, number>();
  reference.forEach((value, index) => map.set(value, index));

  return values.filter(Boolean).sort((a: T, b: T) => {
    const indexA: number = map.get(a as T);
    const indexB: number = map.get(b as T);
    return (indexA !== undefined ? indexA : Infinity) - (indexB !== undefined ? indexB : Infinity);
  }) as T[];
};

export { sortByReference };
