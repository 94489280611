import { Builder } from 'builder-pattern';

import parseAsString from 'lodash/toString';

import type { DeepPartial } from 'utility-types';

class QuestionGroupModel {
  public code: string = 'no-code';
  public name: string = 'no-group';
  public displayOrder: number = Number.MAX_SAFE_INTEGER;
}

class QuestionModel {
  public id: string = '';
  public text: string = '';
  public shortText: string = '';

  public displayInEhr: boolean = true;
  public displayOrder: number = Number.MAX_SAFE_INTEGER;

  public group: QuestionGroupModel = new QuestionGroupModel();
}

class QuestionsCache extends Map<QuestionModel['id'], QuestionModel> {
  private _groups: Map<QuestionGroupModel['code'], QuestionGroupModel> = new Map();
  private _questionToGroupKey: Map<QuestionModel['id'], QuestionGroupModel['code']> = new Map();

  public get(questionKey: QuestionModel['id']): QuestionModel {
    return this.has(questionKey) ? super.get(questionKey) : new QuestionModel();
  }

  public add(question?: DeepPartial<QuestionModel> | void): ReturnType<QuestionsCache['get']> {
    if (!question || !question?.id) {
      console.warn('Question is invalid', question);
      return;
    }

    if (this.has(question.id)) {
      return this.get(question.id);
    }
    const text: string = parseAsString(question?.text || question?.shortText);
    const shortText: string = parseAsString(question?.shortText || question?.text);

    const groupModel: QuestionGroupModel = Builder(QuestionGroupModel)
      .code(parseAsString(question?.group?.code) || 'no-code')
      .name(parseAsString(question?.group?.name) || 'Other answers')
      .displayOrder(question?.group?.displayOrder ?? Number.MAX_SAFE_INTEGER)
      .build();

    if (!this._groups.has(groupModel.code)) {
      this._groups.set(groupModel.code, groupModel);
    }

    if (!this._questionToGroupKey.has(question.id)) {
      this._questionToGroupKey.set(question.id, groupModel.code);
    }

    const questionModel: QuestionModel = Builder(QuestionModel)
      .id(question.id)
      .text(text)
      .shortText(shortText)
      .displayInEhr(question?.displayInEhr ?? true)
      .displayOrder(question?.displayOrder ?? Number.MAX_SAFE_INTEGER)
      .group(groupModel)
      .build();

    this.set(question.id, questionModel);

    return questionModel;
  }

  public getGroup(questionKey: QuestionModel['id']): QuestionGroupModel {
    return this._questionToGroupKey.has(questionKey)
      ? this._groups.get(this._questionToGroupKey.get(questionKey))
      : new QuestionGroupModel();
  }
}

const QuestionsCacheService: QuestionsCache = new QuestionsCache();

export { QuestionsCacheService };
