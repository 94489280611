import parseAsString from 'lodash/toString';
import parseAsNumber from 'lodash/toNumber';

const formatBytes = (bytes: number | string, decimals = 2) => {
  const formattedBytes: number = parseAsNumber(parseAsString(bytes));
  if (!formattedBytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(formattedBytes) / Math.log(k));

  return `${parseFloat((formattedBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export { formatBytes };
