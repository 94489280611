
import { computed, defineComponent, PropType, SetupContext, toRef, toRefs } from 'vue';

import type { MedicalDocumentType } from '@/types';

import { getStaffer, getStafferFirstName, useStaffersStore } from '@/stores/staffers';

import { getReadableDate } from '~/messaging';

const MedicalDocumentsCard = defineComponent({
  props: {
    medicalDocuments: {
      default: () => [],
      required: false,
      type: Array as PropType<MedicalDocumentType[]>,
    },
  },
  emits: ['open-sidebar'],
  setup(props: { medicalDocuments: MedicalDocumentType[] }, { emit }: SetupContext) {
    const { medicalDocuments } = toRefs<{ medicalDocuments: MedicalDocumentType[] }>(props);

    const staffersStore = useStaffersStore();
    const staffers = toRef(staffersStore, 'staffers');

    const isEmpty = computed((): boolean => !medicalDocuments.value?.length);

    const formattedMedicalDocumentsTexts = computed((): string[] =>
      medicalDocuments.value.map((document: typeof medicalDocuments.value['0'], index: number) => {
        const uploadedAt: string = getReadableDate(document?.updatedAt) || '?';
        const uploadedByName: string = getStafferFirstName(getStaffer(document?.uploadedBy?.id, staffers.value)) || '?';

        return `Document #${index + 1} created by ${uploadedByName} at ${uploadedAt} (${
          document?.uploadedFiles?.length || 0
        })`;
      })
    );

    const openMedicalDocumentsSidebar = (): void => emit('open-sidebar');

    return {
      isEmpty,

      formattedMedicalDocumentsTexts,

      openMedicalDocumentsSidebar,
    };
  },
});

export default MedicalDocumentsCard;
