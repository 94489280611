export enum CallOutcomeEnum {
  unknown = 'Unknown',
  promised_to_schedule = 'Promised to schedule',
  scheduled = 'Scheduled',
  cancelled = 'Cancelled',
  convert_to_self_pay = 'Convert to self pay',
  voicemail = 'Voicemail',
  hung_up = 'Hung up',
  wrong_number = 'Wrong number',
  disconnected_number = 'Disconnected number',
  call_auto_rejector = 'Call auto-rejector',
  dialed_in_error = 'Dialed in error',
}

export type CallOutcomeEnumKey = keyof typeof CallOutcomeEnum;
export type CallOutcomeEnumValue = `${CallOutcomeEnum}`;
