const keyAlias: Map<string, string> = new Map();

keyAlias.set('Control', 'Ctrl');
keyAlias.set('ControlRight', 'Ctrl');
keyAlias.set('ControlLeft', 'Ctrl');
keyAlias.set('Ctl', 'Ctrl');
keyAlias.set('Ctrl', 'Ctrl');

keyAlias.set('MetaRight', 'Meta');
keyAlias.set('MetaLeft', 'Meta');
keyAlias.set('OSRight', 'Meta');
keyAlias.set('OSLeft', 'Meta');
keyAlias.set('Meta', 'Meta');

keyAlias.set('AltRight', 'Alt');
keyAlias.set('AltLeft', 'Alt');
keyAlias.set('Alt', 'Alt');

keyAlias.set('ShiftRight', 'Shift');
keyAlias.set('ShiftLeft', 'Shift');
keyAlias.set('Shift', 'Shift');

keyAlias.set('NumpadEnter', 'Enter');
keyAlias.set('Enter', 'Enter');

keyAlias.set('ArrowDown', 'Down');
keyAlias.set('Down', 'Down');

keyAlias.set('ArrowUp', 'Up');
keyAlias.set('Up', 'Up');

keyAlias.set('ArrowLeft', 'Left');
keyAlias.set('Left', 'Left');

keyAlias.set('ArrowRight', 'Right');
keyAlias.set('Right', 'Right');

export { keyAlias };
