
import { computed, defineComponent, toRef, toRefs } from 'vue';

import { useModalsStore } from '@/stores/modals/store';
import { onKeyStroke } from '@vueuse/core/index';

const COMPONENT_NAME: 'Modal' = 'Modal' as const;

// Bootstrap modal is laggy
const Modal = defineComponent({
  props: {
    modalKey: {
      default: '',
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { modalKey } = toRefs(props);

    const modalsStore = useModalsStore();
    const openModals = toRef(modalsStore, 'openModals');

    const isModalOpen = computed((): boolean => openModals.value.includes(modalKey.value));

    const modalClasses = computed((): string[] =>
      [isModalOpen.value ? `${COMPONENT_NAME}--visible` : ''].filter(Boolean)
    );

    // todo: not used
    const modalContentClasses = computed((): string[] =>
      [isModalOpen.value ? `${COMPONENT_NAME}Content--visible` : ''].filter(Boolean)
    );

    const closeModal = () => modalsStore.closeModal(modalKey.value);

    const onModalClick = (event: Event) => {
      event.stopPropagation();
      if (event.target !== event.currentTarget) {
        return;
      }

      closeModal();
    };

    onKeyStroke('Escape', (e) => {
      e.preventDefault();

      closeModal();
    });

    return {
      modalClasses,
      modalContentClasses,

      onModalClick,
      closeModal,
    };
  },
});

export default Modal;
