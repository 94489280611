import { Task } from './Task';
import type { PromiseFunction } from './types';

class TaskQueue {
  private _queue: Task<any, any[]>[] = [];
  private _processing: boolean = false;

  public enqueue<T, A extends any[]>(
    promiseFn: PromiseFunction<T, A>,
    args?: A,
    config?: ConstructorParameters<typeof Task>['2']
  ): Promise<T> {
    const task = new Task<T, A>(promiseFn, args, config);
    const promise = task.getPromise();
    this._queue.push(task as unknown as Task<any, any[]>);
    this.processQueue();
    return promise;
  }

  private processQueue(): void {
    if (this._processing) {
      return;
    }

    this._processing = true;
    this.executeNextTask();
  }

  private executeNextTask(): void {
    if (!this._queue.length) {
      this._processing = false;
      return;
    }

    this._queue
      .shift()
      ?.executeTask()
      .finally(() => this.executeNextTask())
      .catch((error: unknown) => console.error(error));
  }
}

export { TaskQueue };
