import { SpecialKey } from '../types/specialKey';

const getSpecialKeysIfPressed = (event: KeyboardEvent): SpecialKey[] => {
  const specialKeysPressed: SpecialKey[] = [];
  if (event?.ctrlKey) {
    specialKeysPressed.push(SpecialKey.CTRL);
  }

  if (event?.metaKey) {
    specialKeysPressed.push(SpecialKey.META);
  }

  if (event?.altKey) {
    specialKeysPressed.push(SpecialKey.ALT);
  }

  if (event?.shiftKey) {
    specialKeysPressed.push(SpecialKey.SHIFT);
  }

  return specialKeysPressed;
};

export { getSpecialKeysIfPressed };
