import parseAsString from 'lodash/toString';

const getKey = (variables: {
  id?: unknown;
  createdAt?: unknown;
  updatedAt?: unknown;
  deletedAt?: unknown;
  sentAt?: unknown;
  closedAt?: unknown;
  openedAt?: unknown;
}): string => {
  const result: string[] = [`${variables?.id}`];

  if (variables?.createdAt) {
    result.push(`${new Date(parseAsString(variables.createdAt)).getTime()}`);
  }

  if (variables?.updatedAt) {
    result.push(`${new Date(parseAsString(variables.updatedAt)).getTime()}`);
  }

  if (variables?.deletedAt) {
    result.push(`${new Date(parseAsString(variables.deletedAt)).getTime()}`);
  }

  if (variables?.sentAt) {
    result.push(`${new Date(parseAsString(variables.sentAt)).getTime()}`);
  }

  if (variables?.closedAt) {
    result.push(`${new Date(parseAsString(variables.closedAt)).getTime()}`);
  }

  if (variables?.openedAt) {
    result.push(`${new Date(parseAsString(variables.openedAt)).getTime()}`);
  }

  return result.join('+');
};

export { getKey };
