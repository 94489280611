
import { computed, defineComponent, getCurrentInstance, toRef } from 'vue';

import { watchDebounced } from '@vueuse/core';

import { showError } from '@/components/errors';

import { ChatModel, ChatsCacheService, useMessaging, useMessagingStore } from '~/messaging';

const ChatSearchInChat = defineComponent({
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const messagingStore = useMessagingStore();
    const searchInChat = toRef(messagingStore, 'searchInChat');
    const activeChat = toRef(messagingStore, 'activeChat');

    const { setSearchInChat } = messagingStore;

    const { fetchOldMessages } = useMessaging();

    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    const searchPlaceholder = computed((): string => {
      return currentChat.value.displayNameShort
        ? `${currentChat.value.displayNameShort} messages search`
        : 'Search messages';
    });

    const filterMessages = async (): Promise<void> => {
      const chatModel: ChatModel | void = ChatsCacheService.get(activeChat.value);
      if (!chatModel) {
        return;
      }
      messagingStore.increaseChatActions(chatModel.id);

      try {
        await fetchOldMessages();
      } catch (error) {
        showError(vm, `Failed to search messages of ${chatModel?.displayName}.`, 50000);
      } finally {
        messagingStore.decreaseChatActions(chatModel.id);
      }
    };

    watchDebounced(searchInChat, filterMessages, { debounce: 600 });

    return { searchInChat, searchPlaceholder, setSearchInChat };
  },
});

export default ChatSearchInChat;
