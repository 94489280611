import { MaybeRef } from '@vueuse/core';
import { useApollo } from '@/composables/atoms/useApollo';
import type {
  CreateVisitSurveySessionResult,
  CreateVisitSurveySessionVariables,
} from '@/composables/useBackendSegmentEvents/gql';
import { CREATE_VISIT_SURVEY_SESSION } from '@/composables/useBackendSegmentEvents/gql';
import type { DocumentNode } from 'graphql';
import { showError, showMessage } from '@/components/errors';
import { useRoot } from '@/composables/atoms/useRoot';
import { unref } from 'vue';

export function useBackendSegmentEvents() {
  const root = useRoot();
  const apollo = useApollo();

  function throwError(message: string): void {
    throw new Error(message);
  }

  async function emit<ReturnType = any, VariablesType = any>(
    mutation: DocumentNode,
    variables?: VariablesType
  ): Promise<ReturnType | undefined> {
    try {
      const response = await apollo.mutate<ReturnType>({
        mutation,
        variables,
      });

      if (response?.errors?.length) {
        throwError(response.errors.join('. '));
      }

      showMessage(root, 'Success! Event emitted!');

      return response.data;
    } catch (error: any) {
      showError(root, `Oops! Something went wrong. ${error.message}`);
    }
  }

  async function emitVisitSurveyIntakeStarted(
    variables: MaybeRef<CreateVisitSurveySessionVariables>
  ): Promise<CreateVisitSurveySessionResult | undefined> {
    return await emit<CreateVisitSurveySessionResult, CreateVisitSurveySessionVariables>(
      CREATE_VISIT_SURVEY_SESSION,
      unref(variables)
    );
  }

  return { emitVisitSurveyIntakeStarted };
}
