import { MessageStatusEnum } from '@/types';

const isFailedMessage = (status?: MessageStatusEnum): boolean => {
  if (!status) {
    return true;
  }

  switch (status) {
    case MessageStatusEnum.UNDELIVERED:
    case MessageStatusEnum.FAILED:
    case MessageStatusEnum.CANCELED:
      return true;
  }

  return false;
};

export { isFailedMessage };
