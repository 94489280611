import type { PropOptions } from 'vue';

import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';

import {
  arrayPropValidator,
  booleanPropValidator,
  customPropValidator,
  datePropValidator,
  objectPropValidator,
  symbolPropValidator,
  textAndNumberPropValidator,
  textPropValidator,
} from '../validators';

// nullish values will not have validator.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#description
const getPropValidator = <Type, V>(value: unknown, validatorParams?: V): PropOptions<Type>['validator'] => {
  const valueType = typeof value;

  switch (true) {
    case valueType === 'boolean':
      return booleanPropValidator<boolean>();
    case valueType === 'number':
    case valueType === 'bigint':
      return textAndNumberPropValidator<number>(validatorParams);
    case valueType === 'string':
      return textPropValidator<string>(validatorParams);
    case valueType === 'symbol':
      return symbolPropValidator<symbol>();
    case valueType === 'function':
      // If it's a function type, then it has not been called. We can use its constructor to check instance
      // todo:
      // @ts-expect-error todo
      return customPropValidator<Type>(validatorParams);
  }

  if (isArray(value)) {
    return arrayPropValidator<[]>();
  }

  if (isDate(value)) {
    return datePropValidator<Date>();
  }

  // everything else
  return objectPropValidator<object>();
};

export { getPropValidator };
