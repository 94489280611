import gql from 'graphql-tag';

const MESSAGE = gql`
  fragment Message on MessageType {
    id
    createdAt
    updatedAt
    deletedAt
    sentAt
    priority

    subject
    text
    body
    deliveryDetails

    template {
      name
    }

    action {
      id
      status
      assignee {
        id
      }
    }

    authorId
    replyToId

    category
    channel
    status

    attachments {
      deletedAt
      id
      url
      name
      contentId
      contentType
      filesize
      width
      height
      s3DownloadLink
    }
  }
`;

export { MESSAGE };
