import type { MessageAttachmentType } from '@/types';

// type safe attachment message model
class MessageAttachmentModel implements MessageAttachmentType {
  id: string = '';
  deletedAt: string = '';

  name: string = 'new-photo';
  contentId: string = '';
  contentType: string = '';
  filesize: number = 0;
  s3DownloadLink: string = '';
  url: string = '';

  width: number = 0;
  height: number = 0;
}

export { MessageAttachmentModel };
