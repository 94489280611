import isObject from 'lodash/isObject';
import type { PropOptions } from 'vue';

const objectPropValidator = <T>(): PropOptions<T>['validator'] => {
  return (value: T): value is T => {
    return isObject(value);
  };
};

export { objectPropValidator };
