import type { MessageModel } from '../models/MessageModel';

import { MessagesCacheService } from '../services/MessagesCache';

import { Type } from '../types/type';

const getMessageType = (messageKey: MessageModel['key']): MessageModel['type'] =>
  MessagesCacheService.get(messageKey)?.type || Type.SYSTEM;

export { getMessageType };
