import type { ApolloQueryResult, OperationVariables } from 'apollo-client/core/types';
import type { MutationOptions, QueryOptions } from 'apollo-client/core/watchQueryOptions';
import type { FetchResult } from 'apollo-link';
import { computed, ref } from 'vue';
import type { DollarApollo } from 'vue-apollo/types/vue-apollo';
import { useRoot } from '@/composables/atoms/useRoot';

let $apollo: DollarApollo<unknown> = null;

export function useApollo(suppressError: boolean = false) {
  const apollo = $apollo ? $apollo : ($apollo = useRoot().$apollo);
  const _loading = ref<number>(0);

  const loading = computed((): boolean => apollo.loading || _loading.value > 0);

  async function callApollo(name: 'mutate' | 'query', options: any): Promise<any> {
    _loading.value++;

    try {
      return await apollo[name](options);
    } catch (error: any) {
      if (!suppressError) {
        throw error;
      }
    } finally {
      _loading.value = Math.max(_loading.value - 1, 0);
    }
  }

  async function mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): Promise<FetchResult<T>> {
    return await callApollo('mutate', options);
  }

  async function query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>
  ): Promise<ApolloQueryResult<T>> {
    return await callApollo('query', options);
  }

  return {
    apollo,
    loading,
    mutate,
    query,
  };
}
