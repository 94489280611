import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const ASSIGN_CHAT = gql`
  ${CHAT}
  ${MESSAGE}
  mutation AssignChat(
    $chatId: UUID!
    $limit: Int
    $onlyMessagesAfter: DateTime
    $addStaffers: [UUID]
    $removeStaffers: [UUID]
    $addTeams: [UUID]
    $removeTeams: [UUID]
  ) {
    assignChat(
      chatId: $chatId
      limit: $limit
      onlyMessagesAfter: $onlyMessagesAfter
      addStaffers: $addStaffers
      removeStaffers: $removeStaffers
      addTeams: $addTeams
      removeTeams: $removeTeams
    ) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { ASSIGN_CHAT };
