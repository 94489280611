
import { computed, defineComponent } from 'vue';

import { availableShortcuts, ShortcutModel } from '~/messaging';
import { readableShortcut } from '@/composables/atoms/useKeysPressed';

const modalKey: 'chat-shortcuts-modal' = 'chat-shortcuts-modal' as const;

// Bootstrap tabs are starting to lag, if they are controlled by our state
const ChatShortcutsModal = defineComponent({
  setup() {
    const readableShortcuts = computed((): { shortcut: string; description: string }[] =>
      availableShortcuts.map((shortcut: ShortcutModel) => ({
        shortcut: readableShortcut(shortcut.code),
        description: shortcut.description,
      }))
    );

    return {
      modalKey,

      readableShortcuts,
    };
  },
});

export default ChatShortcutsModal;
