import { ClientWorker } from '@/clientWorkers/AbstractClientWorker';

export class ServiceWorkerManager {
  protected serviceWorker: ServiceWorker | null = null;
  protected messagesCache: any[] = [];
  protected broadcast: BroadcastChannel = new BroadcastChannel('ServiceWorkerManager');

  private _clientWorkers: ClientWorker[] = [];

  public constructor() {
    this.broadcast.onmessage = this.onMessage.bind(this);
  }

  public subscribe(...clientWorkers: ClientWorker[]): void {
    this._clientWorkers = [...this._clientWorkers, ...clientWorkers];
  }

  public unsubscribe(clientWorker: ClientWorker): void {
    this._clientWorkers = this._clientWorkers.filter(({ UUID }) => UUID !== clientWorker.UUID);
  }

  public postMessage(message: any): void {
    if (!this.serviceWorker) {
      this.messagesCache.push(message);
      return;
    }

    this.broadcast.postMessage(message);
  }

  public cached(registration: ServiceWorkerRegistration): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onCached?.(registration));
  }

  public error(error: Error): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onError?.(error));
  }

  public offline(): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onOffline?.());
  }

  public ready(registration: ServiceWorkerRegistration): void {
    if (!this.serviceWorker) {
      this.serviceWorker = registration.active;
      this.postCachedMessages();
    }

    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onReady?.(registration));
  }

  public registered(registration: ServiceWorkerRegistration): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onRegistered?.(registration));
  }

  public updated(registration: ServiceWorkerRegistration): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onUpdated?.(registration));
  }

  public updatefound(registration: ServiceWorkerRegistration): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onUpdatefound?.(registration));
  }

  protected onMessage(event: MessageEvent): void {
    this._clientWorkers.forEach((clientWorker: ClientWorker) => clientWorker?.onMessage?.(event));
  }

  protected postCachedMessages(): void {
    this.messagesCache.forEach((message) => this.postMessage(message));
  }
}

export const ServiceWorkerManagerService = new ServiceWorkerManager();
