import gql from 'graphql-tag';

import { ACCOUNT } from './ACCOUNT';

const CLIENT_FULL_ACCOUNT = gql`
  ${ACCOUNT}
  fragment ClientFullAccount on AccountType {
    ...MessagingAccount

    isActive

    preferredPaymentOption
    testingPaymentMethod

    stripeDelinquent
    displayName
    displayNameShort
    checkoutCompletedAt
    medhistoryCompletedAt
    preclinicalCompletedAt
  }
`;

export { CLIENT_FULL_ACCOUNT };
