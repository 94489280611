import gql from 'graphql-tag';

const GET_TEAMS_OPEN_CHATS = gql`
  query GetTeams {
    teams {
      id
      openChatsNumber
    }
  }
`;

export { GET_TEAMS_OPEN_CHATS };
