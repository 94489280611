import { Builder } from 'builder-pattern';

import parseAsString from 'lodash/toString';

import type { MessageModel } from '../models/MessageModel';

import type { ReplySuggestionType } from '@/types';

type ReplySuggestionTypeExtended = Required<
  ReplySuggestionType & {
    messageId: MessageModel['id'];
  }
>;

class SuggestionsCache extends Map<MessageModel['id'], ReplySuggestionTypeExtended> {
  private _messageIdToReplySuggestionId: Map<MessageModel['id'], ReplySuggestionType['id']> = new Map();

  public hasByMessageId(messageId: MessageModel['id']): ReturnType<SuggestionsCache['has']> {
    return this.has(this._messageIdToReplySuggestionId.get(messageId));
  }

  public getByMessageId(messageId: MessageModel['id']): ReturnType<SuggestionsCache['get']> {
    return this.get(this._messageIdToReplySuggestionId.get(messageId));
  }

  public add(
    messageId: MessageModel['id'],
    suggestion?: ReplySuggestionType | void
  ): ReturnType<SuggestionsCache['get']> {
    if (!suggestion || !suggestion?.id?.length) {
      console.warn('Suggestion is invalid', suggestion);
      return;
    }

    const replySuggestionId: string = parseAsString(suggestion?.id);

    if (!this.has(replySuggestionId)) {
      const replySuggestion: ReplySuggestionTypeExtended = Builder<ReplySuggestionTypeExtended>()
        .id(replySuggestionId)
        .createdAt(parseAsString(suggestion?.createdAt))
        .updatedAt(parseAsString(suggestion?.updatedAt))
        .messageId(messageId)

        .text(parseAsString(suggestion?.text))
        .build();

      if (this.hasByMessageId(messageId)) {
        console.warn('Message ID collision (suggestions):', messageId, replySuggestionId);
      }

      this.set(replySuggestionId, replySuggestion);

      this._messageIdToReplySuggestionId.set(messageId, replySuggestionId);

      return replySuggestion;
    }

    return this.get(replySuggestionId);
  }
}

const SuggestionsCacheService: SuggestionsCache = new SuggestionsCache();

export { SuggestionsCacheService };
