import gql from 'graphql-tag';

import { SYSTEM_EVENT } from './fragments/SYSTEM_EVENT';

const GET_SYSTEM_EVENT = gql`
  ${SYSTEM_EVENT}
  query GetSystemEvent($id: UUID) {
    systemEvent(id: $id) {
      ...SystemEvent
    }
  }
`;

export { GET_SYSTEM_EVENT };
