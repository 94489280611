
import type { SetupContext } from 'vue';
import { defineComponent } from 'vue';

import { useModalsStore } from '@/stores/modals/store';

//Bootstrap tabs are starting to lag, if they are controlled by our state
const ChatShortcutsButton = defineComponent({
  setup(props, { emit }: SetupContext) {
    const { openModal } = useModalsStore();

    const openShortcutsModal = (): void => {
      emit('click');
      openModal('chat-shortcuts-modal');
    };

    return {
      openShortcutsModal,
    };
  },
});

export default ChatShortcutsButton;
