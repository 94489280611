import type { ReplyModel } from '../models/ReplyModel';

const isReplyIncludes = (reply?: ReplyModel | void, text: string = ''): boolean =>
  reply &&
  reply.id.length &&
  text?.length &&
  (reply.searchKeyword?.includes(text) ||
    reply.searchName?.includes(text) ||
    reply.searchSubject?.includes(text) ||
    reply.searchRawText?.includes(text));

export { isReplyIncludes };
