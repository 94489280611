
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  ref,
  toRef,
  watch,
} from 'vue';

import { useDebounceFn, useIntersectionObserver } from '@vueuse/core';

import {
  ChatModel,
  ChatsCacheService,
  TeamModel,
  useMessaging,
  useMessagingShortcuts,
  useMessagingStore,
} from '~/messaging';

import { showError } from '@/components/errors';
import type TransitionSlide from '@/components/TransitionSlide/TransitionSlide.vue';

import type { AccountType } from '@/types';
import { ChatSegmentEnum } from '@/types';
import isFunction from 'lodash/isFunction';

const ChatInbox = defineComponent({
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const { onShortcut, offShortcut } = useMessagingShortcuts();

    const { fetchOldInboxChats, updateUrl } = useMessaging();

    const messagingStore = useMessagingStore();

    const searchInChats = toRef(messagingStore, 'searchInChats');
    const currentInbox = toRef(messagingStore, 'currentInbox');
    const activeChat = toRef(messagingStore, 'activeChat');
    const currentStafferOrTeam = toRef(messagingStore, 'currentStafferOrTeam');
    const currentFilter = toRef(messagingStore, 'currentFilter');
    const currentInboxHasMoreChats = toRef(messagingStore, 'currentInboxHasMoreChats');
    const hasInboxActions = toRef(messagingStore, 'hasInboxActions');

    const { setActiveChat, increaseInboxActions, decreaseInboxActions } = messagingStore;

    const scrollHelperElement = ref<HTMLDivElement>(null);
    const threadsInstance = ref<InstanceType<typeof TransitionSlide>>();

    const isLoaderVisible = ref<boolean>(false);

    const showFilter = computed((): boolean => currentFilter.value !== ChatSegmentEnum.ALL);

    const {
      stop: stopIntersectionObserver,
      pause: pauseIntersectionObserver,
      resume: resumeIntersectionObserver,
    } = useIntersectionObserver(scrollHelperElement, async ([{ isIntersecting }]) => {
      isLoaderVisible.value = isIntersecting;
      if (!isLoaderVisible.value) {
        return;
      }
      await debouncedFetchOldInboxChats();
    });

    const debouncedFetchOldInboxChats = useDebounceFn(async (): Promise<void> => {
      const filter: ChatSegmentEnum = currentFilter.value;
      const stafferOrTeamId: AccountType['id'] | TeamModel['id'] = currentStafferOrTeam.value;
      increaseInboxActions(stafferOrTeamId, filter);
      try {
        await fetchOldInboxChats(currentFilter.value);
      } catch (error) {
        showError(vm, `Failed to poll inbox ${currentFilter.value}.`);
      } finally {
        decreaseInboxActions(stafferOrTeamId, filter);
      }

      if (isLoaderVisible.value && currentInboxHasMoreChats.value) {
        await debouncedFetchOldInboxChats();
      }
    }, 200);

    const pause = (): void => {
      pauseIntersectionObserver();
    };

    const resume = (): void => {
      resumeIntersectionObserver();
    };

    const changeActiveChat = async (next: boolean = false): Promise<void> => {
      if (!activeChat.value.length) {
        return;
      }

      let currentChatKeyIndex: number | -1 =
        currentInbox.value.findIndex((chatKey: ChatModel['key']) => chatKey === activeChat.value) ?? -1;

      if (currentChatKeyIndex === -1) {
        currentChatKeyIndex = next ? -1 : 1;
        // todo: switch to active inbox? or switch inbox to the inbox with the chat?
      }

      const newChatModel: ChatModel | void = ChatsCacheService.get(
        currentInbox.value?.[next ? currentChatKeyIndex + 1 : currentChatKeyIndex - 1]
      );

      if (!newChatModel || !newChatModel?.key?.length) {
        return;
      }

      const activeChatElement: Element | void =
        threadsInstance.value?.$el?.getElementsByClassName?.('ChatInboxChat--key-' + newChatModel.key)?.[0] || null;

      if (activeChatElement && isFunction(activeChatElement?.scrollIntoView)) {
        activeChatElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }

      setActiveChat(newChatModel.key);
      await updateUrl(newChatModel);
    };

    const previousConversation = async (): Promise<void> => await changeActiveChat();
    const nextConversation = async (): Promise<void> => await changeActiveChat(true);

    const unmountListeners = (): void => {
      offShortcut('PREVIOUS_CONVERSATION', previousConversation);
      offShortcut('NEXT_CONVERSATION', nextConversation);
    };

    const mountListeners = (): void => {
      onShortcut('PREVIOUS_CONVERSATION', previousConversation);
      onShortcut('NEXT_CONVERSATION', nextConversation);
    };

    onBeforeMount((): void => {
      resume();
      mountListeners();
    });

    onActivated((): void => {
      resume();
      mountListeners();
    });

    onBeforeUnmount((): void => {
      pause();
      stopIntersectionObserver();
      unmountListeners();
    });

    onDeactivated((): void => {
      pause();
      unmountListeners();
    });

    watch(currentFilter, debouncedFetchOldInboxChats);
    watch(searchInChats, debouncedFetchOldInboxChats);

    return {
      scrollHelperElement,
      threadsInstance,

      currentInbox,
      currentInboxHasMoreChats,

      hasInboxActions,

      showFilter,

      debouncedFetchOldInboxChats,
    };
  },
});

export default ChatInbox;
