import { Builder } from 'builder-pattern';
import memoize from 'lodash/memoize';

import { getStaffer } from './getStaffer';

import type { StafferType } from '@/types';

const getStaffers = (staffersIds: StafferType['id'][], staffers: StafferType[]): StafferType[] => {
  const result: StafferType[] = [];

  staffersIds.forEach((stafferId: StafferType['id']) => {
    const staffer: StafferType | void = getStaffer(stafferId, staffers);
    if (!staffer) {
      result.push(Builder<StafferType>().id(stafferId).build());
      return;
    }

    result.push(staffer);
  });

  return result;
};

const getStaffersMemoized: typeof getStaffers = memoize(getStaffers);

export { getStaffersMemoized as getStaffers };
