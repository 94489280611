
import { computed, defineComponent, toRef } from 'vue';

import { useMessagingStore } from '~/messaging';

const ChatMessageFilter = defineComponent({
  setup() {
    const messagingStore = useMessagingStore();
    const isAutomationMessagesFiltered = toRef(messagingStore, 'isAutomationMessagesFiltered');

    const { setMessagesAutomationFilter } = messagingStore;

    const title = computed((): string =>
      isAutomationMessagesFiltered.value ? 'Show automation messages' : 'Hide automation messages'
    );

    const toggleMessagesAutomationFilter = (): void => {
      if (isAutomationMessagesFiltered.value) {
        setMessagesAutomationFilter(false);
        return;
      }

      setMessagesAutomationFilter(true);
    };

    return {
      isAutomationMessagesFiltered,
      title,

      toggleMessagesAutomationFilter,
    };
  },
});

export default ChatMessageFilter;
