import { Builder } from 'builder-pattern';
import uniqBy from 'lodash/uniqBy';
import parseAsString from 'lodash/toString';
import type { MessageTemplateType } from '@/types';
import { ReplyModel, formatStringForSearch, isReplyIncludes, isReplyStartsWith } from '~/messaging';

class RepliesCache {
  public _keywordToId: Map<ReplyModel['keyword'], ReplyModel['id']> = new Map();
  public _idToIndex: Map<ReplyModel['id'], number> = new Map();
  private _replies: ReplyModel[] = [];

  public get getAll(): ReplyModel[] {
    return this._replies;
  }

  public getById(id: ReplyModel['id']): ReplyModel {
    if (!this._idToIndex.has(id)) {
      return new ReplyModel();
    }

    return this.getAll?.[this._idToIndex.get(id)] || new ReplyModel();
  }

  public getByKeyword(keyword: ReplyModel['keyword']): ReplyModel {
    if (!this._keywordToId.has(keyword)) {
      return new ReplyModel();
    }

    return this.getById(this._keywordToId.get(keyword));
  }

  public add(messageTemplate?: MessageTemplateType | void): RepliesCache {
    if (!messageTemplate || !messageTemplate?.id?.length) {
      console.warn('Add::MessageTemplate is invalid', messageTemplate);
      return;
    }

    const replyModel: ReplyModel = Builder(ReplyModel)
      .id(parseAsString(messageTemplate.id))
      .keyword(parseAsString(messageTemplate?.description))
      .name(parseAsString(messageTemplate?.name))
      .subject(parseAsString(messageTemplate?.subject))
      .body(parseAsString(messageTemplate?.body))
      .text(parseAsString(messageTemplate?.text))
      .build();

    if (this._idToIndex.has(replyModel.id)) {
      console.warn('MessageTemplate idToIndex already has', replyModel);
    }

    if (replyModel.keyword && this._keywordToId.has(replyModel.keyword)) {
      console.warn('MessageTemplate keywordToId already has', replyModel);
    }

    this._replies.push(replyModel);
    this._idToIndex.set(replyModel.id, this._replies.length - 1);
    if (replyModel.keyword.length && !this._keywordToId.has(replyModel.keyword)) {
      this._keywordToId.set(replyModel.keyword, replyModel.id);
    }

    return this;
  }

  public update(messageTemplate?: MessageTemplateType | void): RepliesCache {
    if (!messageTemplate || !messageTemplate?.id?.length) {
      console.warn('Update::MessageTemplate is invalid', messageTemplate);
      return;
    }

    const newReplyModel: ReplyModel = Builder(ReplyModel)
      .id(parseAsString(messageTemplate.id))
      .keyword(parseAsString(messageTemplate?.description))
      .name(parseAsString(messageTemplate?.name))
      .subject(parseAsString(messageTemplate?.subject))
      .body(parseAsString(messageTemplate?.body))
      .text(parseAsString(messageTemplate?.text))
      .build();

    const oldReplyModelIndex: number | -1 = this.getAll.findIndex(
      (oldReplyModel: ReplyModel) => oldReplyModel.id === newReplyModel.id
    );
    if (oldReplyModelIndex === -1) {
      console.error('Update::MessageTemplate cannot find message by id', newReplyModel);
      return;
    }

    if (this._replies[oldReplyModelIndex].keyword !== newReplyModel.keyword) {
      this._keywordToId.delete(this._replies[oldReplyModelIndex].keyword);
      this._keywordToId.set(newReplyModel.keyword, newReplyModel.id);
    }

    this._replies[oldReplyModelIndex] = newReplyModel;

    return this;
  }

  public getRepliesWithFilter(searchText: string = ''): ReplyModel[] {
    if (!searchText?.length) {
      return this.getAll;
    }

    const formattedSearchText: string = formatStringForSearch(searchText);

    return this._getIncludesSuggestions(formattedSearchText);
  }

  public getSuggestions(searchText: string = ''): ReplyModel[] {
    const formattedSearchText: string = formatStringForSearch(searchText);
    if (!formattedSearchText.length) {
      return [];
    }

    const keywordReplyModel: ReplyModel = this.getByKeyword(formattedSearchText);

    return uniqBy(
      [
        keywordReplyModel.id.length ? keywordReplyModel : undefined,
        ...this._getStartsWithSuggestions(formattedSearchText),
        ...this._getIncludesSuggestions(formattedSearchText),
      ].filter(Boolean),
      'id'
    );
  }

  public isValidReplyModel(replyModel: ReplyModel): boolean {
    return !!replyModel.id.length && replyModel instanceof ReplyModel;
  }

  private _getStartsWithSuggestions(formattedSearchText: string): ReplyModel[] {
    return this.getAll.filter((reply: ReplyModel) => isReplyStartsWith(reply, formattedSearchText));
  }

  private _getIncludesSuggestions(formattedSearchText: string): ReplyModel[] {
    return this.getAll.filter((reply: ReplyModel) => isReplyIncludes(reply, formattedSearchText));
  }
}

const RepliesCacheService: RepliesCache = new RepliesCache();

export { RepliesCacheService };
