import get from 'lodash/get';
import has from 'lodash/has';
import parseAsString from 'lodash/toString';

interface GraphQLError {
  code?: number;
  message?: string;
}

const parseGraphQLErrorsAsText = (errorToParse: unknown): string => {
  if (!has(errorToParse, 'graphQLErrors')) {
    return get(errorToParse, 'message', '');
  }

  const errors: GraphQLError[] = get(errorToParse, 'graphQLErrors', []);
  if (!errors.length) {
    return '';
  }

  const errorText: string = errors
    .map((error: GraphQLError) => parseAsString(error?.message).trim())
    .filter(Boolean)
    .join(', ');

  return errors.length > 1 ? `Reasons: ${errorText}.` : `Reason: ${errorText}.`;
};

export { parseGraphQLErrorsAsText };
