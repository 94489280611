enum MessageStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  READ = 'read',

  UNDELIVERED = 'undelivered',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export { MessageStatusEnum };
