import type { OutputData } from '@editorjs/editorjs';

class EditorPropsModel {
  public id: string = '';
  public initialData: OutputData = { blocks: [] }; // todo

  // types from EditorConfig
  public autofocus: boolean = false;
  public hideToolbar: boolean = false;
  public minHeight: number = 120;
  public readOnly: boolean = false;
  public placeholder: string = 'Write here';
}

export { EditorPropsModel };
