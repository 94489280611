
import type { SetupContext } from 'vue';
import { defineComponent, toRef } from 'vue';

import { useMessagingStore } from '~/messaging';

import { ChatSegmentEnum } from '@/types';

const ChatInboxFilter = defineComponent({
  emits: ['update-inbox'],
  setup(_props, { emit }: SetupContext) {
    const messagingStore = useMessagingStore();

    const currentFilter = toRef(messagingStore, 'currentFilter');

    const { setCurrentFilter } = messagingStore;

    const getButtonVariant = (filter: ChatSegmentEnum): 'primary' | 'light' => {
      return currentFilter.value === filter ? 'primary' : 'light';
    };

    const toggleInput = (newFilter: ChatSegmentEnum) => {
      setCurrentFilter(newFilter === currentFilter.value ? ChatSegmentEnum.ASSIGNED : newFilter);

      emit('update-inbox');
    };

    return {
      currentFilter,

      getButtonVariant,
      toggleInput,
    };
  },
});

export default ChatInboxFilter;
