type SupportedKeys =
  | 'Ctrl+Alt+KeyA'
  | 'Ctrl+Alt+KeyQ'
  | 'Ctrl+Alt+KeyD'
  | 'Ctrl+Alt+KeyG'
  | 'Ctrl+Alt+KeyF'
  | 'Ctrl+Alt+KeyR'
  | 'Ctrl+Alt+KeyT'
  | 'Ctrl+Alt+KeyY'
  | 'Ctrl+Alt+KeyU'
  | 'Ctrl+Alt+Up'
  | 'Ctrl+Alt+Down'
  | 'Ctrl+Alt+KeyB'
  | 'Ctrl+Shift+KeyS'
  | 'Ctrl+Alt+KeyS'
  | 'Tab';

type SupportedShortcuts =
  | 'ASSIGN'
  | 'SNOOZE'
  | 'OPEN'
  | 'CLOSE'
  | 'COMPOSE_REPLY'
  | 'COMPOSE_NOTE'
  | 'COMPOSE_ACTION'
  | 'INSERT_ATTACHMENT'
  | 'INSERT_MACRO'
  | 'PREVIOUS_CONVERSATION'
  | 'NEXT_CONVERSATION'
  | 'SEND'
  | 'SEND_AND_CLOSE'
  | 'SEND_AND_SNOOZE'
  | 'INSERT_SUGGESTION';

type SupportedCategories = 'CONVERSATION' | 'NAVIGATION' | 'COMPOSER';

class ShortcutModel {
  public name: SupportedShortcuts = 'COMPOSE_REPLY';
  public code: SupportedKeys = 'Ctrl+Alt+KeyG'; // key or keys combination to press to activate

  public description: string = 'Compose reply'; // title displayed in help section
  public category: SupportedCategories = 'CONVERSATION'; // Category displayed in help section
}

export { ShortcutModel };
