import { Builder } from 'builder-pattern';

const tweenVarsDefault: GSAPTweenVars = Builder<GSAPTweenVars>()
  .autoAlpha(0)
  .clearProps('all')
  .duration(0.2)
  .height(0)
  .lazy(true)
  .margin(0)
  .padding(0)
  .build();

export { tweenVarsDefault };
