
import { computed, defineComponent, toRef } from 'vue';

import { ChatsCacheService, useMessagingStore } from '~/messaging';

const ChatWindow = defineComponent({
  setup() {
    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');

    const activeChatId = computed((): string => ChatsCacheService.get(activeChat.value)?.id || '');

    return {
      activeChatId,
    };
  },
});

export default ChatWindow;
