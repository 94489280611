import gql from 'graphql-tag';

const TRY_CREATE_SUBSCRIPTION = gql`
  mutation ($patientId: UUID!, $onlyActions: Boolean!) {
    tryCreateSubscription(patientId: $patientId, onlyActions: $onlyActions) {
      status
    }
  }
`;

export { TRY_CREATE_SUBSCRIPTION };
