import gql from 'graphql-tag';

const SYNC_STRIPE_CUSTOMER = gql`
  mutation ($patientId: UUID!) {
    syncStripeCustomer(patientId: $patientId) {
      status
    }
  }
`;

export { SYNC_STRIPE_CUSTOMER };
