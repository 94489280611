import gql from 'graphql-tag';

const UPDATE_MESSAGE_PRIORITY = gql`
  mutation UpdateMessagePriority($messageId: UUID!, $priority: Int!) {
    updateMessagePriority(messageId: $messageId, priority: $priority) {
      status
    }
  }
`;

export { UPDATE_MESSAGE_PRIORITY };
