import sortedUniqBy from 'lodash/sortedUniqBy';

import { isValid } from 'date-fns';

const sortUniqByCreatedAt = <T extends { createdAt: string }>(dataWithDates: T[] = []): T[] => {
  return sortedUniqBy(
    (dataWithDates || [])
      .filter((data: T) => data?.createdAt?.length && isValid(new Date(data.createdAt)))
      .sort((a: T, b: T) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    'id'
  );
};

export { sortUniqByCreatedAt };
