export enum PatientProgressStageEnum {
  unknown = 'Unknown',
  incomplete_registration = 'ICO',
  pre_testing = 'Pre-Testing',
  testing = 'Testing',
  insurance = 'Insurance',
  pre_clinical = 'Pre-Clinical',
  consultation = 'Consultation',
  subscription = 'Subscription',
  on_hold = 'On Hold',
  cancelled = 'Cancelled',
  completed = 'Completed',
}

export type PatientProgressStageEnumKey = keyof typeof PatientProgressStageEnum;

export const progressStageOptions = Object.entries(PatientProgressStageEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));
