import isNil from 'lodash/isNil';

let answer: void | boolean = null;

const isMacDevice = (): boolean => {
  if (!isNil(answer)) {
    return answer as boolean;
  }

  answer = /(Mac|iPhone|iPod|iPad)/i.test(window?.navigator?.userAgent || '');

  return answer;
};

export { isMacDevice };
