import { Builder } from 'builder-pattern';

import { ShortcutModel } from '../models/ShortcutModel';

// if required component is not rendered - shortcuts won't be working
// prettier-ignore
const availableShortcuts = [
  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Assign')
    .code('Ctrl+Alt+KeyA')
    .name('ASSIGN')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Snooze')
    .code('Ctrl+Alt+KeyQ')
    .name('SNOOZE')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Open')
    .code('Ctrl+Alt+KeyD')
    .name('OPEN')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Close')
    .code('Ctrl+Alt+KeyF')
    .name('CLOSE')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Compose reply')
    .code('Ctrl+Alt+KeyG')
    .name('COMPOSE_REPLY')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Compose note')
    .code('Ctrl+Alt+KeyR')
    .name('COMPOSE_NOTE')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Compose action')
    .code('Ctrl+Alt+KeyT')
    .name('COMPOSE_ACTION')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Insert attachment')
    .code('Ctrl+Alt+KeyY')
    .name('INSERT_ATTACHMENT')
    .build(),

  Builder(ShortcutModel)
    .category('CONVERSATION')
    .description('Insert macro')
    .code('Ctrl+Alt+KeyU')
    .name('INSERT_MACRO')
    .build(),

  Builder(ShortcutModel)
    .category('NAVIGATION')
    .description('Previous conversation')
    .code('Ctrl+Alt+Up')
    .name('PREVIOUS_CONVERSATION')
    .build(),

  Builder(ShortcutModel)
    .category('NAVIGATION')
    .description('Next conversation')
    .code('Ctrl+Alt+Down')
    .name('NEXT_CONVERSATION')
    .build(),

  // there is no reliable way to prevent data from being inserted in the composer
  Builder(ShortcutModel)
    .category('COMPOSER')
    .description('Send')
    .code('Ctrl+Alt+KeyB')
    .name('SEND')
    .build(),

  Builder(ShortcutModel)
    .category('COMPOSER')
    .description('Send and close')
    .code('Ctrl+Shift+KeyS')
    .name('SEND_AND_CLOSE')
    .build(),

  Builder(ShortcutModel)
    .category('COMPOSER')
    .description('Send and snooze')
    .code('Ctrl+Alt+KeyS')
    .name('SEND_AND_SNOOZE')
    .build(),

  Builder(ShortcutModel)
    .category('COMPOSER')
    .description('Insert suggestion (if available)')
    .code('Tab')
    .name('INSERT_SUGGESTION')
    .build()
] as const;

export { availableShortcuts };
