import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';

import type { TagType } from '@/types';

const useTagsStore = defineStore('tags', () => {
  const _tags = ref<TagType[]>([]);

  const hasTags = computed((): boolean => !!_tags.value.length);

  const tags = computed((): TagType[] => _tags.value);

  const addTag = (newTag: TagType): void => {
    _tags.value.push(newTag);
  };

  const removeTag = (tagId: TagType['id']): void => {
    _tags.value = _tags.value.filter((tag: TagType) => tag.id !== tagId);
  };

  const setTags = (newTags: TagType[]): void => {
    _tags.value = newTags;
  };

  const $reset = (): void => {
    _tags.value = [];
  };

  return {
    hasTags,
    tags,

    addTag,
    removeTag,
    setTags,

    $reset,
  };
});

export { useTagsStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useTagsStore, import.meta.webpackHot));
}
