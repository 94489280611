import { CHAT_LIMIT } from '../defaults/limits';

// dont set chat/chats limit too small, because all returned messages could be with same timestamp, and in result, next query will be the same
// which will cause loop/recursion and no new messages after broken timestamp wil be received

class ChatArgumentsModel {
  public id: string; // UUID
  public search: string | void;
  public onlyMessagesBefore: string | void; // Datetime
  public onlyMessagesAfter: string | void; // Datetime; this will return always current message + next messages
  public limit: number = CHAT_LIMIT;
}

export { ChatArgumentsModel };
