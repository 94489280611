const keyboardKeysAliases: Map<string, string> = new Map();

keyboardKeysAliases.set('Control', 'Ctrl');
keyboardKeysAliases.set('ControlRight', 'Ctrl');
keyboardKeysAliases.set('ControlLeft', 'Ctrl');
keyboardKeysAliases.set('Ctl', 'Ctrl');
keyboardKeysAliases.set('Ctrl', 'Ctrl');

keyboardKeysAliases.set('MetaRight', 'Meta');
keyboardKeysAliases.set('MetaLeft', 'Meta');
keyboardKeysAliases.set('OSRight', 'Meta');
keyboardKeysAliases.set('OSLeft', 'Meta');
keyboardKeysAliases.set('Meta', 'Meta');

keyboardKeysAliases.set('AltRight', 'Alt');
keyboardKeysAliases.set('AltLeft', 'Alt');
keyboardKeysAliases.set('Alt', 'Alt');

keyboardKeysAliases.set('ShiftRight', 'Shift');
keyboardKeysAliases.set('ShiftLeft', 'Shift');

export { keyboardKeysAliases };
