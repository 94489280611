import { useStaffersStore } from '@/stores/staffers';
import type { NavigationGuardNext, RawLocation, Route } from 'vue-router';

const useNavigationGuardBeforeEnterVerifyWindowsBetaFeature = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
): any => {
  console.warn(
    'REMEMBER REMOVE: useNavigationGuardBeforeEnterVerifyWindowsBetaFeature when windows feature goes live!'
  );

  if (useStaffersStore()?.currentStafferBetaFeatures?.windows && to?.query?.sidebar?.length) {
    const query = { ...(to.query || {}) };
    const objectId = query?.objectId ? `$${query.objectId}` : '';
    const windows = [`${query.sidebar}${objectId}`];

    delete query.sidebar;
    delete query.objectId;

    const location: RawLocation = { path: to.path, query: { ...query, windows }, hash: to.hash, replace: true };

    return next(location);
  }

  return next();
};

export { useNavigationGuardBeforeEnterVerifyWindowsBetaFeature };
