import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

// this query is very heavy, don't use it to fetch messages
// Just patients
const GET_CHATS = gql`
  ${CHAT}
  ${MESSAGE}
  query GetChats(
    $assigneeId: UUID
    $segment: ChatSegmentEnum
    $limit: Int
    $offset: Int
    $participantId: UUID
    $search: String
    $sortBy: String
    $teamId: UUID
    $latestMessagePriorityGte: Int
    $latestMessageCreatedAfter: DateTime
    $latestMessageCreatedBefore: DateTime
  ) {
    chats(
      assigneeId: $assigneeId
      segment: $segment
      limit: $limit
      offset: $offset
      participantId: $participantId
      search: $search
      sortBy: $sortBy
      teamId: $teamId
      latestMessagePriorityGte: $latestMessagePriorityGte
      latestMessageCreatedAfter: $latestMessageCreatedAfter
      latestMessageCreatedBefore: $latestMessageCreatedBefore
    ) {
      ...Chat
      latestMessage {
        ...Message
      }
      latestClientMessage {
        ...Message
      }
    }
  }
`;

export { GET_CHATS };
