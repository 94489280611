import type { StafferType, UserGroupKey } from '@/types';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { toValue } from '@vueuse/core';
import { computed, defineComponent, type PropType } from 'vue';
import { useStaffersStore } from '@/stores/staffers';
import { UserGroup } from '@/types';
export function useStafferInGroup(staffer: MaybeRefOrGetter<StafferType> = () => useStaffersStore().currentStaffer) {
  return computed((): Record<UserGroupKey | 'ALL', boolean> => {
    const result = {
      ENVIRONMENT_ALLERGY_PROVIDERS: true,
      FOOD_ALLERGY_PROVIDERS: true,
      WEIGHT_LOSS_PROVIDERS: true,
      ALL: true,
    };

    const groups = (toValue(staffer)?.groups ?? []).map(({ id }) => id);

    if (!groups?.length) {
      return result;
    }

    return Object.entries(UserGroup).reduce<Record<UserGroupKey | 'ALL', boolean>>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: groups.includes(`${value}`),
        ALL: acc.ALL && groups.includes(`${value}`),
      }),
      result
    );
  });
}

export const UseStafferInGroup = defineComponent({
  name: 'UseStafferInGroup',
  props: {
    staffer: {
      default: (): StafferType => null,
      required: false,
      type: Object as PropType<StafferType>,
    },
  },
  setup(props, { slots }) {
    const staffersStore = useStaffersStore();
    const inGroup = useStafferInGroup(() => props.staffer ?? staffersStore.currentStaffer);

    return () => {
      if (slots.default) {
        return slots.default({ ...inGroup.value, ...props });
      }
    };
  },
});
