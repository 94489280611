import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';

// Staffers are sorted by name

const useModalsStore = defineStore('modals', () => {
  const openModals = ref<string[]>([]);

  const hasOpenModals = computed((): boolean => !!openModals.value.length);

  const openModal = (modalKey: string): void => {
    if (openModals.value.find((openModalKey) => openModalKey === modalKey)) {
      return;
    }

    openModals.value.push(modalKey);
  };

  const closeModal = (modalKey: string): void => {
    openModals.value = openModals.value.filter((openModalKey) => openModalKey !== modalKey);
  };

  const $reset = (): void => {
    openModals.value = [];
  };

  return {
    openModals,

    hasOpenModals,

    openModal,
    closeModal,

    $reset,
  };
});

export { useModalsStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useModalsStore, import.meta.webpackHot));
}
