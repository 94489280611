import type { UUID } from '.';

export type AppointmentOutcomeType = {
  id: UUID;
  title: string;
};

export const INSURANCE_VISIT_OUTCOME_ID: UUID = '0181d3c9-e58e-c2de-c574-f3f94a3eb141';
export const SLIT_RX_INITIATED_OUTCOME_ID: UUID = '017cf1c3-d09c-e2d4-41d2-44a2fcc5d53f';
export const SLIT_CANCELLED_OUTCOME_ID: UUID = '017cf1c3-d09c-e912-ed35-211ff9860790';
