/* eslint @typescript-eslint/ban-ts-comment: 0 */
import type editorHtml from 'editorjs-html';
import isFunction from 'lodash/isFunction';
import { defer } from '../helpers/defer';

let parser: editorHtml = null;

const wait = defer<typeof parser>();

const getHtmlParser = (): Promise<editorHtml> => {
  return isFunction(parser) ? Promise.resolve(parser) : wait;
};

type TableBlock = {
  type: 'table';
  data: {
    withHeadings: boolean;
    content: string[][];
  };
};

// https://github.com/pavittarx/editorjs-html/pull/42
const tableParser = (block: TableBlock): string => {
  const tableData = block.data.content;
  const withHeadings = block.data.withHeadings;

  let tableHTML = '<table>';

  tableData.forEach((row, rowIndex) => {
    if (withHeadings && rowIndex === 0) {
      tableHTML += '<thead>';
    }

    tableHTML += '<tr>';

    row.forEach((cellData) => {
      if (withHeadings && rowIndex === 0) {
        tableHTML += '<th>' + cellData + '</th>';
      } else {
        tableHTML += '<td>' + cellData + '</td>';
      }
    });

    tableHTML += '</tr>';

    if (withHeadings && rowIndex === 0) {
      tableHTML += '</thead>';
    }
  });

  tableHTML += '</table>';
  return tableHTML;
};

import('editorjs-html').then(({ default: _parser }) => {
  parser = _parser({ table: tableParser });
  wait.resolve(parser);
});

export { getHtmlParser };
