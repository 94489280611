import { sanitize } from 'dompurify';

import parseAsString from 'lodash/toString';

import { Type } from '../types/type';
import { formatSmsText } from './formatSmsText';

import { readableEnum } from '@/tools/converters/readableEnum';

import { MessageCategoryEnum, MessageChannelEnum, MessageType } from '@/types';

const skipAnchorApps: string[] = ['tel:', 'mailto:', 'sms:'];

const formatMessageBody = (message: MessageType, type: Type = Type.SYSTEM) => {
  const formattedBody: string = parseAsString(message?.body).trim() || readableEnum(type);
  if (type === Type.SYSTEM) {
    return message?.category === MessageCategoryEnum.SNOOZE ? `⏰ ${formattedBody}` : formattedBody;
  }

  const sanitizeHtml: HTMLElement = sanitize(
    message?.channel === MessageChannelEnum.SMS ? formatSmsText(formattedBody) : formattedBody,
    { RETURN_DOM: true }
  );

  const links = sanitizeHtml.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    if (skipAnchorApps.find((start: string) => link.href.startsWith(start))) {
      continue;
    }

    link.setAttribute('data-href', link.href);
    link.setAttribute('href', '#');
  }

  return sanitizeHtml.innerHTML;
};

export { formatMessageBody };
