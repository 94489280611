
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  toRef,
} from 'vue';

import { Builder } from 'builder-pattern';

import type { CloseOrOpenChatRequest } from '~/messaging';
import {
  CHAT_ACTION_LIMIT,
  ChatModel,
  ChatsCacheService,
  useMessaging,
  useMessagingApi,
  useMessagingShortcuts,
  useMessagingStore,
} from '~/messaging';

import { showError } from '@/components/errors';
import { capitalizeFirstLetter } from '@/tools/converters/capitalizeFirstLetter';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

const ChatCloseOrOpen = defineComponent({
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const { onShortcut, offShortcut } = useMessagingShortcuts();

    const { addOrUpdateChat } = useMessaging();
    const { closeChat, openChat } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');

    const { increaseChatActions, decreaseChatActions } = messagingStore;

    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    const currentChatName = computed((): string => currentChat.value?.displayName || 'No name');
    const currentChatAction = computed((): 'open' | 'close' => (currentChat.value?.closedAt ? 'open' : 'close'));

    const tooltipTitle = computed((): string =>
      currentChatName.value
        ? `${capitalizeFirstLetter(currentChatAction.value)} conversation with: ${currentChatName.value}`
        : `${capitalizeFirstLetter(currentChatAction.value)} this conversation`
    );

    const closeOrOpenChat = async (action: 'unset' | 'open' | 'close' = 'unset'): Promise<void> => {
      // copy chat id to properly handle loading state
      const { id, key, updatedAt, displayName, closedAt }: ChatModel = currentChat.value;
      if (!key) {
        console.error('close currentChatKey not found', activeChat.value);
        return;
      }

      const request: CloseOrOpenChatRequest = Builder<CloseOrOpenChatRequest>()
        .chatId(id)
        .limit(CHAT_ACTION_LIMIT)
        .onlyMessagesAfter(updatedAt)
        .build();

      increaseChatActions(id);

      try {
        if (action === 'unset') {
          addOrUpdateChat(closedAt ? await openChat(request) : await closeChat(request));
          return;
        }

        addOrUpdateChat(action === 'open' ? await openChat(request) : await closeChat(request));
      } catch (error) {
        showError(
          vm,
          `Failed to close or open conversation with ${displayName}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        decreaseChatActions(id);
      }
    };

    const openChatShortcut = async (): Promise<void> => await closeOrOpenChat('open');
    const closeChatShortcut = async (): Promise<void> => await closeOrOpenChat('close');

    const unmountListeners = (): void => {
      offShortcut('OPEN', openChatShortcut);
      offShortcut('CLOSE', closeChatShortcut);
    };

    const mountListeners = (): void => {
      onShortcut('OPEN', openChatShortcut);
      onShortcut('CLOSE', closeChatShortcut);
    };

    onBeforeMount(mountListeners);
    onActivated(mountListeners);

    onBeforeUnmount(unmountListeners);
    onDeactivated(unmountListeners);

    return {
      tooltipTitle,
      currentChatAction,

      closeOrOpenChat,
    };
  },
});

export default ChatCloseOrOpen;
