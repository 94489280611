import memoize from 'lodash/memoize';

import { getStaffers, getStaffersFirstNames } from '@/stores/staffers';

import { TeamsCacheService } from '../services/TeamsCache';
import { TeamModel } from '../models/TeamModel';

import type { StafferType } from '@/types';

const getChatAssignees = memoize(
  (staffersIds: StafferType['id'][] = [], teamsIds: TeamModel['id'][] = [], staffers: StafferType[] = []): string => {
    const assignedStaffers: string[] = getStaffersFirstNames(getStaffers(staffersIds, staffers));

    const assignedTeams: string[] = teamsIds.map(
      (teamId: TeamModel['id']) =>
        TeamsCacheService.get(teamId)?.getTeamWithMembers(staffers) || `Unknown team ${teamId}`
    );

    const staffersText: string =
      assignedStaffers.length > 1
        ? `Assignees: ${assignedStaffers.join(', ')}`
        : `Assignee: ${assignedStaffers.join()}`;

    const teamsText: string =
      assignedTeams.length > 1
        ? `Assigned teams: ${assignedTeams.join(', ')}`
        : `Assigned team: ${assignedTeams.join()}`;

    if (!assignedTeams.length) {
      return staffersText;
    }

    if (!assignedStaffers.length) {
      return teamsText;
    }

    if (!assignedTeams.length && !assignedStaffers.length) {
      return 'No chat assignees found';
    }

    return `${staffersText}. ${teamsText}.`;
  }
);

export { getChatAssignees };
