import gql from 'graphql-tag';

const GENERATE_REPLY_SUGGESTION = gql`
  mutation GenerateReplySuggestion($chatId: UUID!) {
    generateReplySuggestion(chatId: $chatId) {
      prompt
      text
    }
  }
`;

export { GENERATE_REPLY_SUGGESTION };
