import { formatDistanceStrict } from 'date-fns';

const getReadableTimeDistance = (timeAt: string | number | Date, baseTimeAt: string | number | Date): string => {
  try {
    const formattedTimeAt: number = new Date(timeAt).getTime();
    const formattedBaseTimeAt: number = new Date(baseTimeAt).getTime();
    if (formattedTimeAt > formattedBaseTimeAt) {
      return 'Just now';
    }

    return formatDistanceStrict(formattedTimeAt, formattedBaseTimeAt);
  } catch (error) {
    console.warn('getReadableTimeDistance', error);
  }

  return '';
};

export { getReadableTimeDistance };
