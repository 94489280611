import memoize from 'lodash/memoize';

import type { StafferType } from '@/types';

const getStafferFullNameAndEmail = (staffer: StafferType): string => {
  const firstAndLastName: string =
    `${staffer?.firstName || ''} ${staffer?.lastName || ''}`.trim() ||
    `${staffer?.displayName || ''}`.trim() ||
    `${staffer?.displayNameShort || ''}`.trim();

  if (firstAndLastName && staffer?.email) {
    return `${firstAndLastName} (${staffer.email})`;
  }

  return staffer?.email || staffer?.id || 'Failed to get staffer';
};

const getStafferFullNameAndEmailMemoized: typeof getStafferFullNameAndEmail = memoize(getStafferFullNameAndEmail);

export { getStafferFullNameAndEmailMemoized as getStafferFullNameAndEmail };
