import gql from 'graphql-tag';
import type { PatientType, UserType } from '@/types';

export const CREATE_VISIT_SURVEY_SESSION = gql`
  mutation CreateVisitSurveySession($userId: UUID!, $patientId: UUID) {
    createVisitSurveySession(userId: $userId, patientId: $patientId) {
      id
    }
  }
`;

export type CreateVisitSurveySessionVariables = {
  userId: UserType['id'];
  patientId?: PatientType['id'];
};

export type CreateVisitSurveySessionResult = { id: string };
