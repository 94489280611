
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { TagType } from '@/types';
import isString from 'lodash/isString';
import { useTagsStore } from '@/stores/tags';

@Component
export default class ProfileTags extends Vue {
  @Prop({ default: 'Add tag' }) public addButtonTitle!: string;
  @Prop({ default: (): (TagType | string)[] => [] }) public profileTags!: TagType[] | string[];
  @Prop({ default: false }) public readonly!: boolean | string;
  values: string[] = [];
  search = '';
  interactedWith = false; // flag showing that user had interacted with component
  isCollapsed = true;
  @Prop() private onTagClicked?: (tagId: string) => void | undefined;

  // computed
  get allTags(): TagType[] {
    return useTagsStore().tags;
  }

  // computed
  get allTagsMap(): Map<string, TagType> {
    return new Map(this.allTags.map((tag) => [tag.id, tag]));
  }

  get isReadonly(): boolean {
    // this.readonly has value of "" if it's used in form of <ProfileTags readonly />
    return this.readonly === '' ? true : !!this.readonly;
  }

  get criteria(): string {
    // Compute the search criteria
    return this.search.trim().toLowerCase();
  }

  get availableOptions(): TagType[] {
    const criteria = this.criteria;
    // Filter out already selected options
    const option = this.allTags.filter((tag) => !tag.isHidden && this.values.indexOf(tag.id) === -1);
    if (criteria) {
      // Show only options that match criteria
      return option.filter((tag) => tag.text.toLowerCase().indexOf(criteria) > -1);
    }
    // Show all options available
    return option;
  }

  get searchDesc(): string {
    if (this.criteria && this.availableOptions.length === 0) {
      return 'There are no tags matching your search criteria';
    }
    return '';
  }

  mounted() {
    this.values = this.profileTags.map((tag: TagType | string) => (isString(tag) ? tag : tag.id));
  }

  // handlers

  /**
   * after clicking on the tag, search patient database for people with that tag
   */
  onTagClick(tagId: string) {
    this.$router.push({
      name: 'patients',
      query: { search: `tags:"${this.tagTitle(tagId)}"` },
    });
  }

  /**
   * handles adding and removing tags
   */
  onTagActionClick(tag: TagType | string, action: any) {
    action(isString(tag) ? tag : tag.id);
    this.interactedWith = true;
    this.search = '';
  }

  @Watch('profileTags')
  onProfileTagsChange() {
    // react when profileTags prop is changed (`mounted` is not enough for that)
    this.values = this.profileTags.map((tag: TagType | string) => (isString(tag) ? tag : tag.id));
  }

  @Watch('values')
  onValuesChange() {
    if (!this.interactedWith) {
      // start emitting events only after user had interacted with component
      return;
    }

    this.isCollapsed = false;
    this.$emit('edit', this.values);
  }

  // helpers
  tagTitle(tagId: string): string {
    const tag = this.allTagsMap.get(tagId);
    if (!tag) {
      return tagId;
    }

    return tag.text;
  }

  tagVariant(tagId: string): string {
    const tag = this.allTagsMap.get(tagId);
    if (!tag) {
      return 'secondary';
    }

    switch (tag.kind) {
      case 'critical':
        return 'danger';
      case 'warning':
        return 'warning';
      default:
        return 'success';
    }
  }
}
