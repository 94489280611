import gql from 'graphql-tag';

const UNLINK_FILE_FROM_MEDICAL_DOCUMENT = gql`
  mutation UnlinkFileFromMedicalDocument($id: UUID!) {
    unlinkUploadedFile(uploadedFile: $id) {
      status
    }
  }
`;

export { UNLINK_FILE_FROM_MEDICAL_DOCUMENT };
