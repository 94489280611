import Vue from 'vue';

import VueEditor from 'vue2-editor';

import ChatAttachFile from '@/components/ChatAttachFile/ChatAttachFile.vue';
import ChatMacrosButton from '@/components/ChatMacrosButton/ChatMacrosButton.vue';
import ChatInbox from '@/components/ChatInbox/ChatInbox.vue';
import ChatWindow from '@/components/ChatWindow/ChatWindow.vue';
import RedirectModal from '@/components/RedirectModal/RedirectModal.vue';
import ChatMacrosModal from '@/components/ChatMacrosModal/ChatMacrosModal.vue';
import ChatShortcutsModal from '@/components/ChatShortcutsModal/ChatShortcutsModal.vue';
import ChatInboxSetStafferOrTeam from '@/components/ChatInboxSetStafferOrTeam/ChatInboxSetStafferOrTeam.vue';
import ChatInboxChat from '@/components/ChatInboxChat/ChatInboxChat.vue';
import ChatInboxFilter from '@/components/ChatInboxFilter/ChatInboxFilter.vue';
import ChatSearchInChats from '@/components/ChatSearchInChats/ChatSearchInChats.vue';
import TransitionSlide from '@/components/TransitionSlide/TransitionSlide.vue';
import LinearProgress from '@/components/LinearProgress.vue';
import ChatAssignee from '@/components/ChatAssignee/ChatAssignee.vue';
import ChatCloseOrOpen from '@/components/ChatCloseOrOpen/ChatCloseOrOpen.vue';
import ChatComposer from '@/components/ChatComposer/ChatComposer.vue';
import ChatSearchInChat from '@/components/ChatSearchInChat/ChatSearchInChat.vue';
import ChatLeadProfile from '@/components/ChatLeadProfile/ChatLeadProfile.vue';
import ChatSnooze from '@/components/ChatSnooze/ChatSnooze.vue';
import ChatStream from '@/components/ChatStream/ChatStream.vue';
import ChatMessage from '@/components/ChatMessage/ChatMessage.vue';
import ChatDate from '@/components/ChatDate/ChatDate.vue';
import ChatSystem from '@/components/ChatSystem/ChatSystem.vue';
import ChatAttachment from '@/components/ChatAttachment/ChatAttachment.vue';
import Modal from '@/components/Modal/Modal.vue';
import ModalController from '@/components/ModalController/ModalController.vue';
import Editor from '@/components/Editor/Editor.vue';
import AttachFile from '@/components/AttachFile/AttachFile.vue';
import MedicalDocument from '@/components/MedicalDocument/MedicalDocument.vue';
import ResizableColumns from '@/components/ResizableColumns/ResizableColumns.vue';
import PatientDetails from '@/views/patients/PatientDetails/PatientDetails.vue';
import ChatMessageContextMenu from '@/components/ChatMessageContextMenu/ChatMessageContextMenu.vue';
import ChatMessageFilter from '@/components/ChatMessageFilter/ChatMessageFilter.vue';
import ChatShortcutsButton from '@/components/ChatShortcutsButton/ChatShortcutsButton.vue';
import ChatGenerateReplySuggestionButton from '@/components/ChatGenerateReplySuggestionButton/ChatGenerateReplySuggestionButton.vue';
import CollapsibleCard from '@/components/CollapsibleCard.vue';
import QuestionnaireCard from '@/views/patients/PatientDetails/Cards/QuestionnaireCard.vue';
import TimedCardText from '@/components/TimedCardText.vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(VueEditor);

Vue.component('ModalController', ModalController);
Vue.component('Editor', Editor);
Vue.component('ResizableColumns', ResizableColumns);
Vue.component('PatientDetails', PatientDetails);

Vue.component('ChatAttachFile', ChatAttachFile);
Vue.component('ChatMacrosButton', ChatMacrosButton);
Vue.component('LinearProgress', LinearProgress);
Vue.component('ChatInbox', ChatInbox);
Vue.component('ChatWindow', ChatWindow);
Vue.component('RedirectModal', RedirectModal);
Vue.component('ChatInboxSetStafferOrTeam', ChatInboxSetStafferOrTeam);
Vue.component('ChatInboxChat', ChatInboxChat);
Vue.component('ChatInboxFilter', ChatInboxFilter);
Vue.component('ChatSearchInChats', ChatSearchInChats);
Vue.component('ChatLeadProfile', ChatLeadProfile);
Vue.component('ChatMacrosModal', ChatMacrosModal);
Vue.component('ChatShortcutsModal', ChatShortcutsModal);
Vue.component('ChatShortcutsButton', ChatShortcutsButton);
Vue.component('ChatAssignee', ChatAssignee);
Vue.component('ChatCloseOrOpen', ChatCloseOrOpen);
Vue.component('ChatComposer', ChatComposer);
Vue.component('ChatSearchInChat', ChatSearchInChat);
Vue.component('ChatSnooze', ChatSnooze);
Vue.component('ChatStream', ChatStream);
Vue.component('ChatMessage', ChatMessage);
Vue.component('ChatMessageContextMenu', ChatMessageContextMenu);
Vue.component('ChatMessageFilter', ChatMessageFilter);
Vue.component('ChatDate', ChatDate);
Vue.component('ChatSystem', ChatSystem);
Vue.component('ChatAttachment', ChatAttachment);
Vue.component('ChatGenerateReplySuggestionButton', ChatGenerateReplySuggestionButton);
Vue.component('QuestionnaireCard', QuestionnaireCard);
Vue.component('TimedCardText', TimedCardText);

Vue.component('CollapsibleCard', CollapsibleCard);
Vue.component('Modal', Modal);
Vue.component('TransitionSlide', TransitionSlide);

Vue.component('MedicalDocument', MedicalDocument);
Vue.component('AttachFile', AttachFile);
