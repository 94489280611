export enum PatientStatus {
  any = 'any',
  unknown = 'Unknown',
  incomplete_registration = 'ICO',
  testing = 'Testing',
  insurance = 'Insurance',
  consultation = 'Consultation',
  subscription = 'Subscription',
  no_service = 'Not a patient anymore',
}
