
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  ref,
  toRef,
  toRefs,
} from 'vue';

import { Builder } from 'builder-pattern';

import { useRouter } from 'vue-router/composables';

import { useInterval, useTimeoutPoll } from '@vueuse/core';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import {
  eventAuthorToReadableText,
  eventSourceToReadableText,
  eventTypeToReadableText,
  sortUniqByCreatedAt,
  SystemEventsArgumentsModel,
  useSystemEventsApi,
} from '@/stores/systemEvents';

import { useStaffersStore } from '@/stores/staffers';

import { showError } from '@/components/errors';
import type { PatientType, SystemEventType } from '@/types';

import { getReadableDateTimeShort } from '~/messaging';

const POLL_INTERVAL: 180000 = 180000 as const; // 3 mins

const PatientSystemEventsCard = defineComponent({
  props: {
    email: {
      default: '',
      required: false,
      type: String,
    },
    userId: {
      default: '',
      required: false,
      type: String,
    },
  },
  setup(props: { userId: PatientType['userId']; email: PatientType['user']['email'] }) {
    const vm = getCurrentInstance?.()?.proxy;
    const router = useRouter();

    const { email, userId } = toRefs<{ userId: PatientType['userId']; email: PatientType['user']['email'] }>(props);

    const systemEvents = ref<SystemEventType[]>([]);

    const { loading, getSystemEvents } = useSystemEventsApi();

    const staffersStore = useStaffersStore();
    const staffers = toRef(staffersStore, 'staffers');

    const { counter, reset } = useInterval(1000, { controls: true });

    const progressValue = computed((): number => {
      const value: number = ((counter.value * 1000) / POLL_INTERVAL) * 100;
      return value > 100 ? 100 : value;
    });

    const tableItems = computed((): Record<string, string>[] =>
      systemEvents.value
        .filter((event: SystemEventType) => event?.user?.id?.length && event?.user?.id === userId.value)
        .map((event: SystemEventType) => {
          return {
            at: getReadableDateTimeShort(event?.createdAt),
            author: eventAuthorToReadableText(event, userId.value, staffers.value),
            source: eventSourceToReadableText(event?.source),
            type: eventTypeToReadableText(event?.type),
            old: event?.originalValue || '-',
            new: event?.newValue || '-',
          };
        })
    );

    const isEmpty = computed((): boolean => !tableItems.value.length);

    const pollPatientLastSystemEvents = async (id: PatientType['userId'] = userId.value): Promise<void> => {
      try {
        const newSystemEvents: SystemEventType[] = await getSystemEvents(
          Builder(SystemEventsArgumentsModel).clientId(id).limit(100).build()
        );

        systemEvents.value = sortUniqByCreatedAt([...newSystemEvents, ...systemEvents.value]);
      } catch (error: any) {
        showError(vm, `Failed to get system events user id: ${id}. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        reset();
      }
    };

    const { pause: pausePollSystemEvents, resume: resumePollSystemEvents } = useTimeoutPoll(
      pollPatientLastSystemEvents,
      POLL_INTERVAL,
      {
        immediate: false,
      }
    );

    const pause = (): void => {
      pausePollSystemEvents();
    };

    const resume = (): void => {
      resumePollSystemEvents();
    };

    onBeforeUnmount(pause);
    onDeactivated(pause);

    onMounted(resume);
    onActivated(resume);

    const openSystemEvents = async (): Promise<void> => {
      await router.push({
        name: 'system-events',
        query: {
          search: email.value,
        },
      });
    };

    return {
      loading,

      isEmpty,
      progressValue,

      tableItems,

      openSystemEvents,
    };
  },
});

export default PatientSystemEventsCard;
