import gql from 'graphql-tag';

const CONFIRM_PATIENT_IDENTITY = gql`
  mutation ($id: UUID!) {
    confirmPatientIdentity(id: $id) {
      status
    }
  }
`;

export { CONFIRM_PATIENT_IDENTITY };
