import { Builder } from 'builder-pattern';
import parseAsString from 'lodash/toString';

import { MedicalFileModel } from '../models/MedicalFileModel';

import type { UploadedFileType } from '@/types';
import { UploadedFileKindEnum } from '@/types';

import { getKey } from '@/tools/getKey';

class FilesCache extends Map<MedicalFileModel['id'], MedicalFileModel> {
  public add(fileType?: UploadedFileType | void): ReturnType<FilesCache['get']> {
    if (!fileType || !fileType?.id?.length) {
      console.warn('File is invalid', fileType);
      return;
    }

    const key: string = getKey(fileType);
    if (!this.has(key)) {
      this.set(
        fileType.id,
        Builder(MedicalFileModel)
          .id(fileType.id)
          .createdAt(parseAsString(fileType?.createdAt))
          .updatedAt(parseAsString(fileType?.updatedAt))

          .name(parseAsString(fileType?.name))
          .s3Url(parseAsString(fileType?.s3DownloadLink))

          .contentType(
            (parseAsString(fileType?.contentType) as MedicalFileModel['contentType']) || 'application/octet-stream'
          )
          .kind(
            (parseAsString(fileType?.kind) as MedicalFileModel['kind']) || UploadedFileKindEnum.OTHER_MEDICAL_DOCUMENT
          )

          .build()
      );
    }

    return this.get(key);
  }
}

const FilesCacheService: FilesCache = new FilesCache();

export { FilesCacheService };
