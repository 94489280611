import type { AccountType } from '@/types';

import { getClientModel } from '../helpers/getClientModel';
import type { ClientModel } from '../models/ClientModel';

import { getKey } from '@/tools/getKey';

class ClientsCache extends Map<ClientModel['key'], ClientModel> {
  private _idToKey: Map<ClientModel['id'], ClientModel['key']> = new Map();

  public getById(id: ClientModel['id']): ReturnType<ClientsCache['get']> {
    return this.get(this._idToKey.get(id));
  }

  public add(client?: AccountType | void): ReturnType<ClientsCache['get']> {
    if (!client || !client?.id) {
      console.warn('Client is invalid', client);
      return;
    }

    const key: string = getKey(client);
    if (!this.has(key)) {
      const clientModel: ClientModel = getClientModel(client, key);
      this.set(clientModel.key, clientModel);
      if (this._idToKey.has(clientModel.id)) {
        console.warn('Client ID collision:', clientModel.id, clientModel.key);
      }

      this._idToKey.set(clientModel.id, clientModel.key);
    }

    return this.get(key);
  }
}

const ClientsCacheService: ClientsCache = new ClientsCache();

export { ClientsCacheService };
