
import { defineComponent, type PropType, toRefs } from 'vue';

import { readableEnum } from '@/tools/converters/readableEnum';

export default defineComponent({
  components: {
    CollapsibleCard: () => import('@/components/CollapsibleCard.vue'),
    TimedCardText: () => import('@/components/TimedCardText.vue'),
  },
  props: {
    data: {
      default: (): any[] => [],
      required: true,
      type: Array as PropType<any[]>,
    },
  },

  setup(props) {
    const { data } = toRefs(props);

    return {
      data,

      readableEnum,
    };
  },
});
