import { Builder } from 'builder-pattern';

import type { MimeType } from 'file-type';
import { fileTypeFromBlob } from 'file-type/core';

import type { Options } from 'browser-image-compression';
import imageCompression from 'browser-image-compression';

import parseAsString from 'lodash/toString';
import parseAsNumber from 'lodash/toNumber';

import { AttachmentModel } from '../models';

import { getRandomId } from '@/tools';
import { extractFileExtensionAndBaseName } from '@/tools/extractFileExtensionAndBaseName';

// returns new compressed file for sure in 10-15 sec, or nothing
const compressFile = async (file: File | void, type: MimeType): Promise<File | void> => {
  if (!file) {
    return;
  }

  const controller: AbortController = new AbortController();

  setTimeout(() => {
    const possibleError: Error = new Error('File compression takes too long, abort');

    if (progress < 80) {
      controller.abort(possibleError);
      return;
    }

    setTimeout(() => controller.abort(possibleError), 5000);
  }, 10000);

  let progress: number = 0; // 1-100
  try {
    return await imageCompression(
      new File([file], file.name, { type }),
      Builder<Options>()
        .fileType(type)
        .maxWidthOrHeight(2048)
        .onProgress((newProgress: number) => {
          progress = newProgress;
        })
        .signal(controller.signal) // abort in 10-15 sec
        .build()
    );
  } catch (error: Error | unknown) {
    console.error(error);
  }
};

const formatFileAsAttachmentModel = async (file: File | null): Promise<AttachmentModel> => {
  let fileTypeResult;
  try {
    fileTypeResult = await fileTypeFromBlob(file);
  } catch (e) {
    console.error(e);
  }

  console.log('fileTypeResult', fileTypeResult);

  const fileType: MimeType | 'application/octet-stream' = ((fileTypeResult && fileTypeResult?.mime) ||
    file?.type ||
    'application/octet-stream') as MimeType;

  console.log('fileType', fileType);

  const newFileInstance: File | void = file ? (await compressFile(file, fileType as MimeType)) || file : file || null;

  const { baseName, extension } = extractFileExtensionAndBaseName(file?.name, fileType);

  return (
    Builder(AttachmentModel)
      .id(getRandomId())
      .name(`${baseName}${extension || fileTypeResult?.ext ? '.' : ''}${extension || fileTypeResult?.ext || ''}`)
      .fileInstance(newFileInstance ? newFileInstance : null)
      .type(fileType as MimeType)
      .lastModified(parseAsNumber(parseAsString(file?.lastModified)) || -1)
      .size(parseAsNumber(parseAsString(file?.size)) || -1)
      .loading(true)
      // createObjectURL can cause memory leak, this needs to be unloaded manually, if possible
      .previewUrl(fileType.includes('image') && newFileInstance ? URL?.createObjectURL?.(newFileInstance) || '' : '')
      .build()
  );
};

export { formatFileAsAttachmentModel };
