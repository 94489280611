import gql from 'graphql-tag';

import { MESSAGE_TEMPLATE } from './fragments/MESSAGE_TEMPLATE';

const GET_MESSAGE_TEMPLATES = gql`
  ${MESSAGE_TEMPLATE}
  query GetMessageTemplates {
    messageTemplates(onlyMacro: true, offset: 0, limit: -1) {
      ...MessageTemplate
    }
  }
`;

export { GET_MESSAGE_TEMPLATES };
