import Cookies from 'js-cookie';

const isCookiesStorageSupported = (): boolean => {
  const testKey = '__storage_test__';

  try {
    Cookies.set(testKey, testKey);
    const isCookiesWorking: boolean = Cookies.get(testKey) === testKey;
    Cookies.remove(testKey);

    return isCookiesWorking;
  } catch (error: any) {
    return false;
  }
};

export { isCookiesStorageSupported };
