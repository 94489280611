import gql from 'graphql-tag';

import { MEDIAL_DOCUMENT } from './fragments/MEDIAL_DOCUMENT';

// create new document for user-id with description
const CREATE_MEDICAL_DOCUMENT = gql`
  ${MEDIAL_DOCUMENT}
  mutation CreateMedicalDocument($description: String, $clientId: UUID) {
    createMedicalDocument(description: $description, client: $clientId, uploadedFiles: []) {
      ...MedicalDocument
    }
  }
`;

export { CREATE_MEDICAL_DOCUMENT };
