import type { PatientType } from '@/types';
import { computed } from 'vue';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { toValue } from '@vueuse/core';

export const VIP_TAG_ID = '0191c797-1587-53f2-60a9-a09d9214ab17';

export type UseIsVIPOptions = {
  vipTagId?: string;
};

export function useIsVIP(patient: MaybeRefOrGetter<PatientType>, options?: MaybeRefOrGetter<UseIsVIPOptions>) {
  const tagIds = computed(() => (toValue(patient)?.tags ?? []).map(({ id }) => id));
  const isVIP = computed(() => tagIds.value.includes(toValue(options)?.vipTagId || VIP_TAG_ID));

  return { isVIP };
}
