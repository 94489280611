
import { computed, defineComponent, ref, SetupContext, toRefs, watch } from 'vue';

import sum from 'lodash/sum';

import { useMouse, useMousePressed } from '@vueuse/core';

import { ResizableColumnsPropsModel } from './models/ResizableColumnsPropsModel';

import { buildPropsFromModel } from '@/tools/props';

const ResizableColumns = defineComponent({
  props: buildPropsFromModel(new ResizableColumnsPropsModel()),
  emits: ['input'],
  setup(props: ResizableColumnsPropsModel, { emit }: SetupContext) {
    const { value, safeZone, stackColumns } = toRefs<ResizableColumnsPropsModel>(props);

    const resizingColumnIndex = ref<number | -1>(-1);
    const columnsContainerElement = ref<HTMLDivElement | null>(null);

    const { pressed } = useMousePressed({ target: columnsContainerElement });
    const { x } = useMouse({ target: columnsContainerElement });

    const resizing = computed((): boolean => resizingColumnIndex.value !== -1);

    const resizableColumnsClasses = computed(
      (): Record<string, boolean> => ({
        ['ResizableColumns--resizing']: resizing.value,
        ['ResizableColumns--stack-columns']: stackColumns.value,
      })
    );

    const columnsStyles = computed((): Pick<CSSStyleDeclaration, 'width'>[] =>
      value.value.map(
        (width: number): Pick<CSSStyleDeclaration, 'width'> => ({
          width: stackColumns.value ? '100%' : `${width}%`,
        })
      )
    );

    const startResize = (index: number): void => {
      resizingColumnIndex.value = index;
    };

    const stopResize = (): void => {
      resizingColumnIndex.value = -1;
    };

    const updateColumnWidth = (column: number, movingDistancePercent: number) => {
      const newValues: number[] = [...value.value];

      newValues[column] = newValues[column] + movingDistancePercent;
      newValues[column] = newValues[column] <= safeZone.value ? safeZone.value : newValues[column];

      newValues[column + 1] = newValues[column + 1] - movingDistancePercent;
      newValues[column + 1] = newValues[column + 1] + (100 - sum(newValues));

      if (newValues[column + 1] <= safeZone.value) {
        newValues[column + 1] = safeZone.value;
        newValues[column] = newValues[column] + (100 - sum(newValues));
      }

      emit('input', newValues);
    };

    watch(x, async (newX: number = 0, oldX: number = 0): Promise<void> => {
      if (!pressed.value || !resizing.value || !columnsContainerElement?.value?.offsetWidth) {
        return;
      }

      const containerWidth: number = columnsContainerElement.value.offsetWidth;
      const movingDistancePercent = parseFloat((((newX - oldX) / containerWidth) * 100).toFixed(3));

      updateColumnWidth(resizingColumnIndex.value, movingDistancePercent);
    });

    watch(pressed, async (newPressed: boolean = false): Promise<void> => {
      if (!newPressed && resizing.value) {
        stopResize();
      }
    });

    return {
      columnsContainerElement,

      resizableColumnsClasses,

      columnsStyles,
      stackColumns,

      startResize,
      stopResize,
    };
  },
});

export default ResizableColumns;
