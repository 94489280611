import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const OPEN_CHAT = gql`
  ${CHAT}
  ${MESSAGE}
  mutation OpenChat($chatId: UUID!, $limit: Int, $onlyMessagesAfter: DateTime) {
    reopenChat(chatId: $chatId, limit: $limit, onlyMessagesAfter: $onlyMessagesAfter) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { OPEN_CHAT };
