import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const LOGIN = gql`
  ${STAFFER}
  mutation LoginWithPassword($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      staffer {
        ...Staffer
      }
    }
  }
`;

export { LOGIN };
