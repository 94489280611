import parseAsNumber from 'lodash/toNumber';

const feetAndInchesToCentimeters = (feet: string = '0', inches: string = '0'): number => {
  const cmFromFeet: number = parseAsNumber(feet || 0) * 12 * 2.54;
  const cmFromInches: number = parseAsNumber(inches || 0) * 2.54;
  return parseAsNumber((cmFromFeet + cmFromInches).toFixed(2));
};

const poundsToKilogram = (weight: string = '0'): number => {
  const formattedWeight: number = parseAsNumber(weight || 0);
  if (!formattedWeight) {
    return 0;
  }

  return parseAsNumber(weight || 0) * 0.453592;
};

const calculateBmi = (feet: string = '0', inches: string = '0', pounds: string = '0'): number => {
  try {
    const weight: number = poundsToKilogram(pounds);
    const height: number = feetAndInchesToCentimeters(feet, inches);
    if (!weight || !height) {
      return 0;
    }

    return weight / Math.pow(height / 100, 2);
  } catch (e) {
    console.error(e);
  }

  return 0;
};

export { calculateBmi };
