import type { StafferType } from '@/types';
import { getStaffers, getStaffersFirstNames } from '@/stores/staffers';

class TeamModel {
  public id: string = ''; //UUID
  public name: string = '';
  public assignedStaffersIds: string[] = [];

  public getTeamWithMembers(staffers: StafferType[] = []): string {
    if (!this.assignedStaffersIds.length || !staffers.length) {
      return this.name;
    }

    return `${this.name} (${getStaffersFirstNames(getStaffers(this.assignedStaffersIds, staffers)).join(', ')})`;
  }
}

export { TeamModel };
