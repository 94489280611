class MessagingSounds {
  private _firstPoll: boolean = true;

  public constructor() {
    this._newMessageSoundControls.volume = 0.9;
  }

  private _lastAssignedChatIds: string[] = [];

  public get lastAssignedChatIds(): string[] {
    return this._lastAssignedChatIds;
  }

  public set lastAssignedChatIds(newLastAssignedChatIds: string[]) {
    this._lastAssignedChatIds = newLastAssignedChatIds;
  }

  private _newMessageSoundControls: HTMLAudioElement = new Audio('../assets/sounds/pop.mp3');

  public get newMessageSoundControls(): Readonly<HTMLAudioElement> {
    return this._newMessageSoundControls;
  }

  public shouldPlaySound(newAssignedChats: string[], oldAssignedChats: string[] = this.lastAssignedChatIds): boolean {
    if (this._firstPoll) {
      // dont play sound if its first poll
      this._firstPoll = false;
      return false;
    }

    const removedChatIds = oldAssignedChats.filter((chatId) => !newAssignedChats.includes(chatId));
    const formattedAssignedChatIds = oldAssignedChats.filter((chatId) => !removedChatIds.includes(chatId));

    if (newAssignedChats.join('-') === formattedAssignedChatIds.join('-')) {
      // arrays are equal by order and chat-ids
      // if chat was removed - ignore it
      return false;
    }

    if (window?.location?.href?.includes('/messaging') && document?.visibilityState === 'visible') {
      // dont play sound if already in messaging
      return false;
    }

    return true;
  }

  public async playSound(): Promise<void> {
    await this.newMessageSoundControls.play();
  }
}

export const MessagingSoundsService: MessagingSounds = new MessagingSounds();
