export enum PaymentOptionEnum {
  unknown = 'Undecided',
  insurance = 'Insurance',
  self_pay = 'Self Pay',
}

export type PaymentOptionEnumKey = keyof typeof PaymentOptionEnum;
export type PaymentOptionEnumValue = `${PaymentOptionEnum}`;

export const availablePaymentOptions = Object.entries(PaymentOptionEnum).map(([value, text]) => ({ text, value }));

export const availablePaymentOptionsNoUnknown = Object.entries(PaymentOptionEnum)
  .filter(([key]) => key !== 'unknown')
  .map(([value, text]) => ({ text, value }));
