import gql from 'graphql-tag';

const TEAM = gql`
  fragment Team on TeamType {
    id
    name
    staffers {
      id
    }
  }
`;

export { TEAM };
