import { AttachmentModel } from './AttachmentModel';
import { FileCapture } from '../types/fileCapture';

class AttachFilePropsModel {
  public placeholder: string = 'Add file';

  // files that attached/uploaded using this input
  // name of the value is used for veeValidate rules that are listening to that
  public value: AttachmentModel[] = [];

  // input file attributes
  public id: string = '';
  public autofocus: boolean = false;
  public capture: FileCapture = FileCapture.NOT_SET;
  public multiple: boolean = false; // true if we can attach multiple files
  public name: string = '';
  public title: string = '';

  // states
  public disabled: boolean = false;
  public loading: boolean = false;
  public required: boolean = false;
}

export { AttachFilePropsModel };
