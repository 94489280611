import gql from 'graphql-tag';

const UPDATE_PROVIDER_ACTION = gql`
  mutation UpdateProviderAction($id: UUID!, $status: String) {
    updateProviderAction(id: $id, status: $status) {
      status
    }
  }
`;

export { UPDATE_PROVIDER_ACTION };
