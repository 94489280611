const fallbackCopyTextToClipboard = (text: string): boolean => {
  const textArea: HTMLTextAreaElement = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (error: any) {
    console.log('fallbackCopyTextToClipboard error:', error);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

export { fallbackCopyTextToClipboard };
