import { computed, onActivated, onBeforeMount, onBeforeUnmount, onDeactivated, ref } from 'vue';

import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

import { sortByReference } from '@/tools/sortByReference';
import { createSharedComposable } from '@vueuse/core';

import { SpecialKey } from './types/specialKey';
import { getSpecialKeysIfPressed } from './helpers/getSpecialKeysIfPressed';
import { isValidEvent } from './validation/isValidEvent';
import { keyAlias } from './defaults/keyAlias';

const useKeysPressed = () => {
  const specialKeysOrder: SpecialKey[] = [SpecialKey.CTRL, SpecialKey.META, SpecialKey.ALT, SpecialKey.SHIFT];

  const _pressedKeys = ref<string[]>([]);

  const pressedKeys = computed((): string[] => _pressedKeys.value);
  const currentShortcut = computed((): string => pressedKeys.value.join('+'));

  const reset = (): void => {
    if (!_pressedKeys.value.length) {
      return;
    }

    _pressedKeys.value = [];
  };

  const addPressedKeysListener = (event: KeyboardEvent): void => {
    if (!isValidEvent(event)) {
      return;
    }

    const newPressedKey: string = keyAlias.has(event.code) ? keyAlias.get(event.code) : event.code;

    const newPressedKeys: string[] = sortByReference(
      uniq([..._pressedKeys.value, ...getSpecialKeysIfPressed(event), newPressedKey]),
      specialKeysOrder
    );

    if (isEqual(newPressedKeys, _pressedKeys.value)) {
      return;
    }

    _pressedKeys.value = newPressedKeys;
  };

  const removePressedKeysListener = (event: KeyboardEvent): void => {
    if (!isValidEvent(event)) {
      return;
    }

    const newUnpressedKey: string = keyAlias.has(event.code) ? keyAlias.get(event.code) : event.code;

    const newPressedKeys: string[] = sortByReference(
      uniq([..._pressedKeys.value, ...getSpecialKeysIfPressed(event)]),
      specialKeysOrder
    ).filter((key: string) => key !== newUnpressedKey);

    if (isEqual(newPressedKeys, _pressedKeys.value)) {
      return;
    }

    _pressedKeys.value = newPressedKeys;
  };

  const init = (): void => {
    reset();

    document?.addEventListener('keydown', addPressedKeysListener, { passive: true });
    document?.addEventListener('keyup', removePressedKeysListener, { passive: true });

    window?.addEventListener('focus', reset, { passive: true });
    window?.addEventListener('blur', reset, { passive: true });

    document.addEventListener('visibilitychange', reset, { passive: true });
  };

  const unmount = (): void => {
    document?.removeEventListener('keydown', addPressedKeysListener);
    document?.removeEventListener('keyup', removePressedKeysListener);

    window?.removeEventListener('focus', reset);
    window?.removeEventListener('blur', reset);

    document.removeEventListener('visibilitychange', reset);

    reset();
  };

  onBeforeMount(init);
  onActivated(init);

  onBeforeUnmount(unmount);
  onDeactivated(unmount);

  return {
    currentShortcut,
    pressedKeys,
  };
};

const useKeysPressedService: typeof useKeysPressed = createSharedComposable(useKeysPressed);

export { useKeysPressedService as useKeysPressed };
