enum UploadedFileKindEnum {
  UNKNOWN = 'unknown',
  LAB_RESULT = 'labresult',
  ID_CARD = 'idcard',
  SYMPTOM_PICTURE = 'symptompicture',
  INSURANCE_FRONT = 'insurancefront',
  INSURANCE_BACK = 'insuranceback',
  INSURANCE_CARD = 'insurancecard',
  ASTHMA_ACTION_PLAN = 'asthmaactionplan',
  OTHER_MEDICAL_DOCUMENT = 'other_medical_document',
}

export { UploadedFileKindEnum };
