import gql from 'graphql-tag';

import { MEDIAL_DOCUMENT } from './fragments/MEDIAL_DOCUMENT';

// there can be only one user with these documents, but many patients on the user
// and all of them have access to user documents
const GET_PATIENT_MEDICAL_DOCUMENTS = gql`
  ${MEDIAL_DOCUMENT}
  query GetPatientMedicalDocuments($id: UUID!) {
    patient(id: $id) {
      userId
      medicalDocuments {
        ...MedicalDocument
      }
    }
  }
`;

export { GET_PATIENT_MEDICAL_DOCUMENTS };
