import { AttachmentModel } from '../models/AttachmentModel';

const getPreviewImgStyles = (attachmentModel: AttachmentModel): Partial<CSSStyleDeclaration> => {
  let size: 'cover' | 'auto' = 'cover';
  let image: ReturnType<typeof require> | string = '';

  if (attachmentModel.type.includes('image') && attachmentModel.previewUrl.length) {
    image = attachmentModel.previewUrl;
  } else {
    size = 'auto';
    image = require('../svg/no-preview.svg');
  }

  return { backgroundImage: `url(${image})`, backgroundSize: size };
};

export { getPreviewImgStyles };
