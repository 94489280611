
import {
  computed,
  defineComponent,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  toRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import parseAsString from 'lodash/toString';

import type { Route } from 'vue-router';

import { useModalsStore } from '@/stores/modals/store';

const REDIRECT_KEY: 'to' = 'to' as const;
const MODAL_KEY: 'redirect-modal' = 'redirect-modal' as const;

const RedirectModal = defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    const modalsStore = useModalsStore();
    const openModals = toRef(modalsStore, 'openModals');

    const { openModal, closeModal } = modalsStore;

    const redirectTo = computed((): string => parseAsString(route?.query?.[REDIRECT_KEY]));

    const isRedirectPathValid = computed((): boolean => !!redirectTo.value.length);
    const isModalOpen = computed((): boolean => openModals.value.includes(MODAL_KEY));

    const dismissModal = async (): Promise<void> => {
      const query: Route['query'] = cloneDeep(merge({}, route?.query || {}));
      if (query?.[REDIRECT_KEY]?.length) {
        delete query[REDIRECT_KEY];
        await router.replace({
          ...(route || {}),
          query,
        });
      }

      if (isModalOpen.value) {
        closeModal(MODAL_KEY);
      }
    };

    const proceedToTheExternalWebsite = async (): Promise<void> => {
      window?.open(redirectTo.value, '_blank').focus();
      await dismissModal();
    };

    const init = async (): Promise<void> => {
      // modal is open and redirect is valid
      if (isModalOpen.value && isRedirectPathValid.value) {
        return;
      }

      // modal is open and redirect is not valid
      if (!isRedirectPathValid.value) {
        await dismissModal();
        return;
      }

      // modal is closed and redirect is valid
      openModal(MODAL_KEY);
    };

    // Use the router's navigation guard to track route changes
    router.beforeEach((to, from, next) => {
      if (isModalOpen.value || !to?.query?.[REDIRECT_KEY]?.length) {
        next();
        return;
      }

      openModal(MODAL_KEY);
      next();
    });

    watch(
      isModalOpen,
      async (newIsModalOpen: boolean = false): Promise<void> => {
        if (!newIsModalOpen && route?.query?.[REDIRECT_KEY]?.length) {
          await dismissModal();
        }
      },
      { flush: 'pre' }
    );

    onBeforeMount(init);
    onActivated(init);

    onBeforeUnmount(dismissModal);
    onDeactivated(dismissModal);

    return { MODAL_KEY, redirectTo, isRedirectPathValid, dismissModal, proceedToTheExternalWebsite };
  },
});

export default RedirectModal;
