
import type { SetupContext } from 'vue';
import { computed, defineComponent, getCurrentInstance, ref, toRefs, watch } from 'vue';
import { Builder } from 'builder-pattern';

import { useDropZone } from '@vueuse/core';
import { buildPropsFromModel } from '@/tools/props';

import { AttachFilePropsModel, AttachmentModel } from './models';
import { formatFileAsAttachmentModel, getPreviewImgStyles, removePreviewFile } from './helpers';
import { isPreviewFile } from './validation';

import { getRandomId } from '@/tools';
import { downloadFromLink } from '@/tools/downloadFromLink';
import { showError } from '@/components/errors';

const AttachFile = defineComponent({
  props: buildPropsFromModel(new AttachFilePropsModel()),
  emits: ['input'],
  setup(props: AttachFilePropsModel, { emit }: SetupContext) {
    const vm = getCurrentInstance?.()?.proxy;

    const { placeholder, value, id, autofocus, capture, multiple, name, title, disabled, loading, required } =
      toRefs<AttachFilePropsModel>(props);

    // ID field is required on the input for this component to work, either from props, or from this helper
    const randomId: string = getRandomId();

    const dropZoneElement = ref<HTMLElement>();

    const processingFiles = ref<boolean>(false);

    // assists with click event
    const formattedId = computed((): string => id.value || randomId);

    const formattedPlaceholder = computed((): string => (!isOverDropZone.value ? placeholder.value : 'Drop file here'));

    // assists with click event
    const inputLabelAttributes = computed((): { for: string; title: string } =>
      Builder<{ for: string; title: string }>().for(formattedId.value).title(title.value).build()
    );

    const inputAttributes = computed(
      (): HTMLInputElement =>
        Builder<HTMLInputElement>()
          .id(formattedId.value)
          .autofocus(autofocus.value)
          .disabled(disabled.value)
          .readOnly(disabled.value)
          .required(required.value)
          .required(required.value)
          .placeholder(placeholder.value)
          .type('file')
          .capture(capture.value)
          .multiple(multiple.value)
          .name(name.value)
          .build()
    );

    const isLoaderVisible = computed(
      (): boolean =>
        !!value.value.find((attachmentModel: AttachmentModel) => attachmentModel.loading) ||
        processingFiles.value ||
        loading.value
    );

    const filesAttached = async (filesInstances: FileList | File[] | null): Promise<void> => {
      if (processingFiles.value) {
        return;
      }

      processingFiles.value = true;
      if (!filesInstances || !filesInstances?.length) {
        return;
      }

      const result: AttachmentModel[] = multiple.value ? [...value.value] : [];
      if (!multiple.value) {
        result.push(await formatFileAsAttachmentModel(filesInstances?.[0]));
      } else {
        for (const file of Array.from(filesInstances)) {
          result.push(await formatFileAsAttachmentModel(file));
        }
      }

      emitInput(result);

      processingFiles.value = false;
    };

    const onInputChange = (event: Event) => {
      filesAttached((event?.target as HTMLInputElement)?.files);
    };

    const downloadAttachmentById = async (attachmentId: AttachmentModel['id']): Promise<void> => {
      processingFiles.value = true;
      const attachment: AttachmentModel | void = value.value.find(
        (attachmentModel: AttachmentModel) => attachmentModel.id === attachmentId
      );
      if (!attachment) {
        showError(vm, `Error while loading attachment ${attachmentId}.`);
        return;
      }

      await downloadFromLink(attachment.previewUrl, attachment.name, attachment.type);
      processingFiles.value = false;
    };

    const removeAttachmentById = (attachmentId: AttachmentModel['id']) => {
      emitInput(value.value.filter((attachmentModel: AttachmentModel) => attachmentModel.id !== attachmentId));
    };

    const emitInput = (newAttachmentList: AttachmentModel[]) => {
      emit('input', newAttachmentList);
    };

    watch(
      value,
      async (newAttachments: AttachmentModel[] = [], oldAttachments: AttachmentModel[] = []) => {
        oldAttachments.forEach((oldAttachment: AttachmentModel) => {
          if (!isPreviewFile(oldAttachment?.previewUrl)) {
            return;
          }

          if (newAttachments.find((newAttachment: AttachmentModel) => newAttachment.id === oldAttachment.id)) {
            return;
          }

          removePreviewFile(oldAttachment.previewUrl);
        });
      },
      { immediate: true }
    );

    const { isOverDropZone } = useDropZone(dropZoneElement, { onDrop: filesAttached });

    return {
      dropZoneElement,

      isLoaderVisible,
      formattedPlaceholder,
      inputLabelAttributes,
      inputAttributes,
      isOverDropZone,

      value,

      onInputChange,
      getPreviewImgStyles,
      removeAttachmentById,
      downloadAttachmentById,
    };
  },
});

export default AttachFile;
