import parseAsString from 'lodash/toString';

const getFromQuery = (parameter: string): string => {
  if (!window || !document) {
    return '';
  }

  try {
    const params: URLSearchParams = new URLSearchParams(window.location.search.substring(1));
    return parseAsString(
      new URLSearchParams([...params].map(([key, value]) => [key.toLowerCase(), value])).get(parameter.toLowerCase())
    );
  } catch (error) {
    console.error(error);
  }

  return '';
};

export { getFromQuery };
