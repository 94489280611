import { MessageAttachmentModel } from './MessageAttachmentModel';

import { Type } from '../types/type';

import { MessageCategoryEnum, MessageChannelEnum, MessageStatusEnum, ProviderActionStatusEnum } from '@/types';

// normalized message model
class MessageModel {
  public id: string = ''; //UUID
  public key: string = ''; //id + createdAt + sentAt + updatedAt + deletedAt

  public createdAt: string = ''; //DateTime
  public updatedAt: string = ''; //DateTime
  public deletedAt: string = ''; //DateTime
  public sentAt: string = ''; //DateTime
  public priority: number = 0; // 0 - is default priority

  public authorId: string = '';
  public replyToId: string = ''; // only if channel === email
  public actionId: string = ''; // if channel action
  public actionAssigneeId: string = ''; // if channel action

  public messageTime: string = ''; // Readable time
  public subject: string = ''; // only if channel === email
  public templateName: string = ''; // if automation, name of the flow
  public shortTextBody: string = '';
  public body: string = '';
  public deliveryDetails: string = '';

  public attachments: MessageAttachmentModel[] = [];

  public type: Type = Type.BUBBLE;

  public category: MessageCategoryEnum = MessageCategoryEnum.MESSAGE;
  public channel: MessageChannelEnum = MessageChannelEnum.UNKNOWN;
  public status: MessageStatusEnum = MessageStatusEnum.CREATED;

  public actionStatus: ProviderActionStatusEnum = ProviderActionStatusEnum.DONE;
}

export { MessageModel };
