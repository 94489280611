import omit from 'lodash/omit';

import { ASSIGN_CHAT } from '../gql/ASSIGN_CHAT';
import { CLOSE_CHAT } from '../gql/CLOSE_CHAT';
import { CREATE_MESSAGE_TEMPLATE } from '../gql/CREATE_MESSAGE_TEMPLATE';
import { DELETE_NOTE } from '../gql/DELETE_NOTE';
import { GENERATE_REPLY_SUGGESTION } from '../gql/GENERATE_REPLY_SUGGESTION';
import { GET_CHAT } from '../gql/GET_CHAT';
import { GET_CHATS } from '../gql/GET_CHATS';
import { GET_CLIENT } from '../gql/GET_CLIENT';
import { GET_FULL_CLIENT_ACCOUNT } from '../gql/GET_FULL_CLIENT_ACCOUNT';
import { GET_MESSAGE_TEMPLATES } from '../gql/GET_MESSAGE_TEMPLATES';
import { GET_REPLY_SUGGESTIONS } from '../gql/GET_REPLY_SUGGESTIONS';
import { GET_TEAMS } from '../gql/GET_TEAMS';
import { GET_TEAMS_OPEN_CHATS } from '../gql/GET_TEAMS_OPEN_CHATS';
import { GET_UPLOAD_URL } from '../gql/GET_UPLOAD_URL';
import { OPEN_CHAT } from '../gql/OPEN_CHAT';
import { SEND_MESSAGE } from '../gql/SEND_MESSAGE';
import { SNOOZE_CHAT } from '../gql/SNOOZE_CHAT';
import { UPDATE_MESSAGE_PRIORITY } from '../gql/UPDATE_MESSAGE_PRIORITY';
import { UPDATE_MESSAGE_TEMPLATE } from '../gql/UPDATE_MESSAGE_TEMPLATE';
import { UPDATE_NOTE } from '../gql/UPDATE_NOTE';
import { UPDATE_PROVIDER_ACTION } from '../gql/UPDATE_PROVIDER_ACTION';

import {
  ChatRequestsQueueService,
  ChatsRequestsQueueService,
  ClientRequestsQueueService,
  FullClientRequestsQueueService,
  GenerateMessageChatRequestsQueueService,
  RequestsQueueService,
  UploadUrlRequestsQueueService,
} from '../services/RequestsQueue';

import { ChatArgumentsModel } from '../models/ChatArgumentsModel';

import type { InboxArgumentsModel } from '../models/InboxArgumentsModel';

import type { AssignChatRequest } from '../types/requests/assignChatRequest';
import type { CloseOrOpenChatRequest } from '../types/requests/closeOrOpenChatRequest';
import type { MessageTemplateRequest } from '../types/requests/messageTemplateRequest';
import type { SendMessageRequest } from '../types/requests/sendMessageRequest';
import type { SnoozeChatRequest } from '../types/requests/snoozeChatRequest';
import type { UploadAttachmentRequest } from '../types/requests/uploadAttachmentRequest';
import type { DeleteNoteRequest } from '../types/requests/deleteNoteRequest';
import type { UpdateNoteRequest } from '../types/requests/updateNoteRequest';

import { createSharedComposable } from '@vueuse/core';

import { useApollo } from '@/composables/atoms/useApollo';

import type {
  AccountType,
  ChatType,
  MessageAttachmentType,
  MessageTemplateType,
  MessageType,
  ReplySuggestionType,
  TeamType,
} from '@/types';
import { ChatSegmentEnum, ProviderActionStatusEnum } from '@/types';

const useMessagingApi = () => {
  const { loading, query, mutate } = useApollo();

  const getMessageTemplates = async (): Promise<MessageTemplateType[]> => {
    const response = await query<{ messageTemplates: MessageTemplateType[] }>({
      query: GET_MESSAGE_TEMPLATES,
      fetchPolicy: 'no-cache',
    });

    return response?.data?.messageTemplates?.length ? response.data.messageTemplates : [];
  };

  const getTeamsOpenChats = async (): Promise<TeamType[]> => {
    const response = await query<{ teams: TeamType[] }>({
      query: GET_TEAMS_OPEN_CHATS,
      fetchPolicy: 'no-cache',
    });

    return response?.data?.teams?.length ? response.data.teams : [];
  };

  const getTeams = async (): Promise<TeamType[]> => {
    const response = await query<{ teams: TeamType[] }>({
      query: GET_TEAMS,
      fetchPolicy: 'no-cache',
    });

    return response?.data?.teams?.length ? response.data.teams : [];
  };

  const generateReplySuggestion = async (chatId: ChatType['id']): Promise<string> => {
    const response = await query<{ generateReplySuggestion: { prompt: string; text: string } }>({
      query: GENERATE_REPLY_SUGGESTION,
      fetchPolicy: 'no-cache',
      variables: { chatId },
    });

    if (response.errors?.length || !response?.data?.generateReplySuggestion?.text?.length) {
      return '';
    }

    return response?.data?.generateReplySuggestion?.text || '';
  };

  const getReplySuggestions = async (messageId: MessageType['id']): Promise<ReplySuggestionType[]> => {
    const response = await query<{ replySuggestions: ReplySuggestionType[] }>({
      query: GET_REPLY_SUGGESTIONS,
      fetchPolicy: 'no-cache',
      variables: { messageId },
    });

    if (response.errors?.length || !response?.data?.replySuggestions?.length) {
      return [];
    }

    return response?.data?.replySuggestions || [];
  };

  const updateProviderAction = async (id: string, status: ProviderActionStatusEnum): Promise<boolean> => {
    const response = await mutate<{ updateProviderAction: { status?: 'success' } }>({
      mutation: UPDATE_PROVIDER_ACTION,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        status,
      },
    });

    return !response.errors?.length && response.data?.updateProviderAction?.status === 'success';
  };

  const createMessageTemplate = async (variables: MessageTemplateRequest): Promise<MessageTemplateType> => {
    const response = await query<{ createMessageTemplate: MessageTemplateType }>({
      query: CREATE_MESSAGE_TEMPLATE,
      fetchPolicy: 'no-cache',
      variables: omit(variables, ['id']),
    });

    if (response.errors?.length || !response.data?.createMessageTemplate) {
      return;
    }

    return response?.data?.createMessageTemplate;
  };

  const updateMessageTemplate = async (variables: MessageTemplateRequest): Promise<MessageTemplateType> => {
    const response = await query<{ updateMessageTemplate: MessageTemplateType }>({
      query: UPDATE_MESSAGE_TEMPLATE,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (response.errors?.length || !response.data?.updateMessageTemplate) {
      return;
    }

    return response?.data?.updateMessageTemplate;
  };

  const getChats = async (variables: InboxArgumentsModel): Promise<ChatType[]> => {
    const response = await query<{
      chats: ChatType[];
    }>({
      query: GET_CHATS,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
        assigneeId: variables.segment === ChatSegmentEnum.ALL ? undefined : variables.assigneeId,
        teamId: variables.segment === ChatSegmentEnum.ALL ? undefined : variables.teamId,
      },
    });

    if (response.errors?.length || !response.data?.chats?.length) {
      return [];
    }

    return response.data.chats;
  };

  const getChat = async (variables: ChatArgumentsModel): Promise<ChatType | void> => {
    const response = await query<{
      chat: ChatType;
    }>({
      query: GET_CHAT,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (response.errors?.length || !response.data?.chat) {
      return;
    }

    return response.data.chat;
  };

  const getClient = async (id: string): Promise<AccountType | void> => {
    const response = await query<{
      account: AccountType;
    }>({
      query: GET_CLIENT,
      fetchPolicy: 'no-cache',
      variables: { id },
    });

    if (response.errors?.length || !response.data?.account?.id?.length) {
      return;
    }

    return response.data.account;
  };

  const getFullClientAccount = async (id: string): Promise<AccountType | void> => {
    const response = await query<{
      account: AccountType;
    }>({
      query: GET_FULL_CLIENT_ACCOUNT,
      fetchPolicy: 'no-cache',
      variables: { id },
    });

    if (response.errors?.length || !response.data?.account?.id?.length) {
      return;
    }

    return response.data.account;
  };

  const getUploadUrl = async (
    variables: UploadAttachmentRequest
  ): Promise<{ s3UploadUrl: string; attachment: MessageAttachmentType }> => {
    const response = await mutate<{
      uploadMessageAttachment: { s3UploadUrl: string; attachment: MessageAttachmentType };
    }>({
      mutation: GET_UPLOAD_URL,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (
      response.errors?.length ||
      !response.data?.uploadMessageAttachment?.s3UploadUrl?.length ||
      !response.data?.uploadMessageAttachment?.attachment?.id
    ) {
      throw new Error(response?.errors.join(', ') || 'getUploadUrl failed');
    }

    return response.data.uploadMessageAttachment;
  };

  const sendMessage = async (variables: SendMessageRequest): Promise<ChatType | void> => {
    const response = await mutate<{ sendMessage: MessageType }>({
      mutation: SEND_MESSAGE,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (response.errors?.length || !response.data?.sendMessage) {
      return;
    }

    return response.data.sendMessage;
  };

  const assignChat = async (variables: AssignChatRequest): Promise<ChatType | void> => {
    const response = await mutate<{ assignChat: MessageType }>({
      mutation: ASSIGN_CHAT,
      fetchPolicy: 'no-cache',
      variables,
    });

    if (response.errors?.length || !response.data?.assignChat) {
      return;
    }

    return response.data.assignChat;
  };

  const snoozeChat = async (variables: SnoozeChatRequest): Promise<ChatType | void> => {
    const response = await mutate<{ snoozeChat?: MessageType }>({
      mutation: SNOOZE_CHAT,
      variables,
    });

    if (response.errors?.length || !response.data?.snoozeChat) {
      return;
    }

    return response.data.snoozeChat;
  };

  const closeChat = async (variables: CloseOrOpenChatRequest): Promise<ChatType | void> => {
    const response = await mutate<{ closeChat: ChatType }>({
      mutation: CLOSE_CHAT,
      variables,
    });

    if (response.errors?.length || !response.data?.closeChat) {
      return;
    }

    return response.data.closeChat;
  };

  const openChat = async (variables: CloseOrOpenChatRequest): Promise<ChatType | void> => {
    const response = await mutate<{ reopenChat: ChatType }>({
      mutation: OPEN_CHAT,
      variables,
    });

    if (response.errors?.length || !response.data?.reopenChat) {
      return;
    }

    return response.data.reopenChat;
  };

  const updateMessagePriority = async (id: MessageType['id'], priority: number = 0): Promise<boolean> => {
    const response = await mutate<{ updateMessagePriority: { status?: 'success' } }>({
      mutation: UPDATE_MESSAGE_PRIORITY,
      variables: {
        messageId: id,
        priority,
      },
    });

    return !response.errors?.length && response.data?.updateMessagePriority?.status === 'success';
  };

  const updateNote = async (variables: UpdateNoteRequest): Promise<ChatType | void> => {
    const response = await mutate<{ updateMessagingNote: ChatType }>({
      mutation: UPDATE_NOTE,
      variables,
    });

    if (response.errors?.length || !response.data?.updateMessagingNote) {
      return;
    }

    return response.data.updateMessagingNote;
  };

  const deleteNote = async (variables: DeleteNoteRequest): Promise<ChatType | void> => {
    const response = await mutate<{ deleteMessagingNote: ChatType }>({
      mutation: DELETE_NOTE,
      variables,
    });

    if (response.errors?.length || !response.data?.deleteMessagingNote) {
      return;
    }

    return response.data.deleteMessagingNote;
  };

  return {
    loading,

    getMessageTemplates,

    getTeamsOpenChats,
    getTeams,

    createMessageTemplate,
    updateMessageTemplate,

    updateProviderAction,

    getReplySuggestions: (...args: Parameters<typeof getReplySuggestions>): ReturnType<typeof getReplySuggestions> =>
      GenerateMessageChatRequestsQueueService.enqueue(getReplySuggestions, args),

    generateReplySuggestion: (
      ...args: Parameters<typeof generateReplySuggestion>
    ): ReturnType<typeof generateReplySuggestion> =>
      GenerateMessageChatRequestsQueueService.enqueue(generateReplySuggestion, args),

    getChats: (...args: Parameters<typeof getChats>): ReturnType<typeof getChats> =>
      ChatsRequestsQueueService.enqueue(getChats, args),
    getChat: (...args: Parameters<typeof getChat>): ReturnType<typeof getChat> =>
      ChatRequestsQueueService.enqueue(getChat, args),
    getClient: (...args: Parameters<typeof getClient>): ReturnType<typeof getClient> =>
      ClientRequestsQueueService.enqueue(getClient, args),
    getFullClientAccount: (...args: Parameters<typeof getFullClientAccount>): ReturnType<typeof getFullClientAccount> =>
      FullClientRequestsQueueService.enqueue(getFullClientAccount, args),

    getUploadUrl: (...args: Parameters<typeof getUploadUrl>): ReturnType<typeof getUploadUrl> =>
      UploadUrlRequestsQueueService.enqueue(getUploadUrl, args),

    sendMessage: (...args: Parameters<typeof sendMessage>): ReturnType<typeof sendMessage> =>
      RequestsQueueService.enqueue(sendMessage, args),

    assignChat: (...args: Parameters<typeof assignChat>): ReturnType<typeof assignChat> =>
      RequestsQueueService.enqueue(assignChat, args),

    snoozeChat: (...args: Parameters<typeof snoozeChat>): ReturnType<typeof snoozeChat> =>
      RequestsQueueService.enqueue(snoozeChat, args),

    closeChat: (...args: Parameters<typeof closeChat>): ReturnType<typeof closeChat> =>
      RequestsQueueService.enqueue(closeChat, args),

    openChat: (...args: Parameters<typeof openChat>): ReturnType<typeof openChat> =>
      RequestsQueueService.enqueue(openChat, args),

    updateMessagePriority: (
      ...args: Parameters<typeof updateMessagePriority>
    ): ReturnType<typeof updateMessagePriority> => RequestsQueueService.enqueue(updateMessagePriority, args),

    updateNote: (...args: Parameters<typeof updateNote>): ReturnType<typeof updateNote> =>
      RequestsQueueService.enqueue(updateNote, args),

    deleteNote: (...args: Parameters<typeof deleteNote>): ReturnType<typeof deleteNote> =>
      RequestsQueueService.enqueue(deleteNote, args),
  };
};

const useMessagingApiService: typeof useMessagingApi = createSharedComposable(useMessagingApi);

export { useMessagingApiService as useMessagingApi };
