enum MessageCategoryEnum {
  ASSIGNMENT = 'assignment',
  CLOSE = 'close',
  FILE = 'file',
  MESSAGE = 'message',
  MARKETING_MESSAGE = 'marketing_message',
  NOTE = 'note',
  OPEN = 'open',
  SNOOZE = 'snooze',
  LOGIN_LINK = 'login_link',
  PASSWORD_RESET_LINK = 'password_reset_link',
  EMAIL_CONFIRMATION_LINK = 'email_confirmation_link',
  ASYNC_NOTIFICATION = 'async_notification',
  PRECLINICAL_NOTIFICATION = 'preclinical_notification',
  RENEWAL_NOTIFICATION = 'renewal_notification',
  REFILL_NOTIFICATION = 'refill_notification',
  INSURANCE_CONSULTATION_NOTIFICATION = 'insurance_consultation_notification',
  OFFICE_HOURS_NOTIFICATION = 'office_hours_notification',
}

export { MessageCategoryEnum };
