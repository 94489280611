import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const GET_CURRENT_STAFFER = gql`
  ${STAFFER}
  query GetCurrentUser {
    currentUser {
      ...Staffer
    }
  }
`;

export { GET_CURRENT_STAFFER };
