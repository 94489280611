import type { StafferType, SystemEventType } from '@/types';
import { getStaffer, getStafferFirstName } from '@/stores/staffers';

const eventAuthorToReadableText = (event: SystemEventType, clientId: string, staffers: StafferType[]): string => {
  return event?.source === 'n8n'
    ? 'n8n'
    : event?.initiatedBy?.id.length
    ? clientId === event?.initiatedBy?.id
      ? 'customer' // using "customer" instead of "client" to have consistent language with "Client Database"
      : getStafferFirstName(getStaffer(event?.initiatedBy?.id, staffers))
    : 'unknown';
};

export { eventAuthorToReadableText };
