import { ClientWorker } from '@/clientWorkers';

export class ClientWorkerUpdate extends ClientWorker {
  public onUpdatefound(registration: ServiceWorkerRegistration): void {
    registration.update();
    this.postMessage({ type: 'SkipWaiting' });
  }

  public onError(error: Error) {
    console.error(error);
  }
}
