
import type { PropType, SetupContext } from 'vue';
import {
  computed,
  defineComponent,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  toRefs,
  watch,
} from 'vue';

import isEqual from 'lodash/isEqual';

import { useRoot } from '@/composables/atoms/useRoot';
import { useModalsStore } from '@/stores/modals/store';

import { ReplyModel, useMessagingShortcuts } from '~/messaging';

const MAX_SUGGESTION_LENGTH: 160 = 160 as const;

//Bootstrap tabs are starting to lag, if they are controlled by our state
const ChatMacrosButton = defineComponent({
  props: {
    page: {
      default: 0,
      required: false,
      type: Number,
    },
    suggestion: {
      default: (): ReplyModel => new ReplyModel(),
      required: false,
      type: Object as PropType<ReplyModel>,
    },
    suggestions: {
      default: (): ReplyModel[] => [],
      required: false,
      type: Array as PropType<ReplyModel[]>,
    },
  },
  setup(props, { emit }: SetupContext) {
    const root = useRoot();

    const { onShortcut, offShortcut } = useMessagingShortcuts();

    const { openModal } = useModalsStore();

    const { page, suggestion, suggestions } = toRefs(props);

    const isSuggestionsAvailable = computed((): boolean => !!suggestions.value.length);

    const isPreviousSuggestionVisible = computed((): boolean => isSuggestionsAvailable.value && !!page.value);
    const isNextSuggestionVisible = computed(
      (): boolean => isSuggestionsAvailable.value && page.value < suggestions.value.length - 1
    );

    const popoverTitle = computed((): string => suggestion.value.name);

    const popoverContent = computed((): string => {
      // if content of the tooltip is empty - nothing will be shown
      if (!suggestion.value.searchRawText) {
        return 'Insert macros';
      }

      return suggestion.value.searchRawText.length >= MAX_SUGGESTION_LENGTH
        ? `${suggestion.value.searchRawText.slice(0, MAX_SUGGESTION_LENGTH)}...`
        : suggestion.value.searchRawText;
    });

    watch(
      suggestion,
      (newSuggestion: ReplyModel = new ReplyModel(), oldSuggestion: ReplyModel = new ReplyModel()): void => {
        if (!newSuggestion.searchRawText.length) {
          root.$emit('bv::hide::popover', 'chat-macros-button');
          return;
        }

        if (isEqual(newSuggestion, oldSuggestion)) {
          return;
        }

        // if in hot reload mode, this event will multiply itself with each reload
        root.$emit('bv::show::popover', 'chat-macros-button');
      },
      { deep: true }
    );

    const previousSuggestion = (): void => emit('new-page', page.value - 1);
    const nextSuggestion = (): void => emit('new-page', page.value + 1);

    const openMacrosModal = (): void => {
      emit('click');
      openModal('chat-macros-modal');
    };

    const insertMacro = (): void => openMacrosModal();

    const unmountListeners = (): void => {
      offShortcut('INSERT_MACRO', insertMacro);
    };

    const mountListeners = (): void => {
      onShortcut('INSERT_MACRO', insertMacro);
    };

    onBeforeMount(mountListeners);
    onActivated(mountListeners);

    onBeforeUnmount(unmountListeners);
    onDeactivated(unmountListeners);

    return {
      isSuggestionsAvailable,
      isPreviousSuggestionVisible,
      isNextSuggestionVisible,

      popoverTitle,
      popoverContent,

      previousSuggestion,
      nextSuggestion,

      openMacrosModal,
    };
  },
});

export default ChatMacrosButton;
