
import { computed, defineComponent, onMounted, ref, toRefs } from 'vue';
import type { MimeType } from 'file-type';

import { MessageAttachmentModel } from '~/messaging';

import { buildPropsFromModel } from '@/tools/props';
import { formatBytes } from '@/tools/converters/formatBytes';
import { downloadFromLink } from '@/tools/downloadFromLink';

const ChatAttachment = defineComponent({
  props: buildPropsFromModel(new MessageAttachmentModel()),
  setup(props: MessageAttachmentModel) {
    const {
      id,
      deletedAt,

      name,
      contentType,
      filesize,
      s3DownloadLink,

      width,
      height,
    } = toRefs<MessageAttachmentModel>(props);

    const downloading = ref<boolean>(false);

    const readableFileSize = computed((): string => formatBytes(filesize.value));

    const downloadAttachment = async (): Promise<void> => {
      downloading.value = true;
      await downloadFromLink(s3DownloadLink.value, name.value, contentType.value as MimeType);
      downloading.value = false;
    };

    onMounted(() => {
      // todo: check for expiry and renew
      // preload image
      new Image().src = s3DownloadLink.value;
    });

    return {
      downloading,

      contentType,
      deletedAt,
      filesize,
      height,
      id,
      name,
      s3DownloadLink,
      width,

      readableFileSize,

      downloadAttachment,
    };
  },
});

export default ChatAttachment;
