import gql from 'graphql-tag';

const UPLOADED_FILE = gql`
  fragment UploadedFile on UploadedFileType {
    id
    createdAt
    updatedAt

    name
    s3DownloadLink

    kind
    contentType
  }
`;

export { UPLOADED_FILE };
