import type Vue from 'vue';

export const LS_EVENT_HISTORY_KEY = 'CRX_EVENT_HISTORY';

export function unifyErrorEvents(eventHistory: any[]) {
  if (!eventHistory?.length) {
    return [];
  }

  return eventHistory.reduce((records, record) => {
    if (record.timestamp > new Date().getTime() - 60 * 60 * 24 * 1000) {
      return [...records, { ...record, time: new Date(record.timestamp) }];
    }

    return records;
  }, []);
}

// return events that were logged this day
export const getEventHistory = () => {
  const eventHistoryStr = localStorage.getItem(LS_EVENT_HISTORY_KEY);
  if (!eventHistoryStr) {
    return [];
  }

  return unifyErrorEvents(JSON.parse(eventHistoryStr));
};

// show error message using Vue bootstrap toast at the top-right of the screen
export const showError = (component: Vue, message: string, autoHideDelay: number = 5000) => {
  try {
    console.error('showError', message);

    component.$bvToast.toast(message, {
      title: 'Error',
      autoHideDelay,
      variant: 'danger',
      appendToast: true,
      solid: true,
    });

    const eventHistory = getEventHistory();
    eventHistory.push({
      timestamp: new Date().getTime(),
      event: 'error',
      component: component.constructor.name,
      details: message,
    });

    localStorage.setItem(LS_EVENT_HISTORY_KEY, JSON.stringify(eventHistory));
  } catch (error) {
    console.error('showError error', error);
  }
};

// show informational message using Vue bootstrap toast at the top-right of the screen
export const showMessage = (component: Vue, message: string) => {
  component.$bvToast.toast(message, {
    title: 'Success',
    autoHideDelay: 5000,
    variant: 'success',
    appendToast: true,
  });

  const eventHistory = getEventHistory();
  eventHistory.push({
    timestamp: new Date().getTime(),
    event: 'message',
    component: component.constructor.name,
    details: message,
  });

  localStorage.setItem(LS_EVENT_HISTORY_KEY, JSON.stringify(eventHistory));
};
