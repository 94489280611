
import { computed, defineComponent, onBeforeMount, ref, toRefs, watch } from 'vue';

import parseAsString from 'lodash/toString';
import uniqBy from 'lodash/uniqBy';

import { QuestionsCacheService } from '@/services/questionnaire/QuestionsCache';

import { buildPropsFromModel } from '@/tools/props';

import type { PatientType } from '@/types';

import { useStorage } from '@vueuse/core';

import { stripHtml } from '~/messaging';
import parseAsNumber from 'lodash/toNumber';
import { calculateBmi } from '@/tools/converters/calculateBmi';

const ANSWER_MISSING: '-' = '-' as const;
const COLUMN_WIDTH: Array<'180px' | '100px'> = ['180px', '100px'];

enum CRITICAL_QUESTION_KEY {
  HEIGHT_WEIGHT = 'ghfjckqpan',
  ALLERGIC_REACTION_TO = 'vmxskmencp',
  DANGEROUS_CONDITIONS = 'ogniodpgod', // historical
  HEART_CONDITIONS = 'jbjxejemmh',
  ON_BETA_BLOCKERS = 'ejsncnppkq',
  PARTNER_ANAPHYLAXIS = 'partner-2cbb2b12-e18d-41ad-97c0-389659701dec',
  PARTNER_ANAPHYLAXIS_ALT = 'partner-73f55fee-aa29-4d47-96f8-c79fb3d26761',
  REACTION_TO_IMMUNOTHERAPY = 'jtbxgchosu',
  REQUIRE_EPIPEN = 'zwavrairvm',
  RESCUE_INHALER = 'tkaqwtlbbe',
  ANY_COUGHING_AFTER_HAVING_A_LARGE_MEAL = 'nfghxzkdxn',
  DO_YOU_HAVE_ANY_COUGHING_OR_THROAT_OR_CHEST_DISCOMFORT_WHEN_YOU_LAY_DOWN_IN_BED = 'mgsfqaxsdf',
  DO_YOU_HAVE_FREQUENT_THROAT_CLEARING = 'ythbnfvsdf',
  HAVE_ANTIHISTAMINES_OFFERED_SYMPTOM_RELIEF = 'pwmtshlqtn',
  SEVERE_REACTION = 'aiqqohvwqn', // historical
  SEVERE_REACTION_DESCRIPTION = 'lxmywkowtt', // historical
}

enum WARNING_QUESTION_KEY {
  WHAT_TRIGGERS_YOUR_FOOD_ALLERGIES = 'dcvthbfgsx',
  HAVE_MCD = 'ypqpqxttup', // historical
  IS_PREGNANT = 'auahrimumc',
  IS_PREGNANT_OR_TRYING = 'heknmkiuqv', // historical
  IS_TRYING_TO_CONCEIVE = 'pweklsrgbn',
  PRESCRIBED_MEDICATION = 'zdnlqaxkkn', // historical
}

enum ATTENTION_QUESTION_KEY {
  CONDITIONS_WE_SHOULD_KNOW = 'palqsclgrg', // historical
  HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY_OF_THE_FOLLOWING = 'saacpesqvu', // historical
  DO_YOU_PLAN_TO_GET_A_PET = 'itnwvghhyu',
  WHAT_TRIGGERS_YOUR_ALLERGIES = 'hjmbfhiqsg',
}

const NO_ANSWERS: Array<'no' | 'none' | 'none of above' | 'none of the above'> = [
  'no',
  'none',
  'none of above',
  'none of the above',
];

class QuestionnaireCardPropsModel {
  public answeredQuestions: PatientType['questionnaire']['answeredQuestions'] = [];
  public questions: PatientType['questionnaire']['questions'] = [];
}

const QuestionnaireCard = defineComponent({
  props: buildPropsFromModel(new QuestionnaireCardPropsModel()),
  setup(props: QuestionnaireCardPropsModel) {
    const { answeredQuestions, questions } = toRefs<QuestionnaireCardPropsModel>(props);

    const groupsList = ref<PatientType['questionnaire']['questions'][0]['group'][]>([]);

    const currentFilter = ref<'weight-loss' | 'food-and-env'>('food-and-env');

    const collapsedQuestionnaireGroups = useStorage('cxCollapsedQuestionnaireGroupsColumns', []);

    const hasWeightLossAnswers = computed((): boolean =>
      answeredQuestions.value?.some((answers) => answers?.session?.version?.includes('weight-loss'))
    );

    const hasNotWeightLossAnswers = computed((): boolean =>
      answeredQuestions.value?.some((answers) => !answers?.session?.version?.includes('weight-loss'))
    );

    const filteredAnsweredQuestions = computed((): QuestionnaireCardPropsModel['answeredQuestions'] => {
      if (currentFilter.value === 'food-and-env') {
        return answeredQuestions.value?.filter((answers) => !answers?.session?.version?.includes('weight-loss'));
      }

      return answeredQuestions.value?.filter((answers) => answers?.session?.version?.includes('weight-loss'));
    });

    const isEmpty = computed((): boolean => !filteredAnsweredQuestions.value?.length);

    const tableFields = computed((): { key: string; stickyColumn: boolean }[] => [
      { key: 'question', stickyColumn: true },
      { key: 'answer', stickyColumn: false },
    ]);

    const tableItems = computed(
      (): Array<{
        question: PatientType['questionnaire']['answeredQuestions'][0]['question']['id'];
        answer: string;
        groupCode: string;
        _cellVariants: { answer: ReturnType<typeof getCellColor> };
      }> => {
        const result: typeof tableItems.value = (filteredAnsweredQuestions.value || [])
          .filter(
            (answeredQuestion: PatientType['questionnaire']['answeredQuestions'][0]) =>
              QuestionsCacheService.get(answeredQuestion?.question.id).displayInEhr
          )
          .sort(
            (a, b) =>
              QuestionsCacheService.get(a?.question.id).displayOrder -
              QuestionsCacheService.get(b?.question.id).displayOrder
          )
          .map((answeredQuestion: PatientType['questionnaire']['answeredQuestions'][0]) => ({
            question: parseAsString(answeredQuestion?.question?.id),
            answer: formatAnswer(answeredQuestion.question.id, answeredQuestion?.answers),
            groupCode: QuestionsCacheService.getGroup(answeredQuestion.question.id).code,
            _cellVariants: { answer: getCellColor(answeredQuestion.question.id, answeredQuestion?.answers) },
          }));

        const redAnswers: typeof tableItems.value = result.filter((item) => item._cellVariants.answer === 'danger');
        const yellowAnswers: typeof tableItems.value = result.filter((item) => item._cellVariants.answer === 'warning');
        const blueAnswers: typeof tableItems.value = result.filter((item) => item._cellVariants.answer === 'primary');
        const otherAnswers: typeof tableItems.value = result.filter(
          (item) => !['danger', 'warning', 'primary']?.includes(item._cellVariants.answer)
        );

        return [...redAnswers, ...yellowAnswers, ...blueAnswers, ...otherAnswers];
      }
    );

    const formatAnswer = (
      questionKey: PatientType['questionnaire']['answeredQuestions'][0]['question']['id'],
      answers: PatientType['questionnaire']['answeredQuestions'][0]['answers']
    ): string => {
      switch (answers?.length || 0) {
        case 0: {
          return ANSWER_MISSING;
        }
        case 1: {
          return getAnswerText(questionKey, answers[0], false);
        }
      }

      let formattedAnswers = (answers || [])
        .map(
          (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0], index: number) =>
            `• ${getAnswerText(questionKey, answer, true)}${index === answers.length - 1 ? '.' : ';'}`
        )
        .join('\n');

      if (questionKey === CRITICAL_QUESTION_KEY.HEIGHT_WEIGHT) {
        let heightFeet: string = '';
        let heightInches: string = '';
        let weight: string = '';
        answers.forEach((answer) => {
          // height & weight question with a hack
          if (answer.placeholder === 'Height') {
            heightFeet = parseAsString(parseAsNumber(stripHtml(answer?.value).trim()));
          } else if (answer.placeholder === '⠀') {
            heightInches = parseAsString(parseAsNumber(stripHtml(answer?.value).trim()));
          } else if (answer.placeholder === 'Weight') {
            weight = parseAsString(parseAsNumber(stripHtml(answer?.value).trim()));
          }
        });

        if (heightFeet.length && heightInches.length && weight.length) {
          formattedAnswers = `${formattedAnswers}\n• (BMI): ${
            parseAsString(calculateBmi(heightFeet, heightInches, weight)).split('.')[0]
          }`;
        }
      }

      return formattedAnswers;
    };

    const getCellColor = (
      questionKey: PatientType['questionnaire']['answeredQuestions'][0]['question']['id'],
      answers: PatientType['questionnaire']['answeredQuestions'][0]['answers'] = []
    ): 'success' | 'warning' | 'danger' | 'primary' | '' => {
      if (!answers?.length) {
        return '';
      }

      const containsNoAnswer: boolean = !!answers.find(
        (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
          !answer?.isTextField && NO_ANSWERS.includes(answer.value.toLowerCase() as typeof NO_ANSWERS[0])
      );

      if (Object.values(CRITICAL_QUESTION_KEY).includes(questionKey as CRITICAL_QUESTION_KEY)) {
        switch (questionKey as CRITICAL_QUESTION_KEY) {
          case CRITICAL_QUESTION_KEY.HEART_CONDITIONS:
          case CRITICAL_QUESTION_KEY.ON_BETA_BLOCKERS: {
            return containsNoAnswer ? '' : 'danger';
          }
          case CRITICAL_QUESTION_KEY.RESCUE_INHALER: {
            return answers.find(
              (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                !answer?.isTextField && answer.value.toLowerCase() === '3+ weekly'
            )
              ? 'danger'
              : 'success';
          }
          case CRITICAL_QUESTION_KEY.HEIGHT_WEIGHT: {
            return answers.find(
              (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                answer?.isTextField &&
                answer.placeholder.toLowerCase() === 'weight' &&
                parseAsNumber(answer.value) <= 66
            )
              ? 'danger'
              : '';
          }
          case CRITICAL_QUESTION_KEY.HAVE_ANTIHISTAMINES_OFFERED_SYMPTOM_RELIEF: {
            if (
              containsNoAnswer ||
              answers.find(
                (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                  !answer?.isTextField && answer.value.toLowerCase() === 'not sure'
              )?.value?.length
            ) {
              return 'danger';
            }

            if (
              answers.find(
                (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                  !answer?.isTextField && answer.value.toLowerCase() === 'yes'
              )?.value?.length
            ) {
              return 'success';
            }

            return '';
          }
        }

        if (containsNoAnswer) {
          // critical question eligibility confirmed with positive answer
          return 'success';
        }

        return 'danger';
      }

      if (Object.values(WARNING_QUESTION_KEY).includes(questionKey as WARNING_QUESTION_KEY)) {
        switch (questionKey as WARNING_QUESTION_KEY) {
          case WARNING_QUESTION_KEY.IS_TRYING_TO_CONCEIVE: {
            return containsNoAnswer ? '' : 'warning';
          }
        }

        if (containsNoAnswer) {
          // warning question eligibility confirmed with positive answer
          return 'success';
        }

        return 'warning';
      }

      if (Object.values(ATTENTION_QUESTION_KEY).includes(questionKey as ATTENTION_QUESTION_KEY)) {
        switch (questionKey as ATTENTION_QUESTION_KEY) {
          case ATTENTION_QUESTION_KEY.CONDITIONS_WE_SHOULD_KNOW: {
            return answers.find(
              (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                !answer?.isTextField && (answer.value.toLowerCase() === 'gerd' || answer.value.toLowerCase() === 'copd')
            )
              ? 'primary'
              : '';
          }
          case ATTENTION_QUESTION_KEY.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY_OF_THE_FOLLOWING: {
            if (
              answers.find(
                (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                  !answer?.isTextField && answer.value.toLowerCase() === 'eoe'
              )
            ) {
              return 'danger';
            }

            if (
              answers.find(
                (answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0]) =>
                  !answer?.isTextField &&
                  (answer.value.toLowerCase() === 'gerd' || answer.value.toLowerCase() === 'copd')
              )
            ) {
              return 'primary';
            }

            return '';
          }
        }

        if (containsNoAnswer) {
          // warning question eligibility confirmed with positive answer
          return '';
        }

        return 'primary';
      }

      return '';
    };

    const getAnswerText = (
      questionKey: PatientType['questionnaire']['answeredQuestions'][0]['question']['id'],
      answer: PatientType['questionnaire']['answeredQuestions'][0]['answers'][0],
      multipleAnswersGiven: boolean = true
    ): string => {
      const isTextField: boolean = !!answer?.isTextField;
      const strippedPlaceholder: string = stripHtml(answer?.placeholder).trim();
      const strippedValue: string = stripHtml(answer?.value).trim();

      if (questionKey === CRITICAL_QUESTION_KEY.HEIGHT_WEIGHT) {
        // height & weight question with a hack
        if (answer.placeholder === 'Height') {
          return `(Height): "${strippedValue || ANSWER_MISSING}" feet`;
        }

        if (answer.placeholder === '⠀') {
          return `(Height): "${strippedValue || ANSWER_MISSING}" inches`;
        }
      }

      // hack to properly display answers on the page
      if (questionKey === 'qapzmghfwl' || questionKey === 'ggjwoosndb') {
        return strippedValue;
      }

      if (isTextField) {
        if (multipleAnswersGiven) {
          return `(${strippedPlaceholder || 'Patient answer'}): "${strippedValue || ANSWER_MISSING}"`;
        }

        return strippedValue || ANSWER_MISSING;
      }

      return strippedPlaceholder.length ? strippedPlaceholder : ANSWER_MISSING;
    };

    const cacheQuestions = (newQuestions: PatientType['questionnaire']['questions'] = questions.value): void => {
      let hasOtherQuestions: boolean = false;
      newQuestions.forEach((question: PatientType['questionnaire']['questions'][0]) => {
        const questionModel = QuestionsCacheService.add(question);
        if (questionModel && questionModel.group.code === 'no-code') {
          hasOtherQuestions = true;
        }
      });

      groupsList.value = uniqBy(
        [
          ...newQuestions
            .map(
              (
                question: PatientType['questionnaire']['questions'][0]
              ): PatientType['questionnaire']['questions'][0]['group'] => question?.group
            )
            .filter((group: PatientType['questionnaire']['questions'][0]['group']) => group?.code?.length)
            .sort(
              (
                a: PatientType['questionnaire']['questions'][0]['group'],
                b: PatientType['questionnaire']['questions'][0]['group']
              ) => a?.displayOrder ?? Number.MAX_SAFE_INTEGER - b?.displayOrder ?? Number.MAX_SAFE_INTEGER
            ),
          hasOtherQuestions
            ? {
                code: 'no-code',
                name: 'Other answers:',
                displayOrder: Number.MAX_SAFE_INTEGER,
              }
            : undefined,
        ].filter(Boolean),
        'code'
      );

      groupsList.value = groupsList.value.filter((group: PatientType['questionnaire']['questions'][0]['group']) =>
        tableItems.value.some((item: typeof tableItems.value[0]) => item.groupCode === group.code)
      );
    };

    const getQuestion = (questionKey: string): ReturnType<typeof QuestionsCacheService['get']> =>
      QuestionsCacheService.get(questionKey);

    const getTableItemsPerGroup = (groupCode: string = 'no-code'): typeof tableItems.value => {
      return tableItems.value.filter((item: typeof tableItems.value[0]) => item.groupCode === groupCode);
    };

    const isGroupCollapsed = (groupCode: string = 'no-code'): boolean => {
      return collapsedQuestionnaireGroups.value?.includes(groupCode) || false;
    };

    const toggleGroupCollapse = (groupCode: string = 'no-code'): void => {
      if (collapsedQuestionnaireGroups.value.includes(groupCode)) {
        collapsedQuestionnaireGroups.value = collapsedQuestionnaireGroups.value.filter((code) => groupCode !== code);
        return;
      }

      collapsedQuestionnaireGroups.value.push(groupCode);
    };

    onBeforeMount(() => {
      cacheQuestions();

      if (hasWeightLossAnswers.value) {
        currentFilter.value = 'weight-loss';
      } else {
        currentFilter.value = 'food-and-env';
      }
    });
    watch(questions, cacheQuestions, { deep: true, flush: 'pre' });
    watch(currentFilter, () => cacheQuestions(), { deep: true, flush: 'pre' });

    return {
      currentFilter,
      hasWeightLossAnswers,
      hasNotWeightLossAnswers,

      COLUMN_WIDTH,

      isEmpty,

      tableFields,
      groupsList,

      getQuestion,
      getTableItemsPerGroup,

      isGroupCollapsed,
      toggleGroupCollapse,
    };
  },
});

export default QuestionnaireCard;
