import { isPreviewFile } from '../validation/isPreviewFile';

const removePreviewFile = (url: string | void = ''): void => {
  // createObjectURL can cause memory leak, needs to be unloaded manually if possible
  if (!url || !isPreviewFile(url)) {
    return;
  }

  URL?.revokeObjectURL(url);
};

export { removePreviewFile };
