import gql from 'graphql-tag';

const DOWNLOAD_PDF = gql`
  mutation GenerateChatPdfDownloadLinkMutation($chatId: UUID!, $forceRegenerate: Boolean!, $forceTemplate: String!) {
    generateChatPdfDownloadLink(chatId: $chatId, forceRegenerate: $forceRegenerate, forceTemplate: $forceTemplate) {
      s3Url
    }
  }
`;

export { DOWNLOAD_PDF };
