
import { Builder } from 'builder-pattern';

import type { PropType, SetupContext } from 'vue';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  toRef,
  toRefs,
} from 'vue';

import { useFileDialog } from '@vueuse/core';

import type { UploadAttachmentRequest } from '~/messaging';
import { ChatModel, ChatsCacheService, useMessagingApi, useMessagingShortcuts, useMessagingStore } from '~/messaging';

import { formatFileAsAttachmentModel } from '@/components/AttachFile/helpers';
import { AttachmentModel } from '@/components/AttachFile/models';

import { showError } from '@/components/errors';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

const ChatAttachFile = defineComponent({
  props: {
    value: {
      default: (): AttachmentModel[] => [],
      required: false,
      type: Array as PropType<AttachmentModel[]>,
    },
  },
  setup(props, { emit }: SetupContext) {
    const vm = getCurrentInstance?.()?.proxy;

    const { onShortcut, offShortcut } = useMessagingShortcuts();

    const { open: selectFiles, onChange } = useFileDialog({
      accept: 'image/*, application/pdf, text/calendar',
    });

    const { value } = toRefs<{ value: AttachmentModel[] }>(props);

    const { getUploadUrl } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');

    const { increaseChatActions, decreaseChatActions } = messagingStore;

    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    // new files selected
    onChange(async (filesInstances) => {
      const newAttachments: AttachmentModel[] = [];
      for (const file of Array.from(filesInstances)) {
        newAttachments.push(await formatFileAsAttachmentModel(file));
      }

      emit('input', [...value.value, ...newAttachments]);

      await uploadAttachments(newAttachments);
    });

    const removeFile = (id: string) => {
      emit(
        'input',
        value.value.filter((attachmentModel: AttachmentModel) => attachmentModel.id !== id)
      );
    };

    const uploadAttachments = async (newAttachments: AttachmentModel[]): Promise<void> => {
      const { id }: ChatModel = currentChat.value;

      increaseChatActions(id);
      for (const attachmentForUpload of newAttachments) {
        try {
          const response = await getUploadUrl(
            Builder<UploadAttachmentRequest>()
              .name(attachmentForUpload.name)
              .contentType(attachmentForUpload.type)
              .filesize(attachmentForUpload.size)
              .build()
          );

          await fetch(response.s3UploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': attachmentForUpload.type,
            },
            body: attachmentForUpload.fileInstance,
          });

          emit(
            'input',
            value.value.map((attachment: AttachmentModel) =>
              attachmentForUpload.id === attachment.id
                ? Builder(AttachmentModel, attachment).id(response.attachment.id).uploaded(true).build()
                : attachment
            )
          );
        } catch (error) {
          emit(
            'input',
            value.value.map((attachment: AttachmentModel) =>
              attachment.id === attachmentForUpload.id
                ? Builder(AttachmentModel, attachmentForUpload).error(true).build()
                : attachment
            )
          );

          console.error('error', error);
          showError(
            vm,
            `Failed to upload file: ${attachmentForUpload.name}. ${parseGraphQLErrorsAsText(error)}`,
            50000
          );
        }
      }

      decreaseChatActions(id);
    };

    const insertAttachment = (): void => selectFiles();

    const unmountListeners = (): void => {
      offShortcut('INSERT_ATTACHMENT', insertAttachment);
    };

    const mountListeners = (): void => {
      onShortcut('INSERT_ATTACHMENT', insertAttachment);
    };

    onBeforeMount(mountListeners);
    onActivated(mountListeners);

    onBeforeUnmount(unmountListeners);
    onDeactivated(unmountListeners);

    return {
      value,

      selectFiles,
      removeFile,
    };
  },
});

export default ChatAttachFile;
