import type { PropOptions } from 'vue';

import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import type { MinMaxPropValidator } from '../types';

const textPropValidator = <T>(params: MinMaxPropValidator = {}): PropOptions<T>['validator'] => {
  return (value: T): value is T => {
    if (isNil(value) || !isString(value)) {
      return false;
    }

    const minValid: boolean = isNil(params?.min) ? true : value.length >= params?.min;
    const maxValid: boolean = isNil(params?.max) ? true : value.length <= params?.max;

    return minValid && maxValid;
  };
};

export { textPropValidator };
