import gql from 'graphql-tag';

const GET_UPLOAD_URL = gql`
  mutation GetUploadUrl($contentType: String!, $filesize: Int!, $name: String!) {
    uploadMessageAttachment(contentType: $contentType, filesize: $filesize, name: $name) {
      s3UploadUrl
      attachment {
        id
      }
    }
  }
`;

export { GET_UPLOAD_URL };
