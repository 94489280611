import isEqual from 'lodash/isEqual';

import Vue, { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';

import type { PatientType } from '@/types';

interface PatientStoreType {
  allergyTriggers: string[];
}

type PatientStoreTypeKey = keyof PatientStoreType;

const usePatientsStore = defineStore('patients', () => {
  const _patients = ref<Record<PatientType['id'], PatientStoreType>>({});

  const patients = computed((): typeof _patients.value => _patients.value);

  const setPatientClientAllergyTriggers = (
    patientId: PatientType['id'],
    allergyTriggers: PatientStoreType['allergyTriggers']
  ): void => {
    setPatientData(patientId, 'allergyTriggers', allergyTriggers);
  };

  const setPatientData = (
    patientId: PatientType['id'],
    key: PatientStoreTypeKey,
    data: PatientStoreType[PatientStoreTypeKey]
  ): void => {
    if (_patients.value?.[patientId]?.[key] && isEqual(_patients.value[patientId][key], data)) {
      return;
    }

    Vue.set(_patients.value, patientId, {
      ...(_patients.value?.[patientId] || {}),
      [key]: data,
    });
  };

  const $reset = (): void => {
    _patients.value = {};
  };

  return {
    patients,

    setPatientClientAllergyTriggers,
    setPatientData,

    $reset,
  };
});

export { usePatientsStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(usePatientsStore, import.meta.webpackHot));
}
