import type { MimeType } from 'file-type';

class AttachmentModel {
  public id: string = '';
  public name: string = 'No name';
  public type: MimeType | 'application/octet-stream' = 'application/octet-stream';
  public fileInstance: File | null = null; // File instance should be here until file is uploaded
  public size: number = -1;
  public lastModified: number = -1;
  public previewUrl: string = ''; // url to s3

  public loading: boolean = false;
  public uploaded: boolean = false;
  public error: boolean = false;
}

export { AttachmentModel };
