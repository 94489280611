import { sort } from 'fast-sort';
import { Builder } from 'builder-pattern';

import isEqual from 'lodash/isEqual';
import parseAsNumber from 'lodash/toNumber';
import parseAsString from 'lodash/toString';
import uniqBy from 'lodash/uniqBy';

import { FetchResultModel } from '../models/FetchResultModel';
import { InboxArgumentsModel } from '../models/InboxArgumentsModel';
import { MessageModel } from '../models/MessageModel';
import { ChatArgumentsModel } from '../models/ChatArgumentsModel';
import { ChatModel } from '../models/ChatModel';
import { TeamModel } from '../models/TeamModel';
import { ClientModel } from '../models/ClientModel';

import { Query } from '../types/query';

import { ChatsCacheService } from '../services/ChatsCache';
import { ClientsCacheService } from '../services/ClientsCache';
import { MessagesCacheService } from '../services/MessagesCache';
import { RepliesCacheService } from '../services/RepliesCache';
import { RequestsQueueService } from '../services/RequestsQueue';
import { TeamsCacheService } from '../services/TeamsCache';
import { getPatientIdFromQuery } from '../helpers/getPatientIdFromQuery';

import { useMessagingStore } from '../store/useMessagingStore';

import { MessagingSoundsService } from '../services/MessagingSounds';

import { useMessagingApi } from './useMessagingApi';

import { isNotDateMessage } from '../validation/isNotDateMessage';
import { shouldCreateDateMessage } from '../validation/shouldCreateDateMessage';
import { createDateMessage } from '../helpers/createDateMessage';

import { useStaffersStore } from '@/stores/staffers';

import { createSharedComposable } from '@vueuse/core';

import { useRouter } from 'vue-router/composables';
import type { Location } from 'vue-router/types/router';

import type { AccountType, ChatType, MessageTemplateType, MessageType, StafferType, TeamType } from '@/types';
import { ChatSegmentEnum } from '@/types';

/*
 * Chat/Message key = id + createdAt + updatedAt + deletedAt
 * Chat/Message information will be updated only if their respective key has been changed
 * Teams and Assignee staffers are loaded once upon initializing messaging
 * ---
 * Whenever chat key changed - messages will be refreshed.
 * Current chat is preserved in the view, but everything is refreshed in the chat window.
 * ---
 * if received multiple messages with same message id - most recent backend information will override previous message
 * most recent updates about message position will update message position (with respect to polling)
 * */

const useMessaging = () => {
  const router = useRouter();

  const messagingStore = useMessagingStore();
  const staffersStore = useStaffersStore();

  const { getTeamsOpenChats, getTeams, getMessageTemplates, getChats, getChat, getClient } = useMessagingApi();

  const init = async (): Promise<void> => {
    !messagingStore.hasTeams && (await fetchTeams());
    !RepliesCacheService.getAll.length && (await fetchMessageTemplates());
  };

  const updateClientInformation = async (chatId: string, clientId: string): Promise<void> => {
    if (ClientsCacheService.getById(clientId)) {
      // being used to get client time
      // to check for email/sms subscription status
      return;
    }

    try {
      const client: AccountType | void = await getClient(clientId);
      if (!client) {
        return;
      }

      const clientModel: ClientModel | void = ClientsCacheService.add(client);
      if (!clientModel) {
        return;
      }

      const chatModel: ChatModel | void = ChatsCacheService.getById(chatId);
      if (!chatModel) {
        return;
      }

      messagingStore.setChatClient(chatModel.key, clientModel.key);
    } catch (error) {
      console.error('updateClientInformation error:', chatId, clientId);
    }
  };

  const pollActiveInboxes = async (): Promise<FetchResultModel[]> => {
    if (!messagingStore.isInitialized) {
      return;
    }

    fetchOpenChats();

    // omit all, because its hidden segment that we use only when searching
    return Promise.all(
      Object.values(ChatSegmentEnum)
        .filter((segment: ChatSegmentEnum) => segment !== ChatSegmentEnum.ALL)
        .map(fetchNewInboxChats)
    );
  };

  const fetchOpenChats = async (): Promise<void> => {
    try {
      messagingStore.setInboxOpenChats(getOpenChats(await getTeamsOpenChats()));
    } catch (error) {
      console.error('fetchOpenChats error:', error);
    }
  };

  const fetchNewInboxChats = async (filter: ChatSegmentEnum): Promise<FetchResultModel> => {
    if (!messagingStore.currentStafferOrTeam) {
      console.error('Missing active staffer or team.', messagingStore.currentStafferOrTeam);
      return new FetchResultModel();
    }

    const variablesForRegularChats: InboxArgumentsModel = createVariablesToGetChats(filter);
    const variablesForImportantChats: InboxArgumentsModel = createVariablesToGetImportantChats(filter);

    const [chatTypesForRegularChats, chatTypesForImportantChats] = await Promise.all([
      getChats(variablesForRegularChats),
      getChats(variablesForImportantChats),
    ]);

    if (
      !isEqual(variablesForRegularChats, createVariablesToGetChats(filter)) ||
      !isEqual(variablesForImportantChats, createVariablesToGetImportantChats(filter))
    ) {
      return new FetchResultModel();
    }

    const chatTypes: ChatType[] = uniqBy([...chatTypesForImportantChats, ...chatTypesForRegularChats], 'id');

    const stafferIdOrTeamId: StafferType['id'] | TeamModel['id'] | void = (variablesForRegularChats?.assigneeId ||
      variablesForRegularChats?.teamId) as StafferType['id'] | TeamModel['id'];

    if (stafferIdOrTeamId?.length) {
      messagingStore.setRecentlyUpdatedChats(stafferIdOrTeamId, []);
    }

    const hasMoreChats: boolean =
      chatTypesForRegularChats.length && chatTypesForRegularChats.length >= variablesForRegularChats.limit;

    await addChatsToInbox(stafferIdOrTeamId, filter, createChatModels(chatTypes));

    if (variablesForRegularChats?.latestMessageCreatedAfter && chatTypes.length) {
      const assignedToMeChat: ChatType | void = chatTypes.find((chatType: ChatType) =>
        chatType?.assignedStaffers.find((staffer: StafferType) => staffer.id === staffersStore.currentStaffer.id)
      );

      if (assignedToMeChat) {
        MessagingSoundsService.playSound();
      }
    }

    return Builder(FetchResultModel)
      .moreAvailable(hasMoreChats)
      .filterUsed(!!variablesForRegularChats?.latestMessageCreatedAfter)
      .build();
  };

  const fetchOldInboxChats = async (filter: ChatSegmentEnum): Promise<FetchResultModel> => {
    if (!messagingStore.currentStafferOrTeam) {
      console.error('Missing active staffer or team.', messagingStore.currentStafferOrTeam);
      return new FetchResultModel();
    }

    const variables: InboxArgumentsModel = createVariablesToGetChats(filter, true);

    const chatTypes: ChatType[] = await getChats(variables);
    if (!isEqual(variables, createVariablesToGetChats(filter, true))) {
      return new FetchResultModel();
    }

    const hasMoreChats: boolean = chatTypes.length && chatTypes.length >= variables.limit;
    const stafferIdOrTeamId: StafferType['id'] | TeamModel['id'] = (variables?.assigneeId || variables?.teamId) as
      | StafferType['id']
      | TeamModel['id'];

    messagingStore.setInboxHasMoreChats(stafferIdOrTeamId, filter, hasMoreChats);

    await addChatsToInbox(stafferIdOrTeamId, filter, createChatModels(chatTypes), true);

    return Builder(FetchResultModel)
      .moreAvailable(hasMoreChats)
      .filterUsed(!!variables?.latestMessageCreatedBefore)
      .build();
  };

  const createVariablesToGetChats = (filter: ChatSegmentEnum, end: boolean = false): InboxArgumentsModel => {
    const isTeam: boolean = TeamsCacheService.has(messagingStore.currentStafferOrTeam);

    let chatNewestLatestMessageCreatedAt: MessageModel['createdAt'] | void;
    for (let index = 0; index < messagingStore.currentInbox.length; index++) {
      const chatKey: ChatModel['key'] = messagingStore.currentInbox[index];
      if (messagingStore.recentlyUpdatedChats?.[messagingStore.currentStafferOrTeam]?.includes(chatKey)) {
        continue;
      }

      const chatModel: ChatModel | void = ChatsCacheService.get(chatKey);
      if (!chatModel || !chatModel.latestMessageId.length) {
        continue;
      }

      const chatLatestMessageModel: MessageModel | void = MessagesCacheService.getById(chatModel.latestMessageId);
      if (!chatLatestMessageModel || !chatLatestMessageModel.createdAt.length || chatLatestMessageModel.priority > 0) {
        continue;
      }

      chatNewestLatestMessageCreatedAt = chatLatestMessageModel.createdAt;
      break;
    }

    const chatOldestLastMessageId: ChatModel['latestMessageId'] | void = ChatsCacheService.get(
      messagingStore.currentInbox?.[messagingStore.currentInbox?.length - 1]
    )?.latestMessageId;

    const chatOldestLatestMessageCreatedAt: MessageModel['createdAt'] | void =
      (chatOldestLastMessageId && MessagesCacheService.getById(chatOldestLastMessageId)?.createdAt) || undefined;

    return Builder(InboxArgumentsModel)
      .assigneeId((!isTeam && messagingStore.currentStafferOrTeam) || undefined)
      .teamId((isTeam && messagingStore.currentStafferOrTeam) || undefined)
      .segment(filter || undefined)
      .search(messagingStore.searchInChats || undefined)
      .latestMessageCreatedBefore(end ? chatOldestLatestMessageCreatedAt : undefined)
      .latestMessageCreatedAfter(end ? undefined : chatNewestLatestMessageCreatedAt)
      .build();
  };

  const createVariablesToGetImportantChats = (filter: ChatSegmentEnum): InboxArgumentsModel => {
    const isTeam: boolean = TeamsCacheService.has(messagingStore.currentStafferOrTeam);

    return Builder(InboxArgumentsModel)
      .assigneeId((!isTeam && messagingStore.currentStafferOrTeam) || undefined)
      .teamId((isTeam && messagingStore.currentStafferOrTeam) || undefined)
      .segment(filter || undefined)
      .search(messagingStore.searchInChats || undefined)
      .latestMessagePriority(undefined)
      .latestMessagePriorityGte(1)
      .build();
  };

  const createChatModels = (chatTypes: ChatType[]): ChatModel[] => {
    const newChatModels: ChatModel[] = [];

    chatTypes.forEach((chatType: ChatType) => {
      const chatModel: ChatModel | void = ChatsCacheService.add(chatType);
      if (!chatModel) {
        return;
      }

      newChatModels.push(chatModel);

      updateChatLatestMessages(chatModel, chatType);
    });

    return newChatModels.filter(Boolean);
  };

  const addChatsToInbox = async (
    stafferIdOrTeamId: StafferType['id'] | TeamModel['id'],
    filter: ChatSegmentEnum,
    newChatModels: ChatModel[],
    end: boolean = false
  ): Promise<void> => {
    if (!stafferIdOrTeamId || !filter || !newChatModels) {
      console.error('addChatsToInbox failed', stafferIdOrTeamId, filter, newChatModels);
      return;
    }

    const activeChatModel: ChatModel | void = ChatsCacheService.get(messagingStore.activeChat);

    const currentChatModels: ChatModel[] = getChatModelsFromChatKeys(
      messagingStore.inboxes?.[stafferIdOrTeamId]?.[filter] || []
    ).filter((oldChatModel: ChatModel) => {
      const updatedChatModel: ChatModel | void = newChatModels.find(
        (chatModel: ChatModel) => chatModel.id === oldChatModel.id
      );

      if (updatedChatModel && oldChatModel.key !== updatedChatModel.key) {
        if (updatedChatModel.id === oldChatModel.id) {
          messagingStore.migrateChat(oldChatModel.key, updatedChatModel.key);
        }

        if (activeChatModel && activeChatModel.id === updatedChatModel.id) {
          messagingStore.setActiveChat(updatedChatModel.key);
        }
      }

      return !updatedChatModel;
    });

    const newMergedChatModels: ChatModel[] = end
      ? [...currentChatModels, ...newChatModels]
      : [...newChatModels, ...currentChatModels];

    messagingStore.setInboxChats(stafferIdOrTeamId, filter, getChatKeysFromChatModels(newMergedChatModels));
  };

  const fetchNewMessages = async (): Promise<FetchResultModel> => {
    const variables: ChatArgumentsModel | void = createVariablesToGetChat();
    if (!variables) {
      return new FetchResultModel();
    }

    const chatType: ChatType | void = await getChat(variables);
    if (!chatType || !chatType?.id) {
      return new FetchResultModel();
    }

    const messageTypes: MessageType[] = chatType?.messages;
    if (!isEqual(variables, createVariablesToGetChat())) {
      return new FetchResultModel();
    }

    addOrUpdateChat(chatType);

    if (variables?.onlyMessagesAfter && chatType.messages.length) {
      const chatAssignedToMe: ChatType | void = chatType.assignedStaffers.find(
        (staffer) => staffer?.id === staffersStore.currentStaffer.id
      );

      if (chatAssignedToMe) {
        MessagingSoundsService.playSound();
      }
    }

    const hasMoreMessages: boolean = messageTypes.length && messageTypes.length >= variables.limit;

    return Builder(FetchResultModel).moreAvailable(hasMoreMessages).filterUsed(!!variables?.onlyMessagesAfter).build();
  };

  const fetchOldMessages = async (): Promise<FetchResultModel> => {
    const variables: ChatArgumentsModel | void = createVariablesToGetChat(true);
    if (!variables) {
      return new FetchResultModel();
    }

    const chatType: ChatType | void = await getChat(variables);
    if (!chatType || !chatType?.id) {
      return new FetchResultModel();
    }

    const messageTypes: MessageType[] = chatType?.messages;
    if (!isEqual(variables, createVariablesToGetChat(true))) {
      return new FetchResultModel();
    }

    const chatModel: ChatModel | void = addOrUpdateChat(chatType, true);
    if (!chatModel) {
      return new FetchResultModel();
    }

    const hasMoreMessages: boolean = messageTypes.length && messageTypes.length >= variables.limit;
    messagingStore.setChatHasMoreMessages(chatModel.key, hasMoreMessages);

    return Builder(FetchResultModel).moreAvailable(hasMoreMessages).filterUsed(!!variables?.onlyMessagesBefore).build();
  };

  const createVariablesToGetChat = (end: boolean = false): ChatArgumentsModel | void => {
    const chatModel: ChatModel | void = ChatsCacheService.get(messagingStore.activeChat);
    if (!chatModel) {
      return;
    }

    return Builder(ChatArgumentsModel)
      .id(chatModel.id)
      .search(messagingStore.searchInChat || undefined)
      .onlyMessagesBefore(
        end ? MessagesCacheService.get(messagingStore.currentChatFirstMessage)?.createdAt || undefined : undefined
      )
      .onlyMessagesAfter(
        end ? undefined : MessagesCacheService.get(messagingStore.currentChatLastMessage)?.createdAt || undefined
      )
      .build();
  };

  const addMessagesToChat = (chatModel: ChatModel, messageModels: MessageModel[], end: boolean = false) => {
    if (!chatModel) {
      return;
    }

    const currentMessageModels: MessageModel[] = getMessageModelsFromChatModel(chatModel).filter(
      (currentMessageModel: MessageModel) =>
        !messageModels.find((newMessageModel: MessageModel) => newMessageModel.id === currentMessageModel.id)
    );

    const newMessageModels: MessageModel[] = end
      ? [...currentMessageModels, ...messageModels]
      : [...messageModels, ...currentMessageModels];

    messagingStore.setChatMessages(
      chatModel.key,
      getMessageKeysFromMessageModels(addDateModelsToMessageModels(newMessageModels))
    );
  };

  const fetchTeams = async (): Promise<void> => {
    const teams: TeamType[] = await getTeams();

    messagingStore.setTeams(
      sort(teams.map((team: TeamType) => TeamsCacheService.add(team)).filter(Boolean))
        .asc((teamModel: TeamModel) => teamModel.name)
        .map((teamModel: TeamModel) => teamModel.id)
    );

    messagingStore.setInboxOpenChats(getOpenChats(teams));
  };

  const fetchMessageTemplates = async (): Promise<void> => {
    const messageTemplates: MessageTemplateType[] = sort(await getMessageTemplates()).asc(
      (messageTemplate: MessageTemplateType) => messageTemplate?.name || ''
    );

    messageTemplates.forEach((messageTemplate: MessageTemplateType) => RepliesCacheService.add(messageTemplate));
  };

  const updateChatMessageFromMessageType = (
    chatModel: ChatModel,
    message?: MessageType | void
  ): MessageModel | void => {
    if (!message || !message?.id?.length) {
      return;
    }

    const oldMessageModel: MessageModel | void = MessagesCacheService.getById(message.id);
    const newMessageModel: MessageModel | void = MessagesCacheService.add(message);
    if (!newMessageModel) {
      return oldMessageModel;
    }

    if (!oldMessageModel) {
      return newMessageModel;
    }

    // we found old message and new message, update it in the store
    messagingStore.updateChatMessage(chatModel.key, oldMessageModel.key, newMessageModel.key);
    return newMessageModel;
  };

  const updateChatLatestMessages = (chatModel: ChatModel, chatType: ChatType): void => {
    const newLatestMessageModel: MessageModel | void = updateChatMessageFromMessageType(
      chatModel,
      chatType?.latestMessage
    );
    const newLatestClientMessageModel: MessageModel | void = updateChatMessageFromMessageType(
      chatModel,
      chatType?.latestClientMessage
    );

    newLatestMessageModel && messagingStore.setInboxChatLastMessage(chatModel.key, newLatestMessageModel.key);
    newLatestClientMessageModel &&
      messagingStore.setInboxChatLastClientMessage(chatModel.key, newLatestClientMessageModel.key);
  };

  const updateChatMessages = (chatModel: ChatModel, chatType: ChatType, end: boolean = false): void => {
    if (!chatType?.messages?.length) {
      return;
    }

    addMessagesToChat(chatModel, createMessageModels(chatModel, chatType?.messages), end);
  };

  const addOrUpdateChat = (chatType?: ChatType | void, end: boolean = false): ChatModel | void => {
    if (!chatType || !chatType?.id) {
      console.error('Invalid chat type:', chatType);
      return;
    }

    const currentChatModel: ChatModel | void = ChatsCacheService.getById(chatType?.id);
    // we don't have this chat, lets create new chat data for it
    if (!currentChatModel) {
      const newChatModel: ChatModel | void = ChatsCacheService.add(chatType);
      if (!newChatModel?.key?.length) {
        console.error('Something bad happened with:', chatType);
        return;
      }

      updateChatLatestMessages(newChatModel, chatType);
      updateChatMessages(newChatModel, chatType, end);
      return newChatModel;
    }

    const updatedChatModel: ChatModel | void = ChatsCacheService.add(chatType);
    if (!updatedChatModel || !updatedChatModel?.key?.length) {
      console.error('Something bad happened with:', chatType);
      return;
    }

    // chat that we received from the backend, doesnt have proper timestamps or
    // nothing has changed, return
    if (currentChatModel.key === updatedChatModel.key) {
      updateChatLatestMessages(updatedChatModel, chatType);
      updateChatMessages(updatedChatModel, chatType, end);
      return updatedChatModel;
    }

    const activeChatModel: ChatModel | void = ChatsCacheService.get(messagingStore.activeChat);
    messagingStore.migrateChat(currentChatModel.key, updatedChatModel.key);
    if (activeChatModel.id === updatedChatModel.id) {
      messagingStore.setActiveChat(updatedChatModel.key);
    }

    const newAssignees: Array<ChatModel['staffersIds'][number] | ChatModel['teamsIds'][number]> = [
      ...updatedChatModel.staffersIds,
      ...updatedChatModel.teamsIds,
    ];
    messagingStore.removeChatFromOldAssignees(updatedChatModel.key, newAssignees);

    newAssignees.forEach((assigneeId: ChatModel['staffersIds'][number] | ChatModel['teamsIds'][number]) => {
      if (messagingStore.recentlyUpdatedChats?.[assigneeId]?.includes(updatedChatModel.key)) {
        return;
      }

      messagingStore.setRecentlyUpdatedChats(assigneeId, [
        updatedChatModel.key,
        ...(messagingStore.recentlyUpdatedChats?.[assigneeId] || []),
      ]);
    });

    updateChatLatestMessages(updatedChatModel, chatType);
    updateChatMessages(updatedChatModel, chatType, end);

    // chat was closed, remove it from every inbox but closed
    if (updatedChatModel?.closedAt?.length) {
      messagingStore.removeChatFromOldFilters(updatedChatModel.key, [ChatSegmentEnum.CLOSED]);
      return updatedChatModel;
    }

    // chat might be snoozed in some inboxes, remove chat from closed inboxes
    messagingStore.removeChatFromOldFilters(updatedChatModel.key, [ChatSegmentEnum.ASSIGNED, ChatSegmentEnum.SNOOZED]);

    // chat is not snoozed, remove it from all snoozed inboxes
    if (!updatedChatModel.snoozedIds.length) {
      messagingStore.removeChatFromOldFilters(updatedChatModel.key, [ChatSegmentEnum.ASSIGNED]);
      return updatedChatModel;
    }

    // chat is snoozed for some inboxes, for inboxes with this chat, remove it from every other status but snoozed
    messagingStore.removeChatFromUnsnoozedFilters(updatedChatModel.key, updatedChatModel.snoozedIds);

    return updatedChatModel;
  };

  const restoreChat = async (chatId: ChatType['id'] = router?.currentRoute?.params?.pk || ''): Promise<boolean> => {
    if (!chatId.length) {
      return false;
    }

    const currentChatId: ChatModel['id'] | '' = ChatsCacheService.get(messagingStore.activeChat)?.id || '';
    if (currentChatId === chatId) {
      return true;
    }

    const chatModel: ChatModel | void = ChatsCacheService.getById(chatId);
    if (chatModel) {
      messagingStore.setActiveChat(chatModel.key);
      await updateUrl(chatModel);

      return true;
    }

    const chatType: ChatType | void = await getChat(Builder(ChatArgumentsModel).id(chatId).build());
    // check for restore page, to avoid redundant restore actions
    if (!chatType || (router?.currentRoute?.params?.pk && chatType?.id !== router?.currentRoute?.params?.pk)) {
      return false;
    }

    const chatModels: ChatModel[] = createChatModels([chatType]);
    if (chatModels?.[0]?.key) {
      messagingStore.setActiveChat(chatModels?.[0]?.key);
      await updateUrl(chatModels?.[0]);

      return true;
    }

    return false;
  };

  const updateUrl = async (newChatModel: ChatModel): Promise<void> => {
    const currentChatId: string = router?.currentRoute?.params?.pk || '';
    const currentPatientId: string = getPatientIdFromQuery();
    if (currentChatId === newChatModel.id && currentPatientId === newChatModel.patientId) {
      return;
    }

    // todo: browser navigation is bugged, we should implement same "back" navigation as on patient portal demographics
    // const method: 'replace' | 'push' = newChatModel.id === currentChatId ? 'replace' : 'push';

    const result: Location = {
      params: { pk: newChatModel.id },
      query: newChatModel.patientId?.length ? { [Query.PATIENT_ID]: newChatModel.patientId } : {},
    };

    await router?.['replace'](result);
  };

  const createMessageModels = (chatModel: ChatModel, messageTypes: MessageType[] = []): MessageModel[] => {
    return messageTypes
      .map((messageType: MessageType) => updateChatMessageFromMessageType(chatModel, messageType) as MessageModel)
      .filter(Boolean);
  };

  const addDateModelsToMessageModels = (messageModels: MessageModel[] = []): MessageModel[] => {
    const formattedMessageModels: MessageModel[] = isNotDateMessage(messageModels?.[messageModels.length - 1])
      ? messageModels
      : messageModels.slice(0, -1);

    const newMessageModels: MessageModel[] = [];
    formattedMessageModels.forEach((messageModel: MessageModel) => {
      const lastValidMessage: MessageModel | void = newMessageModels.findLast(isNotDateMessage);
      if (lastValidMessage && shouldCreateDateMessage(lastValidMessage, messageModel)) {
        const dateMessage: MessageModel | void = createDateMessage(lastValidMessage);
        if (dateMessage && !formattedMessageModels.find((models: MessageModel) => models.key === dateMessage.key)) {
          newMessageModels.push(dateMessage);
        }
      }

      newMessageModels.push(messageModel);
    });

    // add date message to the beginning of the received thread
    const dateMessage: MessageModel | void = createDateMessage(newMessageModels[newMessageModels.length - 1]);
    if (dateMessage && !formattedMessageModels.find((models: MessageModel) => models.key === dateMessage.key)) {
      newMessageModels.push(dateMessage);
    }

    return newMessageModels;
  };

  // there are multiple active & listening components on the receiving end
  /*  const insertMacros = (id: string) => {
      const reply: ReplyModel = RepliesCacheService.getById(id);
      if (id !== reply.id) {
        console.error('Failed to insert macros: id !== reply.id', id, reply.id);
        return;
      }

      const client = ClientsCacheService.get(messagingStore.currentClient) || new ClientModel();
      const currentChat = ChatsCacheService.get(messagingStore.activeChat) || new ChatModel();

      const clientName: string = currentChat.displayNameShort || client.firstName || 'Patient';
      const stafferName: string =
        staffersStore.currentStaffer.displayNameShort || staffersStore.currentStaffer.firstName || 'Curex';
      const rawMessage: string = formatStringForSearch(messagingStore.currentDraftMessage);

      switch (messagingStore.currentChannel) {
        case MessageChannelEnum.NOTE:
        case MessageChannelEnum.EMAIL: {
          if (!messagingStore.currentMessageSubject.length) {
            const formattedSubject: string = reply.subject
              .replace(/{{clientFirstName}}/gi, clientName)
              .replace(/{{stafferFirstName}}/gi, stafferName);

            formattedSubject.length && messagingStore.updateSubject(formattedSubject);
          }

          const formattedText: string = reply.body
            .replace(/{{clientFirstName}}/gi, clientName)
            .replace(/{{stafferFirstName}}/gi, stafferName);

          if (!messagingStore.currentDraftMessage.length) {
            quillEditor.value?.quill?.clipboard?.dangerouslyPasteHTML(formattedText);
            return;
          }

          if (isReplyStartsWith(reply, rawMessage) || isReplyIncludes(reply, rawMessage)) {
            quillEditor.value?.quill?.clipboard?.dangerouslyPasteHTML(formattedText);
            return;
          }

          // this will emit update event
          quillEditor.value?.quill?.clipboard?.dangerouslyPasteHTML(messagingStore.currentDraftMessage + formattedText);
          return;
        }
        default: {
          const formattedText: string = reply.text
            .replace(/{{clientFirstName}}/gi, clientName)
            .replace(/{{stafferFirstName}}/gi, stafferName);

          if (!messagingStore.currentDraftMessage.length) {
            messagingStore.setCurrentDraft(formattedText);
            return;
          }

          if (isReplyStartsWith(reply, rawMessage) || isReplyIncludes(reply, rawMessage)) {
            messagingStore.setCurrentDraft(formattedText);
            return;
          }

          messagingStore.setCurrentDraft(messagingStore.currentDraftMessage + formattedText);
        }
      }
    };*/

  const getChatModelsFromChatKeys = (chatKeys: ChatModel['key'][]): ChatModel[] => {
    return chatKeys.map((chatKey: ChatModel['key']) => ChatsCacheService.get(chatKey)).filter(Boolean);
  };

  const getChatKeysFromChatModels = (chatModels: ChatModel[]): ChatModel['key'][] => {
    return chatModels.map((chatModel: ChatModel) => chatModel.key);
  };

  const getMessageModelsFromChatModel = (chatModel: ChatModel): MessageModel[] => {
    return getMessageModelsFromMessageKeys(messagingStore.chatsMessages?.[chatModel.key] || []);
  };

  const getMessageModelsFromMessageKeys = (messageKeys: MessageModel['key'][]): MessageModel[] => {
    return messageKeys.map((messageKey: MessageModel['key']) => MessagesCacheService.get(messageKey)).filter(Boolean);
  };

  const getMessageKeysFromMessageModels = (messageModels?: MessageModel[]): MessageModel['key'][] => {
    return messageModels.map((messageModel: MessageModel) => messageModel.key);
  };

  const getOpenChats = (teams: TeamType[] = []): Record<TeamType['id'], number> => {
    return Object.assign(
      {},
      ...teams.map((team: TeamType) => ({ [team.id]: parseAsNumber(parseAsString(team?.openChatsNumber)) as number }))
    );
  };

  return {
    init: () => RequestsQueueService.enqueue(init, [], { timeout: 31000 }),

    fetchNewInboxChats: (...args: Parameters<typeof fetchNewInboxChats>): ReturnType<typeof fetchNewInboxChats> =>
      RequestsQueueService.enqueue(fetchNewInboxChats, args),

    fetchOldInboxChats: (...args: Parameters<typeof fetchOldInboxChats>): ReturnType<typeof fetchOldInboxChats> =>
      RequestsQueueService.enqueue(fetchOldInboxChats, args),

    fetchNewMessages: () => RequestsQueueService.enqueue(fetchNewMessages),
    fetchOldMessages: () => RequestsQueueService.enqueue(fetchOldMessages),
    restoreChat: (...args: Parameters<typeof restoreChat>): ReturnType<typeof restoreChat> =>
      RequestsQueueService.enqueue(restoreChat, args),

    pollActiveInboxes: (): ReturnType<typeof pollActiveInboxes> =>
      RequestsQueueService.enqueue(async () => await pollActiveInboxes()),

    addOrUpdateChat,
    updateUrl,
    updateClientInformation,
  };
};

const useMessagingService: typeof useMessaging = createSharedComposable(useMessaging);

export { useMessagingService as useMessaging };
