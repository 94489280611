class S3FileUpload {
  public async uploadFile(url: string, file: File, fileType: string): Promise<void> {
    await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': fileType,
      },
      body: file,
    });
  }
}

const S3FileUploadService: S3FileUpload = new S3FileUpload();

export { S3FileUploadService };
