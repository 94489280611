import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const SLACK_LOGIN = gql`
  ${STAFFER}
  mutation LoginWithSlack($accessCode: String!) {
    slackLogin(accessCode: $accessCode) {
      token
      staffer {
        ...Staffer
      }
    }
  }
`;

export { SLACK_LOGIN };
