import gql from 'graphql-tag';

const SEND_LOGIN_LINK = gql`
  mutation ($id: UUID!) {
    sendLoginLink(clientId: $id) {
      status
    }
  }
`;

export { SEND_LOGIN_LINK };
