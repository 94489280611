import { differenceInYears, fromUnixTime, isValid, parse } from 'date-fns';
import { format as formatTZ, toDate as toDateTZ, toZonedTime } from 'date-fns-tz';
import Vue from 'vue';
import parseAsString from 'lodash/toString';
import { Timezone } from '@/types';
import { enUS } from 'date-fns/locale';

/**
 * TODO: Filters (a special date formatters) definitions can be moved to composables and then applied here. Implement once, use everywhere
 */

function toDate(timestamp: string | number | Date, timezone?: Timezone): Date {
  let date = parse(`${timestamp}`, 'yyyy-MM-dd', new Date(), { locale: enUS });

  if (isValid(date)) {
    return date;
  }

  date = timezone ? toZonedTime(timestamp, timezone) : toDateTZ(timestamp);

  if (isValid(date)) {
    return date;
  }

  return new Date(timestamp);
}

Vue.filter(
  'formatDate',
  function (timestamp: string | number | Date, timeZone?: Timezone, showZoneToken?: boolean): string {
    const date: Date = toDate(timestamp, timeZone);

    if (!isValid(date)) {
      return `${timestamp}`;
    }

    const stringFormat = `M/dd/yyyy @ h:mm a${showZoneToken ? ' zzz' : ''}`;

    return formatTZ(date, stringFormat, { timeZone, locale: enUS });
  }
);

Vue.filter('formatDay', function (dateString: string | number | Date, timeZone?: Timezone) {
  if (!dateString) {
    return '';
  }

  const date = toDate(dateString, timeZone);
  const stringFormat = 'M/dd/yyyy';

  return formatTZ(date, stringFormat, { timeZone });
});

Vue.filter('formatSimpleDay', function (dateString: string, timeZone?: Timezone) {
  if (!dateString) {
    return '';
  }

  const date = toDate(dateString, timeZone);
  const stringFormat = 'M/dd/yy';

  return formatTZ(date, stringFormat, { timeZone });
});

Vue.filter('formatTimestampDate', function (dateTimestamp: number, timeZone?: Timezone, showZoneToken?: boolean) {
  if (dateTimestamp === 0 || !dateTimestamp) {
    return '';
  }

  const stringFormat = `M/dd/yyyy @ h:mm a${showZoneToken ? ' zzz' : ''}`;

  return formatTZ(fromUnixTime(dateTimestamp), stringFormat, { timeZone, locale: enUS });
});

Vue.filter('formatTimestampDay', function (dateTimestamp: number, timeZone?: Timezone) {
  if (dateTimestamp === 0 || !dateTimestamp) {
    return '';
  }

  const stringFormat = 'M/dd/yyyy';

  return formatTZ(fromUnixTime(dateTimestamp), stringFormat, { timeZone });
});

Vue.filter('formatTime', function (dateString: string, timeZone?: Timezone, showZoneToken?: boolean) {
  if (!dateString) {
    return dateString;
  }

  const date = toDate(dateString, timeZone);
  const stringFormat = `h:mma${showZoneToken ? ' zzz' : ''}`;

  return formatTZ(date, stringFormat, { timeZone, locale: enUS });
});

Vue.filter('formatSimpleTime', function (dateString: string, timeZone?: Timezone, showZoneToken?: boolean) {
  if (!dateString) {
    return dateString;
  }

  const date = toDate(dateString, timeZone);
  const stringFormat = `h:mm${showZoneToken ? ' zzz' : ''}`;

  return formatTZ(date, stringFormat, { timeZone, locale: enUS });
});

Vue.filter('formatDOB', function (dobString: string) {
  if (dobString) {
    return differenceInYears(new Date(), toDate(dobString));
  }
});

Vue.filter('formatEnum', function (enumValue: string) {
  const formattedSentence: string = parseAsString(enumValue);
  return formattedSentence.charAt(0).toUpperCase() + formattedSentence.slice(1).replaceAll('_', ' ');
});

Vue.filter('formatJSON', function (data: Record<string, unknown> | string): string {
  if (typeof data === 'string') {
    data = JSON.parse(data);
  }
  return (
    Object.entries(data)
      .map(([k, v]) => `${k}: ${v}`)
      .join('; ') || 'None'
  );
});
