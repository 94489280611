import type { PluginObject } from 'vue';
import type { Vue as _Vue } from 'vue/types/vue';
import * as defaultRules from 'vee-validate/dist/rules';

import type { ValidationRule } from 'vee-validate/dist/types/types';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';

import appRules from '@/rules';

const loadRules = (rulesSet: Record<string, Partial<ValidationRule>>) => {
  for (const [ruleName, ruleDefinition] of Object.entries(rulesSet)) {
    extend(ruleName, { ...ruleDefinition });
  }
};

const VeeValidatePlugin: PluginObject<void> = {
  install: (Vue: typeof _Vue): void => {
    /*
     * Load default vee-validate rules and define in ~/rules
     */
    loadRules({ ...defaultRules, ...appRules });

    // Set default mode
    setInteractionMode('debounced', (data) => {
      if (data.errors.length) {
        return {
          on: ['input', 'change'],
          debounce: 0,
        };
      }

      return {
        on: ['input', 'change', 'blur'],
        debounce: 350,
      };
    });

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
  },
};

export default VeeValidatePlugin;
