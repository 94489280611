import { AccountType, PatientType } from '@/types';

class MedicalDocumentPropsModel {
  public documentKey: string = ''; // medical document key
  public patientId: PatientType['id'] = '';
  public clientId: AccountType['id'] = ''; // user-id

  public loading: boolean = false;
}

export { MedicalDocumentPropsModel };
