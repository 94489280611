export enum ProviderActionKindEnum {
  FOLLOW_UP = 'follow_up',
  LAB_ORDER_CUREX = 'lab_order_curex',
  LAB_ORDER_EXAMONE = 'lab_order_examone',
  LAB_ORDER_GETLABS = 'lab_order_getlabs',
  LAB_ORDER_LABCORP = 'lab_order_labcorp',
  LAB_ORDER_OTHER = 'lab_order_other',
  LAB_ORDER_QUEST = 'lab_order_quest',
  OTHER = 'other',
  OUTSTANDING = 'outstanding',
  PRESCRIBE_EPI = 'prescribe_epi',
  PRESCRIBE_OTHER = 'prescribe_other',
  PRESCRIBE_SLIT = 'prescribe_slit',
  SCARLET = 'scarlet',
  SIGN_LAB_ORDER_OTHER = 'sign_lab_order_other',
  SIGN_PRESCRIPTION = 'sign_prescription',
}

export type ProviderActionKindEnumKey = keyof typeof ProviderActionKindEnum;
