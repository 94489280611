import type { TeamModel } from '../models/TeamModel';

import type { AccountType } from '@/types';

type AssignOptionKey = 'separator-1' | 'separator-2' | AccountType['id'] | TeamModel['id'];
type AssignOptionLabel = AccountType['displayName'] | TeamModel['name'];

class AssignOptionModel {
  public key: AssignOptionKey = '';
  public label: AssignOptionLabel = '';
  public selected: boolean = false;
}

export { AssignOptionModel };
