import gql from 'graphql-tag';

import { MESSAGE_TEMPLATE } from './fragments/MESSAGE_TEMPLATE';

const CREATE_MESSAGE_TEMPLATE = gql`
  ${MESSAGE_TEMPLATE}
  mutation CreateMessageTemplate(
    $body: String!
    $description: String
    $name: String!
    $subject: String
    $text: String!
  ) {
    createMessageTemplate(body: $body, description: $description, name: $name, subject: $subject, text: $text) {
      ...MessageTemplate
    }
  }
`;

export { CREATE_MESSAGE_TEMPLATE };
