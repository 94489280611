import { LockWorker } from '@/services/LockWorker';
import { ClientWorker } from '@/clientWorkers';
import { MessagingSoundsService } from '~/messaging/services/MessagingSounds';

export class ClientWorkerMessagingSound extends ClientWorker {
  protected lockWorker?: LockWorker;

  public onReady() {
    this.lock();
  }

  public async onMessage({ data = {} }: MessageEvent): Promise<void> {
    const { type = '', payload = {} } = data;

    if (type !== 'Poll::Sync' || !payload?.assignedChatIds?.length) {
      return;
    }

    if (this.lockWorker?.isLocked && MessagingSoundsService.shouldPlaySound(payload.assignedChatIds)) {
      await MessagingSoundsService.playSound();
    }

    // sync the data in the storage and call sound
    MessagingSoundsService.lastAssignedChatIds = payload.assignedChatIds;
  }

  protected lock(): void {
    if (!this.lockWorker) {
      this.lockWorker = new LockWorker({
        lockId: 'ClientWorkerMessagingSound',
        lockOnInit: true,
        onLock: () => this.postMessage({ type: 'Poll::Start' }),
      });
    }
  }
}
