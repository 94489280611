import gql from 'graphql-tag';

import { CLIENT_FULL_ACCOUNT } from './fragments/CLIENT_FULL_ACCOUNT';

const GET_FULL_CLIENT_ACCOUNT = gql`
  ${CLIENT_FULL_ACCOUNT}
  query GetFullClientAccount($id: UUID) {
    account(id: $id) {
      ...ClientFullAccount
    }
  }
`;

export { GET_FULL_CLIENT_ACCOUNT };
