import gql from 'graphql-tag';

import { ACCOUNT } from './fragments/ACCOUNT';

const GET_CLIENT = gql`
  ${ACCOUNT}
  query GetClient($id: UUID) {
    account(id: $id) {
      ...MessagingAccount
    }
  }
`;

export { GET_CLIENT };
