import gql from 'graphql-tag';

import { REPLY_SUGGESTION } from './fragments/REPLY_SUGGESTION';

const GET_REPLY_SUGGESTIONS = gql`
  ${REPLY_SUGGESTION}
  query GetReplySuggestions($messageId: UUID!) {
    replySuggestions(messageId: $messageId) {
      ...ReplySuggestion
    }
  }
`;

export { GET_REPLY_SUGGESTIONS };
