import isNil from 'lodash/isNil';

import type { ModelToProps, PropsModel } from '../types';

import { getPropType } from './getPropType';
import { getPropValidator } from './getPropValidator';
import { getPropValue } from './getPropValue';

const buildPropsFromModel = <Model extends PropsModel<Model>>(model: Model): ModelToProps<Model> => {
  const result: ModelToProps<Model> = {} as ModelToProps<Model>;

  for (const key in model) {
    // safe call for older browsers
    if (!Object.prototype.hasOwnProperty.call(model, key)) {
      continue;
    }

    const value = model?.[key];
    if (isNil(value)) {
      result[key] = {
        default: value,
      };
    } else {
      result[key] = {
        default: getPropValue(value),
        type: getPropType(value),
        validator: getPropValidator(value),
      };
    }
  }

  return result;
};

export { buildPropsFromModel };
