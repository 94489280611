type ResolveType<T> = (value?: T) => void;
type RejectType = (reason?: unknown) => void;

const defer = <T>(): Promise<T> & { resolve: ResolveType<T>; reject: RejectType } => {
  let resolved: ResolveType<T>;
  let rejected: RejectType;

  const promise: Promise<T> = new Promise((resolve: ResolveType<T>, reject: RejectType): void => {
    resolved = resolve;
    rejected = reject;
  });

  return Object.assign(promise, { resolve: resolved, reject: rejected });
};

export { defer };
