import type { PropOptions } from 'vue';

import isSymbol from 'lodash/isSymbol';
import isNil from 'lodash/isNil';

const symbolPropValidator = <T extends symbol>(): PropOptions<T>['validator'] => {
  return (value: T): value is T => {
    return !isNil(value) && isSymbol(value);
  };
};

export { symbolPropValidator };
