import { computed, onActivated, onBeforeMount, toRef, watch } from 'vue';
import type { MaybeRef } from '@vueuse/core';
import { toValue } from '@vueuse/core';

import uniq from 'lodash/uniq';

import {
  ChatModel,
  ChatsCacheService,
  MessageModel,
  MessagesCacheService,
  useMessagingApi,
  useMessagingStore,
} from '~/messaging';

import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import { showError } from '@/components/errors';
import { useRoot } from '@/composables/atoms/useRoot';

import { ReplySuggestionType } from '@/types';
import { SuggestionsCacheService } from '~/messaging/services/SuggestionsCache';

interface MessagingReplySuggestionsOptions {
  generateSuggestionOnLastInboxClientMessageChange?: boolean;
}

// works of chat updates
const useMessagingReplySuggestions = (
  chatKey: MaybeRef<ChatModel['key']>,
  options: MaybeRef<MessagingReplySuggestionsOptions> = { generateSuggestionOnLastInboxClientMessageChange: true }
) => {
  const root = useRoot();

  const currentChatKey: ChatModel['key'] = toValue(chatKey);
  const currentOptions: MessagingReplySuggestionsOptions = toValue(options);

  const messagingStore = useMessagingStore();

  const inboxChatsLastClientMessage = toRef(messagingStore, 'inboxChatsLastClientMessage');
  const messageReplySuggestionsLoading = toRef(messagingStore, 'messageReplySuggestionsLoading');
  const messageReplySuggestions = toRef(messagingStore, 'messageReplySuggestions');

  const { setMessageReplySuggestions, setMessageReplySuggestionsLoading, increaseChatActions, decreaseChatActions } =
    messagingStore;

  const { getReplySuggestions } = useMessagingApi();

  const currentChat = computed((): ChatModel => ChatsCacheService.get(currentChatKey) || new ChatModel());

  // todo: it seems that we want to get suggestions also from last client message in the chat?
  const currentChatLatestClientMessage = computed(
    (): MessageModel =>
      inboxChatsLastClientMessage.value?.[currentChatKey]
        ? MessagesCacheService.get(inboxChatsLastClientMessage.value?.[currentChatKey]) || new MessageModel()
        : new MessageModel()
  );

  const currentMessageReplySuggestionsLoading = computed(
    (): boolean => messageReplySuggestionsLoading.value?.[currentChatLatestClientMessage.value.id] || false
  );

  const currentMessageReplySuggestions = computed(
    (): ReplySuggestionType['id'][] => messageReplySuggestions.value?.[currentChatLatestClientMessage.value.id] || []
  );

  const currentMessageReplySuggestion = computed((): string =>
    currentMessageReplySuggestions.value?.[0]?.length &&
    SuggestionsCacheService.has(currentMessageReplySuggestions.value?.[0])
      ? SuggestionsCacheService.get(currentMessageReplySuggestions.value?.[0]).text
      : ''
  );

  const generateReplySuggestions = async (
    message: MessageModel = currentChatLatestClientMessage.value,
    chat: ChatModel = currentChat.value
  ): Promise<void> => {
    if (currentMessageReplySuggestionsLoading.value) {
      return;
    }

    // copy message id to properly handle loading state
    const { id: messageId }: MessageModel = message;
    const { id: chatId }: ChatModel = chat;
    if (!messageId.length) {
      console.error('Cannot preload reply suggestion for a chat, last message is missing.');
      return;
    }

    if (!chatId.length) {
      showError(root, 'Cannot preload reply suggestion for a chat, chat is missing.', 1000);
      return;
    }

    if (SuggestionsCacheService.hasByMessageId(messageId)) {
      return;
    }

    setMessageReplySuggestionsLoading(messageId, true);
    increaseChatActions(chatId);

    try {
      const newReplySuggestions: ReplySuggestionType[] = await getReplySuggestions(messageId);
      if (messageId !== currentChatLatestClientMessage.value.id || chatId !== chat.id) {
        return;
      }

      if (!newReplySuggestions.length) {
        console.error(`No suggestions for a chat ${chatId} with message ${messageId}.`);
        return;
      }

      newReplySuggestions.forEach((suggestion: ReplySuggestionType) =>
        SuggestionsCacheService.add(messageId, suggestion)
      );

      setMessageReplySuggestions(
        messageId,
        uniq([
          ...newReplySuggestions.map((suggestion: ReplySuggestionType) => suggestion.id),
          ...currentMessageReplySuggestions.value,
        ])
      );
    } catch (error) {
      showError(
        root,
        `Failed to load reply suggestions for the message: ${messageId}. ${parseGraphQLErrorsAsText(error)}`,
        25000
      );
    } finally {
      setMessageReplySuggestionsLoading(messageId, false);
      decreaseChatActions(chatId);
    }
  };

  const init = async (): Promise<void> => {
    if (!currentOptions.generateSuggestionOnLastInboxClientMessageChange) {
      return;
    }

    await generateReplySuggestions();
  };

  onBeforeMount(init);
  onActivated(init);

  watch(
    currentChatLatestClientMessage,
    async (
      newLatestClientMessage: MessageModel = new MessageModel(),
      oldLatestClientMessage: MessageModel = new MessageModel()
    ): Promise<void> => {
      if (!currentOptions.generateSuggestionOnLastInboxClientMessageChange || !newLatestClientMessage.id.length) {
        return;
      }

      if (newLatestClientMessage.id === oldLatestClientMessage.id && currentMessageReplySuggestions.value.length) {
        return;
      }

      await generateReplySuggestions(newLatestClientMessage);
    },
    { flush: 'pre' }
  );

  return {
    currentMessageReplySuggestionsLoading,
    currentMessageReplySuggestions,
    currentMessageReplySuggestion,

    generateReplySuggestions,
  };
};

export { useMessagingReplySuggestions };
