// This service exists to lazy load this awesome library that doesn't support tree shaking

class Gsap {
  private _gsap: typeof import('gsap') | void = null;

  public get isLoaded(): boolean {
    return !!this._gsap;
  }

  public get get(): Gsap['_gsap'] {
    return this.isLoaded ? this._gsap : null;
  }

  public async load(): Promise<void> {
    try {
      this._gsap = await import(/* webpackMode: "lazy" */ 'gsap');
    } catch (e) {
      console.error('Gsap error:', e);
    }
  }
}

const GsapService: Gsap = new Gsap();

export { GsapService };
