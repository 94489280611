import type { PropOptions } from 'vue';

import isBoolean from 'lodash/isBoolean';

const booleanPropValidator = <T>(): PropOptions<T>['validator'] => {
  return (value: boolean): value is boolean => {
    return isBoolean(value);
  };
};

export { booleanPropValidator };
