import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const GET_STAFFERS = gql`
  ${STAFFER}
  query GetStaffers {
    staffers(roles: [admin, doctor, robot], limit: -1, onlyActive: false) {
      ...Staffer
    }
  }
`;

export { GET_STAFFERS };
