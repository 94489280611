export enum PollenRegionEnum {
  coastal_and_central_ca = 'coastal_and_central_ca',
  desert_southwest = 'desert_southwest',
  eastern_mountains = 'eastern_mountains',
  intermountain_and_high_desert = 'intermountain_and_high_desert',
  mid_atlantic_coastal = 'mid_atlantic_coastal',
  midwest_mississippi_valley = 'midwest_mississippi_valley',
  national_pollen_mix_part_a = 'national_pollen_mix_part_a',
  national_pollen_mix_part_b = 'national_pollen_mix_part_b',
  northeastern_great_plains = 'northeastern_great_plains',
  northeastern_mixed_forest = 'northeastern_mixed_forest',
  pacific_northwest = 'pacific_northwest',
  southeastern_costal_plain = 'southeastern_costal_plain',
  southern_great_plains = 'southern_great_plains',
  subtropical_florida = 'subtropical_florida',
}
