import memoize from 'lodash/memoize';

import { createList } from './createList';
import { getStafferFullNameAndEmail } from './getStafferFullNameAndEmail';
import type { StafferType } from '@/types';

// https://vue-multiselect.js.org

// default multiselect option:
interface Option {
  label: string; // default option text, if option is an object
  value: string; // should be explicitly specified as track-by="value" on the component
  $isActive?: boolean; // internal prop, not vue multiselect prop
  $isDisabled?: boolean;
}

// todo: add unassign

// every argument is passed like this, to keep those properties reactive
const createMultiselectList = (
  staffers: StafferType[] = [],
  currentStaffer?: StafferType | void,
  selectedStaffers: Option[] = []
): Option[] => {
  const formattedList: Option[] = createList(staffers, currentStaffer)
    .map((account: StafferType) => {
      const isActive: boolean = !!selectedStaffers.find(
        (selectedStaffer: Option) => selectedStaffer.value === account.id
      );
      const isDisabled: boolean = !account?.isActive;
      const isMe: boolean = currentStaffer && currentStaffer?.id === account.id;

      const text: string = isActive
        ? getStafferFullNameAndEmail(account)
        : isMe
        ? `Assign to me (${account?.email})`
        : getStafferFullNameAndEmail(account);

      return {
        label: isDisabled ? `${getStafferFullNameAndEmail(account)} (deactivated)` : text,
        value: account.id,
        $isDisabled: isDisabled,
        $isActive: isActive,
      };
    })
    .filter((option: Option) => (option.$isDisabled ? option.$isActive : true));

  const missingStaffers: Option[] = selectedStaffers
    .filter((selectedStaffer: Option) => !staffers.find((staffer: StafferType) => staffer.id === selectedStaffer.value))
    .map((selectedStaffer: Option) =>
      selectedStaffer.value
        ? {
            label: 'Missing staffer ' + selectedStaffer.value,
            value: selectedStaffer.value,
            $isDisabled: true,
            $isActive: true,
          }
        : {
            label: 'Unassigned',
            value: selectedStaffer.value,
            $isDisabled: true,
            $isActive: true,
          }
    );

  return [...formattedList, ...missingStaffers];
};

const createMultiselectListMemoized: typeof createMultiselectList = memoize(createMultiselectList);

export { createMultiselectListMemoized as createMultiselectList };
