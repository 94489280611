import type { TeamModel } from '../models/TeamModel';

import type { AccountType } from '@/types';

type InboxFilterOptionKey = 'separator-1' | AccountType['id'] | TeamModel['id'];
type InboxFilterOptionLabel = AccountType['displayName'] | TeamModel['name'];
type InboxFilterOptionIcon = 'envelope-open-text' | 'envelope' | 'clock' | 'envelopes-bulk' | 'people-group' | '';

class InboxStafferOrTeamOptionModel {
  public key: InboxFilterOptionKey = '';
  public icon: InboxFilterOptionIcon = '';
  public label: InboxFilterOptionLabel = '';
  public openChats: string = '';
  public selected: boolean = false;
}

export { InboxStafferOrTeamOptionModel };
