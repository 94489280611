enum EventSourceEnum {
  UNKNOWN = 'unknown',
  ADMIN = 'admin',
  EHR = 'ehr',
  INTAKE_FORM = 'intake_form',
  MY_CUREX = 'my_curex',
  SEVEN_DROPS = 'seven_drops',
  GETLABS = 'getlabs',
  SCARLET = 'scarlet',
  BIOTEK = 'biotek',
  PERSONA = 'persona',
  STRIPE = 'stripe',
  AWS_SES = 'aws_ses',
  AWS_PINPOINT = 'aws_pinpoint',
  TWILIO = 'twilio',
  INTERCOM = 'intercom',
  N8N = 'n8n',
  AIRCALL = 'aircall',
}

export { EventSourceEnum };
