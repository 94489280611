import memoize from 'lodash/memoize';

import { getStafferFirstName } from './getStafferFirstName';

import type { StafferType } from '@/types';

const getStaffersFirstNames = (staffers: StafferType[]): string[] => {
  return staffers.map(getStafferFirstName);
};

const getStaffersFirstNamesMemoized: typeof getStaffersFirstNames = memoize(getStaffersFirstNames);

export { getStaffersFirstNamesMemoized as getStaffersFirstNames };
