
import { computed, defineComponent, toRefs } from 'vue';

import { MessageModel, MessagesCacheService } from '~/messaging';

const ChatDate = defineComponent({
  props: {
    messageKey: {
      default: '',
      required: false,
      type: String,
    },
  },
  setup(props: { messageKey: string }) {
    const { messageKey } = toRefs<{ messageKey: string }>(props);

    const message = computed((): MessageModel => MessagesCacheService.get(messageKey.value) || new MessageModel());

    return {
      message,
    };
  },
});

export default ChatDate;
