import gql from 'graphql-tag';

import { SYSTEM_EVENT } from './fragments/SYSTEM_EVENT';

const GET_SYSTEM_EVENTS = gql`
  ${SYSTEM_EVENT}
  query GetSystemEvents(
    $clientId: UUID
    $type: EventTypeEnum
    $source: EventSourceEnum
    $search: String
    $sortBy: String
    $offset: Int
    $limit: Int
  ) {
    systemEvents(
      clientId: $clientId
      type: $type
      source: $source
      search: $search
      sortBy: $sortBy
      offset: $offset
      limit: $limit
    ) {
      ...SystemEvent
    }
  }
`;

export { GET_SYSTEM_EVENTS };
