export enum VoicecallsVoiceCallMissedCallReasonChoices {
  ABANDONED_IN_CLASSIC = 'Abandoned in classic',
  ABANDONED_IN_IVR = 'Abandoned in ivr',
  AGENTS_DID_NOT_ANSWER = 'Agents did not answer',
  NO_AVAILABLE_AGENT = 'No available agent',
  OUT_OF_OPENING_HOURS = 'Out of opening hours',
  SHORT_ABANDONED = 'Short abandoned',
  UNKNOWN = 'Unknown',
}

export type VoicecallsVoiceCallMissedCallReasonChoicesKey = keyof typeof VoicecallsVoiceCallMissedCallReasonChoices;
export type VoicecallsVoiceCallMissedCallReasonChoicesValue = `${VoicecallsVoiceCallMissedCallReasonChoices}`;
