
import { computed, defineComponent, ref, toRef } from 'vue';

import parseAsString from 'lodash/toString';

import { ChatModel, ChatsCacheService, useMessagingApi, useMessagingStore } from '~/messaging';

import { useRoot } from '@/composables/atoms/useRoot';
import { showError } from '@/components/errors';
import { setText } from '@/components/Editor/helpers';

import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';
import { MessageChannelEnum } from '@/types';

//Bootstrap tabs are starting to lag, if they are controlled by our state
const ChatGenerateReplySuggestionButton = defineComponent({
  setup: function () {
    const root = useRoot();

    const loading = ref<number>(0);
    const showPopover = ref<boolean>(false);

    const currentSuggestionIndex = ref<number>(0);
    const suggestions = ref<string[]>([]);

    const { generateReplySuggestion } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');
    const currentChannel = toRef(messagingStore, 'currentChannel');

    const { setCurrentDraft, increaseChatActions, decreaseChatActions } = messagingStore;

    const currentSuggestion = computed((): string => parseAsString(suggestions.value?.[currentSuggestionIndex.value]));
    const currentChat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    const popoverText = computed((): string => {
      if (loading.value) {
        return 'Loading suggestion...';
      }

      return !currentSuggestion.value.length ? 'No suggestions' : currentSuggestion.value;
    });

    const generateSuggestion = async (): Promise<void> => {
      // copy chat properly handle loading state
      const { id, key, displayName }: ChatModel = currentChat.value;
      if (!key) {
        console.error('sendMessage chatModel not found', activeChat.value);
        return;
      }

      showPopover.value = true;

      loading.value++;
      increaseChatActions(id);

      try {
        const suggestion: string = await generateReplySuggestion(id);
        if (currentChat.value.id.length && id !== currentChat.value.id) {
          return;
        }

        if (suggestion.length) {
          suggestions.value.push(suggestion);
          currentSuggestionIndex.value = suggestions.value.length - 1;
        }

        showPopover.value = true;
      } catch (error) {
        showError(root, `Failed to generate reply suggestion for ${displayName}. ${parseGraphQLErrorsAsText(error)}`);
        console.error('generateSuggestion', error);
      } finally {
        loading.value--;
        decreaseChatActions(id);
      }
    };

    const generateSuggestionIfNoSuggestion = async (): Promise<void> => {
      // showPopover.value = true;

      if (currentSuggestion.value.length || loading.value) {
        return;
      }

      await generateSuggestion();
    };

    const applySuggestion = async (): Promise<void> => {
      showPopover.value = false;
      setCurrentDraft(currentSuggestion.value);

      if (
        [MessageChannelEnum.NOTE, MessageChannelEnum.ACTION, MessageChannelEnum.EMAIL].includes(currentChannel.value)
      ) {
        await setText(currentChat.value.id, currentSuggestion.value);
      }
    };

    return {
      loading,

      showPopover,
      popoverText,

      currentSuggestion,

      generateSuggestion,
      generateSuggestionIfNoSuggestion,
      applySuggestion,
    };
  },
});

export default ChatGenerateReplySuggestionButton;
