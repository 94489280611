import { copyTextToClipboard, fallbackCopyTextToClipboard } from './helpers';

class Clipboard {
  public get isNavigatorClipboardAvailable(): boolean {
    return navigator && !!navigator.clipboard;
  }

  public async write(text: string): Promise<boolean> {
    if (!text) {
      return false;
    }

    if (this.isNavigatorClipboardAvailable) {
      return await copyTextToClipboard(text);
    }

    return fallbackCopyTextToClipboard(text);
  }
}

const ClipboardService: Clipboard = new Clipboard();

export { ClipboardService, Clipboard };
