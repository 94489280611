import gql from 'graphql-tag';

import { UPLOADED_FILE } from './UPLOADED_FILE';

const MEDIAL_DOCUMENT = gql`
  ${UPLOADED_FILE}
  fragment MedicalDocument on MedicalDocumentType {
    id
    createdAt
    updatedAt
    deletedAt

    description
    client {
      id
    }
    uploadedBy {
      id
    }
    uploadedFiles {
      ...UploadedFile
    }
  }
`;

export { MEDIAL_DOCUMENT };
