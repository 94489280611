const cache: Map<string, string> = new Map();

const removeHelper = (html: string) =>
  new DOMParser()?.parseFromString(html, 'text/html')?.body?.textContent?.replace(/\s\s+/g, ' ') || '';

const stripHtml = (html: string): string => {
  if (html.length <= 128) {
    if (cache.has(html)) {
      return cache.get(html);
    }

    const output: string = removeHelper(html);
    cache.set(html, output);
    return output;
  }

  return removeHelper(html);
};

export { stripHtml };
