import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const SEND_MESSAGE = gql`
  ${CHAT}
  ${MESSAGE}
  mutation SendMessage(
    $attachmentIds: [UUID]
    $channels: [MessageChannelEnum]!
    $chatId: UUID!
    $limit: Int
    $message: String!
    $onlyMessagesAfter: DateTime
    $replyTo: UUID
    $subject: String
  ) {
    sendMessage(
      attachmentIds: $attachmentIds
      channels: $channels
      chatId: $chatId
      limit: $limit
      message: $message
      onlyMessagesAfter: $onlyMessagesAfter
      replyTo: $replyTo
      subject: $subject
    ) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { SEND_MESSAGE };
