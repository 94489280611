import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const DELETE_NOTE = gql`
  ${CHAT}
  ${MESSAGE}
  mutation DeleteNote($messagingNoteId: UUID!, $limit: Int, $onlyMessagesAfter: DateTime) {
    deleteMessagingNote(messagingNoteId: $messagingNoteId, limit: $limit, onlyMessagesAfter: $onlyMessagesAfter) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { DELETE_NOTE };
