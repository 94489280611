import gql from 'graphql-tag';

const MESSAGE_TEMPLATE = gql`
  fragment MessageTemplate on MessageTemplateType {
    id
    name
    description
    subject
    body
    text
  }
`;

export { MESSAGE_TEMPLATE };
