export enum InsuranceProvider {
  unknown = 'unknown',
  cigna = 'cigna',
  united_healthcare = 'united_healthcare',
  aetna = 'aetna',
  blue_cross_shield = 'blue_cross_shield',
  anthem = 'anthem',
  centene = 'centene',
  humana = 'humana',
  medicaid = 'Medicaid',
  medicare = 'Medicare',
  other = 'other',
}

export interface InsuranceCardType {
  cardImageBack: string;
  cardImageFront: string;
  createdAt?: string;
  id: string;
  insuranceProvider: string;
  policyholderDateOfBirth?: Date;
  policyholderName: string;
  policyholderMemberId: string;
  uploadedBy?: { firstName: string; lastName: string };
}
