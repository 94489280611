import { extension } from 'mime-types';
import type { MimeType } from 'file-type';

import kebabCase from 'lodash/kebabCase';
import parseAsString from 'lodash/toString';

const extractFileExtensionAndBaseName = (
  fileName: string,
  fileType: MimeType | 'application/octet-stream' = 'application/octet-stream'
): {
  baseName: string;
  extension: string;
} => {
  const formattedFileName: string = parseAsString(fileName);

  // Split the file name and extension
  const lastDotIndex: number | -1 = formattedFileName.lastIndexOf('.');
  const baseName: string = lastDotIndex !== -1 ? formattedFileName.slice(0, lastDotIndex) : formattedFileName;
  const fileExtension: string = lastDotIndex !== -1 ? formattedFileName.slice(lastDotIndex) : extension(fileType) || '';

  // Check for special cases
  if (baseName.startsWith('.')) {
    // File name starts with a dot (hidden file), treat it as part of the extension
    return { baseName: 'file-with-no-name', extension: kebabCase(formattedFileName) };
  }

  return { baseName: kebabCase(baseName), extension: kebabCase(fileExtension) };
};

export { extractFileExtensionAndBaseName };
