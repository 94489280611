import { SpecialKey } from '../types/specialKey';
import { isMacDevice } from '../validation/isMacDevice';

const readableShortcut = (shortcutCode: string): string => {
  const commonReplacements: string = shortcutCode.replaceAll('+Key', '+');

  if (isMacDevice()) {
    return commonReplacements
      .replace(SpecialKey.CTRL, 'Control')
      .replace(SpecialKey.META, 'Command')
      .replace(SpecialKey.ALT, 'Option');
  }

  return commonReplacements;
};

export { readableShortcut };
