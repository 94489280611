import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import type { Dictionary } from 'lodash';

const mergeObjectsById = <T extends Record<string, any>>(array: T[]): T[] => {
  const groupedById: Dictionary<T[]> = groupBy(array, 'id');
  // todo: fix types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.values(mapValues(groupedById, (group: T[]) => merge({}, ...group)));
};

export { mergeObjectsById };
