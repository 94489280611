
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { AllergenType } from '@/types';

@Component
export default class ProfileLabResultsCard extends Vue {
  @Prop() private data!: [];
  @Prop({ default: '' }) private contentStyle?: string;

  get cardContentStyle(): string {
    return this.contentStyle ? this.contentStyle : '';
  }

  totalIGE(results: string): string {
    if (!results) {
      return '';
    }
    return JSON.parse(results).total_ige;
  }

  sortedAllergens(results: string): AllergenType[] {
    if (!results) {
      return [];
    }

    const allergens = JSON.parse(results).allergens;
    const allergensList: AllergenType[] = allergens ? Object.values(allergens) : [];

    return allergensList.sort((lhs: AllergenType, rhs: AllergenType) => {
      return this.toNumber(lhs.reading_string) < this.toNumber(rhs.reading_string) ? 1 : -1;
    });
  }

  // helpers
  toNumber(value: string): number {
    return Number(value.replace(/[^.\d]/g, ''));
  }

  checkAllergenResult(value: string): boolean {
    return Number(value.replace(/[^.\d]/g, '')) > 0.1;
  }
}
