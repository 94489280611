import { GET_TAGS } from '../gql/GET_TAGS';

import { useApollo } from '@/composables/atoms/useApollo';

import type { TagType } from '@/types';

const useTagsApi = () => {
  const { loading, query } = useApollo();

  const getTags = async (): Promise<TagType[]> => {
    const response = await query<{
      allTags: TagType[];
    }>({
      query: GET_TAGS,
      fetchPolicy: 'no-cache',
    });

    return response?.data?.allTags || [];
  };

  return {
    loading,

    getTags,
  };
};

export { useTagsApi };
