
import { defineComponent, onBeforeMount, onBeforeUnmount, toRefs, watch } from 'vue';
import isObject from 'lodash/isObject';

import EditorJS from '@editorjs/editorjs';

import { EditorInstancesService } from './services/EditorInstances';
import { EditorPropsModel } from './models/EditorPropsModel';
import { getTools } from './modules/tools';

import { buildPropsFromModel } from '@/tools/props';
import { getRandomId } from '@/tools';

const Editor = defineComponent({
  props: buildPropsFromModel(new EditorPropsModel()),
  setup(props: EditorPropsModel) {
    let editorInstance: EditorJS = null;
    const { id, autofocus, placeholder, hideToolbar, minHeight, readOnly, initialData } =
      toRefs<EditorPropsModel>(props);

    const editorId = `${id.value || getRandomId()}`;

    const init = async (): Promise<void> => {
      destroy();

      if (EditorInstancesService.has(editorId)) {
        console.error('Unexpected editor instance', editorId, EditorInstancesService);
        editorInstance = EditorInstancesService.get(editorId);
        return;
      }

      editorInstance = new EditorJS({
        holder: editorId,
        autofocus: autofocus.value,
        placeholder: placeholder.value,
        hideToolbar: hideToolbar.value,
        minHeight: minHeight.value,
        readOnly: readOnly.value,
        data: isObject(initialData.value) ? initialData.value : undefined,
        tools: await getTools(),
        onReady: fixConstantControlButtonsPlacement,
      });

      if (EditorInstancesService.has(editorId)) {
        console.error('Unexpected editor instance', editorId, EditorInstancesService);
        return;
      }

      EditorInstancesService.set(editorId, editorInstance);
    };

    watch(autofocus, (newAutofocus: EditorPropsModel['autofocus'] = false): void => {
      if (!newAutofocus) {
        return;
      }

      editorInstance?.focus();
    });

    watch(
      placeholder,
      async (
        newPlaceholder: EditorPropsModel['placeholder'] = '',
        oldPlaceholder: EditorPropsModel['placeholder'] = ''
      ): Promise<void> => {
        if (!newPlaceholder || newPlaceholder === oldPlaceholder) {
          return;
        }

        await editorInstance.isReady;
        const entryBlock = editorInstance.blocks.getBlockByIndex(0);
        if (!entryBlock) {
          // if there is no default block, after the initiation it will have placeholder from config
          return;
        }

        entryBlock.call('setPlaceholder', { placeholder: newPlaceholder });

        if (editorInstance?.configuration) {
          editorInstance.configuration.placeholder = newPlaceholder;
        }
      },
      { flush: 'post' }
    );

    const destroy = (): void => {
      if (!editorInstance) {
        return;
      }

      EditorInstancesService.delete(editorId);
      editorInstance?.destroy?.();
      editorInstance = null;
    };

    const fixConstantControlButtonsPlacement = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editorInstance?.ui?.nodes?.wrapper?.classList?.add?.('codex-editor--narrow');
    };

    onBeforeMount(init);
    onBeforeUnmount(destroy);

    return { editorId };
  },
});

export default Editor;
