import type { PropOptions } from 'vue';

import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';

const datePropValidator = <T>(): PropOptions<T>['validator'] => {
  return (value: Date): value is Date => {
    return !isNil(value) && isDate(value);
  };
};

export { datePropValidator };
