import { Vue } from 'vue-property-decorator';

const SubscriptionPlansCardProps = Vue.extend({
  props: {
    patientId: {
      required: false,
      type: String,
      default: '',
    },
  },
});

export { SubscriptionPlansCardProps };
