import { createHead, HeadVuePlugin } from '@vueuse/head';
import type { PluginObject } from 'vue';
import type { Vue as _Vue } from 'vue/types/vue';

const UseHeadPlugin: PluginObject<void> = {
  install: (Vue: typeof _Vue): void => {
    const head = createHead();

    Vue.use(HeadVuePlugin, head);
    Vue.use(head);
  },
};

export default UseHeadPlugin;
