import type { MimeType } from 'file-type';

import { extractFileExtensionAndBaseName } from './extractFileExtensionAndBaseName';

const download = (link: string, name: string = 'new-file'): void => {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.download = name;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  a.remove();
};

const makeRequest = <T>(method: 'GET' = 'GET', url: string): Promise<T> =>
  new Promise((resolve, reject) => {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open(method, url);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };

    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send(null);
  });

const downloadViaFetch = async (link: string, name: string = 'new-file'): Promise<boolean> => {
  try {
    const response = await fetch(link, {
      mode: 'cors',
      headers: {
        Origin: window.location.origin,
      },
    });
    if (!response.ok) {
      return false;
    }

    const blob: Blob = await response.blob();
    const urlObject: string = URL.createObjectURL(blob);

    download(urlObject, name);

    URL.revokeObjectURL(urlObject);
    return true;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.error('downloadViaFetch error:', error, error?.response);
  }

  return false;
};

const downloadViaXhr = async (link: string, name: string = 'new-file'): Promise<boolean> => {
  try {
    const response: Blob = await makeRequest('GET', link);

    const urlObject: string = URL.createObjectURL(response);
    download(urlObject, name);
    URL.revokeObjectURL(urlObject);
    return true;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.error('downloadViaXhr error:', error, error?.response);
  }

  return false;
};

const downloadFromLink = async (
  linkToDownloadFile: string,
  fileName: string = 'new-file',
  fileType: MimeType | 'application/octet-stream' = 'application/octet-stream'
): Promise<void> => {
  const { baseName, extension } = extractFileExtensionAndBaseName(fileName, fileType);
  const fileNameFormatted: string = `${baseName}.${extension}`;

  if (await downloadViaFetch(linkToDownloadFile, fileNameFormatted)) {
    console.log('downloaded via fetch');
    return;
  }

  if (await downloadViaXhr(linkToDownloadFile, fileNameFormatted)) {
    console.log('downloaded via xhr');
    return;
  }

  download(linkToDownloadFile, fileNameFormatted);
};

export { downloadFromLink };
