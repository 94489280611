import merge from 'lodash/merge';

import type { ChatType } from '@/types';

import { getChatModel } from '../helpers/getChatModel';

import { ChatModel } from '../models/ChatModel';

import { getKey } from '@/tools/getKey';
import { getRandomId } from '@/tools';

class ChatsCache extends Map<ChatModel['key'], ChatModel> {
  private _idToKey: Map<ChatModel['id'], ChatModel['key']> = new Map();

  public getById(id: ChatModel['id']): ReturnType<ChatsCache['get']> {
    return this.get(this._idToKey.get(id));
  }

  public add(chat?: ChatType | void): ReturnType<ChatsCache['get']> {
    if (!chat || !chat?.id) {
      console.warn('Chat is invalid', chat);
      return;
    }

    const key: string = getKey(chat);
    if (!this.has(key)) {
      const chatModel: ChatModel = getChatModel(chat, key);
      this.set(chatModel.key, chatModel);
      this.updateIdToKeyMap(chatModel);
    }

    return this.get(key);
  }

  public update(updateChatWithKey: ChatModel['key'], newParameters: Partial<ChatModel>): ReturnType<ChatsCache['get']> {
    const currentModel: ChatModel | void = this.get(updateChatWithKey);
    if (!currentModel) {
      console.warn('Something wrong with chat model:', updateChatWithKey, 'update params:', newParameters);
      return;
    }

    const newMessageModel: ChatModel = merge(new ChatModel(), currentModel, newParameters, {
      key: `${updateChatWithKey}+${getRandomId()}`,
    } as Partial<ChatModel>);

    this.set(newMessageModel.key, newMessageModel);
    this.updateIdToKeyMap(newMessageModel);

    return this.get(newMessageModel.key);
  }

  private updateIdToKeyMap(chatModel: ChatModel): ReturnType<typeof this._idToKey.set> {
    if (this._idToKey.has(chatModel.id)) {
      console.warn('Chat ID collision:', chatModel.id, chatModel.key);
    }

    return this._idToKey.set(chatModel.id, chatModel.key);
  }
}

const ChatsCacheService: ChatsCache = new ChatsCache();

export { ChatsCacheService };
