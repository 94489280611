import type { OutputData } from '@editorjs/editorjs';

class MedicalDocumentModel {
  public id: string = '';
  public key: string = ''; // id+created-at+updated-at+deleted-at
  public createdAt: string = '';
  public updatedAt: string = '';
  public deletedAt: string = '';

  public description: OutputData | void; // what this document is used for
  public createdAtReadable: string = ''; // computed created at

  public createdById: string = ''; // id of the account staffer that uploaded it
  public clientId: string = ''; // user-id of the client, who this document belongs to
  public patientId: string = ''; // id of the client, who this document belongs to
  public uploadedFilesIds: string[] = []; // IDs of uploaded files
}

export { MedicalDocumentModel };
