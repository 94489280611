import merge from 'lodash/merge';

import type { MessageType } from '@/types';

import { getMessageModel } from '../helpers/getMessageModel';
import { MessageModel } from '../models/MessageModel';

import { getKey } from '@/tools/getKey';
import { getRandomId } from '@/tools';

class MessagesCache extends Map<MessageModel['key'], MessageModel> {
  private _idToKey: Map<MessageModel['id'], MessageModel['key']> = new Map();

  public getById(id: MessageModel['id']): ReturnType<MessagesCache['get']> {
    return this.get(this._idToKey.get(id) || id);
  }

  public add(message?: MessageType | void): ReturnType<MessagesCache['get']> {
    if (!message || !message?.id) {
      console.warn('Message is invalid', message);
      return;
    }

    const key: string = getKey(message);
    if (!this.has(key)) {
      const messageModel: MessageModel = getMessageModel(message, key);
      this.set(messageModel.key, messageModel);
      this.updateIdToKeyMap(messageModel);
    }

    return this.get(key);
  }

  public update(
    updateMessageWithKey: MessageModel['key'],
    newParameters: Partial<MessageModel>
  ): ReturnType<MessagesCache['get']> {
    const currentModel: MessageModel | void = this.get(updateMessageWithKey);
    if (!currentModel) {
      console.warn('Something wrong with message model:', updateMessageWithKey, 'update params:', newParameters);
      return;
    }

    const newMessageModel: MessageModel = merge(new MessageModel(), currentModel, newParameters, {
      key: `${updateMessageWithKey}+${getRandomId()}`,
    } as Partial<MessageModel>);

    this.set(newMessageModel.key, newMessageModel);
    this.updateIdToKeyMap(newMessageModel);

    return this.get(newMessageModel.key);
  }

  private updateIdToKeyMap(messageModel: MessageModel): ReturnType<typeof this._idToKey.set> {
    if (this._idToKey.has(messageModel.id)) {
      console.warn('Message ID collision:', messageModel.id, messageModel.key);
    }

    return this._idToKey.set(messageModel.id, messageModel.key);
  }
}

const MessagesCacheService: MessagesCache = new MessagesCache();

export { MessagesCacheService };
