
import { Vue } from 'vue-property-decorator';
import moment from 'moment';

export default Vue.extend({
  name: 'InsuranceTimeTracker',

  props: {
    patientId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      intervalId: 0,
      duration: moment.duration(),
    };
  },

  computed: {
    initialTime(): Record<string, number> {
      return JSON.parse(`${localStorage.getItem('InsuranceTimeTracker')}`) || {};
    },
    totalTime(): number {
      return this.duration.hours() * 3600 + this.duration.minutes() * 60 + this.duration.seconds();
    },
    maxProgressTime(): number {
      return 900 * Math.floor(1 + this.totalTime / 900);
    },
    ago(): string {
      const seconds: string = `00${this.duration.seconds()}`.slice(-2);
      const minutes: string = `00${this.duration.minutes()}`.slice(-2);
      const hours: string = `00${this.duration.hours()}`.slice(-2);

      return `${hours}:${minutes}:${seconds}`;
    },
  },

  created(): void {
    this.init();
    this.startInterval();
  },

  destroyed(): void {
    this.saveTimer();
    this.clearInterval();
  },

  methods: {
    init(): void {
      const previousInsuranceTimersExpiry = localStorage.getItem('InsuranceTimeTrackerExpiry');
      if (!previousInsuranceTimersExpiry) return;
      const expiryTime = JSON.parse(previousInsuranceTimersExpiry);
      const now = new Date();

      //if 10 hours passed since accessing a patient (36,000,000 milliseconds), reset all timers
      if (now.getTime() - expiryTime.expires >= 36000000) {
        this.resetAllTimers();
      }
      this.duration = moment.duration({
        second: this.initialTime[this.patientId] || 0,
      });
    },
    startInterval(): void {
      this.intervalId = window.setInterval((): void => {
        this.duration.add(1, 'second');
      }, 1000);

      const now = new Date();
      localStorage.setItem('InsuranceTimeTrackerExpiry', JSON.stringify({ expires: now.getTime() }));
    },
    clearInterval(): void {
      window.clearInterval(this.intervalId);
    },
    saveTimer(): void {
      localStorage.setItem(
        'InsuranceTimeTracker',
        JSON.stringify({
          ...this.initialTime,
          [this.patientId]: this.totalTime,
        })
      );
    },
    resetAllTimers(): void {
      localStorage.removeItem('InsuranceTimeTracker');
    },
  },
});
