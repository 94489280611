import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import parseAsString from 'lodash/toString';
import parseAsNumber from 'lodash/toNumber';

import { useStorage } from '@vueuse/core';

import {
  BETA_FEATURES_KEY,
  CALL_AGENTS_TEAM,
  LAST_UPDATED_KEY,
  stafferDefault,
  TOKEN_KEY,
  TWELVE_HOURS,
} from '../constants';

import type { StafferType } from '@/types';
import { AccountRoleEnum } from '@/types';

const useStaffersStore = defineStore('staffers', () => {
  const _currentStaffer = ref<StafferType>(stafferDefault);
  const _staffers = ref<StafferType[]>([]);

  const _currentStafferToken = useStorage(TOKEN_KEY, '');
  const _currentStafferTokenLastUpdated = useStorage(LAST_UPDATED_KEY, '');
  const _currentStafferBetaFeatures = useStorage<string[]>(BETA_FEATURES_KEY, []);

  const currentStaffer = computed((): StafferType => _currentStaffer.value);
  const staffers = computed((): StafferType[] =>
    _staffers.value.filter((staffer: StafferType) =>
      [AccountRoleEnum.doctor, AccountRoleEnum.admin, AccountRoleEnum.robot].includes(staffer?.role)
    )
  );

  const hasStaffers = computed((): boolean => !!_staffers.value.length);

  const currentStafferToken = computed((): string => _currentStafferToken.value);
  const currentStafferTokenLastUpdated = computed((): string => _currentStafferTokenLastUpdated.value);
  const currentStafferBetaFeatures = computed((): Record<string, true> => {
    if (_currentStaffer.value?.ehrBetaFeatures?.length) {
      _currentStafferBetaFeatures.value = [...new Set(_currentStaffer.value?.ehrBetaFeatures)];
    }

    return _currentStafferBetaFeatures.value.reduce((flags, feature) => ({ ...flags, [feature]: true }), {});
  });

  const isTokenSet = computed((): boolean => !!_currentStafferToken.value.length);

  // not reactive
  const isTokenOutdated = computed((): boolean => {
    if (!isTokenSet.value || !_currentStafferTokenLastUpdated.value) {
      return false;
    }

    return parseAsNumber(_currentStafferTokenLastUpdated.value) + TWELVE_HOURS <= new Date().getTime();
  });

  const doctors = computed((): StafferType[] =>
    _staffers.value.filter((staffer: StafferType) => staffer.role === AccountRoleEnum.doctor)
  );

  const admins = computed((): StafferType[] =>
    _staffers.value.filter((staffer: StafferType) => staffer.role === AccountRoleEnum.admin)
  );

  const callAgents = computed((): StafferType[] =>
    _staffers.value.filter((staffer: StafferType) => staffer.teams.find((team) => team.id === CALL_AGENTS_TEAM))
  );

  const setStaffers = (newStaffers: StafferType[]): void => {
    _staffers.value = newStaffers;
  };

  const setCurrentStaffer = (staffer: StafferType): void => {
    _currentStaffer.value = staffer;
  };

  const updateToken = (token: string): void => {
    _currentStafferToken.value = parseAsString(token);
    _currentStafferTokenLastUpdated.value = parseAsString(new Date().getTime());
  };

  const $reset = (): void => {
    _currentStaffer.value = stafferDefault;
    _currentStafferToken.value = '';
    _currentStafferTokenLastUpdated.value = '';
    _currentStafferBetaFeatures.value = [];
    _staffers.value = [];
  };

  return {
    currentStaffer,
    staffers,

    hasStaffers,

    currentStafferBetaFeatures,
    currentStafferTokenLastUpdated,
    currentStafferToken,

    admins,
    doctors,
    callAgents,

    isTokenSet,
    isTokenOutdated,

    setStaffers,
    setCurrentStaffer,
    updateToken,

    $reset,
  };
});

export { useStaffersStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useStaffersStore, import.meta.webpackHot));
}
