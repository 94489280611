import gql from 'graphql-tag';

import { TEAM } from './fragments/TEAM';

const GET_TEAMS = gql`
  ${TEAM}
  query GetTeams {
    teams {
      ...Team
      openChatsNumber
    }
  }
`;

export { GET_TEAMS };
