import type { AccountType } from '@/types/AccountType';

export enum UserGroup {
  ENVIRONMENT_ALLERGY_PROVIDERS = 2,
  FOOD_ALLERGY_PROVIDERS = 3,
  WEIGHT_LOSS_PROVIDERS = 1,
}

export type UserGroupKey = keyof typeof UserGroup;

export interface GroupType {
  id: string;
  name: string;
  userSet: AccountType[];
}
