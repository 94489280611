
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

export default defineComponent({
  emits: ['click'],
  props: {
    counter: { default: (): number => 0, required: false, type: Number },
    counterVariant: { default: (): string => 'secondary', required: false, type: String },
    tabId: {
      default: (): string => '',
      required: true,
      type: String,
    },
    title: {
      default: (): string => '',
      required: false,
      type: String,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const isActive = computed((): boolean => route.query.tab === props.tabId);
    const showCounter = computed((): boolean => !!props.counter);

    async function onTabActivation(): Promise<void> {
      if (isActive.value) {
        return;
      }

      await router.push({
        ...route,
        query: { tab: props.tabId, search: route.query?.search, page: undefined },
      });
      emit('click');
    }

    return {
      isActive,
      onTabActivation,
      showCounter,
    };
  },
});
