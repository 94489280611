import parseAsString from 'lodash/toString';

const MAX_MESSAGE_LENGTH: 160 = 160 as const;

const getShortMessageTextBody = (originalBody: string): string => {
  const formattedBody: string = parseAsString(originalBody).trim();
  if (!formattedBody.length) {
    return '';
  }

  return formattedBody.length >= MAX_MESSAGE_LENGTH
    ? `${formattedBody.slice(0, MAX_MESSAGE_LENGTH)}...`
    : formattedBody;
};

export { getShortMessageTextBody };
