import type { PropOptions } from 'vue';

// https://vuejs.org/guide/components/props.html#prop-validation
const getPropValue = <Type>(value: unknown): PropOptions<Type>['default'] => {
  const valueType = typeof value;

  switch (true) {
    case valueType === 'boolean':
    case valueType === 'number':
    case valueType === 'bigint':
    case valueType === 'string':
      return value as unknown as PropOptions<Type>['default'];
    case valueType === 'symbol':
    case valueType === 'function':
      // how do we pass the symbol?
      // pass function as is
      return value as unknown as PropOptions<Type>['default'];
  }

  // array, date, object...
  return () => value as Type;
};

export { getPropValue };
