import { Timezone } from '@/types';

// normalized user model with added parameters
class ClientModel {
  public id: string = ''; // Patient user id, UUID
  public key: string = ''; //id + createdAt + updatedAt + deletedAt

  public createdAt: string = ''; //DateTime
  public updatedAt: string = ''; //DateTime
  public deletedAt: string = ''; //DateTime

  public firstName: string = '';
  public lastName: string = '';

  public email: string = '';
  public phone: string = '';

  // todo: add reactivity
  public unsubscribedSms: boolean = false;
  public unsubscribedEmail: boolean = false;

  public state: keyof typeof Timezone | '' = '';
  public timezone: Timezone | '' = '';
}

export { ClientModel };
