import gql from 'graphql-tag';

const CHAT = gql`
  fragment Chat on ChatType {
    id
    openedAt
    updatedAt
    closedAt
    deletedAt

    displayName
    displayNameShort

    clientId
    patientId

    assignedStaffers {
      id
      snoozedUntil
    }
    assignedTeams {
      id
      snoozedUntil
    }
  }
`;

export { CHAT };
