
import { computed, defineComponent, ref, toRef, watch } from 'vue';
import { sort } from 'fast-sort';

import { promiseTimeout, useIntervalFn } from '@vueuse/core';
import { useHead } from '@vueuse/head';
import { useRoute, useRouter } from 'vue-router/composables';

import NavigationBar from '@/components/NavigationBar/NavigationBar.vue';

import { useStaffersApi, useStaffersStore } from '@/stores/staffers';

import { useTagsApi, useTagsStore } from '@/stores/tags';

import type { StafferType, TagType } from '@/types';

const App = defineComponent({
  components: {
    NavigationBar,
  },
  setup() {
    useHead({
      title: 'Curex Provider Portal',
      htmlAttrs: {
        lang: 'en',
      },
    });

    const router = useRouter();
    const route = useRoute();

    const staffersStore = useStaffersStore();
    const hasStaffers = toRef(staffersStore, 'hasStaffers');
    const isOutdated = toRef(staffersStore, 'isTokenOutdated');
    const currentToken = toRef(staffersStore, 'currentStafferToken');

    const { setCurrentStaffer, setStaffers, updateToken, $reset } = staffersStore;

    const { loading: staffersLoading, getStaffers, refreshToken, getCurrentStaffer } = useStaffersApi();

    const tagsStore = useTagsStore();
    const hasTags = toRef(tagsStore, 'hasTags');

    const { setTags } = tagsStore;

    const { getTags } = useTagsApi();

    // init app fully and reliably, so we can use memo on some expensive calculations thought the app
    const initiated = ref<boolean>(false);
    const error = ref<boolean>(false);
    const delayLoading = ref<number>(0);

    const loading = computed((): boolean => !!delayLoading.value || staffersLoading.value || staffersLoading.value);
    const appClasses = computed((): string => (route.path.includes('messaging') ? 'p-0' : ''));

    const isLoginPage = (): boolean => router?.currentRoute?.name === 'login';

    const loginPage = async (): Promise<void> => {
      error.value = false;
      await router.push({ name: 'login' });
    };

    const forceLogout = async (): Promise<void> => {
      const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      const loginPageConfig: { [key: string]: string | { [key: string]: string } } = { name: 'login' };

      if (queryParams.code) {
        // this query param will be set if the page is loaded after "Sign-In with Slack" redirect,
        // passing slack access code to Login page
        loginPageConfig['query'] = { 'slack-access-code': queryParams.code };
      }

      $reset();
      await router.push(loginPageConfig);
    };

    const updateStafferToken = async (retries: number = 5): Promise<void> => {
      if (!retries || error.value) {
        error.value = true;
        return;
      }

      try {
        const { token, staffer } = await refreshToken(currentToken.value);
        setCurrentStaffer(staffer);
        updateToken(token);
        error.value = false;
      } catch (error: unknown) {
        console.error('Refresh token failed:', error);
        // await forceLogout();
        delayLoading.value++;
        await promiseTimeout(500);
        delayLoading.value--;

        if (!isLoginPage()) {
          await updateStafferToken(--retries);
        }
      }
    };

    const updateCurrentStaffer = async (retries: number = 5): Promise<void> => {
      if (!retries || error.value) {
        error.value = true;
        return;
      }

      // get current staffer if token is set and he is not outdated
      try {
        setCurrentStaffer(await getCurrentStaffer());
      } catch (error: unknown) {
        console.error('Get staffer failed:', error);
        // current user should be always set
        // await forceLogout();

        delayLoading.value++;
        await promiseTimeout(500);
        delayLoading.value--;

        if (!isLoginPage()) {
          await updateCurrentStaffer(--retries);
        }
      }
    };

    const updateAllStaffers = async (retries: number = 5): Promise<void> => {
      if (!retries || error.value) {
        error.value = true;
        return;
      }

      try {
        setStaffers(sort(await getStaffers()).asc((staffer: StafferType) => staffer?.email));
      } catch (error: unknown) {
        console.error('Get staffers failed:', error);
        // failure to get staffers will render EHR unusable
        // await forceLogout();

        delayLoading.value++;
        await promiseTimeout(500);
        delayLoading.value--;

        if (!isLoginPage()) {
          await updateAllStaffers(--retries);
        }
      }
    };

    useIntervalFn(updateStafferToken, 8.64e7); // 1 day

    watch(
      currentToken,
      async (newCurrentToken: string = ''): Promise<void> => {
        if (!newCurrentToken && !router?.currentRoute?.path?.includes('login')) {
          // no token and you are not on the login page?
          await forceLogout();
          initiated.value = true;
          return;
        }

        if (!hasStaffers.value) {
          await updateAllStaffers();
        }

        if (!hasTags.value) {
          await updateTags();
        }

        if (newCurrentToken.length && isOutdated.value) {
          await updateStafferToken();
          // return here, because this function will be called again if token has changed
          return;
        }

        await updateCurrentStaffer();
        initiated.value = true;
      },
      { immediate: true }
    );

    const updateTags = async (retries: number = 5): Promise<void> => {
      if (!retries || error.value) {
        error.value = true;
        return;
      }

      try {
        setTags(sort(await getTags()).asc((tag: TagType) => tag?.text));
        error.value = false;
      } catch (error: unknown) {
        console.error('Get tags failed:', error);
        // await forceLogout();
        delayLoading.value++;
        await promiseTimeout(500);
        delayLoading.value--;

        if (!isLoginPage()) {
          await updateTags(--retries);
        }
      }
    };

    const reloadPage = () => window?.location?.reload?.();

    return {
      appClasses,
      loading,
      initiated,
      error,

      reloadPage,
      updateStafferToken,
      loginPage,
    };
  },
});

export default App;
