import gql from 'graphql-tag';

// delete document by document id
const DELETE_MEDICAL_DOCUMENT = gql`
  mutation DeleteMedicalDocument($id: UUID!) {
    removeMedicalDocument(medicalDocument: $id) {
      status
    }
  }
`;

export { DELETE_MEDICAL_DOCUMENT };
