import { MessageTypeIntercomEnum } from './MessageTypeIntercomEnum';
import { MessageChannelEnum } from '@/types/MessageChannelEnum';
import type { MessageAttachmentType } from '@/types/MessageAttachmentType';

enum MessageStatusEnum {
  created = 'created',
  sent = 'sent',
  read = 'read',
}

export enum MessageThreadStatusEnum {
  open = 'open',
  closed = 'closed',
  snoozed = 'snoozed',
}

export interface MessageUserType {
  id?: string;
  intercomId?: string;
  intercomAdminId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
}

// deprecated
interface MessageType {
  id?: string;
  body?: string;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
  intercomId?: string;
  channel?: MessageChannelEnum;
  intercomConversationPartType?: MessageTypeIntercomEnum;
  read?: string;
  author?: MessageUserType;
  assignee?: MessageUserType;
  status?: MessageStatusEnum;
  thread?: {
    status?: MessageThreadStatusEnum;
    assignedTeamId?: string;
    patientId?: string;
    authorId?: string;
    assigneeId?: string;
  };
  attachments?: MessageAttachmentType[];
  threadId?: string;
  patientId?: string;
  authorId?: string;
  assigneeId?: string;
}

export interface MessageThreadType {
  deletedAt?: string;
  intercomId?: string;
  open?: boolean;
  snoozedUntil?: string;
  read?: boolean;
  createdAt?: string;
  updatedAt?: string;
  messages?: MessageType[];
  author?: MessageUserType;
  status: keyof typeof MessageThreadStatusEnum;
}
