import { UploadedFileKindEnum } from '@/types';
import type { MimeType } from 'file-type';

class MedicalFileModel {
  public id: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';

  public name: string = ''; // name of the file, can be changed
  public s3Url: string = ''; // file to show/download. All s3 links should be renewed

  public kind: UploadedFileKindEnum = UploadedFileKindEnum.OTHER_MEDICAL_DOCUMENT; // internal type of the document. Prefer document type if available
  public contentType: MimeType | 'application/octet-stream' = 'application/octet-stream';
}

export { MedicalFileModel };
