
import { computed, defineComponent, getCurrentInstance, ref, SetupContext, toRef, toRefs } from 'vue';
import { Builder } from 'builder-pattern';

import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';

import type { OutputData } from '@editorjs/editorjs';

import { MedicalDocumentPropsModel } from './models';
import { fileModelToAttachmentModel, getDescriptionForSave, mergeObjectsById } from './helpers';

import { DocumentsCacheService, FilesCacheService } from '~/medicalDocuments/services';
import { MedicalDocumentModel, MedicalFileModel } from '~/medicalDocuments/models';
import { UploadFileRequest, UploadFileResponse } from '~/medicalDocuments/types';
import { isNewMedicalDocument } from '~/medicalDocuments/validation';
import { useMedicalRecordsApi } from '~/medicalDocuments';

import { AttachmentModel } from '@/components/AttachFile/models';

import { getStaffer, getStafferFirstName, useStaffersStore } from '@/stores/staffers';

import { showError } from '@/components/errors';

import { buildPropsFromModel } from '@/tools/props';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import { EditorInstancesService } from '@/components/Editor/services';
import { S3FileUploadService } from '@/services/S3FileUpload';

import { MedicalDocumentType, UploadedFileKindEnum } from '@/types';

const MedicalDocument = defineComponent({
  props: buildPropsFromModel(new MedicalDocumentPropsModel()),
  emits: ['refresh-documents'],
  setup(props: MedicalDocumentPropsModel, { emit }: SetupContext) {
    const vm = getCurrentInstance?.()?.proxy;

    const { documentKey, clientId, loading: parentLoading } = toRefs<MedicalDocumentPropsModel>(props);

    const loading = ref<number>(0);
    const newAttachments = ref<AttachmentModel[]>([]);
    const documentAttachmentsModifications = ref<AttachmentModel[]>([]);

    const staffersStore = useStaffersStore();
    const staffers = toRef(staffersStore, 'staffers');
    const currentStaffer = toRef(staffersStore, 'currentStaffer');

    const {
      createMedicalDocument,
      unlinkFileFromMedicalDocument,
      updateMedicalDocument,
      requestS3Link,
      deleteMedicalDocument,
    } = useMedicalRecordsApi();

    const document = computed(
      (): MedicalDocumentModel => DocumentsCacheService.get(documentKey.value) || new MedicalDocumentModel()
    );

    const createdByFirstName = computed((): string => {
      if (!document.value.createdById.length) {
        return '';
      }

      if (document.value.createdById === currentStaffer.value.id) {
        return 'you';
      }

      return getStafferFirstName(getStaffer(document.value.createdById, staffers.value)) || '';
    });

    const documentTitle = computed((): string => {
      if (!document.value.createdAtReadable.length) {
        return 'New document';
      }

      if (createdByFirstName.value.length) {
        return `Created at ${document.value.createdAtReadable} by ${createdByFirstName.value}`;
      }

      return `Created at ${document.value.createdAtReadable}`;
    });

    const documentAttachments = computed((): AttachmentModel[] =>
      document.value.uploadedFilesIds
        .map((fileId: MedicalFileModel['id']) => FilesCacheService.get(fileId))
        .map(fileModelToAttachmentModel)
    );

    const attachments = computed((): AttachmentModel[] => [
      ...newAttachments.value,
      ...mergeObjectsById([...documentAttachments.value, ...documentAttachmentsModifications.value]),
    ]);

    const isLoading = computed((): boolean => parentLoading.value || !!loading.value);
    const isNewDocument = computed((): boolean => isNewMedicalDocument(document.value));

    const onAttachmentChange = async (newAttachmentsValues: AttachmentModel[]): Promise<void> => {
      loading.value++;

      const { id: originalId, key: originalKey, description: originalDescription } = document.value;

      // copy document id, so it remains unchanged
      const documentId: string = isNewDocument.value ? (await createDocument(clientId.value)).id : originalId;

      const outputData: OutputData | void = await EditorInstancesService.get(originalId || originalKey)?.save();
      const newDescription: string | void = getDescriptionForSave(outputData, originalDescription);

      // added attachments will already have their loading set to true
      const addedAttachments: AttachmentModel[] = differenceWith(newAttachmentsValues, attachments.value, isEqual);
      newAttachments.value = addedAttachments;

      const deletedAttachments: AttachmentModel[] = differenceWith(attachments.value, newAttachmentsValues, isEqual);

      await Promise.all(
        deletedAttachments.map((attachment: AttachmentModel) => deleteMedicalDocumentAttachment(attachment))
      );

      const addUploadedFileIds: Array<AttachmentModel['id']> = await Promise.all(
        addedAttachments.map((attachment: AttachmentModel) => uploadFileToS3(attachment))
      );

      await updateMedicalDocumentWrapped(documentId, addUploadedFileIds, newDescription);

      emitRefreshDocuments();
      newAttachments.value = [];
      loading.value--;
    };

    const emitRefreshDocuments = () => emit('refresh-documents');

    const uploadFileToS3 = async (attachment: AttachmentModel): Promise<AttachmentModel['id']> => {
      loading.value++;
      try {
        const result: UploadFileResponse | void = await requestS3Link(
          Builder<UploadFileRequest>()
            .clientId(clientId.value)
            .contentType(attachment.type)
            .kind(UploadedFileKindEnum.OTHER_MEDICAL_DOCUMENT) // hardcoded as other, as a result of conversation with Timur
            .name(attachment.name)
            .build()
        );

        if (!result || !result?.s3Url?.length) {
          showError(vm, `Failed to generate S3 link for attachment ${attachment.name}.`, 50000);
          return '';
        }

        await S3FileUploadService.uploadFile(result.s3Url, attachment.fileInstance, attachment.type);

        return result.id;
      } catch (error: unknown) {
        console.error(error);
        showError(
          vm,
          `Failed to add attachment to document ${document.value.id}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        loading.value--;
      }
    };

    const updateMedicalDocumentWrapped = async (...args: Parameters<typeof updateMedicalDocument>) => {
      loading.value++;
      try {
        await updateMedicalDocument(...args);
      } catch (error: unknown) {
        console.error(error);
        showError(
          vm,
          `Failed to add attachment to document ${document.value.id}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        loading.value--;
      }
    };

    const deleteMedicalDocumentAttachment = async (attachment: AttachmentModel) => {
      loading.value++;
      if (document.value.uploadedFilesIds.includes(attachment.id)) {
        documentAttachmentsModifications.value = [Builder(AttachmentModel).id(attachment.id).loading(true).build()];
      }
      try {
        await unlinkFileFromMedicalDocument(attachment.id); // should this be an array of attachments?
      } catch (error: unknown) {
        console.error(error);
        showError(vm, `Failed to delete attachment ${attachment.name}. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        loading.value--;
      }
    };

    const deleteMedicalDocumentWrapped = async () => {
      loading.value++;
      try {
        await deleteMedicalDocument(document.value.id); // should this be an array of attachments?
      } catch (error: unknown) {
        console.error(error);
        showError(vm, `Failed to delete document ${document.value.id}. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        emitRefreshDocuments();
        loading.value--;
      }
    };

    const updateDescription = async () => {
      loading.value++;
      const { id: originalId, key: originalKey, description: originalDescription } = document.value;

      try {
        //check key, for the new document
        const outputData: OutputData | void = await EditorInstancesService.get(originalId || originalKey)?.save();
        const newDescription: string | void = getDescriptionForSave(outputData, originalDescription);

        if (!newDescription || !newDescription.length) {
          return;
        }

        if (isNewDocument.value) {
          await createDocument(clientId.value, newDescription);
          return;
        }

        await updateMedicalDocument(originalId, [], newDescription);
      } catch (error: unknown) {
        console.error(error);
        showError(
          vm,
          `Failed to update description of a document ${originalId || originalKey}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        loading.value--;
      }
    };

    const createDocument = async (...args: Parameters<typeof createMedicalDocument>): Promise<MedicalDocumentModel> => {
      loading.value++;
      try {
        const newMedicalDocumentType: MedicalDocumentType | void = await createMedicalDocument(...args);
        return newMedicalDocumentType ? DocumentsCacheService.add(newMedicalDocumentType) : new MedicalDocumentModel();
      } catch (error: unknown) {
        console.error(error);
        showError(vm, `Failed to create new document. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        loading.value--;
      }
    };

    return {
      document,
      documentTitle,
      attachments,

      isLoading,
      isNewDocument,

      onAttachmentChange,
      updateDescription,
      deleteMedicalDocumentWrapped,
    };
  },
});

export default MedicalDocument;
