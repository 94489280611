/* eslint @typescript-eslint/ban-ts-comment: 0 */
import isNil from 'lodash/isNil';

import { defer } from '../helpers/defer';
import { ToolSettings } from '@editorjs/editorjs';

const tools: Record<string, ToolSettings> = {
  paragraph: {
    class: null,
  },
  header: {
    class: null,
  },
  quote: {
    class: null,
  },
  list: {
    class: null,
  },
  image: {
    class: null,
  },
  delimiter: {
    class: null,
  },
  table: {
    class: null,
  },
};

const wait = defer<typeof tools>();

const areToolsLoaded = (): boolean => !Object.values(tools).find((tool) => isNil(tool?.class));
const getTools = (): Promise<typeof tools> => {
  return areToolsLoaded() ? Promise.resolve(tools) : wait;
};

// @ts-ignore
import('@editorjs/paragraph').then(({ default: paragraph }) => {
  paragraph.prototype.setPlaceholder = function ({ placeholder = 'Write here' }: { placeholder: string }) {
    this._placeholder = placeholder;
    this._element.dataset.placeholder = placeholder;
  };

  tools.paragraph.class = paragraph;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/header').then(({ default: header }) => {
  tools.header.class = header;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/quote').then(({ default: quote }) => {
  tools.quote.class = quote;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/list').then(({ default: list }) => {
  tools.list.class = list;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/simple-image').then(({ default: image }) => {
  tools.image.class = image;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/delimiter').then(({ default: delimiter }) => {
  tools.delimiter.class = delimiter;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

// @ts-ignore
import('@editorjs/table').then(({ default: table }) => {
  tools.table.class = table;
  if (areToolsLoaded()) {
    wait.resolve(tools);
  }
});

export { getTools };
