import type { Action } from '../types/action';

class ChatMessageContextMenuPropsModel {
  public value: boolean = false;

  public actions: Action[] = [];
  public x: number = 0;
  public y: number = 0;
}

export { ChatMessageContextMenuPropsModel };
