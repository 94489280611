import { Builder } from 'builder-pattern';

import isObject from 'lodash/isObject';
import parseAsString from 'lodash/toString';

import { getKey } from '@/tools/getKey';

import { ChatModel } from '../models/ChatModel';

import type { ChatType } from '@/types';

const getChatModel = (chat?: ChatType | void, key?: string): ChatModel => {
  if (!isObject(chat)) {
    return new ChatModel();
  }

  const id: string = parseAsString(chat?.id);

  const openedAt: string = parseAsString(chat?.openedAt);
  const updatedAt: string = parseAsString(chat?.updatedAt);
  const closedAt: string = parseAsString(chat?.closedAt);
  const deletedAt: string = parseAsString(chat?.deletedAt);

  const displayName: string = parseAsString(chat?.displayName) || 'Not provided';
  const displayNameShort: string = parseAsString(chat?.displayNameShort) || 'Not provided';

  const clientId: string = parseAsString(chat?.clientId);
  const patientId: string = parseAsString(chat?.patientId);
  const latestMessageId: string = parseAsString(chat?.latestMessage?.id);
  const latestClientMessageId: string = parseAsString(chat?.latestClientMessage?.id);

  const staffersIds: ChatModel['staffersIds'][number][] = (chat?.assignedStaffers || [])
    .map((staffer: ChatType['assignedStaffers'][number]) => staffer?.id)
    .filter(Boolean);

  const teamsIds: ChatModel['teamsIds'][number][] = (chat?.assignedTeams || [])
    .map((team: ChatType['assignedTeams'][number]) => team?.id)
    .filter(Boolean);

  const snoozedIds: ChatModel['snoozedIds'][number][] = [
    ...(chat?.assignedStaffers || []),
    ...(chat?.assignedTeams || []),
  ]
    .filter(
      (assigned: ChatType['assignedStaffers'][number] | ChatType['assignedTeams'][number]) =>
        assigned?.id?.length && assigned?.snoozedUntil?.length
    )
    .map((assigned: ChatType['assignedStaffers'][number] | ChatType['assignedTeams'][number]) => assigned?.id);

  return Builder(ChatModel)
    .id(id)
    .key(key || getKey(chat))

    .openedAt(openedAt)
    .updatedAt(updatedAt)
    .closedAt(closedAt)
    .deletedAt(deletedAt)

    .displayName(displayName)
    .displayNameShort(displayNameShort)

    .clientId(clientId)
    .patientId(patientId)
    .latestMessageId(latestMessageId)
    .latestClientMessageId(latestClientMessageId)

    .staffersIds(staffersIds)
    .teamsIds(teamsIds)
    .snoozedIds(snoozedIds)

    .build();
};

export { getChatModel };
