export enum SLITCancellationReasonEnum {
  alternate_treatment = 'Alternate Treatment',
  change_of_circumstances = 'Change of circumstances',
  completed_treatment = 'Completed treatment',
  compliance = 'Compliance',
  consultation_scheduling_issues = 'Scheduling issues',
  declined_to_state = 'Declined to state',
  default_payment = 'Default payment',
  disengaged = 'Disengaged',
  dissatisfied_efficacy = 'Dissatisfied efficacy',
  financial_issues = 'Financial issues',
  food_allergies = 'Food allergies',
  insurance_charges = 'Insurance Charges',
  moving_to_another_country = 'Moving to another country',
  no_allergies = 'No allergies',
  no_longer_using_treatment = 'Stopped treatment',
  not_eligible = 'Not Eligible',
  old_account = 'Old account never cancelled',
  other = 'Other',
  pricing = 'Pricing',
  purchased_by_mistake = 'Mistake purchase',
  too_much_product = 'Too much product',
  other_health_concerns = 'Other health concerns',
  no_longer_interested = 'No longer interested',
  revert_to_shots = 'Revert to shots',
  side_effects = 'Side effects',
  taste = 'Taste',
  testing_is_not_available = 'Testing is not available',
  hbt_negative_result = 'HBT - negative result',
  satisfied_efficacy = 'Satisfied efficacy',
  testing_issues = 'Testing issues',
  unknown = 'Unknown',
}

export const SLITCancellationReasonOptions = Object.entries(SLITCancellationReasonEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));
