import gql from 'graphql-tag';
import { Vue } from 'vue-property-decorator';
import type { ComponentOptions } from 'vue/types/options';
import type { SubscriptionPlanInfo } from '../types';

const PatientSubscriptionPlansQuery: ComponentOptions<Vue>['apollo'] = {
  patientSubscriptionPlans: {
    query: gql`
      query PatientSubscriptionPlans($patientId: UUID!) {
        patientSubscriptionPlans(patientId: $patientId) {
          name
          amount
          renewalDate
          cadence
          cadenceDisplayName
        }
      }
    `,
    fetchPolicy: 'network-only',
    variables(): Record<string, string> {
      return { patientId: this.patientId || this.$route.query?.['patient-id'] || this.$route.params.pk };
    },
    watchLoading(isLoading: boolean): void {
      this.isLoading = isLoading;
    },
    result(response: { data: { patientSubscriptionPlans: SubscriptionPlanInfo[] } }): void {
      this.patientSubscriptionPlans = response?.data?.patientSubscriptionPlans || [];
    },
  },
};

export { PatientSubscriptionPlansQuery };
