import type { PropType } from 'vue';

import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';

// nullish values will not have validator.
// any object will have a type of object
// functions would be validated as models (instance check).
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#description
const getPropType = <Type>(value: unknown): PropType<Type> => {
  const valueType = typeof value;

  switch (true) {
    case valueType === 'boolean':
      return Boolean as unknown as PropType<Type>;
    case valueType === 'number':
      // We validate strings as numbers when we expect number prop
      return [Number, String] as unknown as PropType<Type>;
    case valueType === 'bigint':
      return [Number, BigInt, String] as unknown as PropType<Type>;
    case valueType === 'string':
      return String as unknown as PropType<Type>;
    case valueType === 'symbol':
      return Symbol as unknown as PropType<Type>;
    case valueType === 'function':
      // If it's a function type, then it has not been called. We can use its constructor to check instance
      return value as unknown as PropType<Type>;
  }

  if (isArray(value)) {
    return Array as unknown as PropType<Type>;
  }

  if (isDate(value)) {
    return Date as unknown as PropType<Type>;
  }

  // everything else
  return Object as unknown as PropType<Type>;
};

export { getPropType };
