import { useApollo } from '@/composables/atoms/useApollo';

import { CREATE_MEDICAL_DOCUMENT } from '../gql/CREATE_MEDICAL_DOCUMENT';
import { DELETE_MEDICAL_DOCUMENT } from '../gql/DELETE_MEDICAL_DOCUMENT';
import { GET_PATIENT_MEDICAL_DOCUMENTS } from '../gql/GET_PATIENT_MEDICAL_DOCUMENTS';
import { UNLINK_FILE_FROM_MEDICAL_DOCUMENT } from '../gql/UNLINK_FILE_FROM_MEDICAL_DOCUMENT';
import { UPDATE_MEDICAL_DOCUMENT } from '../gql/UPDATE_MEDICAL_DOCUMENT';
import { UPLOAD_FILE } from '../gql/UPLOAD_FILE';

import type { Status, UploadFileRequest, UploadFileResponse } from '../types';
import type { MedicalFileModel } from '../models/MedicalFileModel';

import type { AccountType, MedicalDocumentType, PatientType } from '@/types';

const useMedicalRecordsApi = () => {
  const { loading, query, mutate } = useApollo();

  const getPatientDocuments = async (id: PatientType['id']): Promise<PatientType['medicalDocuments']> => {
    const response = await query<{ patient: PatientType }>({
      query: GET_PATIENT_MEDICAL_DOCUMENTS,
      fetchPolicy: 'no-cache',
      variables: { id },
    });

    if (response.errors?.length) {
      return [];
    }

    return response.data?.patient?.medicalDocuments || [];
  };

  const createMedicalDocument = async (
    clientId: AccountType['id'],
    description: string = ''
  ): Promise<MedicalDocumentType | void> => {
    const response = await mutate<{ createMedicalDocument: MedicalDocumentType }>({
      mutation: CREATE_MEDICAL_DOCUMENT,
      variables: {
        clientId,
        description,
      },
    });

    if (response.errors?.length || !response.data?.createMedicalDocument?.id?.length) {
      return;
    }

    return response.data?.createMedicalDocument;
  };

  const updateMedicalDocument = async (
    id: MedicalFileModel['id'],
    addUploadedFileIds: Array<MedicalFileModel['id']>,
    description: string | void = ''
  ): Promise<MedicalDocumentType> => {
    const response = await mutate<{ updateMedicalDocument: MedicalDocumentType }>({
      mutation: UPDATE_MEDICAL_DOCUMENT,
      variables: {
        id,
        uploadedFiles: addUploadedFileIds,
        description: description && description?.length ? description : undefined,
      },
    });

    if (response.errors?.length || !response.data?.updateMedicalDocument?.id?.length) {
      return;
    }

    return response.data?.updateMedicalDocument;
  };

  const deleteMedicalDocument = async (id: MedicalDocumentType['id']): Promise<boolean> => {
    const response = await mutate<{ removeMedicalDocument: Status }>({
      mutation: DELETE_MEDICAL_DOCUMENT,
      variables: { id },
    });

    if (response.errors?.length) {
      return false;
    }

    return response.data?.removeMedicalDocument?.status === 'success';
  };

  const unlinkFileFromMedicalDocument = async (id: MedicalFileModel['id']): Promise<boolean> => {
    const response = await mutate<{ unlinkUploadedFile: Status }>({
      mutation: UNLINK_FILE_FROM_MEDICAL_DOCUMENT,
      variables: { id },
    });

    if (response.errors?.length) {
      return false;
    }

    return response.data?.unlinkUploadedFile?.status === 'success';
  };

  const requestS3Link = async (variables: UploadFileRequest): Promise<UploadFileResponse | void> => {
    const response = await mutate<{ uploadFile: UploadFileResponse }>({
      mutation: UPLOAD_FILE,
      variables,
    });

    if (response.errors?.length || !response.data?.uploadFile?.id?.length) {
      return;
    }

    return response.data?.uploadFile;
  };

  return {
    loading,

    getPatientDocuments,

    createMedicalDocument,
    updateMedicalDocument,
    unlinkFileFromMedicalDocument,
    deleteMedicalDocument,

    requestS3Link,
  };
};

export { useMedicalRecordsApi };
