import { formatStringForSearch } from '../helpers/formatStringForSearch';
import { formatSmsText } from '../helpers/formatSmsText';

// class getters will be evaluated upon access to them
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#smart_self-overwriting_lazy_getters

class ReplyModel {
  public id: string = '';
  public keyword: string = '';
  public name: string = '';
  public subject: string = '';
  public body: string = '';
  public text: string = '';

  public get searchKeyword(): string {
    return formatStringForSearch(this.keyword);
  }

  public get searchName(): string {
    return formatStringForSearch(this.name);
  }

  public get searchSubject(): string {
    return formatStringForSearch(this.subject);
  }

  public get searchRawText(): string {
    return formatStringForSearch(this.text);
  }

  public get formattedSmsText(): string {
    return formatSmsText(this.text);
  }
}

export { ReplyModel };
