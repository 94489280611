import type { ReplyModel } from '../models/ReplyModel';

const isReplyStartsWith = (reply?: ReplyModel | void, text: string = ''): boolean =>
  reply &&
  reply.id.length &&
  text?.length &&
  (reply.searchKeyword?.startsWith(text) ||
    reply.searchName?.startsWith(text) ||
    reply.searchSubject?.startsWith(text) ||
    reply.searchRawText?.startsWith(text));

export { isReplyStartsWith };
