import { format } from 'date-fns';

const getReadableDate = (dateAt: string) => {
  try {
    return format(new Date(dateAt), 'M/d/yyyy');
  } catch (error) {
    console.warn('getReadableDate', error);
  }

  return '';
};

export { getReadableDate };
