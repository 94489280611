
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    progressValue: {
      default: 0,
      required: false,
      type: Number,
    },
    title: {
      default: '',
      required: false,
      type: String,
    },
    tooltip: {
      default: '',
      required: false,
      type: String,
    },
    placeholder: {
      default: '',
      required: false,
      type: String,
    },
    icon: {
      default: '',
      required: false,
      type: String,
    },
    startCollapsed: {
      required: false,
      type: Boolean,
    },
    isEmpty: {
      required: false,
      type: Boolean,
    },
    hideToggle: {
      required: false,
      type: Boolean,
    },
    cardProps: {
      default: () => null,
      required: false,
      type: Object,
    },
    scrollable: {
      default: (): boolean => false,
      required: false,
      type: Boolean,
    },
  },
  computed: {
    noBody(): boolean {
      return this.isEmpty || this.isCollapsed;
    },
    toggleIconName(): string {
      return this.isCollapsed ? 'chevron-down' : 'chevron-up';
    },
    bodyClass(): string[] {
      const classes: string[] = this.scrollable ? ['overflow-y-auto'] : [];
      return this.cardProps?.bodyClass?.length ? [this.cardProps?.bodyClass, ...classes] : classes;
    },
  },
  data(): { isCollapsed: boolean } {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapsedState(): void {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  watch: {
    startCollapsed: {
      deep: false,
      handler(startCollapsed: boolean) {
        this.isCollapsed = !!startCollapsed;
      },
      immediate: true,
    },
  },
});
