import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import type { Operation } from 'apollo-link';
import { ApolloLink } from 'apollo-link';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { useStaffersStore } from '@/stores/staffers';
import { captureException } from '@sentry/vue';
import { RetryLink } from 'apollo-link-retry';

// error handling for GraphQL responses
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.length) {
    graphQLErrors?.forEach((error) => {
      console.error('[GraphQL error]:', error);

      captureException(error, {
        extra: error as unknown as Record<string, unknown>,
      });
    });

    if (graphQLErrors?.find((error) => (error as unknown as { code: number })?.code === 403)) {
      // todo: temporary disabled for investigation about frequent logouts
      // router.push({ name: 'login' });
      return;
    }
  }

  if (networkError && 'response' in networkError) {
    console.error('[Network error]:', networkError);

    switch (networkError?.response?.status) {
      case 412: {
        // show login page on permissions error (we use code 412 for permission error 'cause our AWS intercepts 403)
        // todo: temporary disabled for investigation about frequent logouts
        // router.push({ name: 'login' });

        console.error('[Network error] - redirect:', networkError);
        return;
      }
    }
  }
});

const sentryLink = new SentryLink({ uri: '/graphql/doctors' }) as unknown as ApolloLink;
const httpLink = new HttpLink({ uri: '/graphql/doctors' });

const isMutation = (operation: Operation): boolean =>
  Array.isArray(operation?.query?.definitions) &&
  operation.query.definitions.some((def) => def.kind === 'OperationDefinition' && def.operation === 'mutation');

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error: unknown, operation: Operation) => {
      return !isMutation(operation);
    },
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const { isTokenSet, currentStafferToken } = useStaffersStore();

  if (isTokenSet) {
    operation.setContext({
      headers: {
        Authorization: `JWT ${currentStafferToken}`,
      },
    });
  }

  return forward(operation);
});

// HTTP connection to the API with error handling
const link = ApolloLink.from([errorLink, retryLink, sentryLink, httpLink]);

// Cache implementation
const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache,
});

export default apolloClient;
