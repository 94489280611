import gql from 'graphql-tag';

export const PhysicianFragmentLight = gql`
  fragment physicianLight on PhysicianType {
    id
    firstName
    lastName
  }
`;

export const AccountFragmentLight = gql`
  fragment accountLight on AccountType {
    id
    firstName
    lastName
  }
`;

export const PatientFragmentLight = gql`
  fragment patientLight on PatientType {
    id
    firstName
    lastName
    dateOfBirth
    chatId
    shippingAddress {
      address
      city
      state
      zipcode
    }
  }
`;

export const ExternalLabRequisitionFragment = gql`
  fragment externalLabRequisition on ExternalLabOrderType {
    id
    status
    displayStatus
    regions
    displayRegions
    lab
    paymentMethod
    extraAllergens
    signedAt
    cpis
    createdAt
    updatedAt
    externalId
    orderStatus
    displayOrderStatus
    appointmentDate
    lastStatusMessage
    lastStatusMessageDate
    patient {
      ...patientLight
    }
    physician {
      ...physicianLight
    }
  }
  ${PhysicianFragmentLight}
  ${PatientFragmentLight}
`;

export const ScarletOrderFragment = gql`
  fragment scarletOrdersFragment on ScarletOrderType {
    createdAt
    displayStatus
    extraAllergens
    id
    pollenRegions
    status
    physician {
      id
      email
      firstName
      lastName
    }
  }
`;
