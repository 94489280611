export enum Laboratory {
  biotek = 'BioTek',
  exam_one = 'ExamOne',
  getlabs = 'Getlabs',
  lab_corp = 'LabCorp',
  other = 'Other',
  pre_existing = 'Pre-existing',
  quest = 'Quest',
  quest_lab_corp = 'Quest/LabCorp',
  scarlet = 'Scarlet',
  seven_drops = '7Drops',
  spiriplex = 'SPX',
  unknown = 'Unknown',
}

export type LaboratoryKey = keyof typeof Laboratory;
export type LaboratoryValue = `${Laboratory}`;

export const laboratoryOptions = Object.entries(Laboratory).map(([value, text]) => ({ text, value }));

export function getLaboratoryName(laboratory: LaboratoryKey | null): Laboratory {
  return Laboratory?.[laboratory] ?? Laboratory.unknown;
}
