
import { computed, defineComponent, toRefs } from 'vue';

const LinearProgress = defineComponent({
  props: {
    // in pixels
    height: {
      default: 2,
      required: false,
      type: [String, Number],
    },
    // in pixels
    show: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup(props: { height: number | string; show: boolean }) {
    const { height, show } = toRefs<{ height: number | string; show: boolean }>(props);

    const styles = computed(
      (): Partial<CSSStyleDeclaration> => ({
        height: `${height.value}px`,
      })
    );

    const classes = computed(
      (): Record<string, boolean> => ({
        ['LinearProgress__activity--pause']: !show.value,
      })
    );

    return { styles, classes };
  },
});

export default LinearProgress;
