
import type { ComponentPublicInstance, PropType, SetupContext } from 'vue';
import { computed, defineComponent, onBeforeMount, onErrorCaptured, ref, toRefs } from 'vue';
import merge from 'lodash/merge';
import { Builder } from 'builder-pattern';

import { tweenVarsDefault } from './defaults';

import { GsapService } from '@/services/gsap';

const TransitionSlide = defineComponent({
  props: {
    appear: {
      default: false,
      required: false,
      type: Boolean,
    },
    tag: {
      default: 'div',
      required: false,
      type: String as PropType<'div' | 'span'>,
    },
    tweenVarsConfiguration: {
      default: () => tweenVarsDefault,
      required: false,
      type: Object as PropType<GSAPTweenVars>,
    },
  },
  emits: ['after-enter'],
  setup(props, { emit }: SetupContext) {
    const { tag, appear, tweenVarsConfiguration } = toRefs(props);

    const loading = ref<boolean>(GsapService.isLoaded);
    const errorOccurred = ref<boolean>(false);

    const showTransition = computed((): boolean => !loading.value && !errorOccurred.value);

    const beforeMountedHook = async (): Promise<void> => {
      if (GsapService.isLoaded) {
        loading.value = false;
        return;
      }

      await GsapService.load();
      let intervalId: ReturnType<typeof setInterval> = setInterval((): void => {
        if (!GsapService.isLoaded) {
          return;
        }

        loading.value = false;
        clearInterval(intervalId);
        intervalId = null;
      }, 100);
    };

    const afterErrorCaptured = (
      error: unknown | Error,
      instance: ComponentPublicInstance | null,
      info: string
    ): boolean => {
      errorOccurred.value = true;
      console.error(error, instance, info);
      return false;
    };

    onBeforeMount(beforeMountedHook);
    onErrorCaptured(afterErrorCaptured as never);

    const tweenVars = computed((): GSAPTweenVars => {
      if (!showTransition.value) {
        return {};
      }

      const defaultProps: GSAPTweenVars = merge({}, tweenVarsDefault, tweenVarsConfiguration.value);

      return Builder<GSAPTweenVars>(defaultProps)
        .ease(GsapService.get && GsapService.get?.Sine?.easeInOut)
        .build();
    });

    const transitionProps = computed(() => ({
      appear: appear.value,
      css: false,
      tag: tag.value,
    }));

    const tweenFrom = (element: HTMLElement, onComplete: () => void): void => {
      if (!GsapService.get) {
        return;
      }

      GsapService.get.gsap.from(element, {
        ...tweenVars.value,
        onComplete,
      });
    };

    const tweenTo = (element: HTMLElement, onComplete: () => void): void => {
      if (!GsapService.get) {
        return;
      }

      GsapService.get.gsap.to(element, {
        ...tweenVars.value,
        onComplete,
      });
    };

    const afterEnter = () => {
      emit('after-enter');
    };

    return {
      showTransition,
      transitionProps,
      tweenFrom,
      tweenTo,
      afterEnter,
    };
  },
});

export default TransitionSlide;
