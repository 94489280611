import gql from 'graphql-tag';

const UNCONFIRM_PATIENT_IDENTITY = gql`
  mutation ($id: UUID!) {
    unconfirmPatientIdentity(id: $id) {
      status
    }
  }
`;

export { UNCONFIRM_PATIENT_IDENTITY };
