enum Timezone {
  AK = 'America/Anchorage',
  AL = 'America/Chicago',
  AR = 'America/Chicago',
  AZ = 'America/Phoenix',
  CA = 'America/Los_Angeles',
  CO = 'America/Denver',
  CT = 'America/New_York',
  DC = 'America/New_York',
  DE = 'America/New_York',
  FL = 'America/New_York',
  GA = 'America/New_York',
  HI = 'Pacific/Honolulu',
  IA = 'America/Chicago',
  ID = 'America/Denver',
  IL = 'America/Chicago',
  IN = 'America/Indianapolis',
  KS = 'America/Chicago',
  KY = 'America/New_York',
  LA = 'America/Chicago',
  MA = 'America/New_York',
  MD = 'America/New_York',
  ME = 'America/New_York',
  MI = 'America/New_York',
  MN = 'America/Chicago',
  MO = 'America/Chicago',
  MS = 'America/Chicago',
  MT = 'America/Denver',
  NC = 'America/New_York',
  ND = 'America/Chicago',
  NE = 'America/Chicago',
  NH = 'America/New_York',
  NJ = 'America/New_York',
  NM = 'America/Denver',
  NV = 'America/Los_Angeles',
  NY = 'America/New_York',
  OH = 'America/New_York',
  OK = 'America/Chicago',
  OR = 'America/Los_Angeles',
  PA = 'America/New_York',
  RI = 'America/New_York',
  SC = 'America/New_York',
  SD = 'America/Chicago',
  TN = 'America/Chicago',
  TX = 'America/Chicago',
  UT = 'America/Denver',
  VA = 'America/New_York',
  VT = 'America/New_York',
  WA = 'America/Los_Angeles',
  WI = 'America/Chicago',
  WV = 'America/New_York',
  WY = 'America/Denver',
}

export type TimezoneKey = keyof typeof Timezone;

export { Timezone };
