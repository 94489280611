import type { PropOptions } from 'vue';

import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import parseAsNumber from 'lodash/toNumber';
import parseAsString from 'lodash/toString';

import type { MinMaxPropValidator } from '../types';

const textAndNumberPropValidator = <T extends number | string>(
  params: MinMaxPropValidator = {}
): PropOptions<T>['validator'] => {
  return (value: T): value is T => {
    if (isNil(value)) {
      return false;
    }

    let formattedValue: number = value as number;
    if (!isNumber(value)) {
      formattedValue = parseAsNumber(parseAsString(value));
    }

    const minValid: boolean = isNil(params?.min) ? true : formattedValue >= params?.min;
    const maxValid: boolean = isNil(params?.max) ? true : formattedValue <= params?.max;
    return minValid && maxValid;
  };
};

export { textAndNumberPropValidator };
