import { PatientType } from '@/types/PatientType';

export enum PaymentPeriodEnum {
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  semi_annual = 'Semi-annual',
  annual = 'Annual',
  threeyear = '3 year',
  unknown = 'Unknown',
}

export const paymentPeriodOptions = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));

import { PaymentOptionEnum } from '@/types/PaymentOptionEnum';

export const paymentPeriodPriceMap = {
  [PaymentPeriodEnum.monthly]: {
    [PaymentOptionEnum.insurance]: 59,
    [PaymentOptionEnum.self_pay]: 99,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.quarterly]: {
    [PaymentOptionEnum.insurance]: 147,
    [PaymentOptionEnum.self_pay]: 297,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.semi_annual]: {
    [PaymentOptionEnum.insurance]: 294,
    [PaymentOptionEnum.self_pay]: 594,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.annual]: {
    [PaymentOptionEnum.insurance]: 590,
    [PaymentOptionEnum.self_pay]: 990,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.threeyear]: {
    [PaymentOptionEnum.insurance]: 1470,
    [PaymentOptionEnum.self_pay]: 2970,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.unknown]: {
    [PaymentOptionEnum.insurance]: 0,
    [PaymentOptionEnum.self_pay]: 0,
    [PaymentPeriodEnum.unknown]: 0,
  },
};

export const foodPaymentPeriodPriceMap = {
  [PaymentPeriodEnum.monthly]: {
    [PaymentOptionEnum.insurance]: 149,
    [PaymentOptionEnum.self_pay]: 199,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.annual]: {
    [PaymentOptionEnum.insurance]: 1490,
    [PaymentOptionEnum.self_pay]: 1990,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.unknown]: {
    [PaymentOptionEnum.insurance]: 0,
    [PaymentOptionEnum.self_pay]: 0,
    [PaymentPeriodEnum.unknown]: 0,
  },
};

export const paymentPeriodOptionsWithPriceInsurance = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text: `${value} $${paymentPeriodPriceMap[value][PaymentOptionEnum.insurance]}`,
  value: key,
}));

export const paymentPeriodOptionsWithPriceSelfPay = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text:
    value === PaymentPeriodEnum.unknown
      ? value
      : `${value} $${paymentPeriodPriceMap[value][PaymentOptionEnum.self_pay]}`,
  value: key,
}));

export const getPaymentPeriodWithPrice = function (patient: PatientType): string {
  if (!patient.paymentPeriod) {
    return '';
  }

  const selectedPaymentPeriod = PaymentPeriodEnum[patient.paymentPeriod];
  if (selectedPaymentPeriod === PaymentPeriodEnum.unknown) {
    return '';
  }

  const selectedPaymentOption = PaymentOptionEnum[patient.user.preferredPaymentOption];
  if (selectedPaymentOption === PaymentOptionEnum.unknown) {
    return `${selectedPaymentPeriod}`;
  }

  return `${selectedPaymentPeriod} $${paymentPeriodPriceMap[selectedPaymentPeriod][selectedPaymentOption]}`;
};

export const getFoodPaymentPeriodWithPrice = function (patient: PatientType): string {
  if (!patient) {
    return '';
  }

  const selectedPaymentPeriod = PaymentPeriodEnum[patient.paymentPeriod];
  if (selectedPaymentPeriod === PaymentPeriodEnum.unknown) {
    return '';
  }

  const selectedPaymentOption = PaymentOptionEnum[patient.user.preferredPaymentOption];
  if (
    selectedPaymentOption === PaymentOptionEnum.unknown ||
    selectedPaymentPeriod === PaymentPeriodEnum.quarterly ||
    selectedPaymentPeriod === PaymentPeriodEnum.semi_annual ||
    selectedPaymentPeriod === PaymentPeriodEnum.threeyear
  ) {
    return `${selectedPaymentPeriod}`;
  }

  return `${selectedPaymentPeriod} $${foodPaymentPeriodPriceMap[selectedPaymentPeriod][selectedPaymentOption]}`;
};
