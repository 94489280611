import gql from 'graphql-tag';

const TAG = gql`
  fragment Tag on TagType {
    id
    kind
    text
    isHidden
  }
`;
// todo: progressStage - crashes backend
// Error: GraphQL error: Enum 'PatientsTagProgressStageChoices' cannot represent value: <PatientProgressStage instance>

export { TAG };
