import { TaskQueue } from '@/services/task/TaskQueue';

const RequestsQueueService: TaskQueue = new TaskQueue();

const ChatsRequestsQueueService: TaskQueue = new TaskQueue();
const GenerateMessageChatRequestsQueueService: TaskQueue = new TaskQueue();
const ChatRequestsQueueService: TaskQueue = new TaskQueue();
const ClientRequestsQueueService: TaskQueue = new TaskQueue();
const FullClientRequestsQueueService: TaskQueue = new TaskQueue();
const UploadUrlRequestsQueueService: TaskQueue = new TaskQueue();

export {
  RequestsQueueService,
  ChatsRequestsQueueService,
  GenerateMessageChatRequestsQueueService,
  ChatRequestsQueueService,
  ClientRequestsQueueService,
  FullClientRequestsQueueService,
  UploadUrlRequestsQueueService,
};
