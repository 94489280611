
import { defineComponent, getCurrentInstance, type PropType } from 'vue';

import parseAsString from 'lodash/toString';

import { ClipboardService } from '@/services/ClipboardService';

import { showError, showMessage } from '@/components/errors';

const About = defineComponent({
  props: {
    data: {
      default: (): unknown[] => [],
      required: false,
      type: Array as PropType<unknown[]>,
    },
  },
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const copyText = async (action: any): Promise<void> => {
      const formattedDescription: string = parseAsString(action?.description);
      if (!formattedDescription.length) {
        return;
      }

      // this will return source string of none " present
      // returns nothing if only one " present
      // if multiple quotes exists - return only 1st encountered
      // prettier-ignore
      const textFromQuotes: string = formattedDescription
        .match(/(?:"[^"]*"|^[^"]*$)/)[0]
        .replace(/"/g, '');

      if (!textFromQuotes.length) {
        return;
      }

      if (await ClipboardService.write(textFromQuotes)) {
        showMessage(vm, 'Copied!');
        return;
      }

      showError(vm, 'Sorry, cannot copy');
    };

    const showCopyButton = (action: any): boolean => {
      return action?.kind === 'prescribe_in_dosespot' && !!action?.description?.length;
    };

    return { showCopyButton, copyText };
  },
});

export default About;
