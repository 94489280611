import { getCurrentInstance } from 'vue';

let _root: ReturnType<typeof getCurrentInstance>['proxy']['$root'] = null;
export function useRoot() {
  return _root ? _root : (_root = getCurrentInstance()?.proxy?.$root);
}

export function useRootInstall($root: typeof _root): void {
  _root = $root;
}
