import gql from 'graphql-tag';

import { TAG } from './fragments/TAG';

const GET_TAGS = gql`
  ${TAG}
  query GetTags {
    allTags {
      ...Tag
    }
  }
`;

export { GET_TAGS };
