import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const UPDATE_NOTE = gql`
  ${CHAT}
  ${MESSAGE}
  mutation UpdateNote(
    $attachmentIds: [UUID]
    $content: String!
    $messagingNoteId: UUID!
    $limit: Int
    $onlyMessagesAfter: DateTime
  ) {
    updateMessagingNote(
      attachmentIds: $attachmentIds
      content: $content
      messagingNoteId: $messagingNoteId
      limit: $limit
      onlyMessagesAfter: $onlyMessagesAfter
    ) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { UPDATE_NOTE };
