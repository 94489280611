
import type { SetupContext } from 'vue';
import { computed, defineComponent, toRef } from 'vue';

import { InboxStafferOrTeamOptionModel, isSeparator, useMessagingStore } from '~/messaging';

const ChatInboxSetStafferOrTeam = defineComponent({
  emits: ['update-inbox'],
  setup(_props, { emit }: SetupContext) {
    const messagingStore = useMessagingStore();
    const inboxFilterOptions = toRef(messagingStore, 'inboxStafferOrTeamOptions');

    const { setCurrentStafferOrTeam } = messagingStore;

    const dropdownIcon = computed(
      (): InboxStafferOrTeamOptionModel['icon'] =>
        inboxFilterOptions.value.find((option: InboxStafferOrTeamOptionModel) => option.selected)?.icon ||
        'people-group'
    );

    const changeInboxFilter = (option: InboxStafferOrTeamOptionModel): void => {
      setCurrentStafferOrTeam(option.key);

      emit('update-inbox');
    };

    return { inboxFilterOptions, dropdownIcon, changeInboxFilter, isSeparator };
  },
});

export default ChatInboxSetStafferOrTeam;
