
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, toRef, watch } from 'vue';
import { Builder } from 'builder-pattern';

import gql from 'graphql-tag';

import merge from 'lodash/merge';

import {
  ChatArgumentsModel,
  ChatModel,
  ChatsCacheService,
  getReadableDateTime,
  useMessaging,
  useMessagingApi,
  useMessagingStore,
} from '~/messaging';

import { useApollo } from '@/composables/atoms/useApollo';

import BadgeDuplicatedPhoneNumber from '@/components/BadgeDuplicatedPhoneNumber.vue';

import { debouncePromiseWithCancel } from '@/tools/debouncePromiseWithCancel';

import { showError } from '@/components/errors';

import { AccountType, ChatType } from '@/types';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

const ChatLeadProfile = defineComponent({
  components: { BadgeDuplicatedPhoneNumber },
  emits: ['re-render'],
  setup() {
    const { mutate } = useApollo();

    const vm = getCurrentInstance?.()?.proxy;

    const loadingLead = ref<boolean>(false);
    const loadingRegister = ref<boolean>(false);
    const clientAccount = ref<AccountType>({});

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');

    const { updateUrl } = useMessaging();
    const { getFullClientAccount, getChat } = useMessagingApi();

    const chat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());
    const formattedClientAccount = computed((): Array<Record<'name' | 'value', string>> => {
      const result: Array<Record<'name' | 'value', string>> = [
        {
          name: 'Registered at',
          value: getReadableDateTime(clientAccount.value.createdAt) || '-',
        },
        {
          name: 'Account active',
          value: clientAccount.value.isActive ? 'yes' : 'no',
        },
        {
          name: 'Account first name',
          value: clientAccount.value.firstName || '-',
        },
        {
          name: 'Account last name',
          value: clientAccount.value.lastName || '-',
        },
        {
          name: 'Account email',
          value: clientAccount.value.email || '-',
        },
        {
          name: 'Account phone',
          value: clientAccount.value.phone || '-',
        },
        {
          name: 'Account state',
          value: clientAccount.value.state || '-',
        },
        {
          name: 'Unsubscribed from sms',
          value: clientAccount.value.unsubscribedSms ? ' <strong>yes</strong>' : 'no',
        },
        {
          name: 'Unsubscribed from email',
          value: clientAccount.value.unsubscribedEmail ? ' <strong>yes</strong>' : 'no',
        },
      ];

      if (clientAccount.value.checkoutCompletedAt) {
        result.push({
          name: 'Checkout finished at',
          value: getReadableDateTime(clientAccount.value.checkoutCompletedAt),
        });
      } else {
        result.push({
          name: 'Checkout <strong>not</strong> finished',
          value: '-',
        });
      }

      if (clientAccount.value.medhistoryCompletedAt) {
        result.push({
          name: 'Medical history finished at',
          value: getReadableDateTime(clientAccount.value.medhistoryCompletedAt),
        });
      } else {
        result.push({
          name: 'Medical history <strong>not</strong> finished',
          value: '-',
        });
      }

      if (clientAccount.value.preclinicalCompletedAt) {
        result.push({
          name: 'Preclinical finished at',
          value: getReadableDateTime(clientAccount.value.preclinicalCompletedAt),
        });
      } else {
        result.push({
          name: 'Preclinical <strong>not</strong> finished',
          value: '-',
        });
      }

      return result;
    });

    const registerPatient = async (): Promise<boolean> => {
      loadingRegister.value = true;
      const { key: chatKey, id: chatId } = chat.value;

      try {
        await mutate<{ registerPatient: { id?: string } }>({
          mutation: gql`
            mutation RegisterPatient($clientId: UUID!) {
              registerPatient(clientId: $clientId) {
                id
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            clientId: chat.value.clientId,
          },
        });

        if (chatKey !== chat.value.key) {
          return true;
        }

        const chatType: ChatType | void = await getChat(Builder(ChatArgumentsModel).id(chat.value.id).build());
        if (!chatType || !chatType?.patientId?.length) {
          return false;
        }

        if (chatKey !== chat.value.key) {
          return true;
        }

        ChatsCacheService.set(
          chat.value.key,
          merge(new ChatModel(), ChatsCacheService.get(chat.value.key), {
            patientId: chatType?.patientId,
          } as Partial<ChatModel>)
        );

        ChatsCacheService.set(chat.value.key + '+1', ChatsCacheService.get(chat.value.key));
        messagingStore.setActiveChat(chat.value.key + '+1');

        await updateUrl(ChatsCacheService.get(chat.value.key));
        await nextTick();
        if (chatId !== chat.value.id) {
          return true;
        }

        messagingStore.setActiveChat(chat.value.key);
        return true;
      } catch (error: unknown) {
        console.error('registerPatient error:', error);
        showError(vm, `Failed to register patient for ${chat.value.id}. ${parseGraphQLErrorsAsText(error)}`);

        return false;
      } finally {
        loadingRegister.value = false;
      }
    };

    const debouncedGetFullClientAccount = debouncePromiseWithCancel(getFullClientAccount);

    const updateClientAccount = async () => {
      loadingLead.value = false;
      debouncedGetFullClientAccount.cancel();
      clientAccount.value = {};

      if (!chat.value.clientId.length) {
        return;
      }

      loadingLead.value = true;
      try {
        const account: AccountType | void = await debouncedGetFullClientAccount(chat.value.clientId);
        if (!account || !account?.id?.length) {
          return;
        }

        clientAccount.value = account;
      } catch (error: unknown) {
        console.error('updateClientAccount', error);
        showError(vm, `Failed to load lead profile for ${chat.value.displayName}. ${parseGraphQLErrorsAsText(error)}`);
      } finally {
        loadingLead.value = false;
      }
    };

    watch(chat, updateClientAccount, { deep: true, immediate: true });
    onMounted(updateClientAccount);

    return {
      loadingLead,
      loadingRegister,
      chat,
      formattedClientAccount,

      registerPatient,
    };
  },
});

export default ChatLeadProfile;
