import gql from 'graphql-tag';

import { MESSAGE_TEMPLATE } from './fragments/MESSAGE_TEMPLATE';

const UPDATE_MESSAGE_TEMPLATE = gql`
  ${MESSAGE_TEMPLATE}
  mutation UpdateMessageTemplate(
    $id: UUID!
    $body: String
    $description: String
    $name: String
    $subject: String
    $text: String
  ) {
    updateMessageTemplate(
      id: $id
      body: $body
      description: $description
      name: $name
      subject: $subject
      text: $text
    ) {
      ...MessageTemplate
    }
  }
`;

export { UPDATE_MESSAGE_TEMPLATE };
