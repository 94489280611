import { Action } from '../types/action';

const COMPONENT_NAME: 'ChatMessageContextMenu' = 'ChatMessageContextMenu' as const;

const menuOptionsNames: Record<Action, string> = {
  [Action.DELETE]: 'Delete message',
  [Action.UPDATE]: 'Update message',
  [Action.REPLY_TO]: 'Reply to message',
  [Action.MARK_AS_IMPORTANT]: 'Mark as important',
  [Action.MARK_AS_UNIMPORTANT]: 'Mark as unimportant',
};

export { COMPONENT_NAME, menuOptionsNames };
