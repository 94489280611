enum EventTypeEnum {
  USER_REGISTERED = 'user_registered',
  USER_PASSWORD_RESET_REQUEST = 'user_password_reset_request',
  USER_PASSWORD_RESET = 'user_password_reset',
  USER_EMAIL_CHANGED = 'user_email_changed',
  USER_PHONE_CHANGED = 'user_phone_changed',
  USER_PASSWORDLESS_LOGIN_REQUEST = 'user_passwordless_login_request',
  USER_LOGGED_IN = 'user_logged_in',
  USER_DROPS_PAYMENT_METHOD_CHANGED = 'user_drops_payment_method_changed',
  USER_TESTING_PAYMENT_METHOD_CHANGED = 'user_testing_payment_method_changed',
  USER_INSURANCE_ELIGIBILITY_CHANGED = 'user_insurance_eligibility_changed',
  USER_PAYMENT_CARD_REGISTERED = 'user_payment_card_registered',
  USER_INSURANCE_CARD_UPLOADED = 'user_insurance_card_uploaded',
  USER_ID_VERIFIED = 'user_id_verified',
  USER_ID_UNVERIFIED = 'user_id_unverified',
  PATIENT_REGISTERED = 'patient_registered',
  PATIENT_PRE_CLINICAL_DATA_VERIFIED = 'patient_pre_clinical_data_verified',
  PATIENT_INSURANCE_ELIGIBILITY_CHANGED = 'patient_insurance_eligibility_changed',
  PATIENT_DX_LABORATORY_CHANGED = 'patient_dx_laboratory_changed',
  PATIENT_SLIT_STATUS_CHANGED = 'patient_SLIT_status_changed',
  PATIENT_PROGRESS_STAGE_CHANGED = 'patient_progress_stage_changed',
  PATIENT_PHYSICIAN_CHANGED = 'patient_physician_changed',
  PATIENT_CALL_CENTER_AGENT_CHANGED = 'patient_call_center_agent_changed',
  PATIENT_MEDICAL_DOCUMENT = 'patient_medical_document',
  PATIENT_TAG_ADDED = 'patient_tag_added',
  PATIENT_TAG_REMOVED = 'patient_tag_removed',
  INTAKE_FORM_CHECKOUT_STARTED = 'intake_form_checkout_started',
  INTAKE_FORM_CHECKOUT_PAYMENT_RECEIVED = 'intake_form_checkout_payment_received',
  INTAKE_FORM_CHECKOUT_FINISHED = 'intake_form_checkout_finished',
  INTAKE_FORM_MED_HISTORY_STARTED = 'intake_form_med_history_started',
  INTAKE_FORM_MED_HISTORY_FINISHED = 'intake_form_med_history_finished',
  INTAKE_FORM_PRECLINICAL_STARTED = 'intake_form_preclinical_started',
  INTAKE_FORM_PRECLINICAL_FINISHED = 'intake_form_preclinical_finished',
  INTAKE_FORM_RCAT_STARTED = 'intake_form_rcat_started',
  INTAKE_FORM_RCAT_FINISHED = 'intake_form_rcat_finished',
  INTAKE_FORM_VISIT_SURVEY_STARTED = 'intake_form_visit_survey_started',
  INTAKE_FORM_VISIT_SURVEY_FINISHED = 'intake_form_visit_survey_finished',
  ALLERGY_TEST_FEE_CHARGED = 'allergy_test_fee_charged',
  VISIT_NOTE_ADDED = 'visit_note_added',
  VISIT_NOTE_UPDATED = 'visit_note_updated',
  VISIT_NOTE_REVIEWED = 'visit_note_reviewed',
  INTERNAL_NOTE_ADDED = 'internal_note_added',
  INTERNAL_NOTE_UPDATED = 'internal_note_updated',
  LAB_KIT_REGISTERED = 'lab_kit_registered',
  LAB_REQUISITION_CREATED = 'lab_requisition_created',
  LAB_REQUISITION_APPROVED = 'lab_requisition_approved',
  LAB_REQUISITION_DECLINED = 'lab_requisition_declined',
  LAB_REQUISITION_SENT = 'lab_requisition_sent',
  LAB_RESULT_RECEIVED = 'lab_result_received',
  LAB_RESULT_RELEASED = 'lab_result_released',
  LAB_RESULT_REJECTED = 'lab_result_rejected',
  ASTHMA_ACTION_PLAN_UPLOADED = 'asthma_action_plan_uploaded',
  APPOINTMENT_SCHEDULED = 'appointment_scheduled',
  APPOINTMENT_RESCHEDULED = 'appointment_rescheduled',
  APPOINTMENT_CANCELLED = 'appointment_cancelled',
  PRESCRIPTION_CREATED = 'prescription_created',
  PRESCRIPTION_SIGNED = 'prescription_signed',
  EPIPEN_PRESCRIPTION_SIGNED = 'epipen_prescription_signed',
  SUBSCRIPTION_CREATED = 'subscription_created',
  NEXT_SHIPMENT_DATE_CHANGED = 'next_shipment_date_changed',
  SUBSEQUENT_SHIPMENT_DATE_CHANGED = 'subsequent_shipment_date_changed',
  OUTREACH_MESSAGE_SENT = 'outreach_message_sent',
  AUTOMATED_WORKFLOW_STARTED = 'automated_workflow_started',
  VOICE_CALL_OUTCOME_LOGGED = 'voice_call_outcome_logged',
}

export { EventTypeEnum };
