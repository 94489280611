
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  ref,
  toRef,
} from 'vue';

import { Builder } from 'builder-pattern';

import type { BDropdown } from 'bootstrap-vue/src/components/dropdown';

import type { AssignChatRequest } from '~/messaging';
import {
  AssignOptionModel,
  CHAT_ACTION_LIMIT,
  ChatModel,
  ChatsCacheService,
  getChatAssignees,
  isSeparator,
  isTeamId,
  useMessaging,
  useMessagingApi,
  useMessagingShortcuts,
  useMessagingStore,
} from '~/messaging';

import { showError } from '@/components/errors';

import { useStaffersStore } from '@/stores/staffers';
import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';
import isBoolean from 'lodash/isBoolean';

const ChatAssignee = defineComponent({
  setup() {
    const vm = getCurrentInstance?.()?.proxy;

    const { onShortcut, offShortcut } = useMessagingShortcuts();

    const staffersStore = useStaffersStore();
    const staffers = toRef(staffersStore, 'staffers');

    const { addOrUpdateChat } = useMessaging();
    const { assignChat } = useMessagingApi();

    const messagingStore = useMessagingStore();
    const activeChat = toRef(messagingStore, 'activeChat');
    const assignOptions = toRef(messagingStore, 'assignOptions');

    const { increaseChatActions, decreaseChatActions } = messagingStore;

    const assignMultiple = ref<boolean>(false);
    const dropdownInstance = ref<InstanceType<typeof BDropdown>>(null);

    const chat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());

    const tooltipTitle = computed((): string => {
      if (!chat.value.staffersIds.length && !chat.value.teamsIds.length) {
        return 'Change assignee';
      }

      return getChatAssignees(chat.value.staffersIds, chat.value.teamsIds, staffers.value);
    });

    const changeAssignees = async (option: AssignOptionModel): Promise<void> => {
      // copy chat properly handle loading state
      const { id, key, updatedAt, staffersIds, teamsIds, displayName }: ChatModel = chat.value;
      if (!key) {
        console.error('changeAssignees chatModel not found', chat.value);
        return;
      }

      const request: AssignChatRequest = Builder<AssignChatRequest>()
        .chatId(id)
        .limit(CHAT_ACTION_LIMIT)
        .onlyMessagesAfter(updatedAt)
        .build();

      if (isTeamId(option.key)) {
        if (assignMultiple.value) {
          request.addTeams = teamsIds.includes(option.key) ? undefined : [option.key];
          request.removeTeams = teamsIds.includes(option.key) ? [option.key] : undefined;
        } else {
          if (teamsIds.includes(option.key)) {
            request.removeTeams = [option.key];
          } else {
            request.addTeams = [option.key];
            request.removeTeams = teamsIds;
            request.removeStaffers = staffersIds;
          }
        }
      } else {
        if (assignMultiple.value) {
          request.addStaffers = staffersIds.includes(option.key) ? undefined : [option.key];
          request.removeStaffers = staffersIds.includes(option.key) ? [option.key] : undefined;
        } else {
          if (staffersIds.includes(option.key)) {
            request.removeStaffers = [option.key];
          } else {
            request.addStaffers = [option.key];
            request.removeTeams = teamsIds;
            request.removeStaffers = staffersIds;
          }
        }
      }

      increaseChatActions(id);

      try {
        addOrUpdateChat(await assignChat(request));
      } catch (error) {
        showError(vm, `Failed to change assignees for ${displayName}. ${parseGraphQLErrorsAsText(error)}`, 50000);
      } finally {
        decreaseChatActions(id);
      }
    };

    const toggleDropdownShow = (): void => {
      if (!isBoolean(dropdownInstance.value?._data?.visible)) {
        return;
      }

      if (dropdownInstance.value._data.visible) {
        dropdownInstance.value?.hide?.();
        return;
      }

      dropdownInstance.value?.show?.();
    };

    const unmountListeners = (): void => {
      offShortcut('ASSIGN', toggleDropdownShow);
    };

    const mountListeners = (): void => {
      onShortcut('ASSIGN', toggleDropdownShow);
    };

    onBeforeMount(mountListeners);
    onActivated(mountListeners);

    onBeforeUnmount(unmountListeners);
    onDeactivated(unmountListeners);

    return {
      dropdownInstance,
      assignMultiple,

      tooltipTitle,
      assignOptions,

      isSeparator,
      changeAssignees,
    };
  },
});

export default ChatAssignee;
