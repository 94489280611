
import { computed, defineComponent, toRef, watch } from 'vue';

import { useModalsStore } from '@/stores/modals/store';

const COMPONENT_NAME: 'ModalController' = 'ModalController' as const;

// Bootstrap modal is laggy
const ModalController = defineComponent({
  setup() {
    const modalsStore = useModalsStore();
    const _hasOpenModals = toRef(modalsStore, 'hasOpenModals');

    const hasOpenModals = computed((): boolean => _hasOpenModals.value);

    const modalControllerClasses = computed((): string[] =>
      [hasOpenModals.value ? `${COMPONENT_NAME}--blur` : ''].filter(Boolean)
    );

    const disableDocumentScroll = () => {
      document.body?.classList?.add('no-scroll');
      document.documentElement?.classList?.add('no-scroll');
    };

    const enableDocumentScroll = () => {
      document.body?.classList?.remove('no-scroll');
      document.documentElement?.classList?.remove('no-scroll');
    };

    watch(
      hasOpenModals,
      (newHasOpenedModals: boolean = false) => {
        if (newHasOpenedModals) {
          disableDocumentScroll();
          return;
        }

        enableDocumentScroll();
      },
      { immediate: true }
    );

    return {
      modalControllerClasses,
    };
  },
});

export default ModalController;
