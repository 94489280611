import gql from 'graphql-tag';

import { CHAT } from './fragments/CHAT';
import { MESSAGE } from './fragments/MESSAGE';

const SNOOZE_CHAT = gql`
  ${CHAT}
  ${MESSAGE}
  mutation SnoozeChat($chatId: UUID!, $limit: Int, $note: String, $onlyMessagesAfter: DateTime, $untilTimestamp: Int!) {
    snoozeChat(
      chatId: $chatId
      limit: $limit
      note: $note
      onlyMessagesAfter: $onlyMessagesAfter
      untilTimestamp: $untilTimestamp
    ) {
      ...Chat
      messages {
        ...Message
      }
    }
  }
`;

export { SNOOZE_CHAT };
