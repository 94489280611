import type EditorJS from '@editorjs/editorjs';

import { getHtmlParser } from '../modules/parsers';
import { EditorInstancesService } from '../services/EditorInstances';

const getHtml = async (id: string): Promise<string> => {
  const editorInstance: EditorJS = EditorInstancesService.get(id);
  if (!editorInstance) {
    return '';
  }

  await editorInstance.isReady;
  return (await getHtmlParser()).parse(await editorInstance.save()).join('');
};

export { getHtml };
