const isLocalStorageSupported = (): boolean => {
  const testKey = '__storage_test__';

  try {
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);

    return true;
  } catch (error: any) {
    return false;
  }
};

export { isLocalStorageSupported };
