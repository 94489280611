import gql from 'graphql-tag';

const ACCOUNT = gql`
  fragment MessagingAccount on AccountType {
    id
    createdAt
    updatedAt

    firstName
    lastName
    email
    phone
    state
    role

    unsubscribedSms
    unsubscribedEmail
  }
`;

export { ACCOUNT };
