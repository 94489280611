import { Builder } from 'builder-pattern';
import isObject from 'lodash/isObject';
import parseAsString from 'lodash/toString';

import type { OutputData } from '@editorjs/editorjs';

import { MedicalDocumentModel } from '../models/MedicalDocumentModel';

import type { MedicalDocumentType } from '@/types';

import { getKey } from '@/tools/getKey';
import { getReadableDate } from '~/messaging';

const getDescription = (originalDescription: string): OutputData | void => {
  try {
    return JSON.parse(originalDescription);
  } catch (e) {
    console.info('No medical document description, failed to parse: ', e);
  }
};

const getDocumentModel = (documentType?: MedicalDocumentType | void, key?: string): MedicalDocumentModel => {
  if (!isObject(documentType)) {
    return new MedicalDocumentModel();
  }

  const createdAt: string = parseAsString(documentType?.createdAt);
  const updatedAt: string = parseAsString(documentType?.updatedAt);
  const deletedAt: string = parseAsString(documentType?.deletedAt);

  const originalDescription: string = parseAsString(documentType?.description);

  const clientId: string = parseAsString(documentType?.client?.id);
  const patientId: string = parseAsString(documentType?.patient?.id);
  const uploadedById: string = parseAsString(documentType?.uploadedBy?.id);
  const uploadedFilesIds: string[] =
    documentType?.uploadedFiles?.map((file: MedicalDocumentType['uploadedFiles'][0]) => parseAsString(file?.id)) || [];

  const createdAtReadable: string = getReadableDate(createdAt) || createdAt;

  return Builder(MedicalDocumentModel)
    .id(parseAsString(documentType?.id))
    .key(key || getKey(documentType))

    .createdAt(createdAt)
    .updatedAt(updatedAt)
    .deletedAt(deletedAt)

    .description(getDescription(originalDescription))
    .createdAtReadable(createdAtReadable)

    .createdById(uploadedById)
    .clientId(clientId)
    .patientId(patientId)
    .uploadedFilesIds(uploadedFilesIds.filter(Boolean))

    .build();
};

export { getDocumentModel };
