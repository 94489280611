import gql from 'graphql-tag';

const UPDATE_PATIENT_TAGS = gql`
  mutation ($patientId: UUID!, $tagIds: [UUID]!) {
    updatePatientTags(patientId: $patientId, tagIds: $tagIds) {
      status
    }
  }
`;

export { UPDATE_PATIENT_TAGS };
