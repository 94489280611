import gql from 'graphql-tag';

const UPLOAD_FILE = gql`
  mutation UploadFile($clientId: UUID!, $contentType: String!, $kind: UploadedFileKindEnum!, $name: String!) {
    uploadFile(client: $clientId, contentType: $contentType, kind: $kind, name: $name) {
      id
      s3Url
    }
  }
`;

export { UPLOAD_FILE };
