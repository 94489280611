export type LockWorkerOptions = { lockId: string; lockOnInit?: boolean; onLock?: (id?: string) => void };

export class LockWorker {
  public constructor(options: LockWorkerOptions = { lockId: 'LockWorker', lockOnInit: true }) {
    this._lockId = options?.lockId || 'LockWorker';

    if (options?.lockOnInit ?? true) {
      this.lock(options?.onLock);
    }
  }

  protected _isLocked: boolean = false;

  public get isLocked(): boolean {
    return this._isLocked;
  }

  protected _lockId: string;

  public get lockId(): string {
    return this._lockId;
  }

  public lock(onLock?: (lockId?: string) => void) {
    let resolve: () => void;

    const deferred = new Promise<void>((res) => {
      resolve = res;
    });

    navigator.locks.request(this.lockId, () => {
      this._isLocked = true;

      if (!!onLock && typeof onLock === 'function') {
        onLock(this.lockId);
      }

      return deferred;
    });

    return () => {
      this.unlock();
      resolve();
    };
  }

  public unlock(): void {
    this._isLocked = false;
  }
}
