import type { PropOptions } from 'vue';

import isNil from 'lodash/isNil';

import type { ConstructorPropValidator } from '../types';

// Vue will check prop with instanceof helper by default
// https://vuejs.org/guide/components/props.html#runtime-type-checks
const customPropValidator = <T>({ constructor }: ConstructorPropValidator<T>): PropOptions<T>['validator'] => {
  return (value: T): value is T => {
    return !isNil(value) && value instanceof constructor;
  };
};

export { customPropValidator };
