import { useStaffersStore } from '@/stores/staffers';
import { type BeforeFetchContext, createFetch } from '@vueuse/core';

const useFetch = createFetch({
  options: {
    async beforeFetch(ctx: BeforeFetchContext): Promise<BeforeFetchContext> {
      const { isTokenSet, currentStafferToken } = useStaffersStore();

      if (!isTokenSet) {
        return ctx;
      }

      return {
        ...ctx,
        options: {
          ...ctx.options,
          headers: {
            ...ctx.options.headers,
            Authorization: `JWT ${currentStafferToken}`,
          },
        },
      };
    },
  },
});

export { useFetch };
