import { CHATS_LIMIT } from '../defaults/limits';

import { ChatSegmentEnum } from '@/types';

// dont set chat/chats limit too small, because all returned messages could be with same timestamp, and in result, next query will be the same
// which will cause loop/recursion and no new messages after broken timestamp is received

class InboxArgumentsModel {
  public assigneeId: string | void;
  public segment: ChatSegmentEnum = ChatSegmentEnum.ASSIGNED;
  public limit: number = CHATS_LIMIT; // minimum is 1
  public offset: number = 0;
  public participantId: string | void;
  public search: string | void; // search by client name
  public sortBy: '-updated_at' | '-latestClientMessage.updated_at' | '-latestMessage.createdAt' =
    '-latestMessage.createdAt';
  public teamId: string | void;
  public latestMessagePriority: number = 0;
  public latestMessagePriorityGte: number = 0;
  public latestMessageCreatedAfter: string | void; // Datetime; Updated AFTER some time, means newer than timestamp
  public latestMessageCreatedBefore: string | void; // Datetime; Updated BEFORE some time, means older than timestamp
}

// sort by '-latestClientMessage.updated_at' - doesnt work properly

export { InboxArgumentsModel };
