
import { useStaffersStore } from '@/stores/staffers';
import type { ExternalLabRequisitionType, LabRequisitionType, PatientType, ScarletOrderType } from '@/types';
import { StafferType } from '@/types';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { getLaboratoryName } from '@/types/Laboratory';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    CollapsibleCard: () => import('@/components/CollapsibleCard.vue'),
    ScarletListOrders: () => import('@/components/ScarletListOrders/ScarletListOrders.vue'),
    TimedCardText: () => import('@/components/TimedCardText.vue'),
  },
  props: {
    patient: {
      default: (): PatientType => null,
      required: true,
      type: Object as PropType<PatientType>,
    },
  },
  emits: ['create', 'edit'],
  data() {
    return {} as { currentStaffer?: StafferType };
  },
  computed: {
    ...mapState(useStaffersStore, ['currentStaffer']),
    isScarletOrderFeatureEnabled(): boolean {
      return !!this.currentStaffer?.ehrBetaFeatures?.includes('scarlet-order');
    },
    externalRequisitions(): ExternalLabRequisitionType[] {
      return this.patient?.externalLabOrders || [];
    },
    integratedRequisitions(): LabRequisitionType[] {
      return this.patient?.laborder || [];
    },
    scarletOrders(): ScarletOrderType[] {
      return this.isScarletOrderFeatureEnabled ? this.patient?.scarletOrders || [] : [];
    },
    isEmpty(): boolean {
      return !(this.integratedRequisitions?.length || this.externalRequisitions?.length || this.scarletOrders?.length);
    },
  },
  methods: {
    emitCreate(): void {
      this.$emit('create');
    },
    emitEdit(externalRequisition: ExternalLabRequisitionType): void {
      this.$emit('edit', externalRequisition);
    },
    openScarletOrder(scarletOrderId?: ScarletOrderType['id']): void {
      const href = this.$router.resolve({
        name: 'scarletOrder',
        params: { patientId: this.patient.id, scarletOrderId },
        query: this.$route.query,
      }).href;

      window.open(href, '_blank');
    },

    getRequisitionDisplayString(externalRequisition: ExternalLabRequisitionType): string {
      return [
        externalRequisition.displayStatus,
        getLaboratoryName(externalRequisition.lab),
        externalRequisition.displayRegions,
      ]
        .filter((item) => item && item.length)
        .join(' | ');
    },
  },
});
