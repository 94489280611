export enum AppointmentKindEnum {
  CLINICAL_OFFICE_HOURS = 'clinical_office_hours',
  DROPS_OBSERVATION = 'drops_observation',
  EXAMONE_VISIT = 'examone_visit',
  FOLLOWUP = 'followup',
  GENERAL = 'general',
  GETLABS_VISIT = 'getlabs_visit',
  INITIAL_INSURANCE_CONSULTATION = 'initial_insurance_consultation',
  INSURANCE_FOLLOWUP = 'insurance_followup',
  LABCORP_VISIT = 'labcorp_visit',
  OTHER_LAB_VISIT = 'other_lab_visit',
  ONBOARDING_ASYNC_VISIT = 'onboarding_async_visit',
  QUEST_VISIT = 'quest_visit',
  QUICK_HELP = 'quick_help',
  SCREENING = 'screening',
  SUBSCRIPTION_PRECLINICAL = 'subscription_preclinical',
}

export type AppointmentKindEnumKey = keyof typeof AppointmentKindEnum;
