import gql from 'graphql-tag';

const SYSTEM_EVENT = gql`
  fragment SystemEvent on SystemEventType {
    id
    createdAt

    type
    source

    initiatedBy {
      id
    }

    user {
      id
      displayName
    }

    originalValue
    newValue
  }
`;

export { SYSTEM_EVENT };
