import { Builder } from 'builder-pattern';

import parseAsString from 'lodash/toString';

import { TeamModel } from '../models/TeamModel';

import type { TeamType } from '@/types';

class TeamsCache extends Map<TeamModel['id'], TeamModel> {
  public add(team?: TeamType | void): ReturnType<TeamsCache['get']> {
    if (!team || !team?.id) {
      console.warn('Team is invalid', team);
      return;
    }

    if (!this.has(team.id)) {
      this.set(
        team.id,
        Builder(TeamModel)
          .id(parseAsString(team.id))
          .name(parseAsString(team?.name))
          .assignedStaffersIds((team?.staffers || []).map((staffer) => staffer.id))
          .build()
      );
    }

    return this.get(team.id);
  }
}

const TeamsCacheService: TeamsCache = new TeamsCache();

export { TeamsCacheService };
