import { GET_CURRENT_STAFFER } from '../gql/GET_CURRENT_STAFFER';
import { GET_STAFFERS } from '../gql/GET_STAFFERS';
import { GOOGLE_LOGIN } from '../gql/GOOGLE_LOGIN';
import { LOGIN } from '../gql/LOGIN';
import { REFRESH_TOKEN } from '../gql/REFRESH_TOKEN';

import { useApollo } from '@/composables/atoms/useApollo';

import type { StafferType } from '@/types';
import { SLACK_LOGIN } from '@/stores/staffers';

const useStaffersApi = () => {
  const { loading, query, mutate } = useApollo();

  const getCurrentStaffer = async (): Promise<StafferType> => {
    const response = await query<{
      currentUser: StafferType;
    }>({
      query: GET_CURRENT_STAFFER,
      fetchPolicy: 'no-cache',
    });

    return response.data.currentUser;
  };

  const getStaffers = async (): Promise<StafferType[]> => {
    const response = await query<{
      staffers: StafferType[];
    }>({
      query: GET_STAFFERS,
      fetchPolicy: 'no-cache',
    });

    return response?.data?.staffers || [];
  };

  const refreshToken = async (oldToken: string): Promise<{ token: string; staffer: StafferType }> => {
    const response = await mutate<{ refreshToken: { token: string; staffer: StafferType } }>({
      mutation: REFRESH_TOKEN,
      fetchPolicy: 'no-cache',
      variables: {
        token: oldToken,
      },
    });

    return response.data.refreshToken;
  };

  const googleLogin = async (googleToken: string): Promise<{ token: string; staffer: StafferType }> => {
    const response = await mutate<{ googleLogin: { token: string; staffer: StafferType } }>({
      mutation: GOOGLE_LOGIN,
      fetchPolicy: 'no-cache',
      variables: {
        idToken: googleToken,
      },
    });

    return response.data.googleLogin;
  };

  const slackLogin = async (accessCode: string): Promise<{ token: string; staffer: StafferType }> => {
    const response = await mutate<{ slackLogin: { token: string; staffer: StafferType } }>({
      mutation: SLACK_LOGIN,
      fetchPolicy: 'no-cache',
      variables: {
        accessCode: accessCode,
      },
    });

    return response.data.slackLogin;
  };

  const login = async (email: string, password: string): Promise<{ token: string; staffer: StafferType }> => {
    const response = await mutate<{ login: { token: string; staffer: StafferType } }>({
      mutation: LOGIN,
      fetchPolicy: 'no-cache',
      variables: {
        email,
        password,
      },
    });

    return response.data.login;
  };

  return {
    loading,

    getCurrentStaffer,
    getStaffers,

    refreshToken,
    googleLogin,
    slackLogin,
    login,
  };
};

export { useStaffersApi };
