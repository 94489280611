import memoize from 'lodash/memoize';

import type { StafferType } from '@/types';

const getStafferFirstName = (staffer: StafferType | void): string => {
  if (staffer) {
    return (
      `${staffer?.displayNameShort || ''}`.trim() ||
      `${staffer?.firstName || ''}`.trim() ||
      `${staffer?.lastName || ''}`.trim() ||
      `${staffer?.displayName || ''}`.trim() ||
      staffer?.email ||
      staffer?.id ||
      'Failed to get staffer'
    );
  }

  return 'Failed to get staffer';
};

const getStafferFirstNameMemoized: typeof getStafferFirstName = memoize(getStafferFirstName);

export { getStafferFirstNameMemoized as getStafferFirstName };
