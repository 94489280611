import isEqual from 'lodash/isEqual';
import type { OutputData } from '@editorjs/editorjs';

const getDescriptionForSave = (newOutput: OutputData | void, oldOutput: OutputData | void): string | void => {
  try {
    // no new output, dont modify description
    if (!newOutput || !newOutput?.blocks?.length) {
      return;
    }

    // no old output, modify description
    if (!oldOutput) {
      return JSON.stringify(newOutput);
    }

    // descriptions are not the same, update description
    return isEqual(newOutput.blocks, oldOutput.blocks) ? undefined : JSON.stringify(newOutput);
  } catch (e) {
    console.error(e);
    return;
  }
};

export { getDescriptionForSave };
