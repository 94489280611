import gql from 'graphql-tag';

const IMPORT_INTERCOM_DATA = gql`
  mutation ($clientId: UUID, $patientId: UUID, $intercomId: String, $overrideClientIntercomId: Boolean) {
    importMessagesFromIntercom(
      clientId: $clientId
      patientId: $patientId
      intercomId: $intercomId
      overrideClientIntercomId: $overrideClientIntercomId
    ) {
      status
      importedConversationsCount
    }
  }
`;

export { IMPORT_INTERCOM_DATA };
