import EditorJS from '@editorjs/editorjs';

import { getHtml } from './getHtml';
import { setHtml } from './setHtml';
import { EditorInstancesService } from '../services/EditorInstances';

const addHtml = async (id: string, value: string): Promise<void> => {
  const editorInstance: EditorJS = EditorInstancesService.get(id);
  if (!editorInstance) {
    return;
  }

  await setHtml(id, (await getHtml(id)) + value);
};

export { addHtml };
