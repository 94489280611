import { MessageChannelEnum } from '@/types';

const isDoubleByte = (value: string): boolean => {
  for (let i = 0, n = value.length; i < n; i++) {
    if (value.charCodeAt(i) > 255) {
      return true;
    }
  }
  return false;
};

const getSmsMaxCharacterLength = (message: string): string => {
  const limitedMessageLength: string = 'Limited message length due to unicode symbols.';

  const containsUnicode: boolean = isDoubleByte(message);
  const maxMessageLength: 70 | 160 = containsUnicode ? 70 : 160;

  const messageSplit: number = Math.ceil(message.length / maxMessageLength);
  if (messageSplit > 1) {
    return `${message.length} characters used.<br/>This length would segment your message in ${messageSplit}.${
      containsUnicode ? '<br/>' + limitedMessageLength : ''
    }`;
  }

  return `${message.length} characters used.`;
};

const getComposerFooterText = (message: string, channel: MessageChannelEnum = MessageChannelEnum.UNKNOWN) => {
  if (channel === MessageChannelEnum.SMS || channel === MessageChannelEnum.EMAIL_AND_SMS) {
    return getSmsMaxCharacterLength(message);
  }

  return '';
};

export { getComposerFooterText };
