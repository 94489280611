import memoize from 'lodash/memoize';

import type { StafferType } from '@/types';

const getStaffer = (stafferId: StafferType['id'] | void, staffers: StafferType[]): StafferType | void => {
  return stafferId && stafferId?.length && staffers.find((staffer: StafferType) => staffer.id === stafferId);
};

const getStafferMemoized: typeof getStaffer = memoize(getStaffer);

export { getStafferMemoized as getStaffer };
