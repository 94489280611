
import { computed, defineComponent, nextTick, onMounted, PropType, ref, toRefs } from 'vue';

import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import parseAsNumber from 'lodash/toNumber';
import parseAsString from 'lodash/toString';

import type { BTable } from 'bootstrap-vue/src/components/table';

import type { PatientType } from '@/types';

import { getReadableDate } from '~/messaging';

const ANSWER_MISSING: '-' = '-' as const;
const SCORE_MISSING: -1 = -1 as const;

const AnsweredArtQuestionsCard = defineComponent({
  emits: ['click:art'],
  props: {
    answeredQuestions: {
      default: (): PatientType['answeredRcatQuestions'] => [],
      required: false,
      type: Array as PropType<PatientType['answeredRcatQuestions']>,
    },
    hideArt: {
      default: (): boolean => false,
      required: false,
      type: Boolean,
    },
  },
  setup(props: { answeredQuestions: PatientType['answeredRcatQuestions'] }, { emit }) {
    const { answeredQuestions } = toRefs<{ answeredQuestions: PatientType['answeredRcatQuestions'] }>(props);

    const tableElement = ref<(BTable & { $el: HTMLTableElement | void }) | void>(null);

    const isEmpty = computed((): boolean => !answeredQuestions.value?.length);

    const questionKeyToLongQuestion = computed(
      (): Record<string, string> =>
        Object.assign(
          {},
          ...(answeredQuestions.value || []).map((question: PatientType['answeredRcatQuestions'][0]) => ({
            [`${question?.question.id}`]: parseAsString(question?.question?.text || question?.question?.shortText),
          }))
        )
    );

    const questionKeyToShortQuestion = computed(
      (): Record<string, string> =>
        Object.assign(
          {},
          ...(answeredQuestions.value || []).map((question: PatientType['answeredRcatQuestions'][0]) => ({
            [`${question?.question.id}`]: parseAsString(question?.question?.shortText || question?.question?.text),
          }))
        )
    );

    const normalizedCellData = computed(
      (): {
        name: string;
        score: number | -1;
        textLong: string;
        textShort: string;
        variant: 'success' | 'danger' | '';
      }[][] =>
        (answeredQuestions.value || []).map((question: PatientType['answeredRcatQuestions'][0]) => {
          const answers: typeof normalizedCellData.value[0] = [
            {
              name: 'question',
              score: -1,
              textLong: parseAsString(question?.question?.id),
              textShort: parseAsString(question?.question?.id),
              variant: '',
            },
          ];

          (question?.answerList || []).forEach((answerInList: typeof question['answerList'][0], index: number) => {
            const text: string = formatAnswer(question.question.id, answerInList);

            const answer: typeof normalizedCellData.value[0][0] = {
              name: `${index + 1}`,
              score: getAnswerScore(answerInList?.answers?.[0]),
              textLong: text,
              textShort: text,
              variant: '',
            };

            if (!index) {
              answers.push(answer);
              return;
            }

            const previousScore: typeof normalizedCellData.value[0][0] | void = answers.findLast(
              (answerForScore) => answerForScore.score !== -1
            );

            if (!previousScore) {
              answers.push(answer);
              return;
            }

            answers.push({ ...answer, variant: getCellColor(answer.score, previousScore.score) });
          });

          return answers;
        })
    );

    const tableFields = computed((): { key: string; stickyColumn: boolean }[] =>
      (normalizedCellData.value?.[0] || []).map((cell: typeof normalizedCellData.value[0][0], index: number) => ({
        key: cell.name,
        stickyColumn: !index,
      }))
    );

    const tableItems = computed(
      (): Array<
        Record<string, string> & {
          _cellVariants: Record<string, string>;
        }
      > =>
        normalizedCellData.value.map((cells) => {
          const result: typeof tableItems.value[0] = { _cellVariants: {} } as typeof tableItems.value[0];

          cells.forEach((answer) => {
            result[answer.name] = answer.textShort;

            if (answer.variant) {
              result._cellVariants[answer.name] = answer.variant;
            }
          });

          return result;
        })
    );

    const columnWidths = computed((): Array<'180px' | '100px' | '21px'> => {
      return tableFields.value.map((field: typeof tableFields.value[0]) => {
        if (field.stickyColumn) {
          return '180px';
        }

        if (tableItems.value.find((item: typeof tableItems.value[0]) => item?.[field.key] !== '-')) {
          return '100px';
        }

        return '21px';
      });
    });

    const formatAnswer = (
      questionKey: PatientType['answeredRcatQuestions'][0]['question']['id'],
      answer: PatientType['answeredRcatQuestions'][0]['answerList'][0]
    ): string => {
      if (questionKey === 'tmuvsiydwr') {
        const answerText: string = getAnswerText(answer?.answers?.[0]);
        return answerText === ANSWER_MISSING ? answerText : getReadableDate(answerText) || ANSWER_MISSING;
      }

      switch (answer?.answers?.length || 0) {
        case 0: {
          return ANSWER_MISSING;
        }
        case 1: {
          return getAnswerText(answer?.answers?.[0]);
        }
      }

      return (answer?.answers || [])
        .map(
          (answers: PatientType['answeredRcatQuestions'][0]['answerList'][0]['answers'][0]) =>
            `• ${getAnswerText(answers)};`
        )
        .join('\n');
    };

    const getCellColor = (
      newScore: number | typeof SCORE_MISSING = SCORE_MISSING,
      previousScore: number | typeof SCORE_MISSING = SCORE_MISSING
    ): 'success' | 'danger' | '' => {
      if (newScore === SCORE_MISSING || previousScore === SCORE_MISSING || newScore === previousScore) {
        return '';
      }

      if (newScore > previousScore) {
        return 'success';
      }

      return 'danger';
    };

    const getAnswerScore = (answer: PatientType['answeredRcatQuestions'][0]['answerList'][0]['answers'][0]): number => {
      return answer?.isTextField ? SCORE_MISSING : parseAsNumber(parseAsString(answer?.value)) || SCORE_MISSING;
    };

    const getAnswerText = (answer: PatientType['answeredRcatQuestions'][0]['answerList'][0]['answers'][0]): string => {
      return answer?.isTextField
        ? answer?.value || ANSWER_MISSING
        : parseAsString(answer?.placeholder || answer?.value) || ANSWER_MISSING;
    };

    const scrollToTheEndOfTable = (): void => {
      if (tableElement.value && isNumber(tableElement.value?.$el?.scrollLeft)) {
        tableElement.value.$el.scrollLeft = Number.MAX_SAFE_INTEGER;
      }
    };

    const afterTableRendered = debounce(() => {
      nextTick(scrollToTheEndOfTable);
    }, 1);

    const onClickART = (): void => {
      emit('click:art');
    };

    onMounted(afterTableRendered);

    return {
      tableElement,

      isEmpty,

      afterTableRendered,

      columnWidths,

      tableFields,
      tableItems,

      questionKeyToLongQuestion,
      questionKeyToShortQuestion,
      onClickART,
    };
  },
});

export default AnsweredArtQuestionsCard;
