export abstract class ClientWorker {
  protected _UUID: string = Math.random().toString(32).slice(2);
  protected broadcast: BroadcastChannel = new BroadcastChannel('ServiceWorkerManager');

  public get UUID(): string {
    return this._UUID;
  }

  public postMessage(message: any): void {
    this.broadcast.postMessage(message);
  }

  public onMessage?<T = any>(event: MessageEvent<T>): void;

  public onCached?(registration: ServiceWorkerRegistration): void;
  public onError?(error: Error): void;
  public onOffline?(): void;
  public onReady?(registration: ServiceWorkerRegistration): void;
  public onRegistered?(registration: ServiceWorkerRegistration): void;
  public onUpdated?(registration: ServiceWorkerRegistration): void;
  public onUpdatefound?(registration: ServiceWorkerRegistration): void;
}
