import type { AccountType } from '@/types';

import { TeamsCacheService } from '../services/TeamsCache';
import type { TeamModel } from '../models/TeamModel';

const isTeamId = (id?: AccountType['id'] | TeamModel['id'] | void): id is TeamModel['id'] => {
  return id && id?.length && !!TeamsCacheService.has(id);
};

export { isTeamId };
