import Vue, { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';

import isEqual from 'lodash/isEqual';

import { MedicalDocumentModel } from '../models/MedicalDocumentModel';
import { MedicalFileModel } from '../models/MedicalFileModel';

import type { AccountType } from '@/types';

const useMedicalDocumentsStore = defineStore('medicalDocuments', () => {
  const _userId = ref<AccountType['id']>('');
  const _documentKey = ref<MedicalDocumentModel['key']>('');

  const _userDocuments = ref<Record<AccountType['id'], Array<MedicalDocumentModel['key']>>>({});
  const _documentFiles = ref<Record<MedicalDocumentModel['key'], Array<MedicalFileModel['id']>>>({});

  const currentPatientHasDocuments = computed((): boolean => !!currentMedicalDocuments.value.length);
  const currentDocumentHasFiles = computed((): boolean => !!currentDocumentFiles.value.length);

  const currentMedicalDocuments = computed(
    (): Array<MedicalDocumentModel['key']> => _userDocuments.value?.[_userId.value] || []
  );

  const currentDocumentFiles = computed(
    (): Array<MedicalFileModel['id']> => _documentFiles.value?.[_documentKey.value] || []
  );

  // todo: add documents by type

  const setCurrentUserId = (id: AccountType['id']): void => {
    _userId.value = id;
  };

  const setCurrentDocumentKey = (key: MedicalDocumentModel['key']): void => {
    _documentKey.value = key;
  };

  const addDocument = (userId: AccountType['id'], documentKey: MedicalDocumentModel['key']): void => {
    if (!_userDocuments.value?.[userId]?.length) {
      setUserDocuments(userId, [documentKey]);
      return;
    }

    if (_userDocuments.value[userId].includes(documentKey)) {
      return;
    }

    setUserDocuments(userId, [..._userDocuments.value[userId], documentKey]);
  };

  const removeDocument = (userId: AccountType['id'], newDocumentKey: MedicalDocumentModel['key']): void => {
    if (!_userDocuments.value?.[userId]?.length) {
      setUserDocuments(userId, []);
      return;
    }

    if (!_userDocuments.value[userId].includes(newDocumentKey)) {
      return;
    }

    setUserDocuments(
      userId,
      _userDocuments.value[userId].filter((documentKey: MedicalDocumentModel['key']) => documentKey !== newDocumentKey)
    );
  };

  const updateDocument = (
    userId: AccountType['id'],
    oldDocumentKey: MedicalDocumentModel['key'],
    newDocumentKey: MedicalDocumentModel['key']
  ): void => {
    if (!_userDocuments.value?.[userId]?.length) {
      setUserDocuments(userId, [newDocumentKey]);
      return;
    }

    const userDocuments: MedicalDocumentModel['key'][] = _userDocuments.value[userId].filter(
      (documentKey: MedicalDocumentModel['key']) => documentKey !== oldDocumentKey
    );

    // remove old document and return
    if (userDocuments.includes(newDocumentKey)) {
      setUserDocuments(userId, userDocuments);
      return;
    }

    addDocument(userId, newDocumentKey);
  };

  const setUserDocuments = (userId: AccountType['id'], documentKeys: Array<MedicalDocumentModel['key']>): void => {
    if (_userDocuments.value?.[userId]?.length && isEqual(_userDocuments.value[userId], documentKeys)) {
      return;
    }

    Vue.set(_userDocuments.value, userId, [...(_userDocuments.value?.[userId] || []), documentKeys]);
  };

  const addFile = (documentKey: MedicalDocumentModel['key'], fileId: MedicalFileModel['id']): void => {
    if (!_userDocuments.value?.[documentKey]?.length) {
      setDocumentFiles(documentKey, [fileId]);
      return;
    }

    if (_userDocuments.value[documentKey].includes(documentKey)) {
      return;
    }

    setDocumentFiles(documentKey, [..._userDocuments.value[documentKey], documentKey]);
  };

  const removeFile = (documentKey: MedicalDocumentModel['key'], fileId: MedicalFileModel['id']): void => {
    setDocumentFiles(
      documentKey,
      _documentFiles.value[documentKey].filter((documentKey: MedicalDocumentModel['key']) => documentKey !== fileId)
    );
  };

  const setDocumentFiles = (documentKey: MedicalDocumentModel['key'], fileIds: Array<MedicalFileModel['id']>): void => {
    if (_documentFiles.value?.[documentKey]?.length && isEqual(_documentFiles.value[documentKey], fileIds)) {
      return;
    }

    Vue.set(_documentFiles.value, documentKey, [...(_documentFiles.value?.[documentKey] || []), fileIds]);
  };

  const $reset = (): void => {
    _userId.value = '';
    _documentKey.value = '';

    _userDocuments.value = {};
    _documentFiles.value = {};
  };

  return {
    currentPatientHasDocuments,
    currentDocumentHasFiles,
    currentMedicalDocuments,
    currentDocumentFiles,

    setCurrentUserId,
    setCurrentDocumentKey,

    addDocument,
    removeDocument,
    updateDocument,
    setUserDocuments,

    addFile,
    removeFile,
    setDocumentFiles,

    $reset,
  };
});

export { useMedicalDocumentsStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useMedicalDocumentsStore, import.meta.webpackHot));
}
