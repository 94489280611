export enum MessageTypeIntercomEnum {
  ASSIGNMENT = 'assignment', //	An assignment of the conversation to an admin, or Nobody
  ASSIGN_AND_UNSNOOZE = 'assign_and_unsnooze', //	Assign a conversation and unsnooze it in one action.
  ASSIGN_AND_REOPEN = 'assign_and_reopen', //	Assign a conversation and unsnooze it in one action.
  AWAY_MODE_ASSIGNMENT = 'away_mode_assignment', //	Conversation has been assigned due to the admin being in away mode
  CLOSE = 'close', //	Identifies a conversation has been closed
  COMMENT = 'comment', //	Standard reply from customer or admin to a conversation
  CONVERSATION_RATING_CHANGED = 'conversation_rating_changed', //	A conversation rating has been changed, not this can only occur before the rating has been submitted. Once it has been submitted it cannot be changed
  CONVERSATION_RATING_REMARK_ADDED = 'conversation_rating_remark_added', //	A conversation remark has been added to the converation
  NOTE = 'note', //	A note created by an admin on the conversation. A note will only be viewable as an admin
  NOTE_AND_REOPEN = 'note_and_reopen', //	Add a note and reopen the conversations in the one action
  OPEN = 'open', //	Identifies a conversation has been opened
  PARTICIPANT_ADDED = 'participant_added', //	A participant has been added to a group conversation
  PARTICIPANT_REMOVED = 'participant_removed', //	A participant has been removed from a group conversation
  SNOOZED = 'snoozed', //	A conversation has been snoozed
  TIMER_UNSNOOZE = 'timer_unsnooze', //	Unsnooze a conversation after a set time
  UNSNOOZED = 'unsnoozed', //	A conversation has been unsnoozed
  BULK_REASSIGNMENT = 'bulk_reassignment',
  MESSAGE_ASSIGNMENT = 'message_assignment',
  NOTIFICATION = 'notification', // Messages send from Patient tracker
  SOURCE = 'source', //	custom type?
}
