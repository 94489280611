import Vue from 'vue';
import pinia from './plugins/pinia';

import App from './App.vue';
import router from './router';
import { apolloClient } from './api';

import './filters';

import VueApollo from 'vue-apollo';
import FontAwesome from '@/components/FontAwesome.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VeeValidatePlugin from './plugins/veeValidate';
import UseHeadPlugin from './plugins/useHead';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import * as Sentry from '@sentry/vue';
import './plugins/globalComponents';
import './registerServiceWorker';
import { useEnvironment } from '@/composables/useEnvironment';
import { useRootInstall } from '@/composables/atoms/useRoot';

Sentry.init({
  Vue,
  dsn: 'https://32d8fae4a50042b6bdd613d22fcc1dd5@o402527.ingest.sentry.io/4504442012368896',
  environment: useEnvironment(),
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Capture Replay for 100% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  // Called for message and error events
  beforeSend(event) {
    // block this error, because we are over sentry limit of errors with it
    if (event?.message?.includes('NotAllowedError: play()')) {
      return;
    }

    return event;
  },
});

// TODO: replace font-awesome with bootstrap vue icons
Vue.component('FontAwesome', FontAwesome);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(VeeValidatePlugin);
Vue.use(UseHeadPlugin);

Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

const app = new Vue({
  pinia,
  router,
  apolloProvider,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

useRootInstall(app);
