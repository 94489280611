
import type { SetupContext } from 'vue';
import { computed, defineComponent, toRef } from 'vue';

import { useMessagingStore } from '~/messaging';

import { watchDebounced } from '@vueuse/core';

const ChatSearchInChats = defineComponent({
  emits: ['update-inbox'],
  setup(_props, { emit }: SetupContext) {
    const messagingStore = useMessagingStore();

    const searchInChats = toRef(messagingStore, 'searchInChats');

    const { setSearchInChats } = messagingStore;

    const searchPlaceholder = computed((): string => {
      return 'Search in all inboxes';
    });

    const searchInChatsComputed = computed((): string => searchInChats.value);

    watchDebounced(
      searchInChatsComputed,
      async (newSearchInChats: string = '', oldSearchInChats: string = ''): Promise<void> => {
        if (newSearchInChats === oldSearchInChats) {
          return;
        }

        emit('update-inbox');
      },
      { debounce: 500 }
    );

    return { searchInChats, searchPlaceholder, setSearchInChats };
  },
});

export default ChatSearchInChats;
