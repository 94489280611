import gql from 'graphql-tag';

const REPLY_SUGGESTION = gql`
  fragment ReplySuggestion on ReplySuggestionType {
    id
    createdAt
    updatedAt
    text
  }
`;

export { REPLY_SUGGESTION };
