import type { MessageModel } from '../models/MessageModel';

const isAutomationBot = (authorId: MessageModel['authorId']) => {
  switch (authorId) {
    case '0189bc95-9364-2461-5111-c6b138f9b41e': // staging n8n-bot@curex.org
    case '0189bc8e-4d7d-38e2-3a7d-a12dd3b46137': // n8n-bot@curex.org
    case '0185f1df-4694-2906-9e9a-a8bc7675e8b7': // sam-bot@curex.org
    case '018ab8a2-76da-e8f4-7844-da7d0520202a': // ehr@curex.org
      return true;
  }

  return false;
};

export { isAutomationBot };
