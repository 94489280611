import type { PropOptions } from 'vue';

import isArray from 'lodash/isArray';

import isNil from 'lodash/isNil';
import type { MinMaxPropValidator } from '../types';

const arrayPropValidator = <T>(params: MinMaxPropValidator = {}): PropOptions<T>['validator'] => {
  return (value: T[]): value is T[] => {
    if (isNil(value) || !isArray(value)) {
      return false;
    }

    const minValid: boolean = isNil(params?.min) ? true : value.length >= params?.min;
    const maxValid: boolean = isNil(params?.max) ? true : value.length <= params?.max;

    return minValid && maxValid;
  };
};

export { arrayPropValidator };
