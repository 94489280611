import { format } from 'date-fns';

const getReadableDateTime = (timeAt: string) => {
  try {
    return format(new Date(timeAt), "do LLLL yyyy 'at' h:mm a");
  } catch (e) {
    console.warn('getReadableDateTime', e);
  }

  return '';
};

export { getReadableDateTime };
