import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import differenceBy from 'lodash/differenceBy';

import { sortUniqByCreatedAt } from '../helpers/sortUniqByCreatedAt';

import type { SystemEventType } from '@/types';

const useSystemEventsStore = defineStore('systemEvents', () => {
  const _systemEventsTotal = ref<number>(0);
  const _systemEvents = ref<SystemEventType[]>([]);

  const systemEventsTotal = computed((): number => _systemEventsTotal.value);
  const hasSystemEvents = computed((): boolean => !!systemEvents.value.length);

  const systemEvents = computed((): SystemEventType[] => _systemEvents.value);

  const addSystemEvent = (newSystemEvent: SystemEventType): void => {
    if (systemEvents.value.find((event: SystemEventType) => event.id !== newSystemEvent.id)) {
      return;
    }

    _systemEvents.value = sortUniqByCreatedAt([newSystemEvent, ..._systemEvents.value]);
  };

  const addSystemEvents = (newSystemEvents: SystemEventType[] = []): void => {
    const newEvents: SystemEventType[] = differenceBy(newSystemEvents, _systemEvents.value, 'id');
    if (!newEvents.length) {
      return;
    }

    _systemEvents.value = sortUniqByCreatedAt([...newEvents, ..._systemEvents.value]);
  };

  const setSystemEvents = (index: number, newSystemEvents: SystemEventType[] = []): void => {
    _systemEvents.value.splice(index, newSystemEvents.length - 1, ...newSystemEvents);
  };

  const setSystemEventsTotal = (newTotal: number = 0): void => {
    if (_systemEventsTotal.value === newTotal) {
      return;
    }

    _systemEventsTotal.value = newTotal;
    _systemEvents.value.unshift(...Array(newTotal - _systemEventsTotal.value).fill(undefined));
  };

  const $reset = (): void => {
    _systemEvents.value = [];
  };

  return {
    systemEventsTotal,

    hasSystemEvents,
    systemEvents,

    setSystemEventsTotal,
    setSystemEvents,

    addSystemEvent,
    addSystemEvents,

    $reset,
  };
});

export { useSystemEventsStore };

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useSystemEventsStore, import.meta.webpackHot));
}
