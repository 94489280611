import { differenceInCalendarDays } from 'date-fns';

import type { MessageModel } from '../models/MessageModel';

const shouldCreateDateMessage = (currentMessage: MessageModel, previousMessage: MessageModel): boolean => {
  try {
    return differenceInCalendarDays(new Date(currentMessage.createdAt), new Date(previousMessage.createdAt)) > 0;
  } catch (error) {
    console.error('shouldCreateDateMessage', error);
    return false;
  }
};

export { shouldCreateDateMessage };
