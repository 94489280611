import { EventSourceEnum } from '@/types';

const eventSourceEnumToReadableText: Record<EventSourceEnum, string> = {
  [EventSourceEnum.UNKNOWN]: 'Unknown',
  [EventSourceEnum.ADMIN]: 'Admin',
  [EventSourceEnum.EHR]: 'EHR',
  [EventSourceEnum.INTAKE_FORM]: 'Intake Form',
  [EventSourceEnum.MY_CUREX]: 'My Curex',
  [EventSourceEnum.SEVEN_DROPS]: 'Seven Drops',
  [EventSourceEnum.GETLABS]: 'Getlabs',
  [EventSourceEnum.SCARLET]: 'Scarlet',
  [EventSourceEnum.BIOTEK]: 'Biotek',
  [EventSourceEnum.PERSONA]: 'Persona',
  [EventSourceEnum.STRIPE]: 'Stripe',
  [EventSourceEnum.AWS_SES]: 'AWS SES',
  [EventSourceEnum.AWS_PINPOINT]: 'AWS Pinpoint',
  [EventSourceEnum.TWILIO]: 'Twilio',
  [EventSourceEnum.INTERCOM]: 'Intercom',
  [EventSourceEnum.N8N]: 'n8n',
  [EventSourceEnum.AIRCALL]: 'Aircall',
};

export { eventSourceEnumToReadableText };
