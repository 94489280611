import { Builder } from 'builder-pattern';

import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isObject from 'lodash/isObject';
import parseAsString from 'lodash/toString';

import { ClientModel } from '../models/ClientModel';
import { getKey } from '@/tools/getKey';

import type { AccountType } from '@/types';
import { Timezone } from '@/types';

const getClientModel = (patient?: AccountType | void, key?: string): ClientModel => {
  if (!isObject(patient)) {
    return new ClientModel();
  }

  const firstName: string = parseAsString(patient?.firstName);
  const lastName: string = parseAsString(patient?.lastName);

  const email: string = parseAsString(patient?.email);
  const phone: string = parseAsString(patient?.phone);

  const unsubscribedSms: boolean =
    isNull(patient?.unsubscribedSms) || isUndefined(patient?.unsubscribedSms) ? false : patient.unsubscribedSms;

  const unsubscribedEmail: boolean =
    isNull(patient?.unsubscribedEmail) || isUndefined(patient?.unsubscribedEmail) ? false : patient.unsubscribedEmail;

  const state: keyof typeof Timezone = parseAsString(patient?.state) as keyof typeof Timezone;
  const timezone: Timezone | '' = Timezone?.[state] || '';

  return Builder(ClientModel)
    .id(parseAsString(patient?.id))
    .key(key || getKey(patient))

    .createdAt(parseAsString(patient?.createdAt))
    .updatedAt(parseAsString(patient?.updatedAt))
    .deletedAt(parseAsString(patient?.deletedAt))

    .firstName(firstName)
    .lastName(lastName)

    .email(email.includes('@') ? email : '') // per backend implementation, there was intention to use phone numbers in email field
    .phone(phone)

    .unsubscribedSms(unsubscribedSms)
    .unsubscribedEmail(unsubscribedEmail)

    .state(state)
    .timezone(timezone)

    .build();
};

export { getClientModel };
