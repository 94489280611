import { LockWorker } from '@/services/LockWorker';
import { ClientWorker } from '@/clientWorkers';

export class ClientWorkerPollMessages extends ClientWorker {
  protected readonly timeoutInterval: number = 65000;
  protected timeoutID: number = 0;
  protected lockWorker?: LockWorker;
  protected unloadHandlerFn: EventListener | null = null;

  public onReady() {
    this.lock();
  }
  public onMessage<T = any>(event: MessageEvent<T & { type: string; payload: { status: string } }>) {
    if (!this.lockWorker?.isLocked) {
      return;
    }

    switch (event?.data?.type) {
      case 'Poll::Status':
        this.handlePollState(event?.data?.payload);
        break;
      case 'Poll::Sync':
        this.restartHeartbeat();
        break;
    }
  }

  protected statusPoll(): void {
    this.postMessage({ type: 'Poll::Status' });
  }

  protected startPoll(): void {
    this.postMessage({ type: 'Poll::Start' });
  }

  protected restartPoll(): void {
    this.postMessage({ type: 'Poll::Restart' });
  }

  protected stopPoll(): void {
    this.postMessage({ type: 'Poll::Stop' });
  }

  protected handlePollState(payload: { status: string }): void {
    if (payload?.status !== 'running') {
      this.startPoll();
      this.restartHeartbeat();
    }
  }

  protected startHeartbeat(): void {
    this.timeoutID = window.setTimeout(() => this.restartPoll(), this.timeoutInterval);
    this.unloadHandlerFn = () => this.stopHeartbeat();
    window.addEventListener('unload', this.unloadHandlerFn, { once: true, passive: true });
  }

  protected stopHeartbeat(): void {
    if (typeof this.unloadHandlerFn === 'function') {
      window.removeEventListener('unload', this.unloadHandlerFn);
    }

    window.clearTimeout(this.timeoutID);
    this.unloadHandlerFn = null;
  }

  protected restartHeartbeat(): void {
    this.stopHeartbeat();
    this.startHeartbeat();
  }

  protected lock(): void {
    if (!this.lockWorker) {
      this.lockWorker = new LockWorker({
        lockId: 'ClientWorkerPollMessages',
        lockOnInit: true,
        onLock: () => {
          this.statusPoll();
          this.startHeartbeat();
        },
      });
    }
  }
}
