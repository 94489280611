import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const REFRESH_TOKEN = gql`
  ${STAFFER}
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      staffer {
        ...Staffer
      }
    }
  }
`;

export { REFRESH_TOKEN };
