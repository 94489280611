/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { ServiceWorkerManagerService } from '@/services/ServiceWorkerManager';
import { ClientWorkerAuthToken, ClientWorkerUpdate } from '@/clientWorkers';
import { ClientWorkerMessagingSound, ClientWorkerPollMessages } from '~/messaging/clientWorkers';
import { useEnvironment } from '@/composables/useEnvironment';

if (useEnvironment() !== 'development' && 'serviceWorker' in navigator) {
  ServiceWorkerManagerService.subscribe(
    new ClientWorkerUpdate(),
    new ClientWorkerAuthToken(),
    new ClientWorkerMessagingSound(),
    new ClientWorkerPollMessages()
  );

  register(`${process.env.BASE_URL}service-worker.js`, ServiceWorkerManagerService);
}
