import type { MedicalDocumentType } from '@/types';

import { getDocumentModel } from '../helpers/getDocumentModel';

import type { MedicalDocumentModel } from '../models/MedicalDocumentModel';

import { getKey } from '@/tools/getKey';
import { FilesCacheService } from '~/medicalDocuments/services/FilesCache';

class DocumentsCache extends Map<MedicalDocumentModel['key'], MedicalDocumentModel> {
  private _idToKey: Map<MedicalDocumentModel['id'], MedicalDocumentModel['key']> = new Map();

  public getById(id: MedicalDocumentModel['id']): ReturnType<DocumentsCache['get']> {
    return this.get(this._idToKey.get(id) || id);
  }

  public add(document?: MedicalDocumentType | void): ReturnType<DocumentsCache['get']> {
    if (!document || !document?.id) {
      console.warn('Document is invalid', document);
      return;
    }

    const key: string = getKey(document);
    if (!this.has(key)) {
      const documentModel: MedicalDocumentModel = getDocumentModel(document, key);
      this.set(documentModel.key, documentModel);
      if (this._idToKey.has(documentModel.id)) {
        console.warn('Document ID collision:', documentModel.id, documentModel.key);
      }

      this._idToKey.set(documentModel.id, documentModel.key);
    }

    if (document?.uploadedFiles?.length) {
      document.uploadedFiles.forEach((uploadedFile: MedicalDocumentType['uploadedFiles'][0]) =>
        FilesCacheService.add(uploadedFile)
      );
    }

    return this.get(key);
  }
}

const DocumentsCacheService: DocumentsCache = new DocumentsCache();

export { DocumentsCacheService };
