import { EventTypeEnum } from '@/types';

const eventTypeEnumToReadableText: Record<EventTypeEnum, string> = {
  [EventTypeEnum.USER_REGISTERED]: 'User Registered',
  [EventTypeEnum.USER_PASSWORD_RESET_REQUEST]: 'User Password Reset Request',
  [EventTypeEnum.USER_PASSWORD_RESET]: 'User Password Reset',
  [EventTypeEnum.USER_EMAIL_CHANGED]: 'User Email Changed',
  [EventTypeEnum.USER_PHONE_CHANGED]: 'User Phone Changed',
  [EventTypeEnum.USER_PASSWORDLESS_LOGIN_REQUEST]: 'User Passwordless Login Request',
  [EventTypeEnum.USER_LOGGED_IN]: 'User Logged In',
  [EventTypeEnum.USER_DROPS_PAYMENT_METHOD_CHANGED]: 'User Drops Payment Method Changed',
  [EventTypeEnum.USER_TESTING_PAYMENT_METHOD_CHANGED]: 'User Testing Payment Method Changed',
  [EventTypeEnum.USER_INSURANCE_ELIGIBILITY_CHANGED]: 'User Insurance Eligibility Changed',
  [EventTypeEnum.USER_PAYMENT_CARD_REGISTERED]: 'User Payment Card Registered',
  [EventTypeEnum.USER_INSURANCE_CARD_UPLOADED]: 'User Insurance Card Uploaded',
  [EventTypeEnum.USER_ID_VERIFIED]: 'User ID Verified',
  [EventTypeEnum.USER_ID_UNVERIFIED]: 'User ID Unverified',
  [EventTypeEnum.PATIENT_REGISTERED]: 'Patient Registered',
  [EventTypeEnum.PATIENT_PRE_CLINICAL_DATA_VERIFIED]: 'Patient Pre-Clinical Data Verified',
  [EventTypeEnum.PATIENT_INSURANCE_ELIGIBILITY_CHANGED]: 'Patient Insurance Eligibility Changed',
  [EventTypeEnum.PATIENT_DX_LABORATORY_CHANGED]: 'Patient Dx Laboratory Changed',
  [EventTypeEnum.PATIENT_SLIT_STATUS_CHANGED]: 'Patient SLIT Status Changed',
  [EventTypeEnum.PATIENT_PROGRESS_STAGE_CHANGED]: 'Patient Progress Stage Changed',
  [EventTypeEnum.PATIENT_PHYSICIAN_CHANGED]: 'Patient Physician Changed',
  [EventTypeEnum.PATIENT_CALL_CENTER_AGENT_CHANGED]: 'Patient Call Center Agent Changed',
  [EventTypeEnum.PATIENT_MEDICAL_DOCUMENT]: 'Patient Medical Document',
  [EventTypeEnum.PATIENT_TAG_ADDED]: 'Patient Tag Added',
  [EventTypeEnum.PATIENT_TAG_REMOVED]: 'Patient Tag Removed',
  [EventTypeEnum.INTAKE_FORM_CHECKOUT_STARTED]: 'Intake Form Checkout Started',
  [EventTypeEnum.INTAKE_FORM_CHECKOUT_PAYMENT_RECEIVED]: 'Intake Form Checkout Payment Received',
  [EventTypeEnum.INTAKE_FORM_CHECKOUT_FINISHED]: 'Intake Form Checkout Finished',
  [EventTypeEnum.INTAKE_FORM_MED_HISTORY_STARTED]: 'Intake Form Medical History Started',
  [EventTypeEnum.INTAKE_FORM_MED_HISTORY_FINISHED]: 'Intake Form Medical History Finished',
  [EventTypeEnum.INTAKE_FORM_PRECLINICAL_STARTED]: 'Intake Form Preclinical Started',
  [EventTypeEnum.INTAKE_FORM_PRECLINICAL_FINISHED]: 'Intake Form Preclinical Finished',
  [EventTypeEnum.INTAKE_FORM_RCAT_STARTED]: 'Intake Form RCAT Started',
  [EventTypeEnum.INTAKE_FORM_RCAT_FINISHED]: 'Intake Form RCAT Finished',
  [EventTypeEnum.INTAKE_FORM_VISIT_SURVEY_STARTED]: 'Intake Form Visit Survey Started',
  [EventTypeEnum.INTAKE_FORM_VISIT_SURVEY_FINISHED]: 'Intake Form Visit Survey Finished',
  [EventTypeEnum.ALLERGY_TEST_FEE_CHARGED]: 'Allergy Test Fee Charged',
  [EventTypeEnum.VISIT_NOTE_ADDED]: 'Visit Note Added',
  [EventTypeEnum.VISIT_NOTE_UPDATED]: 'Visit Note Updated',
  [EventTypeEnum.VISIT_NOTE_REVIEWED]: 'Visit Note Reviewed',
  [EventTypeEnum.INTERNAL_NOTE_ADDED]: 'Internal Note Added',
  [EventTypeEnum.INTERNAL_NOTE_UPDATED]: 'Internal Note Updated',
  [EventTypeEnum.LAB_KIT_REGISTERED]: 'Lab Kit Registered',
  [EventTypeEnum.LAB_REQUISITION_CREATED]: 'Lab Requisition Created',
  [EventTypeEnum.LAB_REQUISITION_APPROVED]: 'Lab Requisition Approved',
  [EventTypeEnum.LAB_REQUISITION_DECLINED]: 'Lab Requisition Declined',
  [EventTypeEnum.LAB_REQUISITION_SENT]: 'Lab Requisition Sent',
  [EventTypeEnum.LAB_RESULT_RECEIVED]: 'Lab Result Received',
  [EventTypeEnum.LAB_RESULT_RELEASED]: 'Lab Result Released',
  [EventTypeEnum.LAB_RESULT_REJECTED]: 'Lab Result Rejected',
  [EventTypeEnum.ASTHMA_ACTION_PLAN_UPLOADED]: 'Asthma Action Plan Uploaded',
  [EventTypeEnum.APPOINTMENT_SCHEDULED]: 'Appointment Scheduled',
  [EventTypeEnum.APPOINTMENT_RESCHEDULED]: 'Appointment Rescheduled',
  [EventTypeEnum.APPOINTMENT_CANCELLED]: 'Appointment Cancelled',
  [EventTypeEnum.PRESCRIPTION_CREATED]: 'Prescription Created',
  [EventTypeEnum.PRESCRIPTION_SIGNED]: 'Prescription Signed',
  [EventTypeEnum.EPIPEN_PRESCRIPTION_SIGNED]: 'Epipen Prescription Signed',
  [EventTypeEnum.SUBSCRIPTION_CREATED]: 'Subscription Created',
  [EventTypeEnum.NEXT_SHIPMENT_DATE_CHANGED]: 'Next Shipment Date Changed',
  [EventTypeEnum.SUBSEQUENT_SHIPMENT_DATE_CHANGED]: 'Subsequent Shipment Date Changed',
  [EventTypeEnum.OUTREACH_MESSAGE_SENT]: 'Outreach Message Sent',
  [EventTypeEnum.AUTOMATED_WORKFLOW_STARTED]: 'Automated Workflow Started',
  [EventTypeEnum.VOICE_CALL_OUTCOME_LOGGED]: 'Voice Call Outcome Logged',
};

export { eventTypeEnumToReadableText };
