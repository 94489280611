import gql from 'graphql-tag';

import { STAFFER } from './fragments/STAFFER';

const GOOGLE_LOGIN = gql`
  ${STAFFER}
  mutation LoginWithGoogle($idToken: String!) {
    googleLogin(idToken: $idToken) {
      token
      staffer {
        ...Staffer
      }
    }
  }
`;

export { GOOGLE_LOGIN };
