import memoize from 'lodash/memoize';

import type { StafferType } from '@/types';

import { getStaffer } from './getStaffer';

const isStaffer = (stafferId?: StafferType['id'] | void, staffers: StafferType[] = []): boolean => {
  return !!getStaffer(stafferId, staffers);
};

const isStafferMemoized: typeof isStaffer = memoize(isStaffer);

export { isStafferMemoized as isStaffer };
