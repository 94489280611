const copyTextToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error: any) {
    console.log('copyTextToClipboard error:', error);
    return false;
  }
};

export { copyTextToClipboard };
